<div class="jumbotron">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h2 class="text-center">Set New Password</h2>
        <form [formGroup]="newPasswordForm" (ngSubmit)="onNewPasswordSubmit()">
          <div class="form-group">
            <label for="newPassword">New Password</label>
            <input type="password" formControlName="newPassword" id="newPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
              <div *ngIf="f.newPassword.errors.required">New Password is required</div>
              <div *ngIf="f.newPassword.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmNewPassword">Confirm New Password</label>
            <input type="password" formControlName="confirmNewPassword" id="confirmNewPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmNewPassword.errors }" />
            <div *ngIf="submitted && f.confirmNewPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmNewPassword.errors.required">Confirm New Password is required</div>
              <div *ngIf="f.confirmNewPassword.errors.passwordMismatch">Passwords must match</div>
            </div>
          </div>
          <div class="form-group text-center">
            <button [disabled]="loading" class="btn btn-std">Set New Password</button>
            <span *ngIf="loading">Your request is being processed.</span>
            <span *ngIf="success">
              <p>Your password has been successfully updated.</p>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
