import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alsterAPI_URL, leewayAPI_URL } from '../app-settings/api-urls';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserHandlingService {

  constructor(private httpClient: HttpClient,private authService: AuthService) { 
    
  }
  private readonly customerId = '5eed24ff4fe2bd337ccab5d3';
  private readonly apiToken = 'cqt1bii3tq5fuzo0c5id';

  sendSubscriptionRequest(companies, userData, userMail, emailMode): Promise<any> {
    let endpoint = ""

    let body = {
      userMail,
      userData: userData
    }
    if (emailMode === "subscription") { endpoint = "newsubscription", body["companies"] = companies }
    else if (emailMode === "companyintro") { endpoint = "newcompanyintro", body["message"] = companies }
    else if (emailMode === "requestcoverage") { endpoint = "requestcoverage", body["message"] = companies }
    else if (emailMode === "subscribetoevent") { endpoint = "subscribetoevent", body["events"] = companies }


    const url = alsterAPI_URL+'customsubscriptions/' + endpoint + '?'+ 'customerId=' + this.customerId;



    return this.httpClient.post(url, body).toPromise();

  }


}
