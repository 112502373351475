<div [ngClass]="{'border border-dark rounded' : adminMode && isEditing()}" id="{{data.blockTag}}">

  <app-markets-editor [presentationType]="presentationType" [data]="editorContent" [isEditorMode]="isEditorMode" class="bg-secondary"
    (editorClosedEventhandler)="editorClosedByChildEvent($event)" [editortouchedNOSAVE]="showLoadingIndicator"
    [altTag]="altTag">
  </app-markets-editor>

  <div class="btn btn-link mb-3 px-0" (click)="toggleShowAll()" *ngIf="truncateFlag && presentationType === '' && truncateFlag">
    <i class="fa fa-chevron-up px-2" *ngIf="showAll"></i>
    <i class="fa fa-chevron-down px-2" *ngIf="!showAll"></i>
    {{showAllLabel}}
  </div>

  <div class="row">
    <div class="col-12">
      <h3 *ngIf="adminMode">
        <hr>
      </h3>
    </div>
    <div class="col-2">
      <button *ngIf="adminMode && !changeMode && isEditing() && !reportPanelMode"
        class="btn blockButton  btn-sm form-control btn-inverted-gray" (click)="startChangeMode()">Show
        settings</button>
      <button *ngIf="adminMode && changeMode && isEditing() && !reportPanelMode"
        class="btn  blockButton btn-sm form-control btn-inverted-gray" (click)="endChangeMode()">Hide settings</button>
    </div>
    <div class="col-4"> <small *ngIf="this.showSaveErrMsg">Autosave-Error: Please click "Save text in editor".</small>
    </div>
    <div class="col-2" *ngIf="adminMode && changeMode && !reportPanelMode">
      <button
        ngbPopover="
        In order to delete blocks that have just been inserted, the page must be reloaded once. (reload or click on another subpage)"
        triggers="mouseenter:mouseleave" popoverTitle="Info"
        class="btn blockButton  btn-sm btn-inverted-gray form-control" (click)="deleteBlock()">Delete block </button>
    </div>
    <div class="col-3" *ngIf="adminMode && changeMode  && !reportPanelMode">

      <button class="btn btn-inverted-gray blockButton  form-control" (click)="updateBlockData(true, false)">Save all
        changes</button>
    </div>
    <div class="col-3 form-group form-inline" *ngIf="adminMode && changeMode  && !reportPanelMode">
      <label for="blockTagInput"><small>Block Tag</small></label>
      <input name="blockTagInput" class="form-control" type="text" [(ngModel)]="data.blockTag">
    </div>
    <div class="col-1" *ngIf="adminMode && changeMode"></div>
  </div>
  <div *ngIf="adminMode && changeMode" style="background-color: lightcyan" class="mx-2 my-2 px-4 py-4">
    <div class="row">
      <div *ngIf="!reportPanelMode" class="col-12 text-center">
        <h5>Setting for {{ translate(blockType) }}</h5>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-3"></div>
          <div [ngClass]="{'col-12':reportPanelMode, 'col-6':!reportPanelMode}" class=" text-center" ngbPopover="
          Starting the editor can take a few seconds." triggers="mouseenter:mouseleave" popoverTitle="Info">
            <button *ngIf="!isEditorMode" class="btn blockButton  btn-std" [ngClass]="{'btn-sm':!reportPanelMode}"
              (click)="startEditorMode()">Open
              editor</button>

          </div>
          <div class="col-3 text-center">
            <!-- <strong *ngIf="loadingEditor">Starting editor...</strong> -->
            <strong class="bg-warning" *ngIf="showLoadingIndicator">Saving...</strong>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
