import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";
import { SEOService } from "src/app/services/seo.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { DataServiceService } from "../../../services/data-service.service";
import { niceDate } from "../../../utilities/numberFormatting.util";
import { CompanyInfoService } from "src/app/services/company-info.service";
import { parseParams } from "src/app/utilities/general.util";
import { Tables } from "src/app/utilities/tables.util";
@Component({
  selector: "app-c-page",
  templateUrl: "./c-page.component.html",
  styleUrls: ["./c-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CPageComponent implements OnInit, OnDestroy {
  @ViewChild("companyLogo", { static: true })
  companyLogo: ElementRef<HTMLImageElement>;
  @ViewChild("complexHeader", { static: true }) complexHeaderDiv: ElementRef;
  mobile = false;
  adminMode = false;
  changeMode = false;
  archiveDate;
  archiveTitle;
  newArchiveTitle;
  initializedTitleChange = false;
  currentChangeIndex = 0;
  initializedArchiveDeletion = false;
  oldPageTypes = [];
  numArchivesShown = 4;
  esgRating: number = 0;
  esgRatingPercentString: string = "0%";
  calculations = {};
  videoURLObj = {};
  loadingNewData = true;
  recColors: object = {
    "Spec. BUY": "#10B981",
    BUY: "#10B981",
    HOLD: "#F59E0B",
    SELL: "#F87171",
  };

  quotes: any = {
    close: 0,
    change: 0,
  };
  rating = "n/a";
  curr = "EUR";

  public isHiddenCompany = false;
  public mapping: any = {};
  public companyData: any = {};
  public companyName: any = "WelcomePageStatic";
  public symbolExchange: any = "XXX:XX";
  public baseyear: number = 2022;
  public buildingBlocksInvestmentCase: Observable<any>;
  public buildingBlocksSWOT: Observable<any>;
  public buildingBlocksValuation: Observable<any>;
  public buildingBlocksResearch: Observable<any>;
  public buildingBlocksFinancials: Observable<any>;
  public buildingBlocksSnapshot: Observable<any>;
  public buildingBlocksCorporate: Observable<any>;
  public archives: Array<any> = [];
  public loadingIndicator = true;
  public isCompanyLogoFullWidth = false;
  title: string;
  transactionMode = false;
  public pushDownPadding = 0;

  lang = "eng";
  decimals = 1;

  infotextTileSettings = {
    height: 1,
    width: 1,
    tiles: [
      {
        headline: "Desktop Version",
        type: "text",
        content: [
          "This is the mobile version of the ResearchHub.",
          " For more graphs, tables and other cool features (like our DCF-model) head over to our desktop version.",
        ],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  };

  ICsettings = {
    showAll: false,
    expanded: false,
    showAllLabel: "Read more...",
  };

  researchTileSettings = {
    height: 1,
    width: 1,
    tiles: [
      {
        headline: "Interim Figures",
        type: "table-change",
        content: [
          "sales_q",
          "ebitda_q",
          "ebitda_margin_q",
          "ebit_q",
          "ebit_margin_q",
          "eps_q",
        ],
        height: 2,
        width: 1,
        mobile: true,
      },
    ],
  };

  keyfiguresTileSettings = {
    height: 2,
    width: 1,
    tiles: [
      {
        headline: "Key Figures",
        type: "figure-slider",
        content: [
          "sales",
          "growth_sales",
          "ebitda",
          "margin_ebitda",
          "ebit",
          "margin_ebit",
          "eps",
          "dps",
        ],
        height: 1,
        width: 1,
        link: "below",
        mobile: true,
      },
      {
        headline: "",
        type: "figure-graph",
        content: [],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  };

  chartTileSettingsIC = {
    height: 1,
    width: 2,
    tiles: [
      {
        headline: "Investment Case in Charts",
        type: "template-chart",
        content: [
          "pie timeseries",
          "Line Chart Regional Sales",
          "shareholders",
        ],
        height: 2,
        width: 1,
        mobile: false,
      },
      {
        headline: "Investment Case in Charts",
        type: "template-chart",
        content: ["Sales and earnings momentum", "ROCE chart", "FCF growth"],
        height: 2,
        width: 1,
        mobile: false,
      },
    ],
  };

  swotTileSettings = {
    height: 1,
    width: 1,
    tiles: [
      {
        headline: "ROCE",
        type: "figure",
        content: ["roce"],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  };

  valuationTileSettings = {
    height: 2,
    width: 1,
    tiles: [
      {
        headline: "DCF",
        type: "figure-slider",
        content: [
          "dcf_dcfps",
          "dcf_cost_of_debt",
          "dcf_term_val_wacc",
          "dcf_share_c",
        ],
        height: 1,
        width: 1,
        mobile: true,
      },
      {
        headline: "Multiples",
        type: "vertical-bars",
        content: ["ev_ebitda", "ev_ebit", "pe"],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  };

  financialTileSettings = {
    height: 3,
    width: 1,
    tiles: [
      {
        headline: "Financial Health",
        type: "figure-slider",
        content: ["la_eop", "net_debt_ebitda", "equity_ratio"],
        height: 1,
        width: 1,
        // link: 'right',
        mobile: true,
      },
      {
        headline: "CAPEX",
        type: "figure-graph",
        content: ["capex"],
        height: 1,
        width: 1,
        mobile: true,
      },
      {
        headline: "EBITDA-Margin",
        type: "figure-graph",
        content: ["margin_ebitda"],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  };

  currentURL = null;
  private unsubscribe$: Subject<void> = new Subject();
  JSON = null;
  currentCompanyCMSEntry = null;
  legacySubPages = [
    "research",
    "overview",
    "quality",
    "scores",
    "chart",
    "esg",
    "valuation",
    "financials",
    "news",
    "risk",
    "downloads",
    "snapshot",
    "growth",
    "Investment Case",
    "swotanalysis",
    "corporate",
    "swot",
    "investment-case",
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private companyInfoService: CompanyInfoService,
    private dataService: DataServiceService,
    private seoService: SEOService,
    private authService: AuthService,
    public utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef,
    private tableUtils: Tables
  ) {
    // catch and remove old subpages and old URL format
    this.activatedRoute.url.subscribe((parts) => {
      if (parts.length >= 3 && this.legacySubPages.includes(parts[1].path)) {
        let newUrl = "/" + parts[0].path + "/" + parts[2].path;
        this.router.navigate([newUrl]);
      }
    });

    this.JSON = JSON;
    this.oldPageTypes = this.companyInfoService.getAvailablePages();

    this.router.events.subscribe((params) => {
      this.onURLEvent(params);
    });
  }

  ngOnInit(): void {
    //this.initMiscData()
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 500);
  }

  ngAfterViewInit() {
    if (this.complexHeaderDiv.nativeElement.clientWidth > 992) {
      // 992px is bootstrap lg breakpoint
      this.pushDownPadding = this.complexHeaderDiv.nativeElement.offsetHeight;
      if (this.transactionMode) {
        this.pushDownPadding = this.pushDownPadding + 50;
      }
    }
    //this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  scrollToTop(ms) {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
        ms;
    });
  }

  initMiscData() {
    this.currentCompanyCMSEntry = null;
    this.currentCompanyCMSEntry = this.companyInfoService
      .getListOfCompanies()
      .find((item) => item.bloombergTicker === this.symbolExchange);
    this.chartTileSettingsIC.tiles[0].content =
      this.currentCompanyCMSEntry.charts[0].split(",");
    this.chartTileSettingsIC.tiles[1].content =
      this.currentCompanyCMSEntry.charts[1].split(",");
    if (this.currentCompanyCMSEntry) {
      this.isHiddenCompany = this.currentCompanyCMSEntry.hidden;
    }
    this.baseyear = this.companyInfoService.getCompanyDataByBloombergTicker(
      this.symbolExchange
    )?.baseyear;
    this.transactionMode = this.companyInfoService.getTransactionMode(); //unique
    this.dataService.getMapping().then((mapping) => {
      this.mapping = mapping;
    }); //unique
    this.getCompanyFigures();
    this.adminMode = this.authService.isAllowedCompany(this.symbolExchange); //unique
  }

  loadDataFromBE(newCompanyName, newParams) {
    this.buildingBlocksInvestmentCase =
      this.companyInfoService.getLayoutForCompany(
        this.companyInfoService.getBloombergTicker(newCompanyName),
        "investment-case",
        newParams.date,
        newParams.title,
        "cpage investment case"
      );
    this.buildingBlocksSWOT = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(newCompanyName),
      "swot",
      newParams.date,
      newParams.title,
      "cpage swot"
    );
    this.buildingBlocksValuation = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(newCompanyName),
      "valuation",
      newParams.date,
      newParams.title,
      "cpage valuation"
    );
    this.buildingBlocksResearch = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(newCompanyName),
      "research",
      newParams.date,
      newParams.title,
      "cpage research"
    );
    this.buildingBlocksFinancials = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(newCompanyName),
      "financials",
      newParams.date,
      newParams.title,
      "cpage financials"
    );
    this.buildingBlocksSnapshot = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(newCompanyName),
      "snapshot",
      newParams.date,
      newParams.title,
      "cpage snapshot shareholders"
    );
    this.buildingBlocksCorporate = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(newCompanyName),
      "corporate",
      newParams.date,
      newParams.title,
      "cpage corporate shareholders"
    );

    this.buildingBlocksValuation.subscribe((res) => {
      //console.log('RES', res)
    });

    this.buildingBlocksFinancials.subscribe((financialsPage) => {
      if (financialsPage) {
        for (let tile of this.financialTileSettings.tiles) {
          if (financialsPage.blocks.length > 1) {
            tile["decimals"] = financialsPage.blocks[1].data.numDecimals;
            this.decimals = financialsPage.blocks[1].data.numDecimals;
          }
        }
      }
      this.scrollToTop(500);
    });

    this.buildingBlocksResearch.subscribe((researchPage) => {
      if (researchPage) {
        this.title = researchPage.archiveTitle;
        this.loadArchives(researchPage);
      }
    });
  }

  handleHiddenCompany() {
    if (this.companyInfoService.companyIsHidden(this.symbolExchange)) {
      this.router.navigate(["/welcome"]);
    }
  }

  handleSEOSettings() {
    if (this.companyName !== "welcomePageStatic") {
      let description =
        "mwb research professional MiFID II compliant equity research for " +
        this.companyName +
        " (" +
        this.symbolExchange +
        "). Here you will find the equity research and much more. Financials, strengths and weaknesses (SWOT), downloads of company reports and presentation as well as, of course, our full research PDFs.";

      let title = "Research Hub: " + this.companyName;
      if (this.title) {
        title = this.title;
      }

      this.seoService.setMetaTags(title, description, this.router.url, null);
    } else {
      this.seoService.setMetaTags(null, null, this.router.url, null);
    }
  }

  onURLEvent(event) {
    if (event instanceof NavigationEnd && event.url !== this.currentURL) {
      this.loadingNewData = true;
      this.currentURL = event.url;
      let splitURL = event.url.split("/");
      if (splitURL[1] === "companies") {
        if (
          this.oldPageTypes.some((pageType) => event.url.includes(pageType))
        ) {
          this.router.navigate([
            "/companies/" + decodeURIComponent(splitURL[3].split(/#|\?/)[0]),
          ]);
        }
        this.currentCompanyCMSEntry = null;
        this.currentCompanyCMSEntry = this.companyInfoService
          .getListOfCompanies()
          .find((item) => item.bloombergTicker === this.symbolExchange);
        let newCompanyName = decodeURIComponent(splitURL[2].split(/#|\?/)[0]);
        let newParams = parseParams(splitURL[2].split(/#|\?/)[1]);
        this.archiveDate = newParams.date;
        this.archiveTitle = newParams.title;
        this.companyName = newCompanyName;
        this.symbolExchange =
          this.companyInfoService.getBloombergTicker(newCompanyName);
        this.loadDataFromBE(newCompanyName, newParams);
        this.initMiscData();

        this.handleSEOSettings();
        this.handleHiddenCompany();
      }
      //this.cdr.detectChanges();
    }
  }

  onEditModeChange(event, type: string) {
    this.changeMode = event;

    switch (type) {
      case "investment-case":
        this.buildingBlocksInvestmentCase =
          this.companyInfoService.getLayoutForCompany(
            this.symbolExchange,
            type,
            this.archiveDate,
            this.archiveTitle,
            "cpage investment case"
          );
        break;
      case "swot":
        this.buildingBlocksSWOT = this.companyInfoService.getLayoutForCompany(
          this.symbolExchange,
          type,
          this.archiveDate,
          this.archiveTitle,
          "cpage swot"
        );
        break;
      case "valuation":
        this.buildingBlocksValuation =
          this.companyInfoService.getLayoutForCompany(
            this.symbolExchange,
            type,
            this.archiveDate,
            this.archiveTitle,
            "cpage valuation"
          );
        break;
      case "research":
        this.buildingBlocksResearch =
          this.companyInfoService.getLayoutForCompany(
            this.symbolExchange,
            type,
            this.archiveDate,
            this.archiveTitle,
            "cpage research"
          );
        break;
      case "financials":
        this.buildingBlocksFinancials =
          this.companyInfoService.getLayoutForCompany(
            this.symbolExchange,
            type,
            this.archiveDate,
            this.archiveTitle,
            "cpage financials"
          );
        break;
      case "snapshot":
        this.buildingBlocksSnapshot =
          this.companyInfoService.getLayoutForCompany(
            this.symbolExchange,
            type,
            this.archiveDate,
            this.archiveTitle,
            "cpage snapshot shareholders"
          );
        break;
      case "corporate":
        this.buildingBlocksCorporate =
          this.companyInfoService.getLayoutForCompany(
            this.symbolExchange,
            type,
            this.archiveDate,
            this.archiveTitle,
            "cpage corporate"
          );
        break;
    }
  }

  loadArchives(cmsPage) {
    this.archives = [];
    let titles = {};
    if (cmsPage.archivedPages) {
      for (let archiveParams of cmsPage.archivedPages) {
        setTimeout(() => {
          this.loadArchive(archiveParams, titles);
        }, 500);
      }
    }
  }

  loadArchive(archiveParams, titles) {
    // let include = { //manually include archives that break the filtering system
    //   '15.06.2021 May 2021 traffic figures': true,

    // }

    let archivePage = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(this.companyName),
      "research",
      archiveParams.archiveDate,
      archiveParams.archiveTitle,
      "cpage research archive"
    );

    let include = {
      "13.09.2022 H1-Zahlen bestätigen Erholung; KAUFEN": true,
    };

    archivePage.subscribe((page) => {
      let title = "";
      let titleSplit = archiveParams.archiveTitle.split(" ");
      for (let i = 1; i < titleSplit.length; i++) {
        if (titleSplit[i] !== "-" || i > 2) {
          title = title + titleSplit[i] + " ";
        }
      }

      if (page.blocks) {
        let buttonIndex = page.blocks.findIndex(
          (block) => block.blockType === "button"
        );
        let downloadLink = page.blocks[buttonIndex].data.link;
        let downloadDate = page.blocks[buttonIndex].data.date;
        // let correctContent = this.decodeHtml(page.blocks[2].data.editorcontent).includes(title.trim());
        let correctContent = true;
        let correctDownloadLink = true;

        let splitDate = titleSplit[0].split(".");
        for (let dateComponent of splitDate) {
          let datePattern = "\\D" + dateComponent + "\\D";
          let datePattern2 = "\\D" + dateComponent.replace("0", "") + "\\D";

          if (
            !downloadLink ||
            (!downloadLink.match(datePattern) &&
              !downloadLink.match(datePattern2) &&
              !downloadDate.includes(dateComponent) &&
              !downloadDate.includes(dateComponent))
          ) {
            correctDownloadLink = false;
          }
        }

        if (
          (correctContent && correctDownloadLink) ||
          include[archiveParams.archiveTitle]
        ) {
          //check if archive is not broken
          let archive = {
            archiveDate: archiveParams.archiveDate,
            archiveTitle: archiveParams.archiveTitle,
            archivePage: archivePage,
            expanded: false,
          };

          archive["downloadLink"] = downloadLink;
          page.blocks.splice(buttonIndex, 1);
          archive.archivePage = of(page);

          if (!titles[title]) {
            titles[title] = true;
            this.archives.unshift(archive);
          }
        } else {
        }
        this.archives = this.archives.sort(
          (b, a) =>
            new Date(a.archiveDate).getTime() -
            new Date(b.archiveDate).getTime()
        );
      }
    });
  }

  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  getCompanyFigures() {
    this.esgRating = 0;
    this.esgRatingPercentString = "0%";
    this.quotes = {
      close: 0,
      change: 0,
    };
    this.rating = "n/a";

    this.dataService
      .getFinancialsNew(this.symbolExchange)
      .subscribe((res: any) => {
        this.loadingNewData = false;
        this.companyData = res;
        this.rating = res.general_information.cus_rating;
      });

    this.dataService
      .getLiveQuote(
        this.utilitiesService.getMappedExchange(this.symbolExchange),
        null
      )
      .subscribe((res: any) => {
        this.quotes = res;
        this.quotes = this.companyInfoService.changeCurrencies(
          [res],
          "code"
        )[0];
        this.cdr.detectChanges();

        setTimeout(() => {
          //really crappy hack but every other way I can think of is just shit so...
          this.cdr.detectChanges();
        }, 5000);
      });

    this.companyInfoService
      .getCompanyVideo(this.symbolExchange)
      .subscribe((res) => {
        this.videoURLObj = res;
        this.cdr.detectChanges();
      });

    // this.companyInfoService.getESGScore(this.symbolExchange).subscribe((res: any) => {
    //   if (res.rating.total !== null && res.rating.total !== 11.0177) {
    //     this.esgRating = Math.min(Math.max(res.rating.total, 0), 100);
    //     this.esgRatingPercentString = this.esgRating + "%";
    //   }
    // });
  }

  public getLogoForCompany(companyId: string): string {
    return this.companyInfoService.getLogoForCompany(companyId);
  }

  public getNewsQueryTerm(companyId: string): string {
    return this.companyInfoService.getNewsQueryForCompany(companyId);
  }

  public changeTitle(page, pagetype) {
    this.initializedTitleChange = false;
    if (this.newArchiveTitle && this.newArchiveTitle !== "") {
      page["newTitle"] = this.newArchiveTitle;
      page["companyId"] = this.symbolExchange;
      page["pageType"] = pagetype;
      this.dataService.changeTitle(page).subscribe(() => {});
    }
    this.newArchiveTitle = "";
  }

  public changeTitleMode(i, pageTitle) {
    this.initializedTitleChange = !this.initializedTitleChange;
    this.currentChangeIndex = i;
    this.newArchiveTitle = pageTitle;
  }

  public deleteArchive(page, pagetype) {
    this.initializedArchiveDeletion = false;
    page["companyId"] = this.symbolExchange;
    page["pageType"] = pagetype;
    this.dataService.deleteArchive(page).subscribe(() => {});
  }

  public cleanUrl(url) {
    return url.replace(/%20/g, " ").replace(/\?.+$/, "").replace("#X", "");
  }

  public niceDate(date) {
    return niceDate(date);
  }

  goToAnotherCompany(next) {
    let companies = this.companyInfoService
      .getListOfCompanies()
      .filter(
        (item) => item.hidden === false && item.name !== "welcomePageStatic"
      )
      .sort((a, b) =>
        a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
          ? 1
          : b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
          ? -1
          : 0
      );
    if (!this.isHiddenCompany) {
      let index = companies.findIndex(
        (x) => x.bloombergTicker === this.symbolExchange
      );

      if (next) {
        if (index < companies.length - 1) {
          return companies[index + 1].name;
        } else {
          return companies[0].name;
        }
      } else if (!next) {
        if (index > 0) {
          return companies[index - 1].name;
        } else {
          return companies[companies.length - 1].name;
        }
      } else {
        if (companies[index] !== undefined && companies[index] !== null) {
          return companies[index].name;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }

  getCompanyfiguresValue(key: string) {
    let value = "na";
    let offset = 0;

    let offsetPattern = /_\d$/;
    if (offsetPattern.test(key)) {
      let split = key.split("_");
      key = split[0];
      for (let i = 1; i < split.length - 1; i++) {
        key += "_" + split[i];
      }
      offset = parseInt(split[split.length - 1]);
    }

    if (this.mapping[key]) {
      let mappingEntry = this.mapping[key];

      if (mappingEntry.type === "VALUE") {
        value = this.tableUtils.getValue(
          this.companyData,
          mappingEntry,
          this.lang,
          this.decimals
        )[0];
      } else if (mappingEntry.type === "TIMESERIES") {
        value = this.tableUtils.getOneFromTimeseries(
          this.companyData,
          mappingEntry,
          offset,
          "value",
          this.lang,
          this.decimals
        )[0];
      }
    }

    return value;
  }
}
