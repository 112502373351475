import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CompanyInfoService } from "src/app/services/company-info.service";

@Component({
  selector: "app-book-a-meeting",
  templateUrl: "./book-a-meeting.component.html",
  styleUrls: ["./book-a-meeting.component.scss"],
})
export class BookAMeetingComponent implements OnInit {
  @Input() companyName: any;
  @Input() symbolExchange;

  lastname: any;
  firstname: any;
  email: any;
  requesterscompany: any;
  phonenumber: any;
  requiredFieldMissingInput = false;
  requestPending = false;
  requestSent = false;
  constructor(
    public activeModal: NgbActiveModal,
    private companyInfoService: CompanyInfoService
  ) {}

  ngOnInit(): void {}

  checkInput() {
    if (
      this.lastname === undefined ||
      this.firstname === undefined ||
      this.email === undefined ||
      this.requesterscompany === undefined
    ) {
      this.requiredFieldMissingInput = true;
    } else {
      this.requiredFieldMissingInput = false;
    }
  }

  sendNotification() {
    this.checkInput();
    if (!this.requiredFieldMissingInput) {
      this.sendPublishRequest();
    }
  }

  closeWindow() {
    this.activeModal.close();
  }

  public sendPublishRequest() {
    this.requestPending = true;
    this.companyInfoService
      .sendBookAMeetingRequestForNewResearch(
        this.companyName,
        this.lastname,
        this.firstname,
        this.email,
        this.requesterscompany,
        this.phonenumber
      )
      .then((res) => {
        this.requestPending = false;
        this.requestSent = true;
        // setTimeout(() => {
        //   this.requestSent = false;
        // }, 60000);
      });
  }
}
