import { Component, Input } from "@angular/core";

@Component({
  selector: "app-file-metadata",
  templateUrl: "./file-metadata.component.html",
  styleUrls: ["./file-metadata.component.scss"],
})
export class FileMetadataComponent {
  @Input() fileMetadata: any;
}
