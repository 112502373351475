import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import {
  NewUser,
  NewContact,
  investorTypes,
  PossibleCustomerTypes,
  InvestorProfileClass,
  Haltedauer,
  InvestorStil,
  Region,
  Size,
  TicketSize,
  AUM
} from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { checkValueInEnum } from 'src/app/utilities/general.util';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  loading = false;
  success = false;
  showAlert = false
  editingProfile = false;

  customerTypes: string[]
  investorProfileFields: string[]

  allNewsletters = null
  newslettersForLaterAutoSubscription = []
  profileOptions = {
    PROFIL_AUM: AUM,
    PROFIL_GROESSE: Size,
    PROFIL_HALTEDAUER: Haltedauer,
    PROFIL_INVESTITIONSSTIL: InvestorStil,
    PROFIL_INVESTORENTYP: investorTypes,
    PROFIL_REGION: Region,
    PROFIL_TICKET_SIZE: TicketSize,

  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // Initialize as empty object to get the keys
    this.customerTypes = Object.values(PossibleCustomerTypes);
    // PROFIL_INVESTORENTYP is not filtered out, but hidden in HTML to keep the form-data consistent
    this.investorProfileFields = Object.keys(new InvestorProfileClass())

    this.initializeForm();
    this.getAllNewsletters()
  }

  // Function to get enum options based on the field name
  getEnumOptions(fieldName: string): string[] {
    return this.profileOptions[fieldName]; // EnumValues should be an object mapping field names to their respective enum arrays
  }

  getAllNewsletters() {
    this.authService.getNewsletterList().subscribe((result: any) => {
      this.allNewsletters = result.data[0]
    });
  }

  addToAutoSubscribeList(listId) {
    console.log(listId)
    this.newslettersForLaterAutoSubscription.push(listId)
  }


  saveNewslettersForAutoSubscritionAfterLogin() {
    localStorage.setItem('newslettersforuseaftersignup', JSON.stringify(
      this.newslettersForLaterAutoSubscription
    )
    );

  }


  initializeForm(): void {

    let possiblyPrefilledEmail: string = ""

    if (this.authService.temporaryEmailInput !== "") {
      possiblyPrefilledEmail = this.authService.temporaryEmailInput
    }

    const formControls = {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [possiblyPrefilledEmail, [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],
      customerType: ['', Validators.required],
      companyName: ['', []],
      city: ['', []],
    };


    for (const field of this.investorProfileFields) {
      formControls[field] = [''];
    }

    this.registerForm = this.formBuilder.group(formControls, {
      validator: this.passwordMatchValidator
    });


    // Listen for changes in the customerType field
    this.registerForm.get('customerType').valueChanges.subscribe(value => {
      this.updateConditionalValidators(value);
    });

    // Initialize validators based on default or pre-filled customerType
   // this.updateConditionalValidators(this.registerForm.get('customerType').value);

  }

  formHasProfileErrors(){
    if (this.registerForm.controls) {
      if(
        this.registerForm.controls["PROFIL_AUM"].errors || 
        this.registerForm.controls["PROFIL_GROESSE"].errors || 
        this.registerForm.controls["PROFIL_HALTEDAUER"].errors || 
        this.registerForm.controls["PROFIL_INVESTITIONSSTIL"].errors || 
        this.registerForm.controls["PROFIL_REGION" ].errors || 
        this.registerForm.controls["PROFIL_TICKET_SIZE"].errors
      ){
        return true
      }
    } else {
      return false;
    }
  }

  formHasBasicInfoErrors(){
    if (this.registerForm.controls) {
      if(
        this.registerForm.controls["password"].errors || 
        this.registerForm.controls["lastName"].errors || 
        this.registerForm.controls["firstName"].errors || 
        this.registerForm.controls["email"].errors || 
        this.registerForm.controls["customerType" ].errors || 
        this.registerForm.controls["confirmPassword"].errors
      ){
        return true
      }
    } else {
      return false;
    }
  }

  formHasAnyErrors() {
    if (this.registerForm.controls) {
      return Object.values(this.registerForm.controls).some((field: any) => field.errors && Object.keys(field.errors).length > 0)

    } else {
      return false;
    }
  }
  get isCustomerInvestor(): boolean {
    return investorTypes.includes(this.registerForm.get('customerType').value);
  }
  get f() {
   // console.log(this.registerForm.controls)
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.success = false;


    for(let fieldname of Object.keys(this.registerForm.controls)){      
      console.log(fieldname, this.registerForm.controls[fieldname].value)
    }
    // Stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    let newUser: NewUser = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      userRole: "customer",
    }
    let newContact: Partial<NewContact> = {
      VORNAME: this.registerForm.value.firstName,
      NACHNAME: this.registerForm.value.lastName,
      CITY: this.registerForm.value.city,
      customerType: this.registerForm.value.customerType,
      COMPANY_NAME: this.registerForm.value.companyName,
      PROFIL_INVESTORENTYP: this.registerForm.value.customerType
    }
    this.registerForm.value["PROFIL_INVESTORENTYP"] = this.registerForm.value["customerType"]
    // add the rest of the optional profile data if user is investor
    if (checkValueInEnum(this.registerForm.value.customerType, investorTypes)) {
      for (let profilePoint of this.investorProfileFields) {
        if (this.registerForm.value[profilePoint]) {
          newContact[profilePoint] = this.registerForm.value[profilePoint];
        }
      }
    }
    this.saveNewslettersForAutoSubscritionAfterLogin()
    this.authService.signup(newUser, newContact).then(res => {
      this.showAlert = false
      if (res["success"] === true && res["emailStatus"] === 201) {
        this.success = true;
      } else {
        this.success = false;
        if (res["success"] === true && !res["emailStatus"]) {
          this.showAlert = true
        }
      }
      this.loading = false;
    })

  }

  toggleEditingProfile() {
    this.editingProfile = !this.editingProfile;
    this.scrollToTop(100);
  }

  //TODO: check if this really works
  private passwordMatchValidator(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;

    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ passwordMismatch: true });
    } else {
      return null;
    }
  }

  updateConditionalValidators(customerTypeValue: string) {
    const requiredTypes = [PossibleCustomerTypes.InvestorA, PossibleCustomerTypes.InvestorB, PossibleCustomerTypes.InvestorC] as string[];
    console.log(PossibleCustomerTypes.InvestorA, customerTypeValue)
    for (const field of this.investorProfileFields.filter(name=> name !== "PROFIL_INVESTORENTYP")) {
      if(requiredTypes.includes(customerTypeValue)){
        this.registerForm.get(field).setValidators([Validators.required]);
      }else{
        this.registerForm.get(field).setValidators([]);

      }
      this.registerForm.get(field).updateValueAndValidity();
    }
  }


  scrollToTop(ms) {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }), ms
    });
  }
}