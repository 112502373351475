import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateDashboardComponent } from './corporate-dashboard/corporate-dashboard.component';
import { CorporateDashboardRoutingModule } from './corporate-dashboard-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../sharedModule/shared.module';
import { InvestorprofilemodalComponent } from './investorprofilemodal/investorprofilemodal.component';
import { ResearchListComponent } from './parts/research-list/research-list.component';
import { DriveUploaderComponent } from './parts/drive-uploader/drive-uploader.component';
import { DragNDropFilesDirective } from './parts/drag-n-drop-files/drag-n-drop-files.directive';



@NgModule({
  declarations: [CorporateDashboardComponent, InvestorprofilemodalComponent, ResearchListComponent, DriveUploaderComponent, DragNDropFilesDirective],
  imports: [
    CommonModule,
    CorporateDashboardRoutingModule,
    NgbModule,
    SharedModule
  ]
})
export class CorporateDashboardModule { }
