import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
import { niceDate } from 'src/app/utilities/numberFormatting.util';
@Component({
  selector: 'app-block-button',
  templateUrl: './block-button.component.html',
  styleUrls: ['./block-button.component.scss']
})
export class BlockButtonComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() deleteEmitter: any;
  @Input() blockType: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()

  constructor(

  ) { }
  ngOnInit(): void {
    //TODO: move this to a better place
    // this is a quick fix for the 7markets api change, so we dont have to change it in the db
    if (this.data.link) {
      this.data.link = this.data.link.replace("api.7markets.de", "downloads.research-hub.de")
    }
    if (!this.data.blockTag) {
      this.data.blockTag = '#latestResearchButton';
    }
    this.data.date = niceDate(this.data.date);
  }

}
