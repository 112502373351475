import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class EmittentenGuard implements CanActivate {
  userRole: string;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { this.userRole = this.authService.getCurrentUserRole(); }
  
  canActivate(): boolean {

   
    if (this.authService.isCustomerEmittent()) {
      return true
    } 

    return false;
  }
}

