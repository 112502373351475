<div class="tile" [class.double]="config?.height===2" [ngStyle]="{'background-color': bgColor}" (click)="handleClick(config.headline)" [ngClass]="{'interactive' : targets[config.headline]}">
    <div class="row">
        <div class="col-10 col-lg-11"><p class="tile-title" [ngStyle]="{'color': color}">{{config?.headline}}  <span *ngIf="config.type === 'figure-slider' || config.type === 'vertical-bars'">{{year+1}}<span *ngIf="year>=baseyear">E</span></span></p></div>
        <div *ngIf="targets[config.headline]" class="col-2 col-lg-1 px-0">
            <svg class="float-right mr-3" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z" fill="#0F6BB2"/>
            </svg>
        </div>
    </div>
    <div class="row full-height"  >
        <app-figure class="full-width tile-component" *ngIf="config.type === 'figure'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-figure>
        <app-figure-slider class="full-width tile-component" *ngIf="config.type === 'figure-slider'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-figure-slider>
        <app-figure-graph class="full-width tile-component" *ngIf="config.type === 'figure-graph'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-figure-graph>
        <app-sidebars class="full-width tile-component" *ngIf="config.type === 'sidebars'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-sidebars>
        <app-vertical-bars  class="full-width tile-component text-center" *ngIf="config.type === 'vertical-bars'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-vertical-bars>
        <app-table class="full-width tile-component" *ngIf="config.type === 'table'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-table>
        <app-table-change class="full-width tile-component" *ngIf="config.type === 'table-change'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-table-change>
        <app-table-slider class="full-width tile-component" *ngIf="config.type === 'table-slider'" [valueTable]="true"[companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-table-slider>
        <app-template-chart class="full-width tile-component text-center" *ngIf="config.type === 'template-chart'" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config" (tileEvent)="onTileEvent($event)"></app-template-chart>
        <app-text class="full-width tile-component" *ngIf="config.type === 'text'" [config]="config" ></app-text>
    </div>
</div>
