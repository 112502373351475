import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { UserHandlingService } from 'src/app/services/user-handling.service';
@Component({
  selector: 'app-newsletter-subscribe',
  templateUrl: './newsletter-subscribe.component.html',
  styleUrls: ['./newsletter-subscribe.component.scss']
})
export class NewsletterSubscribeComponent implements OnInit {


  @Input() formMode = 'newsletter'



  /**
   * clean this whole component from unused code when new forms are in prod
   *
   */

  @ViewChild('instance', { static: true }) instance: NgbTypeahead; // delete this when new forms are used in production
  click$ = new Subject<string>(); // delete this when new forms are used in production
  focus$ = new Subject<string>();  // delete this when new forms are used in production
  @Input() emailMode = "" // delete this when new forms are used in production
  @Input() selectedEvent = null // delete this when new forms are used in production
  @Input() emailInput = null // delete this when new forms are used in production
  listOfCompanies = [] // delete this when new forms are used in production
  checkBoxData = false; // delete this when new forms are used in production
  checkedCompanies = {} // delete this when new forms are used in production
  userMail = "" // delete this when new forms are used in production
  loadingRequest = false; // delete this when new forms are used in production
  showResult = false; // delete this when new forms are used in production

  public message = ""
  submitted = false;
  subscriptionForm: FormGroup

  //  unsafeURL = "https://55133281.sibforms.com/serve/MUIEAAGDKKhNuyzgZVoig4X3n3dN1oePl7NxpRfqVue---khFn-J9ZyzF3xaECI4fyuPkyC6sfju6fwaMt8U-QxXtpznBLpDINR2LoDx6JoLmxijFMLEZfrEhqUWU_mFLbti6u7OQM8B8gcoKIj1KKS70VAvYXU6oPviE2J0c6erhOHiKPDhb5wOI24uYOoD7pQFXMWrbRf9ObSN"
  unsafeURLLoaded = "https://55133281.sibforms.com/serve/MUIEADw8WKxNtRNKgyPwEPG09FCOyin8pZdl7v5-1GDQ80o0XuOr2NGJoXOypFDba-TxK4myfhnrXJIX9IoXXk0E5vWVRAcEem2xo4kq-otmiuJYc4MtkahQwTvB0V1Ebv6F3xLF3P-8c0qe4pm5A9hK5JiSSW6JnJ22yfIDOx_QRieTLzsYmdrsBRdDIqbzfeJx-MgdDiaMOvby"
  unsafeURL: any = null;


  constructor(
    public activeModal: NgbActiveModal,
    private companyInfoService: CompanyInfoService,
    private userHandlingService: UserHandlingService,

    private formBuilder: FormBuilder) { }





  ngOnInit(): void {


    this.companyInfoService.loadFormURL(this.formMode).then((result) => {
      this.unsafeURL = result.formURL as any

    }, (err) => {
      this.unsafeURL = this.unsafeURLLoaded
    })





    this.listOfCompanies = this.companyInfoService.getListOfCompanies().filter(el => el.hidden === false && el.name !== "welcomePageStatic" && el.name !== "AAA-PDF-Company").map(el => {

      this.checkedCompanies[el.name] = false
      return el.name


    }
    )

    this.subscriptionForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      company: ['', Validators.required],
      city: [''],
      country: [''],
      phone: [''],
      function: [''],
      gender: [''],
      postal: [''],
      datasecurity: [false, Validators.requiredTrue],


      email: ['', [Validators.required, Validators.email]],


    }, {
      // validator: (this.MustMatch('password', 'confirmPassword'))
    });

    this.subscriptionForm.get('email').setValue(this.emailInput)
  }

  onCheckBoxChange(companyName) {
    if (this.checkedCompanies[companyName] === null || this.checkedCompanies[companyName] === undefined) {
      this.checkedCompanies[companyName] = true
    } else {
      this.checkedCompanies[companyName] = !this.checkedCompanies[companyName]
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(20), distinctUntilChanged());
    // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => this.listOfCompanies
        .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1))
      // .slice(0,10))
    );
  }

  selectAllCompanies(newValue) {
    Object.keys(this.checkedCompanies).forEach((key, value) => {
      return this.checkedCompanies[key] = newValue
    })
  }

  addCompany($event): void {
    $event.preventDefault()
    this.checkedCompanies[$event.item] = (this.checkedCompanies[$event.item] === null || this.checkedCompanies[$event.item] === undefined) ? true : !this.checkedCompanies[$event.item]
  }

  sendMail() {
    this.loadingRequest = true
    // let companies = []
    // Object.keys(this.checkedCompanies).forEach((key, value) => {
    //   if (this.checkedCompanies[key] === true) {
    //     companies.push(key)
    //   }
    // })
    let content = "";
    let userData = this.subscriptionForm.value
    if (this.emailMode === 'requestcoverage') { (content as any) = this.message }
    if (this.emailMode === 'companyintro') { (content as any) = this.message }
    if (this.emailMode === 'subscribetoevent') { (content as any) = this.selectedEvent }
    this.userHandlingService.sendSubscriptionRequest(content, userData, this.userMail, this.emailMode).then(
      result => {
        this.loadingRequest = false
        this.showResult = true

        setTimeout(() => {
          this.showResult = false
          this.activeModal.close()
        }, 1000);
      }
    )

  }
  // convenience getter for easy access to form fields
  get f() { return this.subscriptionForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.subscriptionForm.invalid) {
      return;
    } else {
      this.userMail = this.subscriptionForm.value.email
      this.sendMail()
    }


  }


  // MustMatch(controlName: string, matchingControlName: string) {
  //   return (formGroup: FormGroup) => {
  //     const control = formGroup.controls[controlName];
  //     const matchingControl = formGroup.controls[matchingControlName];

  //     if (matchingControl.errors && !matchingControl.errors.mustMatch) {
  //       // return if another validator has already found an error on the matchingControl
  //       return;
  //     }

  //     // set error on matchingControl if validation fails
  //     if (control.value !== matchingControl.value) {
  //       matchingControl.setErrors({ mustMatch: true });
  //     } else {
  //       matchingControl.setErrors(null);
  //     }
  //   }
  // }
}
