<ul class="pagination">
  <li class="page-item" [class.disabled]="currentPage === 1">
    <a class="page-link" (click)="previousPage()">&laquo;</a>
  </li>
  <ng-container *ngFor="let page of displayedPages">
    <li class="page-item" [class.active]="page === currentPage" *ngIf="page !== -1">
      <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
    </li>
    <li class="page-item disabled" *ngIf="page === -1">
      <a class="page-link">...</a>
    </li>
  </ng-container>
  <li class="page-item" [class.disabled]="currentPage === totalPages">
    <a class="page-link" (click)="nextPage()">&raquo;</a>
  </li>
</ul>
