<div class="tile-title row">
  <div class="col-10">
    <h3>Investor Profile</h3>
  </div>

  <div class="col-2 text-right fingerFix" (click)="closeWindow()">
    <div>X</div>
  </div>
</div>

<div class="row ">
  <div class="col-6 mx-auto my-4 white-tile">
    <div class="mt-2">
      <h4>{{person.company}}</h4>
    </div>
    <div class="mt-2">
      <h5>{{person.firstName}} {{person.lastName}}</h5>
    </div>
    <div class="mt-2">
      <h5>{{investorProfileMapping['investorType'].values[investorProfile['investorType']]}}</h5>
    </div>

  </div>
  <div class="col-12">
    <h4 *ngIf="profileHasDetails()">Details</h4>
    <div *ngIf="profileHasDetails()" class="table-responsive" style="padding-top: 0.5rem;">
      <table class="table" style="color:white">
        <tbody>
          <ng-container *ngFor="let item of investorProfile | keyvalue:keyAscOrder">
            <tr *ngIf="item.key !== 'investorType' && item.key !== 'description' ">
              <th>{{investorProfileMapping[item.key].title}}</th>
              <td>{{investorProfileMapping[item.key].values[item.value-1]}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-12 mt-3" *ngIf="investorProfile.description">
    <h4>{{investorProfileMapping['description'].title}}</h4>
    <div style="padding-left: 0.75rem; font-size:1.25rem">{{investorProfile.description}}</div>
  </div>
</div>