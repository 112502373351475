import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SingleEvent } from 'src/app/models/event.model';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})


export class EventListComponent implements OnInit {
  
  listTypes = ["nextEvent", "pastEvents", "upcomingEvents"]
  @Input() listType = 1
  @Input() events: SingleEvent[] = []
  @Input() currentUsersCompanyName = null
  @Input() existingCompany = false
  @Input() corporateView = false
  @Input() registeredEventsOnly = false
  @Output() eventRemoved = new EventEmitter<any>();
  shownEvents = 2
  multipleEvents = false


  constructor() { }

  ngOnInit(): void {
   if(this.events.length > 2){
     this.multipleEvents = true
   }
  }

  toggleMore(){
    if(this.shownEvents < this.events.length){
      this.shownEvents = this.events.length
    }else{
      this.shownEvents = 2
    }
    
  }
  triggerEventsReload($event){
    this.eventRemoved.emit()
  }

}
