<div class="container d-flex align-items-center"
     style="height: 80vh;">

  <div class="row align-items-center justify-content-center  "
       style="padding-top: 4rem; gap: 3rem">

    <div class="col-11 col-lg-6" style="font-size: 1.5rem;">Dear visitor,
      we analyse website traffic to better understand the needs of our users and to tailor our product accordingly.
      Please understand that we cannot provide access to our website without your consent to our cookie policy.</div>
    <div class="col-9 col-lg-6 d-flex justify-content-center">
      <button class="btn btn-std uppercase bigger mx-md-3"
              (click)="backToCookieConsent()">
        Back to cookie consent
      </button>
    </div>
  </div>
</div>
