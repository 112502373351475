import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'renamePageTypes' })
export class RenamePageTypes implements PipeTransform {
  transform(value: any): any {

    if (value === "scores") { value = "esg" }
    else if (value === "news") { value = "research" }

    return value
  }



}
