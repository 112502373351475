import { Component, EventEmitter, OnInit, OnChanges, Input, Output } from '@angular/core';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-figure',
  templateUrl: './figure.component.html',
  styleUrls: ['./figure.component.scss']
})
export class FigureComponent implements OnInit {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: { headline: string, type: string, content: string[], height: number, width: number, mobile: boolean };
  @Input() decimals = 0;
  @Input() lang = 'eng';

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  data: [{ title: string, value: string, valueModifier: string }];
  index: number = 0;
  bgColor = 'transparent';
  color = 'black';

  constructor(private tableUtils: Tables) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.prepareData();
    this.navigate(2);
  }

  prepareData() {
    if (this.mapping && this.companyData && this.companyData.base) {
      for (let key of this.config.content) {

        if (this.mapping[key]) {
          let mappingEntry = this.mapping[key];
          let entry;
          if (mappingEntry.type === 'VALUE') {
            entry = { title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.decimals)[0] };
          } else if (mappingEntry.type === 'TIMESERIES') {
            entry = { title: mappingEntry[this.lang], value: this.tableUtils.getTimeseries(key, this.companyData,mappingEntry,2,3,'value', this.lang,this.decimals), date: this.tableUtils.getTimeseries(key, this.companyData,mappingEntry,2,3,'date', this.lang,this.decimals)};
          }

          if (entry) {
            if (!mappingEntry.unit) {
              entry.valueModifier = this.companyData?.base?.curr + 'm';
            }

           this.data = entry;
          }

         this.updateColor();
        }
      }
    }
  }

  navigate(step: number){
    this.index = Math.min(this.data['value'].length-1,Math.max(0,this.index+step));
    this.updateColor();
  }

  updateColor(){
    let key = this.config.content[0];
    if (key === 'roce') {
      let matches = String(this.data['value'][this.index]).match(/-?\d+(.\d+)?/g);
      if (matches) {
        let value: number = parseInt(matches[0])
        if (value < -5) {
          this.bgColor = '#fda4af';
          this.color = '#f43f5e';
        } else if (value < 0) {
          this.bgColor = '#FEF2F2';
          this.color = '#f43f5e';
        } else if (value < 8) {
          this.bgColor = '#FFFBEB'
          this.color = '#EAB308';
        } else if (value < 12) {
          this.bgColor = '#ECFDF5'
          this.color = '#047857';
        } else if (value >= 12) {
          this.bgColor = '#86efac';
          this.color = '#047857';
        }
      }
      this.tileEvent.emit({ type: 'color-change', change: { backgroundColor: this.bgColor, color: this.color } });
    }
  }

}
