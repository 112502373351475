import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CustomRouteReuseStategy } from '../utilities/customRouterReloadStrategy.util';
import { CorporateDashboardComponent } from './corporate-dashboard/corporate-dashboard.component';
import { UserRoleGuard } from '../services/auth/userrole-guard.service';
import { AuthGuardService } from '../services/auth/auth-guard.service';
import { EmittentenGuard } from '../services/auth/emittenten-guard.service';


/**
 * Der ganze Spaß den wir an die Routes angebaut haben wir hier automatisch übernommen.
 */
/**
 * 
 * Add , IssuerGuard to all non-public issuer/corporate routes
 * 
 */
const routes: Routes = [
   {path:'dashboard', component:CorporateDashboardComponent ,canActivate: [AuthGuardService,EmittentenGuard] },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CorporateDashboardRoutingModule { }
