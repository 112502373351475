import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
import { translateProgrammingString } from 'src/app/utilities/namingTranslation.util';
@Component({
  selector: 'app-markets-block-large-image',
  templateUrl: './block-large-image.component.html',
  styleUrls: ['./block-large-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockLargeImageComponent implements OnInit {
  @Input() data: any;  // data.url
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()
  constructor() { }
  ngOnInit(): void {
  }

  translate(s: string): string {
    return translateProgrammingString(s);
  }
}
