import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyInfoService } from 'src/app/services/company-info.service';

@Component({
  selector: 'app-esg-score',
  templateUrl: './esg-score.component.html',
  styleUrls: ['./esg-score.component.scss']
})
export class EsgScoreComponent implements OnInit {
  @Input() symbolExchange = '';
  @Input() companyName = '';
  @Output() sendLWCompanyname  = new EventEmitter();

  noData = false;
  stock: any = {
    companyName:"",
    rating: {
      taxRate: 0,
      Social: 0,
      total: 0,
      Environment: 0,
      emissionReduction:0,
      Governance: 0,
      ceoPayRatio: 0
    }
  }


  constructor(private companyInfoSerice: CompanyInfoService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.companyInfoSerice.getESGScore(this.symbolExchange).subscribe((res: any) => {
      this.stock["rating"] = res.rating;
      if(this.stock.rating.total!==null && this.stock.rating.total !== 11.0177){
        this.stock.rating.total = Math.min(Math.max(this.stock.rating.total,0),100);
      }
      
      this.cdr.detectChanges();
    }, (err) => {
      this.noData = true
    });
    this.cdr.detectChanges();
  }
}
