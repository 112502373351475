import { Component, EventEmitter, OnInit, OnChanges, Input, Output } from '@angular/core';
import { link } from 'fs';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-figure-slider',
  templateUrl: './figure-slider.component.html',
  styleUrls: ['./figure-slider.component.scss']
})
export class FigureSliderComponent implements OnInit, OnChanges {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: { headline: string, type: string, content: string[], height: number, width: number, mobile: boolean };
  @Input() decimals = 0;
  @Input() lang = 'eng';

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  data: [{ title: string, value: string, valueModifier: string }];
  index: number = 0;

  constructor(private tableUtils: Tables) { }

  ngOnInit(): void {
    this.navigate(1);
    this.navigate(-1);
  }

  ngOnChanges(): void {
    this.prepareData();
  }

  prepareData() {
    if (this.mapping && this.companyData && this.companyData.base) {
      for (let key of this.config.content) {

        if (this.mapping[key]) {
          let mappingEntry = this.mapping[key];
          let entry;
          if (mappingEntry.type === 'VALUE') {
            entry = { title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.decimals)[0] };
          } else if (mappingEntry.type === 'TIMESERIES') {
            entry = { title: mappingEntry[this.lang], value: this.tableUtils.getOneFromTimeseries(this.companyData, mappingEntry, 1, 'value', this.lang, this.decimals)[0] };
            if(entry.value < 100){ //Hack to show small numbers with decimal
              entry.value = this.tableUtils.getOneFromTimeseries(this.companyData, mappingEntry, 1, 'value', this.lang, 1)[0]
            }
          }

          if (entry) {
            if (!mappingEntry.unit) {
              entry.valueModifier = ' in ' + (this.companyData?.base?.curr) + 'm';
            } else if (mappingEntry.unit === 'FIX') {
              entry.valueModifier = ' in ' + (this.companyData?.base?.curr);
            }

            if (this.data) {
              this.data.push(entry);
            } else {
              this.data = [entry];
            }
          }
        }

      }
    }
  }

  navigate(step: number) {
    if (this.index + step > this.data.length - 1) {
      this.index = 0;
    } else if (this.index + step < 0) {
      this.index = this.data.length - 1;
    } else {
      this.index = this.index + step;
    }
    
    let linkedTitle = this.data[this.index].title;
    if(this.data[this.index].valueModifier){ linkedTitle = linkedTitle + this.data[this.index].valueModifier; }
    this.tileEvent.emit({ type: 'key-change', change: { content: [this.config.content[this.index]], title: linkedTitle } })
  }

}
