import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyInfoService } from 'src/app/services/company-info.service';

/**
 * TODO: there is a bug with 123Fahrschule, probably because of the numbers in companyname
 */
@Component({
  selector: 'app-company-slider',
  templateUrl: './company-slider.component.html',
  styleUrls: ['./company-slider.component.scss']
})
export class CompanySliderComponent implements OnInit, OnChanges {
  public isHiddenCompany = false;
  @Input() symbolExchange: any = 'XXX:XX';
  @Input() onlyLeft: false;
  @Input() onlyRight: false;
  @Output() onURLEventEmitter = new EventEmitter();

  public listOfCompanies = [];
  public currentCompanyIndex = 0;
  constructor(
    private companyInfoService: CompanyInfoService,
    public router: Router,

  ) {
    this.router.events.subscribe(params => {
      this.onURLEvent(params);
    });
    let tmpcmp = this.companyInfoService.getListOfCompanies().find(item => item.bloombergTicker === this.symbolExchange)
    if (tmpcmp) {
      this.isHiddenCompany = tmpcmp.hidden
    }
    this.listOfCompanies = this.companyInfoService.getListOfCompanies().filter(item => item.hidden === false && item.name !== "welcomePageStatic").sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 : ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0))
    this.currentCompanyIndex = this.listOfCompanies.findIndex(x => x.bloombergTicker === this.symbolExchange);
   }

  ngOnInit(): void {

  }
  ngOnChanges() {
    this.currentCompanyIndex = this.listOfCompanies.findIndex(x => x.bloombergTicker === this.symbolExchange);
  }
  onURLEvent(event) {
    // this calls the onURLEvent-Logic in c-page-component
    if (event instanceof NavigationEnd) {
      this.onURLEventEmitter.emit(event)
    }
  }

}
