<div class="col-12 sidebars">
    <section class="bar-graph bar-graph-horizontal bar-graph-one">
        <ng-container *ngFor="let bar of data">
            <div class="bar-one">
                <div class="bar" [style.width.%]="bar.value"></div>
                <div style="position:absolute;">{{bar.value | number:'1.1-1'}} % {{bar.title}}</div>
            </div>
        </ng-container>
        <!-- <div class="bar-one">
            <div class="bar" style="background-color: #C7E7FD;" [style.width.%]="freefloat"></div>
            <div style="position:absolute;">{{freefloat | number:'1.1-1'}} % Freefloat</div>
        </div> -->
    </section>
</div>