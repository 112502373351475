<div class="container-fluid px-0" style="padding-top: 50px;">
  <section class="px-5">
    <div class="row content-wrapper limit-pagewidth">
      <div class="col-lg-12 col-md-12 text-center p-5 d-none d-sm-block">
        <h2 class="p-4">Check out <br><span class="blueText">all stocks we cover</span></h2>
        <p class="bigger px-4">Pick your favorites or browse our highlighted stocks.
        </p>
      </div>
      <div class="col-lg-12 col-md-12 text-center p-5 d-sm-none d-block">
        <h4 class="p-4">Find new research for <br><span class="blueText">interesting companes</span></h4>
      </div>
    </div>
  </section>

  <section class="px-5">
    <div class="content-wrapper limit-pagewidth">
      <app-company-grid [showAll]="true" [numRows]=1></app-company-grid>
    </div>
  </section>
  
  <!-- subscibe banner-->
  <section class="px-5">
    <div class="content-wrapper limit-pagewidth">
      <hr> <!-- häh warum wird die nicht angezeigt -.-.- -->
      <div class="py-5">
        <app-subscribe-banner class="full-width bg-bluearea"></app-subscribe-banner>
      </div>
    </div>
  </section>
</div>
