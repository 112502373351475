<div [ngClass]="{ 'border border-dark rounded': adminMode }" id="{{data.blockTag}}">
  <div id="investmentcase-key-figures" class="fingerFix border-top border-light-blue py-2 full-width collapseBlockHeadline" (click)="data.collapse = !data.collapse">
    <i class="fa fa-chevron-up px-2" *ngIf="!data.collapse"></i>
    <i class="fa fa-chevron-down px-2" *ngIf="data.collapse"></i>
    {{data.blockHeadline || "Key Figures"}}
  </div>
  <div class="keyfacts-wrapper"  [ngClass]="{'is-hidden': data.collapse}">
    <div class="text-center" *ngIf="!notInitalized">
      <app-keyfacts *ngIf="data.bloombergTicker !== 'Eingabe erforderlich'" [lang]="lang" [companyId]="data.bloombergTicker"
        [decimals]="data.numDecimals" [companyData]="metaCompanyData"></app-keyfacts>
    </div>
    <div class="row" *ngIf="adminMode">
      <div class="col-12">
        <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
          [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName"
          [metaBloombergTicker]="metaBloombergTicker" [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData">
        </app-block-handling>
      </div>
    </div>

  </div>
</div>1
