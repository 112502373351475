<div class="col-12 figure-slider" *ngIf="data">
    <div class="row">
        <div class="col-12 figure-slider-title">
            {{data[index]?.title}} <span *ngIf="data[index]?.valueModifier">{{data[index]?.valueModifier}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-2 fingerFix figure-slider-navigation" (click)="navigate(-1)"><i class="fa fa-chevron-left pb-3"></i></div>
        <div class="col-8 figure-slider-content">{{data[index]?.value}} </div>
        <div class="col-2 fingerFix figure-slider-navigation" (click)="navigate(1)"><i class="fa fa-chevron-right pb-3" ></i></div>
    </div>
</div>
