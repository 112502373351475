<div class="col-12 tile-table">
    <div class="table-responsive mx-auto">
        <div>
            <table class="table table-sm table-hover mb-0 tile-table">
                <thead *ngIf="!valueTable">
                    <td>in {{companyData?.base?.quote_curr || companyData?.base?.curr}}m</td>
                    <td class="tile-table-headline headlines" *ngFor="let date of dates">
                        <b>{{date}}</b>
                    </td>
                    <td class="tile-table-headline headlines">
                        <b>yoy chg.</b>
                    </td>
                </thead>
                <tbody>
                    <tr *ngFor="let row of data">
                        <td class="tile-table-title content">
                            {{ row.title }}
                        </td>
                        <td *ngFor="let datapoint of row.value" class="tile-table-content content">
                            <span [class.negative]="datapoint.includes('-')">{{datapoint}}</span> <span
                                *ngIf="row.valueModifier">{{row?.valueModifier |
                                    number:'1.1-1'}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>