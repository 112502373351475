<div>
    <!--  DONT FORGET TO USE THE DOWNLOAD RESEARCH STATISTICS BUTTON-->
    <div class="tile-title row">
        <div class="col-10">Research</div>

        <div class="col-2 text-right fingerFix" (click)="closeWindow()">
            <div>X</div>
        </div>

    </div>
        <div *ngIf="researchPDFs !== undefined" class="row my-3 text-center" >
            <div class="col-12 my-3">
                <button class="btn btn-std">
                    <a *ngIf="researchPDFs && researchPDFs.length>0" href="{{researchPDFs[0]?.url}}" target="_blank">Download the latest research</a>
                </button>
            </div>
        </div>
        <div *ngIf="researchPDFs !== undefined" class="col-12 overflow-auto" style="max-height:300px">
            <table class="table-hover table  ">
                <thead>
                    <th>Release date</th>
                    <th>Title</th>
                    <th></th>
                </thead>
                <tbody>
                <tr *ngFor="let pdf of correctedPDFs">
                    <td>{{pdf.archiveTitle | slice:0:10}}</td>
                    <td><a href="{{pdf.downloadLink}}" target="_blank">{{pdf.archiveTitle | slice:10 | HTMLSpecialChar}}</a> </td>
                    <td><a target="_blank" class="btn btn-link p-0 float-right" [href]="pdf.downloadLink">Download PDF</a></td>
                </tr>
            </tbody>
            </table>

        </div>

</div>