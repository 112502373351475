import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @Input() status: false;
  @Input() reroute: false;
  @Input() rerouteURL: '/user/dashboard';
  data = { userName: "", password: "" };
  showError = false;
  constructor(

    private authSerice: AuthService,
    public activeModal: NgbActiveModal,
    private router:Router
  ) {
   }

  login() {
    this.showError = false;
    this.authSerice.login(this.data).then(
      () => {

        if(this.reroute){
          this.authSerice.temporaryEmailInput = ""
          this.router.navigate([this.rerouteURL]);
          this.activeModal.dismiss('login');
        }else{
          this.authSerice.temporaryEmailInput = ""

          this.activeModal.dismiss('login');

          if(this.authSerice.isCustomerEmittent()){
            this.router.navigate(['corporate/dashboard']);
          }else{
            this.router.navigate(['user/dashboard']);

          }
        }

      }, err => {
        this.showError = true;
        this.authSerice.temporaryEmailInput = this.data.userName
      }

    );

  }

  logOff() {
    this.authSerice.logOff();
    this.activeModal.dismiss('logOff');
    this.router.navigate([''])

  }

  goToSignup(){
    this.activeModal.dismiss('login');
    this.router.navigate(['signup']);
  }


  ngOnInit(): void {
  }

}
