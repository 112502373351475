import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilehandlerService } from 'src/app/services/filehandler.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() pageType = ''
  @Input() reportPanelMode = false;
  @Input() uploaderMode = '';
  @Input() bloombergTicker;
  @Input() companyName;
  @Input() pdfMode;
  @Input() pdfLinks: any = [];
  @Input() pdfImages: any = [];
  files: any = [];
  images: any = [];
  newPdfs = 0;
  showMissingImageErrorMsg = false;
  useDefaultImage = false

  requestPromisesImages: Array<Promise<any>> = [];

  uploadStarted = false;

  imagePath;
  uploadFinished = false;
  // metaCompanyName
  // metaPageType
  // block_id
  // blockType
  // data
  constructor(public activeModal: NgbActiveModal, private fileUploaderService: FilehandlerService) { }

  ngOnInit(): void {
    if (this.reportPanelMode) {
      this.useDefaultImage = true
    }
    this.prefillUploader()
  }

  /**
   *
   * large image uploader
   */

  uploadImageFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push({
        name: element.name,
        file: element,
        eLoaded: 0,
        eTotal: 0,
        eprogress: 0,
        uploadStartet: false,
        uploadComplete: false,
        type: element.type
      });


    }
    //
  }
  deleteImageAttachment(index) {
    this.files.splice(index, 1);
  }
  sendImageFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fileUploaderService.uploadImages(file.file)
        .subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              file.eLoaded = event.loaded;
              file.eTotal = event.total;
              const percentDone = Math.round(100 * event.loaded / event.total);
              file.eprogress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              file.uploadComplete = true;
              resolve(event.body[0]);
            }
          },
          (err) => {
            console.log('Upload Error:', err);
          }, () => {
          }
        );
    });

  }
  startImageUpload() {
    const uploaderPromises = [];
    for (let i = 0; i < this.files.length; i++) {
      // if(this.files[i].type ==='text/csv'){
      if (true) {
        this.files[i].uploadStartet = true;
        uploaderPromises.push(this.sendImageFile(this.files[i]));
      }

    }
    Promise.all(uploaderPromises).then(result => { this.activeModal.close(result[0]); });
  }



  /**
   *
   * PDF and Image Uploader and Preview Function
   */


  prefillUploader() {
    for (let index = 0; index < this.pdfLinks.length; index++) {
      const element = this.pdfLinks[index];
      this.files.unshift({
        name: element.name,
        file: element,
        eLoaded: 0,
        eTotal: 0,
        eprogress: 0,
        uploadStartet: false,
        uploadComplete: true,
        shownName: element.shownName,
        imageMapperID: element.imageMapperID,
        imageURL: element.imageURL,
        teaserText: element.teaserText,
        prefilled: true,
        previewURL: null
      });





    }
  }

  uploadFile(event) {

    this.uploadStarted = false
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if (element.type.includes('pdf')) {
        this.files.unshift({
          name: element.name,
          file: element,
          eLoaded: 0,
          eTotal: 0,
          eprogress: 0,
          uploadStartet: false,
          uploadComplete: false,
          type: element.type,
          shownName: element.name.split('.')[0],
          imageMapperID: this.createImageMapperID(element.name),
          imageURL: '',
          teaserText: 'Hier kann eine Beschreibung stehen',
          prefilled: false,
        });
      }


    }
    this.newPdfs++
  }

  randomString(length) {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
  }

  createImageMapperID(filename) {
    return filename + '__' + this.randomString(20);
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.newPdfs--
  }

  deleteImage(imageMapperID) {
    this.images = this.images.filter(el => el.imageMapperID !== imageMapperID);
  }

  sendFile(file, image): Promise<any> {

    return new Promise((resolve, reject) => {
      this.fileUploaderService.uploadPDFs(
        file.file,
        this.bloombergTicker,
        this.companyName,
        this.pdfMode,
        file.shownName,
        file.imageMapperID)
        .then(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              file.eLoaded = event.loaded;
              file.eTotal = event.total;

              const percentDone = Math.round(100 * event.loaded / event.total);
              file.eprogress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {

              file.uploadComplete = true;


              let newPDF = {
                url: event.body[0].fileURL,
                filename: file.name.split('.')[0],
                shownName: file.shownName,
                imageMapperID: file.imageMapperID,
                mode: this.pdfMode,
                teaserText: file.teaserText,
              }

              newPDF["imageURL"] = file.file.imageURL

              this.pdfLinks.unshift(newPDF);

              if (image !== null && typeof image !== 'undefined' && image.imageMapperID !== null) {
                image.uploadStartet = true;
                this.requestPromisesImages.push(this.sendImage(image));
              }


            }
            this.uploadFinished = this.checkIfUploadFinished()
            resolve(true);
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  sendImage(file): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fileUploaderService.uploadPDFImages(file.file, this.bloombergTicker, this.companyName, this.pdfMode, file.imageMapperID)
        .then(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              file.eLoaded = event.loaded;
              file.eTotal = event.total;

              const percentDone = Math.round(100 * event.loaded / event.total);
              file.eprogress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              file.uploadComplete = true;


              this.pdfImages.unshift({
                url: event.body[0].fileURL,
                filename: file.name.split('.')[0],
                mode: this.pdfMode,
                imageMapperID: file.imageMapperID
              });

              for (const image of this.pdfImages) {
                for (const pdf of this.pdfLinks) {
                  if (image.imageMapperID === pdf.imageMapperID) {
                    pdf.imageURL = image.url;
                  }
                }
              }


            }
            this.uploadFinished = this.checkIfUploadFinished()
            resolve(true)
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  addImage(event, imageMapperID) {

    const element = event[0]; // nur das erste hochgeladene bild wird mit dem pdf verbunden
    if (element.type.includes('image')) {
      this.images.unshift({
        name: element.name,
        file: element,
        eLoaded: 0,
        eTotal: 0,
        eprogress: 0,
        uploadStartet: false,
        uploadComplete: false,
        type: element.type,
        imageMapperID: imageMapperID
      });


      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].imageMapperID === imageMapperID) {
          this.files[i].previewURL = this.previewImage(element, this.files[i]);

        }
      }
    }
  }

  checkIfUploadFinished() {
    //this.uploadStarted = false;
    let result = this.files.filter(item => item.uploadComplete !== true)

    let imagesFinished = this.images.filter(item => item.uploadComplete !== true)

    if (result.length > 0 || imagesFinished.length > 0) {
      return false //
    } else {
      return true
    }
  }


  setDefaultImage() {
    let defaultImageURL = this.files[this.files.length - 1].file.imageURL
    this.files.map(item => {
      if (item.file.imageURL === "" || item.file.imageURL === null || item.file.imageURL === undefined) { item.file.imageURL = defaultImageURL }
    })
  }


  allPDFsHaveImages() {
    let missingImageURL = false
    this.files.map(item => {
      if (item.file.imageURL === "" || item.file.imageURL === null || item.file.imageURL === undefined) { missingImageURL = true }
    })

    return !missingImageURL
  }

  uploadClicked() {
    if (!this.allPDFsHaveImages() && !this.useDefaultImage) {
      this.showMissingImageErrorMsg = true;
      setTimeout(() => {
        this.showMissingImageErrorMsg = false;
      }, 15000)

    } else {
      if (this.useDefaultImage) {
        this.setDefaultImage()

      } else {
        this.showMissingImageErrorMsg = true;
        setTimeout(() => {
          this.showMissingImageErrorMsg = false;
        }, 15000)
      }
      this.startUpload().then(() => {
        this.uploadFinished = this.checkIfUploadFinished()
      })
    }

  }

  startUpload(): Promise<any> {
    return new Promise((resolve, reject) => {

      this.uploadStarted = true
      const requestPromisesPDF = [];
      for (const file of this.files) {

        if (file.uploadComplete !== true) {
          file.uploadStartet = true;

          let imageForPDF;
          for (const image of this.images) {
            if (image.imageMapperID === file.imageMapperID) {
              imageForPDF = image;
            }
          }

          requestPromisesPDF.push(this.sendFile(file, imageForPDF));
        }
      }

    })

  }

  hackySequentialRequests(promiseArr, type) {
    return new Promise((resolve, reject) => {
      const doNextPromise = (index) => {
        promiseArr[index].then((result) => {
          index++;
          if (index < promiseArr.length) { doNextPromise(index) } else {
            resolve(true)
          }
        })
      }

      doNextPromise(0)
    })
  }

  uploadPDFPromiseWrapper(promiseArr, type): Promise<any> {
    return new Promise((resolve, reject) => {
      for (let singlePromise of promiseArr) {
        singlePromise.then(() => {
          Promise.resolve(() => {
            setTimeout(() => {
            }, 1000);
          })
        })
      }
      resolve(true)
    })
  }

  closeWindow() {
    this.activeModal.close({ pdfLinks: this.pdfLinks, pdfImages: this.pdfImages });
  }

  previewImage(file, pdf) {
    if (file === undefined || file === null) {
      return;
    }

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) {

      return 'Only images are supported.';
    }

    const reader = new FileReader();
    this.imagePath = file;
    let imgURL;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      pdf.previewURL = imgURL = reader.result;
    };

  }



}
