<div class="row mt-md-5 pt-md-5 content-wrapper limit-pagewidth">
  <div class="col-12">
    <div class="mt-md-5 p-md-5 bg-bluearea row">
      <div class="col-12">
        <div class="row">
          <div class="col-6 mx-auto">
            <h2 class="text-center">Reset your Password</h2>
            <form [formGroup]="resetForm" (ngSubmit)="onResetSubmit()">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" formControlName="email" id="email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>
              <div class="form-group text-center">
                <button [disabled]="loading" class="btn btn-std">Request Password Reset</button>
                <span *ngIf="loading">Your request is being processed.</span>
                <span *ngIf="success">
                  <p>An email with instructions to reset your password has been sent to your email address.</p>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>