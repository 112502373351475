import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-charting',
  templateUrl: './block-charting.component.html',
  styleUrls: ['./block-charting.component.scss']
})
export class BlockChartingComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: boolean;
  @Input() blockType: string;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: string;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: string;
  @Input() metaCompanyData: any;
  isEditorMode = false;
  loadingEditor = false;
  changeMode = true;
  notInitalized = true;
  blockHeadline = ""
  defaultTemplateName = "default-chart";
  allTemplateNames = []
  constructor() { }
  ngOnInit(): void {
    //TODO: this should be done in handler
    this.notInitalized = this.data && (this.data.bloombergTicker === 'Eingabe erforderlich') ? true : false
    if (this.notInitalized) {
      if (this.metaBloombergTicker) { this.data.bloombergTicker = this.metaBloombergTicker }
      this.notInitalized = this.data && (this.data.bloombergTicker === 'Eingabe erforderlich' ) ? true : false
    }
  }

}
