<ng-template #rt let-r="result" let-t="term">
  <!--bla-->
  <a style="color:black!important;" class="underlineonhover">
    {{r.name}}
  </a>
</ng-template>

<nav id="e2e-header" class="navbar fixed-top header mh-100" style="z-index:9999 !important">
  <div class="container-fluid ">
    <div class="row ">
      <div class="col-12 navbar-wrapper justify-content-end justify-content-md-between">
        <a class="logo navbar-brand ml-0" href="/" [ngClass]="{'force-full-width':mobileHeader}">
          <img loading="lazy" src="../../../assets/ResearchHubLogo.svg" alt="logo image"
            class="logo__img d-none d-md-block">
          <span *ngIf="!navbarOpen" class="d-block d-md-none" style="color:black">ResearchHub</span>
        </a>

        <div class="button-group button-group-wrapper text-md-center "
          [ngClass]="{ 'd-block': navbarOpen, 'd-none':!navbarOpen }">

          <input [hidden]="mobileHeader" class="textinput e2e-companysearch" type="text" [resultTemplate]="rt"
            (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
            #instance="ngbTypeahead" placeholder="FIND COMPANY" (selectItem)="goToCompanyPage($event)"
            [resultFormatter]="formatter" [ngbTypeahead]="search" label="companysearch" />

          <button class="btn btn-transparent d-block d-md-none" [routerLink]="['/']">Home</button>
          <!-- <button class="btn btn-transparent" (click)="nextPage('all-stocks')">All Stocks</button> -->
          <button class="btn btn-transparent" (click)="nextPage('screener')">Screener</button>
          <!-- <button class="btn btn-transparent" [routerLink]="['/']" [fragment]="'eventgrid'">Events</button> -->
          <button class="btn btn-transparent" (click)="nextPage('events')">Events</button>

          <button class=" btn btn-transparent" (click)="nextPage('blog/weeklyScreener')">Featured Ideas</button>
          <button class=" btn btn-transparent" (click)="nextPage('disclaimer')">Disclaimer</button>
          <button *ngIf="!isAuthenticated()" class="btn btn-std uppercase mx-2 my-2 my-md-0"
                  (click)="openSubscription()">Subscribe</button>
          <button *ngIf="!isAuthenticated()" class="btn btn-std uppercase mx-2 my-2 my-md-0" (click)="openLogin()">Login</button>


           <div *ngIf="isAuthenticated()" ngbDropdown
                class="d-none d-md-inline-block"
                display="dynamic"
                placement="bottom-right">
            <button type="button" class="btn btn-outline-primary overwrite-colors" id="dropdown-menu" ngbDropdownToggle>
              <span >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-person-fill" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
              </span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdown-menu " style="min-width: 250px;">
              <div class="row mx-auto">
                <div class="col-12 text-center">
                  <b> {{currentUserName || ""}}</b>
                </div>

                <div class="col-12  my-2 dropdown-divider"></div>
                <div class="col-12 my-2">
                  <a *ngIf="isCustomer()" href="/user/dashboard"> <button class=" btn btn-std w-100">
                      Your Dashboard
                    </button>
                  </a>
                </div>

                <div class="col-12 my-2" *ngIf="isCustomerEmittent()">
                  <a  href="/corporate/dashboard"> <button class=" btn btn-std w-100">
                      Corporate-Dashboard
                    </button>
                  </a>

                </div>

                <div class="col-12 my-2 dropdown-divider"></div>
                <div class="col-12 my-2">
                  <button *ngIf="isAuthenticated()" class="btn btn-std uppercase w-100"
                    (click)="logOff()">Logout</button>
                </div>


                <div *ngIf="isAdmin() || isContributor()" class="col-12 my-2 dropdown-divider"></div>
                <div class="col-12 my-2">
                  <a href="/admin" *ngIf="canUseAdminPanel()"> <button class="btn btn-std w-100 "> Admin
                      Panel</button>
                  </a>
                </div>
                <div class="col-12 my-2" *ngIf="isAdmin() || isContributor()">
                  <a href="/reportpanel" > <button class="btn btn-std w-100">Upload
                      <span *ngIf="!canUseAdminPanel()"> Research</span>
                    </button>
                  </a>
                </div>


              </div>

            </div>
          </div>


        </div>
  <div class="d-flex flex-column  d-block d-md-none">
        <button class="navbar-toggler btn btn-transparent border-dark" type="btn "
          (click)="toggleNavbar()">
          <span *ngIf="!navbarOpen">
            <i  class="fas fa-bars"></i>
            <i  class="fas fa-arrow-down ml-1"></i>
          </span>
          <span *ngIf="navbarOpen">
             <i  class="fas fa-bars"></i>
             <i  class="fas fa-arrow-up ml-1"></i>
          </span>


        </button>

        <div *ngIf="isAuthenticated() && navbarOpen" ngbDropdown
             class="d-inline-block d-md-none my-3"
             display="dynamic"
             placement="bottom-right">
          <button type="button" class="btn btn-outline-primary overwrite-colors" id="dropdown-menu" ngbDropdownToggle>
              <span >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-person-fill" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
              </span>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdown-menu " style="min-width: 250px;">
            <div class="row mx-auto">
              <div class="col-12 text-center">
                <b> {{currentUserName || ""}}</b>
              </div>

              <div class="col-12  my-2 dropdown-divider"></div>
              <div class="col-12 my-2">
                <a *ngIf="isCustomer()" href="/user/dashboard"> <button class=" btn btn-std w-100">
                  Your Dashboard
                </button>
                </a>
              </div>

              <div class="col-12 my-2" *ngIf="isCustomerEmittent()">
                <a  href="/corporate/dashboard"> <button class=" btn btn-std w-100">
                  Corporate-Dashboard
                </button>
                </a>

              </div>

              <div class="col-12 my-2 dropdown-divider"></div>
              <div class="col-12 my-2">
                <button *ngIf="isAuthenticated()" class="btn btn-std uppercase w-100"
                        (click)="logOff()">Logout</button>
              </div>


              <div *ngIf="isAdmin() || isContributor()" class="col-12 my-2 dropdown-divider"></div>
              <div class="col-12 my-2">
                <a href="/admin" *ngIf="canUseAdminPanel()"> <button class="btn btn-std w-100 "> Admin
                  Panel</button>
                </a>
              </div>
              <div class="col-12 my-2" *ngIf="isAdmin() || isContributor()">
                <a href="/reportpanel" > <button class="btn btn-std w-100">Upload
                  <span *ngIf="!canUseAdminPanel()"> Research</span>
                </button>
                </a>
              </div>


            </div>

          </div>
        </div>

  </div>
      </div>
    </div>
    <div class="row"  [hidden]="!mobileHeader" >
      <div class="col-12">
        <input class="textinput full-width e2e-companysearch"  type="text" [resultTemplate]="rt"
               (focus)="focusMobile$.next($any($event).target.value)" (click)="clickMobile$.next($any($event).target.value)"
               #instanceMobile="ngbTypeahead" placeholder="FIND COMPANY" (selectItem)="goToCompanyPage($event)"
               [resultFormatter]="formatter" [ngbTypeahead]="searchMobile" label="companysearch" />
      </div>
      <div class="col-12">
        <app-transactionbanner [mobileHeader]="mobileHeader"
                               *ngIf="transactionMode && mobileHeader && !navbarOpen"></app-transactionbanner>
      </div>
    </div>
  </div>

</nav>
<app-transactionbanner *ngIf="transactionMode && !mobileHeader"></app-transactionbanner>
