
import { environment } from '../../environments/environment';

// this file contains the URLs for the API endpoints
// this is the production URL
// it is used on all endpoints

export const leewayAPI_URL = environment.backendLeewayURL + '/api/v1/'
// export const leewayAPI_URL ="http://localhost:3000/api/v1/" 

// this is the URL for the PDFs
// it is used on the uploadPDFs endpoint, but only as content of an pdf-download-link
export const alsterPDF_URL = environment.alsterPDF_URL

//export const alsterAPI_URL = "https://api.research-hub.de/api/" 
export const alsterAPI_URL = environment.backendAlsterURL+"/api/" 

//export const researchHub_URL = "https://research-hub.de/"
//export const researchHub_URL = "https://investor-hub.de/" 
export const researchHub_URL = environment.frontendURL //"http://localhost:4200/"


