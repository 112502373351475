<div class="create-event-wrapper container-fluid">
    <div class="row py-5">
      <div class="col-11">
        <h3>Create new Company</h3>
      </div>
      <div class="col-1">
        <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        Ticker
      </div>
      <div class="col-8">
        <input class="form-control" placeholder="Ticker" [(ngModel)]="id">
      </div>
    </div>
    <div class="row">
        <div class="col-4">
          Name
        </div>
        <div class="col-8">
          <input class="form-control" placeholder="Company name" [(ngModel)]="name">
        </div>
      </div>
    <hr>
  
  
  
  
    <div class="row py-5">
      <app-button (click)="createCompany()" color="primary">Create new company</app-button>
      {{err}}
    </div>
  </div>
  