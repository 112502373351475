<div class="d-flex pt-3 justify-content-start">
  <div class="pl-4">
    <h3>Settings</h3>
  </div>
  <div class="pl-4" >
    <button class="btn btn-secondary"
      *ngIf="(showDeletionWarning[company.bloombergTicker] === null || showDeletionWarning[company.bloombergTicker] === undefined) || showDeletionWarning[company.bloombergTicker] === false"
      (click)="deleteCompany(false,company.bloombergTicker)">{{company.name}} /
      {{company.bloombergTicker}}
      delete
    </button>
    <button class="btn btn-sm btn-std" style="background-color:red;"
      *ngIf="showDeletionWarning[company.bloombergTicker] === true"
      (click)="deleteCompany(true,company.bloombergTicker)">{{company.name}} /
      {{company.bloombergTicker}}
      delete
    </button>
  </div>
</div>
<div class="container-fluid custom-container p-3">
  <div class="row">
    <div class="col-12 d-flex flex-wrap pb-2" style="gap: 0.5rem">
      <div class="col-3 border text-center px-1 pt-1">
        <div class="border-bottom" style="background-color: #4950571c;">
          <div class="col-12 p-2">
            <strong>Name</strong>
          </div>
        </div>
        <div class="">
          <div class="col-12 p-2">
            <a [routerLink]="['/companies/investment-case/',company.name]">{{company.name}}</a>
          </div>
        </div>
      </div>
      <div class="col-2 border text-center px-1 pt-1">
        <div class="border-bottom" style="background-color: #4950571c;">
          <div class="col-12 p-2">
            <strong>Ticker</strong>
          </div>
        </div>
        <div class="">
          <div class="col-12 p-2">
            {{company.bloombergTicker}}
          </div>
        </div>
      </div>
      <div class="col-2 border text-center px-1 pt-1">
        <div class="border-bottom" style="background-color: #4950571c;">
          <div class="col-12 p-2">
            <strong>Visibility</strong>
          </div>
        </div>
        <div class="">
          <div class="col-12 p-2">
            <button *ngIf="company.hidden" class="btn btn-sm btn-inverted-gray"
                        (click)="hideCompany(false)">show</button>
                      <button *ngIf="!company.hidden" class="btn btn-sm btn-std"
                        (click)="hideCompany(true)">hide</button>
          </div>
        </div>
      </div>
      <div class="col-2 border text-center px-1 pt-1">
        <div class="border-bottom" style="background-color: #4950571c;">
          <div class="col-12 p-2">
            <strong>Prefill pages</strong>
          </div>
        </div>
        <div class="">
          <div class="col-12 p-2">
            <button class="btn btn-sm btn-std" (click)="openSubpageLayoutSelection(company.bloombergTicker)">
              Prefill
            </button>
          </div>
        </div>
      </div>
      <div class="col-1 border text-center px-1 pt-1">
        <div class="border-bottom" style="background-color: #4950571c;">
          <div>
            <button class="btn btn-sm btn-inverted-gray"
                        (click)="openLogoUploader(company.bloombergTicker)">Upload logo </button>
          </div>
        </div>
        <div class="">
          <div class="col-12 p-2">
            <small>
              <span class="bg-gray" [autoClose]="'outside'" [closeDelay]="3000" ngbPopover="{{company.logoUrl}}"
                triggers="mouseenter:mouseleave">show url</span>
            </small>
          </div>
        </div>
      </div>
      <div class="col-1 border text-center px-1 pt-1">
        <div class="border-bottom" style="background-color: #4950571c;">
          <div>
            <button class="btn btn-sm btn-inverted-gray"
                        (click)="openCompanyImageUploader(company.bloombergTicker)">Upload image </button>
          </div>
        </div>
        <div class="">
          <div class="col-12 p-2">
            <small>
              <span class="bg-gray" [autoClose]="'outside'" [closeDelay]="3000" ngbPopover="{{company.imageURL}}"
              triggers="mouseenter:mouseleave">show url</span>
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex flex-wrap" style="gap: 0.5rem">
      <div class="col-4 d-flex flex-wrap border text-center px-1 pt-1">
        <div class="col-12 border-bottom d-flex flex-wrap" style="background-color: #4950571c;">
          <div class="col-8 p-2">
            <strong>Chart Settings</strong>
          </div>
          <div class="col-4 p-2">
            <button class="btn btn-sm btn-std" (click)="setCharts()">Apply</button>
          </div>
        </div>
        <div class="col-12 p-2 d-flex flex-wrap">
          <div class="col-4 d-flex align-items-center">
            <span class="text-muted"> Chart #1 </span>
          </div>
          <div class="col-8">
            <input name="charts1" type="text" placeholder="#1 Charts names" class="smallerInput"
                        [(ngModel)]="company.charts[0]">
          </div>
        </div>
        <div class="col-12 p-2 d-flex flex-wrap">
          <div class="col-4 d-flex align-items-center">
            <span class="text-muted"> Chart #2 </span>
          </div>
          <div class="col-8">
            <input name="charts2" type="text" placeholder="#2 Chart names" class="smallerInput"
            [(ngModel)]="company.charts[1]">
          </div>
        </div>
      </div>

      <div class="col-4 d-flex flex-wrap border text-center px-1 pt-1">
        <div class="col-12 border-bottom d-flex flex-wrap align-items-center" style="background-color: #4950571c;">
          <div class="col-8 p-2">
            <strong>Ticker Mapping</strong>
          </div>
          <div class="col-4 p-2">
            <button [disabled]="isLoading" (click)="saveExchangeToMapping()" class="btn btn-std btn-sm">
              Apply
              <!-- disabled if loading  -->
            </button>
          </div>
        </div>
        <div class="col-12 p-2 d-flex flex-wrap">
          <div class="col d-flex align-items-center">
            <span class="text-muted text-left"> New Ticker: </span>
          </div>
          <div class="col d-flex align-items-center">
            <input [(ngModel)]="newMappingSet.mappedTicker" placeholder="New Ticker"> 
            <!-- add ngModel -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex py-3 justify-content-end">
  <div class="pr-3" >
    <button ngbAutofocus (click)="activeModal.close()" class="btn btn-std">Close</button>
  </div>
</div>