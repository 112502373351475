import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { DataServiceService } from "../../../services/data-service.service";
import { Tables } from "src/app/utilities/tables.util";

@Component({
  selector: "app-financials-table",
  templateUrl: "./financials-table.component.html",
  styleUrls: ["./financials-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialsTableComponent implements OnInit, OnChanges {
  @Input() companyId: string;
  @Input() companyData: any = {};
  @Input() templateName = "financials-GKV";
  @Input() decimals = 0;

  public headline: string = "";
  expanded = false;
  //put this as tableTemplate in DB. Allow template selection in table creation
  //write UI to create templates
  // -> select  and set commonSize true/false
  // -> select keys
  // -> mark highlight and (NO)DATA/DAtes
  // -> validate everything marked DATA (default) is contained in mapping

  tableLayout = this.tableUtil.defaultLayout;

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: "Company data; mwb research",
    ger: "Firmenangaben; mwb research",
  };

  constructor(
    private tableUtil: Tables,
    private dataService: DataServiceService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataService
      .getTableTemplate(this.templateName)
      .subscribe((res: any) => {
        this.tableLayout = res.template;
        this.tableUtil
          .createTableData(
            this.companyData,
            this.tableLayout,
            this.decimals,
            this.companyId
          )
          .then((table) => {
            this.tableLayout = table;
            this.headline = res.settings.title[table[0].settings.language];

            this.cdr.detectChanges();
          });
      });
  }

  ngOnChanges(): void {
    this.dataService
      .getTableTemplate(this.templateName)
      .subscribe((res: any) => {
        this.tableLayout = res.template;
        this.tableUtil
          .createTableData(
            this.companyData,
            this.tableLayout,
            this.decimals,
            this.companyId
          )
          .then((table) => {
            this.tableLayout = table;
            this.headline = res.settings.title[table[0].settings.language];
            this.cdr.detectChanges();
          });
      });
  }
}
