<div class="block-pdf-list-wrapper" [ngClass]="{ 'border rounded': adminMode }" id="{{data.blockTag}}">
  <div class="text-centerx" *ngIf="!isEditorMode">
    <h3>{{ headline }}</h3>
    <div *ngIf="!data || data.pdfLinks.length <= 0" class="text-center">
      No PDFs uploaded
    </div>

    <span *ngIf="loadingLatestPdfs && alwaysGetLatestResearch"> loading...</span>

    <div *ngIf="data && layoutOptionPDFSlider && !reportPanelMode " id="carouselExampleControls" class="carousel slide"
      data-ride="carousel" class="container-fluid mx-0 px-0">

      <div *ngIf="!reportPanelMode" class="carousel-inner mt-4" role="listbox">
        <div class="row">
          <div class="col-lg-1 col-sm-2 my-auto text-center px-0">
            <i class="fas fa-arrow-left icon-4x fingerFix largerIcon" (click)="getPrev()"></i>
            <!-- <button class="align-middle" >Prev</button> -->
          </div>
          <div class="col-lg-10 col-sm-8">
            <div class="row">
              <div *ngFor="let item of data.pdfLinks; let i = index "
                [ngClass]="'col-' + 12/(min(data.pdfLinks.length, shownPDFsinCarousel))">
                <div *ngIf="i < shownPDFsinCarousel">
                  <figure class="content shadow-sm my-3" style="height:165px; width:117px;">
                    <a target="_blank" Pdfdownload [clickedURL]="data.pdfLinks[carouselIndex+i].url"
                      [href]="data.pdfLinks[carouselIndex+i].url">
                      <div class="content-overlay"></div>
                      <img src="{{ showSlide(data.pdfLinks, carouselIndex + i) }}" alt="Latest Research"
                        class="mx-auto img-responsive" style="height:165px;   width:100%;   object-fit: cover"
                        [ngClass]="{'file-preview__img_quer':pdfMode==='companypresentation' }">
                      <figcaption class="content-details fadeIn-bottom">
                        <p class="content-text text-wrap">
                          {{data.pdfLinks[carouselIndex + i].shownName}}</p>
                      </figcaption>
                    </a>
                  </figure>
                  <div class="text-center content-text text-wrap my-3" *ngIf="data.companyNames">
                    <small>
                      <a Pdfdownload [clickedURL]="data.pdfLinks[carouselIndex+i].fileURL"
                        [href]="data.pdfLinks[carouselIndex+i].fileURL" target="_blank">
                        {{data.companyNames[carouselIndex + i]}}
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-1 col-sm-2 my-auto text-center px-0">
            <i class="fas fa-arrow-right icon-4x fingerFix largerIcon" (click)="getNext()"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data && layoutOptionImageList && !reportPanelMode" class="container-fluid mx-0 px-0 my-2">
      <div class="row">
        <div *ngFor="let pdf of data.pdfLinks.slice(0,numPdfsShown)" class="col-lg-3 col-md-6 col-sm-12 my-2">
          <figure class="content shadow my-3" style="height:165px; width:117px;">
            <span *ngIf="pdf.imageURL && pdf.imageURl !== ''">
              <a target="_blank" [href]="pdf.url" Pdfdownload [clickedURL]="pdf.url">
                <div class="content-overlay"></div>
                <img [src]="pdf.imageURL" alt="Company PDF" class="mx-auto img-responsive"
                  style="height:165px;   width:100%;   object-fit: cover"
                  [ngClass]="{'file-preview__img_quer':pdfMode==='companypresentation ','img-thumbnail':pdfMode !=='companypresentation' }"
                  alt="pdf-image">
                <figcaption class="content-details fadeIn-bottom">
                  <p class="content-text text-wrap">
                    {{ pdf.shownName }}
                  </p>
                </figcaption>
              </a>
            </span>
          </figure>
          <div class="text-center content-text text-wrap my-3">
            <small>
              <a [href]="pdf.url" target="_blank" Pdfdownload [clickedURL]="pdf.url">
                {{ pdf.shownName }}
              </a>
            </small>
          </div>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-12 text-center" *ngIf="displayMode!=='companyPage'">
          <button (click)="showMorePDFs()" *ngIf="numPdfsShown < data.pdfLinks.length" class="btn smaller"><i
              class="fas fa-angle-down mr-2"></i>show more</button>
        </div>
      </div>
    </div>

    <div *ngIf="data && layoutOptionList && data.pdfLinks.length > 0 && !reportPanelMode"
      class="container-fluid mx-0 px-0">
      <table class="table table-sm">
        <tr>
          <th class="tabHeader" scope="col">Title</th>
          <th class="text-center tabHeader" scope="col">PDF</th>
        </tr>
        <tr *ngFor="let pdf of data.pdfLinks">
          <td> <a [href]="pdf.url" target="_blank">
              {{ pdf.shownName }}
            </a></td>
          <td class="text-center" style="width:20% !important">

            <a class="btn btn-login" type="button" [href]="pdf.url" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                <path
                  d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                <path
                  d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              </svg>
            </a>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="data && layoutOptionDetailedList && !reportPanelMode" class="container-fluid mx-0 px-0">
      <div class="row">
        <div class="col-12" *ngFor="let pdf of data.pdfLinks.slice(0,numPdfsShown); let i = index">
          <div class="row my-4">
            <div class="col-lg-3 col-md-12 pl-4 pr-2 my-1">
              <span *ngIf="pdf.imageURL">
                <a class="d-block" target="_blank" [href]="pdf.url" Pdfdownload [clickedURL]="pdf.url">
                  <img class="file-preview__img" [src]="pdf.imageURL" alt="no image" />
                </a>
              </span>

              <span *ngIf="!pdf.imageURL">
                <a class="d-block" target="_blank" [href]="pdf.url" Pdfdownload [clickedURL]="pdf.url">
                  <img class="file-preview__img" src="assets/blankpdf.jpg" alt="no image" />
                </a>
              </span>

            </div>

            <div class="col-lg-9 col-md-12 px-2 my-1">
              <h5>
                <a [href]="pdf.url" target="_blank" Pdfdownload [clickedURL]="pdf.url">
                  {{ pdf.shownName }}
                </a>
              </h5>

              <p>{{ pdf.teaserText }}</p>
            </div>
          </div>
          <hr *ngIf="index < numPdfsShown" class="my-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-10"></div>
        <div class="col-2"><a *ngIf="numPdfsShown < data.pdfLinks.length" class="fingerFix"
            (click)="showMorePDFs()">show more</a></div>
      </div>
    </div>

    <div class="row" *ngIf="adminMode && !changeMode && !reportPanelMode">
      <div class="col-12">
        <h3>
          <hr />
        </h3>
      </div>
      <div class="col-2">
        <button *ngIf="adminMode && !changeMode" class="btn  blockButton  btn-sm form-control btn-inverted-gray"
          (click)="startChangeMode()">
          Show settings</button>

      </div>
    </div>

    <div class="row" *ngIf="adminMode && changeMode && !reportPanelMode">
      <div class="col-12">
        <h3>
          <hr>
        </h3>
      </div>
      <div class="col-2">
        <button *ngIf="adminMode && !changeMode" class=" blockButton btn btn-sm form-control btn-inverted-gray"
          (click)="startChangeMode()">Show settings</button>
        <button *ngIf="adminMode && changeMode" class=" blockButton btn btn-sm form-control btn-inverted-gray"
          (click)="endChangeMode()">Hide settings</button>
      </div>
      <div class="col-4"></div>
      <div class="col-2">
        <button
          ngbPopover="Um gerade erst eingefügte Blöcke zu löschen, muss die Seite einmal neu geladen werden. (reload oder klick auf andere unterseite)"
          triggers="mouseenter:mouseleave" popoverTitle="Info"
          class=" blockButton btn btn-sm btn-inverted-gray form-control" (click)="deleteBlock()">Delete block </button>
      </div>
      <div class="col-3">
        <button *ngIf="!notInitalized" class=" blockButton btn btn-inverted-gray form-control"
          (click)="saveAllChanges()">Save all changes</button>
      </div>
      <div class="col-1"></div>
      <div class="col-3" *ngIf="adminMode && changeMode">
        <label for="blockTagInput"><small>Block Tag</small></label>
        <input name="blockTagInput" type="text" [(ngModel)]="data.blockTag">
      </div>
    </div>
    <div *ngIf="adminMode && changeMode" style="background-color: lightcyan" class="mx-2 my-2 px-4 py-4">
      <div class="row" *ngIf="notInitalized && !reportPanelMode">
        <div class="col-12 text-center">
          <h5>Settings for {{ translate(blockType) }}</h5>
        </div>
        <div class="col-12">
          <p>
            1. Save a Bloomberg Ticker. Only after that other settings are shown.
          </p>

          <p class="text-center">Bloomberg Ticker</p>
          <p class="text-center">
            <input type="text" name="bloombergticker" id="bloombergticker" placeholder="bloombergticker"
              [(ngModel)]="data.bloombergTicker" />
          </p>
          <p>
            The type of reports can be chosen. "Research" or
            "Company-Reports". Default: {{ pdfMode }}
          </p>
          <p class="text-center">Art der PDFs</p>
          <div class="btn-group btn-group-toggle btn-group-sm text-center d-block">
            <button class="btn btn blockButton " [ngClass]="{
                'btn-std': pdfMode === 'research',
                'btn-inverted-gray': pdfMode !== 'research'
              }" (click)="setPDFMode('research', false)">
              Research-PDFs
            </button>

            <button class="btn btn blockButton " [ngClass]="{
                'btn-std': pdfMode === 'reports',
                'btn-inverted-gray': pdfMode !== 'reports'
              }" (click)="setPDFMode('reports', false)">
              Company-Reports
            </button>

            <button class="btn btn-sm blockButton " [ngClass]="{
                'btn-std': pdfMode === 'factsheet',
                'btn-inverted-gray': pdfMode !== 'factsheet'
              }" (click)="setPDFMode('factsheet', true)">
              Factsheets
            </button>

            <button class="btn btn-sm blockButton " [ngClass]="{
                'btn-std': pdfMode === 'companypresentation',
                'btn-inverted-gray': pdfMode !== 'companypresentation'
              }" (click)="setPDFMode('companypresentation', true)">
              Company-Presentation
            </button>
          </div>
          <p class="text-center my-5">
            <button class="btn btn-std blockButton " (click)="initBlock(pdfMode)">
              Initialize block
            </button>
          </p>
        </div>
      </div>
      <div reportPanelMode class="row" *ngIf="!notInitalized && !reportPanelMode">
        <div class="col-12 text-center my-4">
          <h5>Settings for {{ translate(blockType) }}</h5>
        </div>

        <div class="col-5 px-3 border-right" style="max-width: 100% !important">
          <div class="row">
            <div class="col-12">Ticker: {{ bloombergTicker }}</div>
          </div>
          <div class="row">
            <hr />
          </div>
          <div class="row">
            <div class="col-12">Headline for PDF-category:</div>
            <input class="form-control col-12" type="text" name="headline" id="headline" placeholder="Block-Überschrift"
              [(ngModel)]="data.headline" />
          </div>
          <div class="row">
            <hr />
          </div>
          <div class="row">
            <div class="col-12">
              <small>Style</small>
            </div>
            <div class="btn-group btn-group-toggle btn-group-sm col-12">
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': layoutOptionList,
                  'btn-inverted-gray': !layoutOptionList
                }" (click)="setLayout('list')">
                Simple list
              </button>
              <button class="  blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': layoutOptionImageList,
                  'btn-inverted-gray': !layoutOptionImageList
                }" (click)="setLayout('imageList')">
                List with images
              </button>
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': layoutOptionDetailedList,
                  'btn-inverted-gray': !layoutOptionDetailedList
                }" (click)="setLayout('detailedList')">
                List with images and text
              </button>
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                'btn-std': layoutOptionPDFSlider,
                'btn-inverted-gray': !layoutOptionPDFSlider
              }" (click)="setLayout('PDFSlider')" [disabled]="data.pdfLinks.length < shownPDFsinCarousel"
                ngbPopover="For this option there must be at least 4 PDFs." triggers="mouseenter:mouseleave"
                popoverTitle="Info">
                PDF-Slider
              </button>
            </div>
          </div>
          <div class="row">
            <hr />
          </div>
          <div class="row">
            <div class="col-12">
              <small>Type of PDFs</small>
            </div>
            <div class="btn-group btn-group-toggle btn-group-sm col-12">
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': pdfMode === 'research',
                  'btn-inverted-gray': pdfMode !== 'research'
                }" (click)="setPDFMode('research', true)">
                Research-PDFs
              </button>
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': pdfMode === 'reports',
                  'btn-inverted-gray': pdfMode !== 'reports'
                }" (click)="setPDFMode('reports', true)">
                Company-Reports
              </button>
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': pdfMode === 'factsheet',
                  'btn-inverted-gray': pdfMode !== 'factsheet'
                }" (click)="setPDFMode('factsheet', true)">
                Factsheets
              </button>
              <button class=" blockButton btn btn-sm form-control" [ngClass]="{
                  'btn-std': pdfMode === 'companypresentation',
                  'btn-inverted-gray': pdfMode !== 'companypresentation'
                }" (click)="setPDFMode('companypresentation', true)">
                Company-Presentation
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 px-3" style="background-color: lightcyan">
          Saved PDFs <small>(Change order with Drag&Drop )</small>
          <div cdkDropList class="example-list-dragdrop my-2" (cdkDropListDropped)="drop($event)">
            <div class="text-center my-2" *ngIf="this.data.pdfLinks.length <= 0">
              <div class="row my-2">
                <div class="col-12 text-center">No PDFS saved yet.</div>
              </div>
              <div class="row">
                <div class="col-3"></div>
                <button class="col-6  blockButton btn btn-std form-control" *ngIf="!uploaderMode"
                  (click)="showUploader()">Upload PDF</button>
                <button class="col-6  blockButton btn btn-inverted-gray" *ngIf="uploaderMode"
                  (click)="showUploader()">Hide PDF-uploader
                </button>

                <div class="col-3"></div>
              </div>
            </div>
            <div class="example-box-dragdrop px-2 py-2"
              *ngFor="let pdf of this.data.pdfLinks.slice(0,shownAdminPDFs); let i = index" cdkDrag>
              <div class="my-1">
                <div class="example-custom-placeholder-dragdrop" *cdkDragPlaceholder></div>

                <h6 class="text-center"
                  style="display:flex; flex-direction: row; align-items: center; justify-content: space-between;">
                  PDF {{i+1}}:
                  <small class="fingerFix" (click)="deletePDF(pdf)">X </small>
                </h6>
                <p class="text-center"
                  style="display:flex; flex-direction: row; align-items: center; justify-content: space-between;">
                  Shown Name: <span>{{pdf.shownName}}</span> <span (click)="toggleChangeName(i)"
                    ngbPopover="A new name for the PDF can be entered here. Click on 'Save all changes' to save the new name."
                    triggers="mouseenter:mouseleave" popoverTitle="Info" class="fingerFix"><small>edit</small></span>
                </p>
                <small *ngIf="pdfsInChangeMode[i]">Name</small>
                <p *ngIf="pdfsInChangeMode[i]"><span style="width:100%"><input style="width:100%" type="text"
                      name="shownname" id="shownname" [(ngModel)]="pdf.shownName" /></span></p>
                <small *ngIf="pdfsInChangeMode[i]">Image-URL</small>
                <p *ngIf="pdfsInChangeMode[i]"><span style="width:100%"><input style="width:100%" type="text"
                      name="imageURL" id="imageURL" [(ngModel)]="pdf.imageURL" /></span></p>
                <p>
                  <textarea rows="2" cols="33" style="width:100%; height:100%;" type="text" name="teaserText"
                    class="form-control" id="teaserText" placeholder="Hier kann eine Beschreibung stehen."
                    [(ngModel)]=pdf.teaserText></textarea>
                </p>

              </div>
            </div>
            <div class="col-12 text-center">
              <!-- <span class="  text-muted">show all ({{myEvents.length}} more)</span> -->
              <button (click)="shownAdminPDFs=this.data.pdfLinks.length" class="btn"> <i class="fas fa-angle-down"></i>
                show all
                ({{this.data.pdfLinks.length - shownAdminPDFs}} more)</button>

              <button (click)="shownAdminPDFs=maxAdminPDFs" class="btn text-muted smaller">show less </button>
            </div>
          </div>

        </div>
      </div>
      <!-- below this is shown when reportPanelMode is true, everything else is hidden-->
      <div class="row my-4">
        <h5>{{statusMessage}}</h5>
        <button class="col-6 btn blockButton  btn-std form-control" *ngIf="!uploaderMode"
          [ngClass]="{'col-12':reportPanelMode, 'col-6':!reportPanelMode}" (click)="showUploader()">Upload PDF</button>
        <div class="col-4" *ngIf="!reportPanelMode">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
              [(ngModel)]="data.alwaysGetLatestResearch" (change)="onCheckBoxChange(data.alwaysGetLatestResearch)">
            <label class="form-check-label" for="flexCheckDefault"
              ngbPopover="This option always loads the 15 most recently uploaded PDFs. As long as the option is active, manually deleted PDFs will also be reloaded.
              After loading the 15 PDFs, the option can be deactivated to delete entries. However, the list is then no longer updated automatically."
              triggers="mouseenter:mouseleave" popoverTitle="Info">
              Always load latest PDFs
              <small>Warning: The current PDF-list will be overwritten!</small>
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
