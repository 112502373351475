<plotly-plot  *ngIf="chart" 
    [data]="chart.data" 
    [layout]="chart.layout" 
    [config]="chart.config" 
    [useResizeHandler]="chart.useResizeHandler"
    [style]="chart.style"></plotly-plot>
    <!--
        setting parent height on the chart component does not work
        problem with plotly and flex
        https://community.plotly.com/t/plot-sizing-problems/1620/30

        https://community.plotly.com/t/cant-seem-to-change-default-height-on-graph/6742/4
    -->