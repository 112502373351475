import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { alsterAPI_URL } from "../../../app-settings/api-urls";

@Injectable({
  providedIn: "root",
})
export class FileService {
  private URL = alsterAPI_URL + "fileupload";
  constructor(private http: HttpClient) {}
  uploadFiles(formData: FormData): Observable<any> {
    const url = this.URL + "/uploadfile";
    return this.http.post(url, formData);
  }

  getFileList(
    page: number,
    pageSize: number,
    searchTerm: string,
    searchOptions: any
  ): Observable<any> {
    const url = `${this.URL}/list?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&pdf=${searchOptions.pdf}&image=${searchOptions.image}`;
    return this.http.get(url);
  }

  syncFileList(): Observable<any> {
    return this.http.post(`${this.URL}/sync`, {});
  }
  getFileMetadata(filename: string): Observable<any> {
    return this.http.get(`${this.URL}/metadata/${filename}`);
  }
}
