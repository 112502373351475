import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'HTMLSpecialChar'
})
export class HTMLSpecialCharPipe implements PipeTransform {

  constructor() { }


  public transform(value: any): string {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = value
    return tempElement.innerText
  }

}
