import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: {headline: string, type: string, content: string[], height: number, width: number, mobile: boolean, decimals: number};
  @Input() lang = 'eng';
  @Input() valueTable = false;

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  data: [{title: string, value: string[]}];
  dates: string[];
  index: number = 0;

  constructor(private tableUtils: Tables) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{

    this.prepareData();
  }

  prepareData() {
    this.data = null;
    let numFigures = 3;
    if(this.mapping && this.companyData  && this.companyData.base){
      for(let key of this.config.content){

        if(this.mapping[key]){
          let mappingEntry = this.mapping[key];
          if(mappingEntry.type === 'VALUE'){
            let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.config.decimals)};

            if (key === 'mc' && this.valueTable) {// hack for market cap
              entry['valueModifier'] = (this.companyData?.base?.quote_curr || this.companyData?.base?.curr) + 'm';
            }

            if(this.data){
              this.data.push(entry);
            }else{
              this.data = [entry];
            }
          } else if(mappingEntry.type === 'TIMESERIES'){
            let quarterlyPattern = /_q$/;
            //crap hack
            //uses halfyearly data if quarterly isnt there
            if(quarterlyPattern.test(key)
              && (this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0] === 'na' || this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0].includes('WERT'))
              && (this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[1] === 'na' || this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0].includes('WERT'))
              ) {
              if(this.tableUtils.getTimeseries(key.replace('_q','_h'), this.companyData, this.mapping[key.replace('_q','_h')], -1, numFigures, 'value', this.lang, this.config.decimals)[0] !== 'na'){
                key = key.replace('_q','_h');
                mappingEntry = this.mapping[key.replace('_q','_h')];
                this.tileEvent.emit({ type: 'half-year'})
              }
            }

            if(!this.dates){
              this.dates = this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'date', this.lang, this.config.decimals);
            }

            let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)};
            
            if(this.data){
              this.data.push(entry);
            }else{
              this.data = [entry];
            }
          }
        }

      }
    }
  }
}
