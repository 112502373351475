import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataServiceService } from '../../../services/data-service.service';
import { utcToZonedTime, zonedTimeToUtc,format } from 'date-fns-tz';
import { parseISO } from 'date-fns';


interface eventType {
  date: any;
  title: string;
  isPublished: boolean;
  isArchived: boolean;
  videoURL: string;
  presentationURL: string;
  companyName: string;
}



@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})



export class CreateEventComponent implements OnInit {

  @Input() mode = "createEvent"
  @Input() currentEvent

  dateA: String = ""
  noDate = false;
  time = { hour: 0, minute: 0 };
  today = new Date(Date.now())
  newDate = null

  data: eventType = {
    title: "",
    isArchived: false,
    isPublished: false,
    presentationURL: "",
    videoURL: "",
    companyName: "",
    date: {
      "year": this.today.getFullYear(),
      "month": this.today.getMonth() + 1,
      "day": this.today.getDate(),
    }

  };

  constructor(public activeModal: NgbActiveModal, private dataService: DataServiceService) {
    if (this.currentEvent) {
      this.newDate = this.formatDate(this.currentEvent.date)
    }
  }

  ngOnInit(): void {

  }

  sendEvent() {
    this.data.date["hour"] = this.time.hour;
    this.data.date["minute"] = this.time.minute;
    let date = this.createNewDateInAlsterTZ(this.data.date.year,this.data.date.month,this.data.date.day,this.data.date.hour,this.data.date.minute,0)
    if (this.noDate) {
      date = null
    }
    this.data.date = date
    this.activeModal.close(this.dataService.addEvent(this.data).subscribe(() => {
      window.location.reload()
    }));
  }
  saveNewDate() {

    this.newDate["hour"] = this.time.hour;
    this.newDate["minute"] = this.time.minute;
    this.currentEvent.date = this.createNewDateInAlsterTZ(this.newDate.year, this.newDate.month, this.newDate.day, this.newDate.hour, this.newDate.minute, 0)
    this.activeModal.close(this.currentEvent)
  }



  formatDate(input) {
    let formattedDate = {}
      input = new Date(input)
      formattedDate["year"] = input.getFullYear()
      formattedDate["month"] = input.getDate()
      formattedDate["day"] = input.getMonth() + 1
      formattedDate["hour"] = input.getHours()
      formattedDate["minute"] = input.getMinutes()
    

    return formattedDate
  }

createNewDateInAlsterTZ(year, month, day, hours, minutes, seconds):string {
  const clientTime = new Date(year, month - 1, day, hours, minutes, seconds);

  const pattern = 'EEE MMM dd yyyy HH:mm:ss \'GMT\'XXX'

  const alsterTime = format(clientTime, pattern, { timeZone: 'Europe/Berlin' });
  return alsterTime;
}
}

export class NgbdTimepickerBasic {
  time = { hour: 13, minute: 30 };
}
