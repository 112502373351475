import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { CompanyInfoService } from '../../../services/company-info.service';
import { DataServiceService } from '../../../services/data-service.service';
import { formatValue } from '../../../utilities/numberFormatting.util';
import { Tables } from '../../../utilities/tables.util';

@Component({
  selector: 'app-dcf-model',
  templateUrl: './dcf-model.component.html',
  styleUrls: ['./dcf-model.component.scss']
})
export class DcfModelComponent implements OnInit {
  @Input() companyData: any = {};
  @Input() dcfType = 'Default';

  dcfData = {
    HL_dcf: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_nopat: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_depr: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_chg_wc: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_chg_long_prov: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_capex: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_cash_flow: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_present_value: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    RW_dcf_wacc: {
      value: [],
      key: '',
      type: 'TIMESERIES'
    },
    dcf_share_price: { key: '', value: '' },
    dcf_tpv: { key: '', value: '' },
    dcf_mid_yr_adj: { key: '', value: '' },
    dcf_net_debt: { key: '', value: '' },
    dcf_fin_asset: { key: '', value: '' },
    dcf_prov: { key: '', value: '' },
    dcf_eq_value: { key: '', value: '' },
    dcf_nosh: { key: '', value: '' },
    dcf_dcfps: { key: '', value: '' },
    dcf_upside: { key: '', value: '' },
    dcf_plan_hor: { key: '', value: '' },
    dcf_term_val_gro: { key: '', value: '' },
    dcr_roce: { key: '', value: '' },
    dcf_term_val_wacc: { key: '', value: '' },
    dcf_share_a: { key: '', value: '' },
    dcf_share_b: { key: '', value: '' },
    dcf_share_c: { key: '', value: '' },
    dcf_cost_borr: { key: '', value: '' },
    dcf_long_term_tax_rate: { key: '', value: '' },
    dcf_beta: { key: '', value: '' },
    dcf_unlevered_beta: { key: '', value: '' },
    dcf_target_debt_equity: { key: '', value: '' },
    dcf_relevered_beta: { key: '', value: '' },
    dcf_rfr: { key: '', value: '' },
    dcf_erp: { key: '', value: '' },
    dcf_cost_of_debt: { key: '', value: '' },
    sensi_a: { key: '', value: '' },
    sensi_b: { key: '', value: '' },
    sensi_c: { key: '', value: '' },
    sensi_d: { key: '', value: '' },
    sensi_e: { key: '', value: '' },
    sensi_f: { key: '', value: '' },
    sensi_g: { key: '', value: '' },
    sensi_h: { key: '', value: '' },
    sensi_i: { key: '', value: '' },
    sensi_j: { key: '', value: '' },
    sensi_k: { key: '', value: '' },
    sensi_l: { key: '', value: '' },
    sensi_m: { key: '', value: '' },
    sensi_n: { key: '', value: '' },
    sensi_o: { key: '', value: '' },
    sensi_p: { key: '', value: '' },
    sensi_q: { key: '', value: '' },
    sensi_r: { key: '', value: '' },
    sensi_s: { key: '', value: '' },
    sensi_t: { key: '', value: '' },
    sensi_u: { key: '', value: '' },
    sensi_v: { key: '', value: '' },
    sensi_w: { key: '', value: '' },
    sensi_x: { key: '', value: '' },
    sensi_y: { key: '', value: '' },
    sensi_z: { key: '', value: '' },
    sensi_aa: { key: '', value: '' },
    sensi_ab: { key: '', value: '' },
    sensi_ac: { key: '', value: '' },
    sensi_ad: { key: '', value: '' },
    sensi_ae: { key: '', value: '' },
    sensi_af: { key: '', value: '' },
    sensi_ag: { key: '', value: '' },
    sensi_ah: { key: '', value: '' },
    sensi_ai: { key: '', value: '' },
  }

  baseYear;
  notApplicable = 'na';
  language = 'eng';
  defaultDecimals = 1;

  start = 2020;
  mid = 2024;
  mid2 = 2025;
  end = 2028;

  constructor(private tableUtil: Tables, private dataService: DataServiceService, private companyInfoService: CompanyInfoService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    of(this.companyData).subscribe(res => {
      if(res){
        this.prepareData();
      }
    })
  }

  async prepareData() {
    let mapping = await this.dataService.getMapping();
    let bY = this.companyInfoService.getCompanyDataByBloombergTicker(this.companyData.CompanyId)?.baseyear;

    if (typeof bY !== 'undefined' && bY !== null) {
      this.baseYear = bY.toString();
    }

    let dcfDates = this.tableUtil.getData(mapping['HL_dcf'].path, this.companyData);
    this.start = dcfDates[0].value;
    this.mid = dcfDates[3].value
    this.mid2 = dcfDates[4].value
    this.end = dcfDates[dcfDates.length - 2].value;

    for (let key in this.dcfData) {
      let entry = this.dcfData[key];

      if (entry.type === 'TIMESERIES') {
        entry.value = this.tableUtil.getTimeseries(key, this.companyData, mapping[key], -1, 10, 'value', 'eng', 1);
      } else {
        entry.value = this.tableUtil.getValue(this.companyData, mapping[key], this.language, this.defaultDecimals);
      }

      if (key === 'dcf_plan_hor') {
        entry.key = mapping[key][this.language] + ' (' + this.start + ' - ' + this.end + ')';
      } else if (key === 'dcf_term_val_gro') {
        entry.key = mapping[key][this.language] + ' (' + this.end + ' - infinity)';
      } else if (key === 'dcf_dcfps') {
        entry.value = parseFloat(entry.value);
      } else {
        entry.key = mapping[key][this.language];
      }
    }

    this.cdr.detectChanges();
  }


  getValue(companyData, mappingEntry, language: string, defaultDecimals): Array<string> {
    let tableData = [];
    let originalData = this.tableUtil.getData(mappingEntry.path, companyData);

    if (typeof originalData !== 'undefined') {
      tableData.push(formatValue(originalData, mappingEntry, language, defaultDecimals));
    } else {
      tableData.push(this.notApplicable);
    }

    return tableData;
  }
}
