<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <app-file-list></app-file-list>
    </div>
    <div class="col-md-4">
      <app-file-upload></app-file-upload>
    </div>
  </div>
</div>
