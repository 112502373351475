import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { alsterAPI_URL, leewayAPI_URL } from "src/app/app-settings/api-urls";
import { EventService } from "src/app/events/services/event.service";
import { CustomerUser } from "src/app/models/user.model";

@Injectable({
  providedIn: "root",
})
export class CorporateDashboardService {
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiToken = "cqt1bii3tq5fuzo0c5id";
  private readonly apiUserURL = leewayAPI_URL + "cms/alsteruser/";
  constructor(
    private httpClient: HttpClient,
    private eventService: EventService
  ) {}

  getEmittentenPageData(currentUsersCompanyBloombergticker) {
    //BAT:SS
    // load filtered events by cloomberg ticker
    // -> events
    // GET	http://localhost:3000/api/v1/cms/event/all?apitoken=cqt1bii3tq5fuzo0c5id

    // load logo by cloomberg ticker
    // load company by cloomberg ticker?
    // load alster contant person (hardcode for now)
    return this.eventService.getEventsWithSIBContext(
      currentUsersCompanyBloombergticker
    );
  }

  getCurrentCompanyCurrentResearch(currentUsersCompanyBloombergticker: string) {
    const url =
      alsterAPI_URL +
      "companyinfo/research/" +
      currentUsersCompanyBloombergticker;

    return this.httpClient.get(url);
  }

  getCurrentCompanyPresentation(currentUsersCompanyBloombergticker: string) {
    const url =
      alsterAPI_URL +
      "companyinfo/companypresentation/" +
      currentUsersCompanyBloombergticker;

    return this.httpClient.get(url);
  }
  getEmittentenUsers(
    page: number = 1,
    limit: number = 5,
    sortField: string = "lastName",
    sortOrder: string = "asc",
    searchEmail: string = ""
  ) {
    const url = alsterAPI_URL + "hubuser/usersbytype";
    const body = {
      customerType: "Corporate",
      page: page,
      limit: limit,
      sortField: sortField,
      sortOrder: sortOrder,
      searchEmail: searchEmail,
    };
    return this.httpClient.post(url, body);
  }

  updateEmittentenUser(emittentenUser: any) {
    const url = alsterAPI_URL + "hubuser/approvecorporate";
    const update = {
      isCorporateUser: emittentenUser.isCorporateUser,
      bloombergTicker: emittentenUser.bloombergTicker,
      companyName: emittentenUser.companyName,
    };
    const payload = { usermail: emittentenUser.email, update };
    return this.httpClient.post(url, payload);
  }
}
