import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { CompanyInfoService } from "src/app/services/company-info.service";
import { UtilitiesService } from "src/app/services/utilities.service";

@Component({
  selector: "app-company-grid",
  templateUrl: "./company-grid.component.html",
  styleUrls: ["./company-grid.component.scss"],
})
export class CompanyGridComponent implements OnInit {
  public model: any;
  math = Math;
  focus$ = new Subject<any>();
  click$ = new Subject<any>();
  companiesPerRow = 4;
  companyHighlightsPerRow = 2;
  @Input() numRows = 4;
  numRowsTMP = this.numRows;
  numHighlights = 6;
  @Input() showAll = false;
  @Input() listOfCompanies = [];
  highlightedList = [];
  shortenedHighlightList = [];
  @Input() displayMode = "full"; // full || companyPage || ???

  // old colors
  // recColors: object = {
  //   'BUY': 'green',
  //   'HOLD': '#ffa500',
  //   'SELL': 'red'
  // };

  recColors: object = {
    "Spec. BUY": "#10B981",
    BUY: "#10B981",
    HOLD: "#F59E0B",
    SELL: "#F87171",
  };

  constructor(
    private companyService: CompanyInfoService,
    private router: Router,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    if (this.showAll === true) {
      this.numRows = 1000;
    } //TODO: Bad hack, this number should be something meaningful instad of just a high number

    this.companyService.getTileData().then((res) => {
      this.listOfCompanies = res.all;
      this.highlightedList = res.highlight;
      this.shortenedHighlightList = res.shortHighlight.slice(0, 6); // show only 6 highlights initially
    });
  }

  selectCountryFlag(ticker) {
    return this.utilitiesService.selectCountryFlag(ticker);
  }

  showFullHighlights() {
    this.shortenedHighlightList = this.highlightedList;
  }

  shortenHighlights(highlightsList: any[]) {
    let shortened = JSON.parse(JSON.stringify(highlightsList));

    if (highlightsList.length > this.numHighlights) {
      let numRemove = shortened.length - this.numHighlights;
      for (let i = shortened.length - 1; i > -1; i--) {
        if (shortened[i].isFeatured === "Update" && numRemove > 0) {
          shortened.splice(i, 1);
          numRemove--;
        }
      }
    }

    this.shortenedHighlightList = shortened;
  }

  public search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.listOfCompanies
              .filter((company) => company.hidden !== true)
              .filter(
                (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 20)
      )
    );

  public nextPage(target): void {
    target.preventDefault();
    this.model = "";
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.navigate(['/companies/snapshot/' + target.item.name]);

    this.router
      .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([
          "/companies/investment-case/" + target.item.name,
        ]);
      });
  }

  goToURL(target) {
    this.router.navigate([target]);
  }

  getColourClass(badge: string) {
    if (badge === "Initiation") {
      return "green";
    } else if (badge === "Update") {
      return "yellow";
    } else if (badge === "Transaction") {
      return "red";
    }
  }
}
