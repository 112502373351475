import { ViewportScroller } from '@angular/common';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { NewsletterSubscribeComponent } from 'src/app/basic-pages/modals/newsletter-subscribe/newsletter-subscribe.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SEOService } from 'src/app/services/seo.service';
import { DataServiceService } from '../../services/data-service.service';
import { niceDate } from '../../utilities/numberFormatting.util';
import { SubscriptionRequestedModalComponent } from '../modals/subscription-requested-modal/subscription-requested-modal.component';
import { SubscriptionSuccessModalComponent } from '../modals/subscription-success-modal/subscription-success-modal.component';
import { VideoModalComponent } from '../modals/video-modal/video-modal.component';
import { CompanyInfoService } from 'src/app/services/company-info.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @ViewChild('videoBlock', { static: true }) videoBlock: ElementRef;
  math = Math;

  buildingBlocks: Observable<any>;
  newsTicker = "XXX:XX";
  newsQuery = 'boerse';
  adminMode = false;

  blogCompany = "Blog"
  blogTicker = "weekly:screener"
  blogPageType = "weeklyScreener"

  companyName = this.blogCompany
  pageType = this.blogPageType
  bloombergTicker = this.blogTicker

  emailInput = null

  archiveDate;
  archiveTitle;
  newArchiveTitle;
  initializedTitleChange = false;
  currentChangeIndex = 0;

  initializedArchiveDeletion = false;


  teaserText = "Our sales desk and our analysts take turns in uncovering hidden, jewels and findin new angles on old acquaintances."


  constructor(
    public activatedRoute: ActivatedRoute,
    private companyInfoService: CompanyInfoService,
    private dataService: DataServiceService,
    private seoService: SEOService,
    private viewPortScroller: ViewportScroller,
    private modalService: NgbModal,
    private authService: AuthService,

  ) { }



  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.confirmed !== undefined && params.confirmed === 'true') {
        this.openSuccess()
      }
      if (params.requested !== undefined && params.requested === 'true') {
        this.openRequested()
      }
    })


    this.seoService.setMetaTags(null, null, null, null);

    this.buildingBlocks = this.companyInfoService.getLayoutForCompany(this.blogTicker, this.blogPageType, '', '', "welcome init 1");

    const urlParameters = combineLatest(this.activatedRoute.params,
      this.activatedRoute.queryParams, (params, queryParams) => ({ ...params, ...queryParams }));

    urlParameters.subscribe(params => {
      this.archiveDate = params.date;
      // this.buildingBlocks = this.companyInfoService.getLayoutForCompany(this.bloombergTicker, this.pageType, params.date, params.title, "welcome subscribe 1");
    })

    this.adminMode = this.authService.isAdmin();

  }

  public onEditModeChange() {
    this.buildingBlocks = this.companyInfoService.getLayoutForCompany(this.bloombergTicker, this.pageType, '', '', "block editor 1");
  }

  scrollOnClick(anchorTag) {
    this.viewPortScroller.scrollToAnchor(anchorTag)
  }

  public cleanUrl(url) {
    return url.replace(/%20/g, ' ').replace(/\?.+$/, '');
  }

  public niceDate(date) {
    return niceDate(date);
  }

  // public gotoRandomCompany() {
  //   let random = Math.random();
  //   let comps = this.companyInfoService.getListOfCompanies().filter(company => company.name !== 'welcomePageStatic').filter(company => company.hidden !== true);
  //   let randomCompany = comps[Math.floor(random * comps.length)];
  //   this.router.navigate(['/companies/snapshot/' + randomCompany.name]);
  // }

  requestCoverage() {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.emailMode = 'requestcoverage' // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'newsletter'
  }

  openVideoModal() {
    const modalRef = this.modalService.open(VideoModalComponent, { centered: true, size: 'lg', windowClass: 'biggerModal' });
  }

  subscribeToEvents() {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.emailMode = 'subscribetoevent' // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'events'
  }



  public changeTitle(page, index) {



    this.initializedTitleChange = false;
    if (this.newArchiveTitle && this.newArchiveTitle !== '') {
      page['newTitle'] = this.newArchiveTitle;
      page['companyId'] = this.bloombergTicker;
      page['pageType'] = this.pageType;
      this.dataService.changeTitle(page).subscribe(() => { });
    }
    this.newArchiveTitle = '';
  }

  public deleteArchive(page, index) {
    this.initializedArchiveDeletion = false;
    page['companyId'] = this.bloombergTicker;
    page['pageType'] = this.pageType;
    this.dataService.deleteArchive(page).subscribe(() => { });
  }


  iniArchivetAction(type, index, title) {
    this.currentChangeIndex = index;
    if (type === 'change') {
      this.initializedTitleChange = !this.initializedTitleChange;
      this.newArchiveTitle = title;
    } else if (type === 'delete') {
      this.initializedArchiveDeletion = !this.initializedArchiveDeletion;
    }
  }






  openSubscription() {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.emailMode = "subscription" // delete this when new forms are used in production
    modalRef.componentInstance.emailInput = this.emailInput // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'newsletter'
  }

  encodeForURL(input) {
    let encoder = new HttpUrlEncodingCodec();
    return encoder.encodeKey(input);
  }


  openRequested() {
    const modalRef = this.modalService.open(SubscriptionRequestedModalComponent, { centered: true, size: 'lg' });
  }
  openSuccess() {
    const modalRef = this.modalService.open(SubscriptionSuccessModalComponent, { centered: true, size: 'lg' });
  }
}
