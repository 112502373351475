import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-publishwarning',
  templateUrl: './publishwarning.component.html',
  styleUrls: ['./publishwarning.component.scss']
})
export class PublishwarningComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
    ) { }





  ngOnInit(): void {
  }

}
