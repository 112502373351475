import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'esgLabelRenamer' })
export class ESGLabelRenamer implements PipeTransform {
  transform(value: any, language: string): any {
    if (language === "ger") {
      // positive
      if (value === "High research investments (Top 25 %)") { return "Hohe Investition in Forschung" }
      if (value === "High tax payments (Top 25 %)") { return "Hohe Steuerzahlungen" }
      if (value === "Free of palmOil") { return "Kein Palmöl" }
      if (value === "Free of alcoholic") { return "Kein Alkohol" }
      if (value === "Free of smallArms") { return "Keine Handfeuerwaffen" }
      if (value === "Free of gambling") { return "Kein Glücksspiel" }
      if (value === "Free of gmo") { return "Keine Genforschung" }
      if (value === "Free of controversialWeapons") { return "Keine umstrittenen Waffen" }
      if (value === "Free of militaryContract") { return "Keine Verträge mit Militär" }
      if (value === "Free of furLeather") { return "Keine Pelze" }
      if (value === "Free of coal") { return "Keine Kohle" }
      if (value === "Free of tobacco") { return "Kein Tabak" }
      if (value === "Free of adult") { return "Keine Erwachseneninhalte" }
      if (value === "Free of nuclear") { return "Keine Kernkraft" }
      if (value === "Free of pesticides") { return "Keine Pestizide" }
      if (value === "Free of animalTesting") { return "Keine Tierversuche" }
      if (value === "Low water consumption (Bottom 25 %)") { return "Niedriger Wasserverbrauch" }
      if (value === "Low tax payments (Bottom 25 %)") { return "Niedrige Steuerzahlungen" }
      if (value === "High recycling quota (Top 25 %)") { return "Hohe Recyclingquote" }
      if (value === "High representation of women in management (Top 25 %)") { return "Hohe Frauenquote im Management" }

      //negative
      if (value === "High injury rate in the workforce (Top 25 %)") { return "Hohe Verletzungsrate der Arbeiter" }
      if (value === "High CEO compensation relative to cashflow (Top 25 %)") { return "Hohe CEO-Vergütung vs. Cashflow" }
      if (value === "Involved in pesticides") { return "Pestizide" }
      if (value === "Involved in animalTesting") { return "Tierversuche" }
      if (value === "Workers have died in the past") { return "Tod von Mitarbeitern" }
      if (value === "High Co2e emissions (Top 25 %)") { return "Hohe Co2-Emissionen" }
      if (value === "High waste generation (Top 25 %)") { return "Hohe Müllproduktion" }
      if (value === "Low recycling quota (Bottom 25 %)") { return "Niedrige Recyclingquote" }
      if (value === "High water consumption (Top 25 %)") { return "Hoher Wasserverbrauch" }
      if (value === "Fined for breaking environmental laws") { return "Strafzahlungen wegen Verstoßes gegen Umweltgesetze" }

      if (value === "Low CEO compensation relative to cashflow (Bottom 25 %)") { return "Niedrige CEO-Vergütung vs. Cashflow" }
      if (value === "Low Co2e emissions (Bottom 25 %)") { return "Niedrige Co2-Emissionen" }
      if (value === "Low waste generation (Bottom 25 %)") { return "Niedrige Müllproduktion" }
      if (value === "Low representation of women in management (Bottom 25 %)") { return "Niedrige Frauenquote im Management" }



    } else {
      //
      for (let i = 0; i < this.listOfSubstringsToFix.length; i++) {
        if (value.includes(this.listOfSubstringsToFix[i].original)) {
          value = this.fixSubstring(value, this.listOfSubstringsToFix[i])
          break;
        }
      }

      value = this.changeStaticText(value)



    }
    return value
  }

  listOfSubstringsToFix = [

    { original: "palmOil", fixed: "palmoil" },
    { original: "animalTesting", fixed: "animal testing" },
    { original: "nuclear", fixed: "nuclear power production" },
    { original: "adult", fixed: "adult content" },
    { original: "tobacco", fixed: "tobacco products" },
    { original: "furLeather", fixed: "fur and leather products" },
    { original: "militaryContract", fixed: "military contracts" },
    { original: "controversialWeapons", fixed: "controversial weapons" },
    { original: "smallArms", fixed: "small firearms" },
    { original: "alcoholic", fixed: "alcoholic products" },
    { original: "Co2e", fixed: "Co2" },


    //"High representation of women in management (Top 25 %)",
    //"High recycling quota (Top 25 %)",
    //"Low tax payments (Bottom 25 %)",
    //"Low water consumption (Bottom 25 %)",
    //"Free of coal",
    //"Free of pesticides",
    //"Free of gmo",
    //"Free of gambling",
    //"High research investments (Top 25 %)",
    //"High tax payments (Top 25 %)",
    //"High waste generation (Top 25 %)",
    //"High Co2 emissions (Top 25 %)",
    //"Workers have died in the past",
    //"Involved in pesticides",
    //"High CEO compensation relative to cashflow (Top 25 %)",
    //"High injury rate in the workforce (Top 25 %)",
    //"Low recycling quota (Bottom 25 %)",
    //"Low representation of women in management (Bottom 25 %)", ,
    //Low waste generation (Bottom 25 %)",
    //"Low CEO compensation relative to cashflow (Bottom 25 %)",
    //"Fined for breaking environmental laws",
    //"High water consumption (Top 25 %)"
  ]

  fixSubstring(orig: string, substring: any) {
    return orig.replace(substring.original, substring.fixed);
  }

  changeStaticText(orig: string){
    return orig.replace("Free of", "No");
  }


}
