import { Location } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { merge, Observable, OperatorFunction, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { UtilitiesService } from "src/app/services/utilities.service";
import { SigninComponent } from "../../auth/signin/signin.component";
import { NewsletterSubscribeComponent } from "../modals/newsletter-subscribe/newsletter-subscribe.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { CompanyInfoService } from "src/app/services/company-info.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  @ViewChild("instanceMobile", { static: true }) instanceMobile: NgbTypeahead;

  justClickedStartEditor = false;
  currentCompany = "";
  currentPageType = "";
  focus$ = new Subject<any>();
  click$ = new Subject<any>();
  focusMobile$ = new Subject<any>();
  clickMobile$ = new Subject<any>();
  isSubPage = false;
  public model: any;
  listOfCompanies: any = [];
  navbarOpen = true;
  innerWidth: any;
  mobileHeader = false;
  transactionMode = true;
  transactionBannerContent = { text: "", link: "" };
  currentUserName = "";
  fakeCompanyAsAllStocks = { name: "All Stocks" };
  currentSearchTerm = "";
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  //testing
  routerState: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private utilitiesService: UtilitiesService,
    private companyInfoService: CompanyInfoService
  ) {
    location.onUrlChange((url) => {
      if (this.mobileHeader) {
        this.navbarOpen = false;
      }

      if (url.includes("companies")) {
        this.currentPageType = url.split("/")[1];
        this.currentCompany = url.split("/")[2];
        this.isSubPage = true;
        this.cdr.detectChanges();
      } else {
        this.isSubPage = false;
        this.cdr.detectChanges();
      }
    });

    this.currentUserName = this.authService.getCurrentUserName();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 540) {
      this.mobileHeader = true;
      this.navbarOpen = false;
    }

    this.transactionMode = this.companyInfoService.getTransactionMode();
    this.transactionBannerContent =
      this.companyInfoService.getTransactionBannerContent();
    this.listOfCompanies = this.companyInfoService
      .getListOfCompanies()
      .filter(
        (item) => item.hidden === false && item.name !== "welcomePageStatic"
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    this.listOfCompanies.unshift(this.fakeCompanyAsAllStocks);
    this.utilitiesService.getRouterstate().subscribe((state) => {
      this.routerState = state;
      this.cdr.detectChanges();
    });
  }

  getCompanySymbols(): Array<any> {
    let symbols = [];
    let companies = this.companyInfoService
      .getListOfCompanies()
      .filter((item) => item.name !== "welcomePageStatic")
      .filter((company) => company.hidden !== true);

    for (let company of companies) {
      let title = company.name;
      let id = "SWB:" + company.bloombergTicker.split(":")[0];

      symbols.push({ proName: id, title: title });
    }

    return symbols;
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }
  canUseAdminPanel() {
    return this.authService.canUseAdminPanel();
  }
  isAdmin() {
    return this.authService.isAdmin();
  }
  isCustomer() {
    return this.authService.isCustomer();
  }
  isCustomerEmittent() {
    return this.authService.isCustomerEmittent();
  }
  isCustomerInvestor() {
    return this.authService.isCustomerInvestor();
  }
  isContributor() {
    return this.authService.isContributor();
  }

  openLogin() {
    if (this.mobileHeader) {
      this.navbarOpen = true;
    }
    const modalRef = this.modalService.open(SigninComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.status = this.isAuthenticated();
  }

  openSubscription() {
    if (this.mobileHeader) {
      this.navbarOpen = true;
    }
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.emailMode = "subscription"; // delete this when new forms are used in production
    modalRef.componentInstance.formMode = "newsletter";
  }

  nextPage(target) {
    this.router.navigate(["/" + target]);
  }

  formatter = (result) => result.name;

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ""
          ? this.listOfCompanies
          : this.addAllStocksInfirstPlace(this.listOfCompanies, term)
      )
    );
  };
  searchMobile: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickMobile$.pipe(
      filter(() => !this.instanceMobile.isPopupOpen())
    );
    const inputFocus$ = this.focusMobile$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ""
          ? this.listOfCompanies
          : this.addAllStocksInfirstPlace(this.listOfCompanies, term)
      )
    );
  };

  addAllStocksInfirstPlace(arr, term) {
    arr = arr.filter(
      (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
    );
    arr.unshift(this.fakeCompanyAsAllStocks);
    return arr;
  }

  public goToCompanyPage($event): void {
    $event.preventDefault();
    if ($event.item.name === this.fakeCompanyAsAllStocks.name) {
      this.router.navigate(["/all-stocks"]);
    } else {
      this.router.navigate(["/companies/" + $event.item.name]);
    }
  }
  logOff() {
    this.authService.logOff();
    this.router.navigate([""]);
  }
}
