<ng-template #rt let-r="result" let-t="term">
  <!--bla-->
  <a style="color:black!important;" class="underlineonhover" (click)="selectCompanyForEditing(r)">
    {{r.name}}
  </a>
</ng-template>

<div class="row mt-5 pt-5 content-wrapper limit-pagewidth">
  <div class="col-12">
    <div class="container-fluid mt-5 p-5 bg-yellow">
      <div class="row" *ngIf="isEditorMode">
        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4 ">
              <h3 class="my-2">Upload your research</h3>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-12">
                  <h3 class="my-2">{{companyName}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4 ">
              <h4>1. Select a company</h4>
              <p>select a company from the list or search for one.</p>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-6">
                  <div class=" w-100" ngbDropdown>
                    <button style="overflow-x: hidden;   text-overflow: ellipsis;                "
                      class="  mw-100 btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                      {{companyName || 'select a company'}}</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button (click)="selectCompanyForEditing(company)" *ngFor="let company of allowedCompanies"
                        ngbDropdownItem>{{company.name}}</button>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <input class="  mw-100 w-100 lessPadding" type="text" [(ngModel)]="tester" [resultTemplate]="rt"
                    #instance="ngbTypeahead" placeholder="search a company" [inputFormatter]="formatter"
                    [editable]='false' [resultFormatter]="formatterCode" [ngbTypeahead]="search" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4">
              <h4>2. Archive previous research</h4>
              <p>Save and archive old research</p>
              <p><small>Warning: Changing the report <u>without</u> archiving it first, will overwrite the old
                  content!</small></p>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-12">


                  <label for="archivedate">Archive date set to the date of publishing the research.</label>
                  <input disabled class="w-100 mb-3" id="archivedate" placeholder="Archive: Date"
                    [ngModel]="publishedDate">
                  <label for="archiveTitle">Archive title, set as latest research title by default</label>
                  <input *ngIf="latestResearchTitle" class="w-100 mb-3" id="archiveTitle" placeholder="Archive: Titel" [(ngModel)]="latestResearchTitle" disabled>
                  <input *ngIf="!latestResearchTitle" class="w-100 mb-3" id="archiveTitle" placeholder="Archive: Titel" [(ngModel)]="latestResearchTitle">
                  <button *ngIf="!loadingArchive" class="btn btn-std my-2" (click)="archivePublishedPage()">Archive
                    now</button>
                  <button *ngIf="loadingArchive" disabled class="btn btn-std my-2">Saving archive...</button>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4">
              <h4>3. + 4. Upload Text and PDFs</h4>
              <div class="mt-5">
                <p> <strong>4.</strong></p>
                <p>Replace the current homepage-text with your new research.</p>
              </div>

              <div style="position: absolute; bottom:25%">
                <p><strong>5. Upload your PDF</strong></p>
                <ol>
                  <li>Click the button to open the popup.</li>
                  <li>scroll down until you see "select pdfs", then click that</li>
                  <li>select the PDF you want to upload and click OK</li>
                  <li>now click "upload all"</li>

                </ol>

              </div>

            </div>
            <div class="col-8 ">
              <div class="row markets-custom-layout-wrapper min-height"
                *ngIf="(researchBuildingBlocks | async) as companyLayoutBlocks">
                <div class="col-12 border-red px-2 mb-4">
                  <label for="researchtitle"><b><i>Future archive title (first line of the research).</i></b></label>
                  <input class="w-100 mb-3" id="newResearchtitle" placeholder="Research Title"
                    [(ngModel)]="newResearchTitle">
                </div>
                <div class="col-12">
                  <!-- research blocks-->
                  <h4 class="pt-4">Research</h4>
                  <app-markets-custom-layout *ngIf="companyLayoutBlocks" [buildingBlocks]="companyLayoutBlocks"
                    [companyName]="companyName" [pageType]="'research'" [companyData]="companyData"
                    [archiveTitle]="archiveTitle" [archiveDate]="archiveDate" [reportPanelMode]="true"
                    (publishedDate)="readPublishedDate($event)">
                  </app-markets-custom-layout>
                </div>
              </div>
              <div class="row markets-custom-layout-wrapper min-height"
                *ngIf="(corporateBuildingBlocks | async) as companyLayoutBlocks">
                <div class="col-12">
                  <!-- corporate blocks-->
                  <h4 class="pt-4">Company</h4>
                  <app-markets-custom-layout *ngIf="companyLayoutBlocks" [buildingBlocks]="companyLayoutBlocks"
                    [companyName]="companyName" [pageType]="'corporate'" [companyData]="companyData"
                    [archiveTitle]="archiveTitle" [archiveDate]="archiveDate" [reportPanelMode]="true"
                    (publishedDate)="readPublishedDate($event)">
                  </app-markets-custom-layout>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4 ">
              <h4>5.</h4>
              <p>Publish research and figures</p>
            </div>
            <div class="col-8">
              <div class="row">
                <!-- <div class="col-12">
                  <small>
                    <textarea class="w-100" placeholder="optional: write a small message for your publish request"
                      [(ngModel)]="publishRequestMessage"></textarea>
                  </small>
                </div> -->
                <div class="col-4">
                  <button (click)="publishFigures()" class="btn btn-std">{{publishFiguresLabel}}</button>
                </div>
                <div class="col-4">
                  <button (click)="publishPages()" class="btn btn-std">{{publishPagesLabel}}</button>
                  <div *ngIf="message">{{message}}</div>
                </div>
                <!-- <div class="col-12">
                  <button *ngIf="!sendingPublishRequest" (click)="sendPublishRequest()" class="btn btn-std">Finish and
                    send
                    a publish-request</button>
                  <button *ngIf="sendingPublishRequest" class="btn btn-inverted-gray">Sending request...</button> 
                </div> -->
                <div class="col-12 my-4">
                  <p *ngIf="publishRequestHasBeenSent">Your publish request has been sent. </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4">
              <h4>6. Enter News content</h4>
              <p>Content for the RSS feed</p>
              <p>Research title and what's it all about</p>
              <h4 class="mt-4"><i>Deutsch</i></h4>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-12 px-2 mb-4">
                  <label for="researchtitle"><i>Future archive title, set in step 3.</i></label>
                  <input class="w-100 mb-3" id="researchtitle" placeholder="Research Title" disabled [(ngModel)]="newResearchTitle">
                </div>
                <div class="col-12">
                  <label for="newstitle">Title of the research.</label>
                  <input class="w-100 mb-3" id="newstitle" placeholder="News: Title" [(ngModel)]="newsDE.title">
                  <label for="newsContent">Content of the news.</label>
                  <textarea class="w-100 mb-3" id="newsContent" rows="5" placeholder="News: Content"
                    [(ngModel)]="newsDE.description"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-4">
              <h4 class="mt-4"><i>English</i></h4>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-12">
                  <label for="newstitle">Title of the research.</label>
                  <input class="w-100 mb-3" id="newstitle" placeholder="News: Title" [(ngModel)]="newsEN.title">
                  <label for="newsContent">Content of the news.</label>
                  <textarea class="w-100 mb-3" id="newsContent" rows="5" placeholder="News: Content"
                    [(ngModel)]="newsEN.description"></textarea>
                  <button class="btn btn-std my-2"
                    (click)="publishNews('EN'); publishNews('DE')">{{news_button_label}}</button>
                    <div *ngIf="message">{{message}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!isEditorMode">
        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4 ">
              <h3 class="my-2">Upload your research</h3>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-12">
                  <h3 class="my-2">{{companyName}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4 ">
              <h4>1. Select a company</h4>
              <p>select a company from the list or search for one.</p>
            </div>
            <div class="col-8 ">
              <div class="row">
                <div class="col-6">
                  <div class=" w-100" ngbDropdown>
                    <button style="overflow-x: hidden;   text-overflow: ellipsis;                "
                      class="  mw-100 btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                      {{companyName || 'select a company'}}</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button (click)="selectCompanyForEditing(company)" *ngFor="let company of allowedCompanies"
                        ngbDropdownItem>{{company.name}}</button>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <input class="  mw-100 w-100 lessPadding" type="text" [(ngModel)]="tester" [resultTemplate]="rt"
                    #instance="ngbTypeahead" placeholder="search a company" [inputFormatter]="formatter"
                    [editable]='false' [resultFormatter]="formatterCode" [ngbTypeahead]="search" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isLoading">
        <div class="col-12 mt-1 bg-white shadow mb-3 rounded p-3">
          <div class="row">
            <div class="col-4 ">
              <h4>1. Select a company</h4>
            </div>
            <div class="col-8">
              <h4>Loading...</h4>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>