import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  @Input() videoURL = 'https://vimeo.com/522221637'
  constructor(public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
