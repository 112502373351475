import { Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class BuildingBlock {
  constructor(
    public component: Type<any>,
    public blockType: string,
    public data: any,
    public block_id: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    public adminMode: boolean = false,
    public deleteEmitter: BehaviorSubject<any> = new BehaviorSubject({}),
    public metaCompanyName: string = '',
    public metaBloombergTicker: string = '',
    public metaPageType: string = ''
  ) { }
}
