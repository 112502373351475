import { Component, EventEmitter, OnInit, OnChanges, Input, Output } from '@angular/core';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: { headline: string, type: string, content: string[], height: number, width: number, mobile: boolean };
  @Input() decimals = 0;
  @Input() lang = 'eng';

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  constructor(private tableUtils: Tables) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
 
  }

}
