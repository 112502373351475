import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
@Component({
  selector: 'app-block-screener',
  templateUrl: './block-screener.component.html',
  styleUrls: ['./block-screener.component.scss']
})
export class BlockScreenerComponent implements OnInit {
  @Input() deleteEmitter: any;
  @Input() block_id: any;
  @Input() blockType: string;
  @Input() adminMode: any;
  @Input() data: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()

  constructor() { }

  ngOnInit(): void {
  }

}
