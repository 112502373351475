<a  *ngIf="!mobileHeader" class="navbar fixed-top mh-100 bannerTransaction" target="_blank" href="{{transactionBannerContent.link}}">
  <div class="container-fluid limit-pagewidth">
    <div class="row">
      <div class="col-12 navbar-wrapper justify-content-end justify-content-md-between">
        <p class="my-auto py-2"><a style="white-space: break-spaces" href="{{transactionBannerContent.link}}"
            target="_blank">{{transactionBannerContent.text}}</a></p>


      </div>
    </div>
  </div>

</a>


<span *ngIf="mobileHeader" class="navbar fixed-top mh-100 bannerTransaction-mobile">
  <a *ngIf="!expanded"(click)="toggleExpanded()" class="text-center">{{transactionBannerContent.text |  slice:0:25 }}...</a>
  <div *ngIf="expanded" (click)="toggleExpanded()">
    <div class="container-fluid limit-pagewidth">
      <div class="row">
        <div class="col-12 navbar-wrapper justify-content-end justify-content-md-between">
          <p class="my-auto py-2"><a style="white-space: break-spaces" href="{{transactionBannerContent.link}}"
              target="_blank">{{transactionBannerContent.text}}</a></p>


        </div>
      </div>
    </div>
  </div>
</span>
