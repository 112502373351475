<div class="sectionx" *ngIf="isEditorMode">
  <div class="container border border-warningx  px-2 py-2"
    [ngClass]="{'bg-warning': editortouchedNOSAVE === true,'bg-white': editortouchedNOSAVE === false }">
    <div class="text-center">
      <p class=" mt-5 bg-warning px-2 py-2"><small>Changes in the editor are saved automatically. The orange border
          shows while a save is ongoing. To be sure, you should still click on "Save text in editor".
          The editor closes only when clicking on "Close Editor". </small></p>
    </div>


    <editor id="customEditor" [(ngModel)]="data" apiKey="mc27e24qav5ca95i2gb3lv6jh2skggnp8getjaklrloj61c6" [init]="{
          height: 800,

          menubar: ' file edit view insert format tools table help ',
          plugins: 'autosave print preview paste autolink autosave code save directionality visualblocks visualchars image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars',
          toolbar_sticky: true,
          autosave_ask_before_unload: true,
          autosave_interval: '15',
          toolbar: 'undo redo | bold italic underline strikethrough |  fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap |  preview save print | insertfile image media template link anchor | ltr rtl | code ',
          autosave_prefix: 'researchhub-autosave-{path}{query}-{id}-'
        }">

      <!-- put this in editor-tag above to enable auto-save   (onChange)="handleEditorChangedEvent($event)"   -->
    </editor>
    <div class="text-center">
      <!-- <p class=" mt-5 bg-warning px-2 py-2">Ggf. muss die Seite <strong>nach</strong> dem Speichern neu geladen werden, damit alle Änderungen angezeigt werden.</p> -->
      <button class="btn  btn-lg btn-primary" (click)="saveContent()" [disabled]="sg?true:''">Save text in
        editor</button>
      <button class="btn btn-lg btn-primary" (click)="endEditorMode()">Close editor (save before clicking
        here!)</button>
    </div>
  </div>
</div>

<!--plugins -->
<!-- menubar   -->
<!-- toolbar -->


<div *ngIf="!isEditorMode">
  <div class="publishedText mt-4" *ngIf="presentationType===''">
    <div [innerHTML]="parsedData"></div>
  </div>

  <div class="swot-block container mt-4" *ngIf="presentationType==='SWOT'">
    <div class="row">
      <div class="col-12 my-3 px-2">
        <span class="swot-title  p-1 mx-2" *ngFor="let swot of parsedData; let i = index"
          (click)="swotIndex = i" [class.active]="swotIndex === i">{{swot.title}}</span>
      </div>
    </div>
    <div class="row full-height overflowFix mt-3">
      <div class="col-12 swot-content">
        <ul class="px-3" style="max-width:65%">
          <li class="pb-1" *ngFor="let entry of parsedData[swotIndex].content">{{entry}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="swot-block container mt-4" *ngIf="presentationType==='SWOT_MOBILE'">
    <div class="row" *ngFor="let swot of parsedData; let i = index">
      <div class="col-12 my-3 px-2">
        <span class="swot-title  p-1 mx-2" >{{swot.title}}</span>
      </div>
      <div class="col-12 swot-content">
        <ul class="px-3" style="max-width:90%">
          <li class="pb-1" *ngFor="let entry of swot.content">{{entry}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>