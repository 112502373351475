import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AllStocksComponent } from './basic-pages/all-stocks/all-stocks.component';
import { BlogComponent } from './basic-pages/blog/blog.component';
import { CookiesDeniedPageComponent } from './basic-pages/cookies-denied-page/cookies-denied-page.component';
import { DatenschutzComponent } from './basic-pages/datenschutz/datenschutz.component';
import { ImpressumComponent } from './basic-pages/impressum/impressum.component';
// import { LandingpageComponent } from './basic-pages/landingpage/landingpage.component';
import { LegalDisclaimerComponent } from './basic-pages/legal-disclaimer/legal-disclaimer.component';
import { MifidDisclaimerComponent } from './basic-pages/mifid-disclaimer/mifid-disclaimer.component';
import { StockScreenerComponent } from './basic-pages/stock-screener/stock-screener.component';
import { WelcomeComponent } from './basic-pages/welcome/welcome.component';
import { AdminPanelComponent } from './company-info/admin-panel/admin-panel.component';
import { CPageComponent } from './company-info/pages/c-page/c-page.component';
import { ReportPanelComponent } from './company-info/report-panel/report-panel.component';

import { CustomRouteReuseStategy } from './utilities/customRouterReloadStrategy.util';
import { SignupComponent } from './auth/signup/signup.component';
import { AdminAuthGuardService } from './services/auth/admin-auth-guard.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { RequestpasswordComponent } from './auth/requestpassword/requestpassword.component';
import { SignupCompleteComponent } from './basic-pages/signup-complete/signup-complete';


const spaceForAllHeaders = 113

const routes: Routes = [
  { path: '', component: WelcomeComponent },
  { path: 'welcome', component: WelcomeComponent },
  // unternehmensseiten
  { path: 'signup', component: SignupComponent },
  { path: 'requestnewpassword', component: RequestpasswordComponent },
  { path: 'setnewpassword/:token', component: ResetpasswordComponent },

  { path: 'companies/:companyName', component: CPageComponent, data: { shouldReuse: true } },
  { path: 'companies/:pagetype/:companyName', component: CPageComponent, data: { shouldReuse: true } },
  { path: 'blog/:pagetype', component: BlogComponent, data: { shouldReuse: true } },
  { path: 'screener', component: StockScreenerComponent, data: { shouldReuse: true } },
  { path: 'all-stocks', component: AllStocksComponent, data: { shouldReuse: true } },
  { path: 'datenschutz', component: DatenschutzComponent, data: { shouldReuse: true } },
  { path: 'impressum', component: ImpressumComponent, data: { shouldReuse: true } },
  { path: 'mifid2', component: MifidDisclaimerComponent, data: { shouldReuse: true } },
  { path: 'disclaimer', component: LegalDisclaimerComponent, data: { shouldReuse: true } },
  { path: 'cookies-denied', component: CookiesDeniedPageComponent, data: { shouldReuse: true } },
  { path: 'dd', component: LegalDisclaimerComponent, data: { shouldReuse: true } },
  { path: 'welcome', component: WelcomeComponent, data: { shouldReuse: true } },
  { path: 'signupcomplete', component: SignupCompleteComponent, data: { shouldReuse: true } },
  //{ path: 'events', component: EventsComponent, data: { shouldReuse: true } },
  { path: 'admin', component: AdminPanelComponent, canActivate: [AuthGuardService, AdminAuthGuardService], data: { shouldReuse: true } },
  { path: 'reportpanel', component: ReportPanelComponent, canActivate: [AuthGuardService], data: { shouldReuse: true } },
  { path: 'corporate', loadChildren: () => import('./corporate-dashboard/corporate-dashboard.module').then(m => m.CorporateDashboardModule),  data: { shouldReuse: true } },
  { path: 'user', loadChildren: () => import('./customer-dashboard/customer-dashboard.module').then(m => m.CustomerDashboardModule),  data: { shouldReuse: true } },

  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule), canActivate: [], data: { shouldReuse: true } },
  // auffangen der 404 seiten
  // { path: 'landing', component: LandingpageComponent, data: { shouldReuse: true } },
  { path: '**', redirectTo: '/', data: { shouldReuse: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled', scrollOffset: [0, -spaceForAllHeaders] })],
  exports: [RouterModule],
  providers: [

    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },

  ]
})
export class AppRoutingModule { }
