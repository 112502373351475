<div class="col-12 tile-table" style="overflow: hidden;">
    <div class="table-responsive mx-auto">
        <div *ngIf="!data">
            <p class="text-center">No ESG data available</p>
        </div>
        <div>
            <table class="table table-sm table-hover mb-0 tile-table">
                <thead *ngIf="!valueTable">
                    <td>in {{companyData?.base?.quote_curr || companyData?.base?.curr}}m</td>
                    <td class="tile-table-headline headlines" *ngFor="let date of dates">
                        <b>{{date}}</b>
                    </td>
                </thead>
                <tbody>
                    <tr *ngFor="let row of data">
                       <ng-container #normal *ngIf="row.title !== 'ESG Score'; else progressBar">
                        <td class="tile-table-title content">
                            {{ row.title }}
                        </td>
                        <td  class="tile-table-content content">
                            <span 
                                *ngIf="row.valueModifier">{{row?.valueModifier}} </span>
                                <!--this is a stupid way to adress the datapoints, ask lars if he already has a method for dealing with timesieries data-->
                            <span *ngIf="row.title !== 'Number of Shares'">{{row.value[row.value.length-1]}}<span *ngIf="row.unit">{{row.unit}}</span></span> 
                            <span *ngIf="row.title === 'Number of Shares'">{{row.value[row.value.length-1]|number:'1.1-1'}}<span *ngIf="row.unit">{{row.unit}}</span></span> 
                           
                        </td>
                    </ng-container>
                    <ng-container #progressBar *ngIf="row.title === 'ESG Score'">
                        <td colspan="2" class=" tile-table-title content" style="width: 100%;">  
                            {{ row.title }}  
                        <span class="float-right" style="color:white"
                            *ngIf="row.value[row.value.length-1] !==undefined && row.value[row.value.length-1] !==null && row.value[row.value.length-1] !== 11.0177">
                            {{row.value[row.value.length-1] | number: '1.0-0' }} / 100</span>
                            <span *ngIf="browserService.browserName !== 'firefox'" class="score-progress ">
                                <meter  name="gesamtBewertung" value="{{row.value[row.value.length-1] || -120 }}" min="-120" low="-1" optimum="100" high="66"
                                  max="120"></meter>
                            </span>
                            <span *ngIf="browserService.browserName === 'firefox'">
                                <progress class="progress-bar-compatible" name="gesamtBewertung" value="{{row.value[row.value.length-1] || -120 }}" min="-120" low="-1" optimum="100" high="66"
                                  max="120"></progress>
                            </span>
                        </td>
                    </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>