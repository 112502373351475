export function bloombergTickerEODMapping(ticker: string): string {
  let symbol = ticker.split(':')[0];
  let exchange = ticker.split(':')[1];

  if (symbol === "GSJ") { symbol = "SGF" }

  if (exchange === 'GR') {
    if (symbol === '2GI' || symbol === 'R1B' || symbol === 'CDZ0' || symbol === '14D' || symbol === '991') {
      exchange = 'MU';
    } else if (
      // symbol === '123F' ||
      symbol === 'XCX') {
      exchange = 'DU';
    } else if (symbol === 'ZO1') {
      exchange = 'HM';
    } else if (symbol === 'NSP') {
      exchange = 'MI'
    } else if (symbol === "P7V") {
      exchange = "F"
    }

    else {
      exchange = 'XETRA';
    }
  }
  if (symbol === 'NSP') { exchange = 'MI' }

  return symbol + '.' + exchange;
}

export function parseParams(urlParams: string): any {
  let obj = {};

  if (urlParams) {
    let splitURL = urlParams.split(/&/);

    for (let part of splitURL) {
      let s = part.split(/=/);
      obj[s[0]] = s[1];
    }
  }

  return obj;
}

export function checkValueInEnum<T>(
  value: T,
  enumObject: Record<string, T>
): boolean {
  return Object.values(enumObject).includes(value);
}

