<div *ngIf="showHeaderAndFooter">
  <app-header ></app-header>

 <!-- Contact Widget -->
 <div *ngIf="showContactButton" class="contactSlider">
  <a (click)="showContactSlider = !showContactSlider" class="fingerFix contactSlider__Button mr-2">
    <img loading="lazy" src="../../../assets/streamlinehq-user-question.SVG" alt="help icon" class="help-icon">
  </a>
  <div class="contactSlider__Field p-2" *ngIf="showContactSlider">
    <!-- <p class="bigger"><b>How can we help you?</b></p>
    <p class="bigger">Contact Us</p> -->
    <div class="contactSlider__Info p-2">
      <!-- <p>By phone</p> -->
      <p class="mt-1 mb-4 bigger">
        <img loading="lazy" src="../../../assets/streamlinehq-mobile-phone.SVG" alt="phone icon" class="phone-icon pr-3">
        <a href="tel:+494030929353">+49 40 309 293-53</a>
      </p>
      <!-- <p>Via E-Mail</p> -->
      <p class="mb-1 bigger">
        <img loading="lazy" src="../../../assets/streamlinehq-mail.SVG" alt="mail icon" class="mail-icon pr-3">
        <a href="mailto:info@mwb-research.com">info@mwb-research.com</a>
      </p>
      <!-- <a class="" href="https://www.mwb-research.com/#comp-kkf5zy1l">
        <button class="btn btn-std">Contact Form</button>
      </a> -->
    </div>
  </div>
</div>

  <div class="pageSpacer">
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    <router-outlet></router-outlet>
  </div>

  <app-footer [isCookiesAccepted]="isCookiesAccepted" ></app-footer>
</div>

<div *ngIf="!showHeaderAndFooter">
  <router-outlet></router-outlet>
</div>
