import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-tile-wrapper-grid',
  templateUrl: './tile-wrapper-grid.component.html',
  styleUrls: ['./tile-wrapper-grid.component.scss'],
})
export class TileWrapperGridComponent implements OnInit {

  @Input() companyData: any;
  @Input() mapping: any;
  @Input() baseyear: number;
  @Input() config: { height: number, width: number, tiles: [{ headline: string, type: string, content: string[] | string[][], height: number, width: number, mobile: boolean }] };
  tiles: { headline: string, type: string, content: string[] | string[][], height: number, width: number, mobile: boolean }[][];

  lang = 'eng'; 

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.prepareTiles();
  }

  prepareTiles() {
    if (this.config && Object.keys(this.companyData).length > 0 && Object.keys(this.mapping).length > 0) {
      this.tiles = [];
      while (this.tiles.length < this.config.height) {
        this.tiles.push([]);
      }

      let rowIndex = 0;
      let currentWidth = 0;
      let link;
      for (let tile of this.config.tiles) {
        if (tile.mobile || window.innerWidth > 768) {
          if (currentWidth + tile.width > this.config.width) {
            rowIndex++;
            currentWidth = 0;
          }

          if (rowIndex < this.tiles.length) {
            this.tiles[rowIndex].push(tile);
            currentWidth += tile.width;
          }

          if (link && link.row === rowIndex && link.col === this.tiles[rowIndex].length - 1) {
            tile.content = [link.tile.content[0]];
            if (this.mapping[link.tile.content[0]]) {
              tile.headline = this.mapping[link.tile.content[0]]['eng'];
            }
          }

          if (tile['link']) {
            switch (tile['link']) {
              case 'right': {
                link = { row: rowIndex, col: this.tiles[rowIndex].length, tile: tile };
                tile['link'] = { row: rowIndex, col: this.tiles[rowIndex].length };
                break;
              }
              case 'below': {
                link = { row: rowIndex + 1, col: currentWidth - 1, tile: tile };
                tile['link'] = { row: rowIndex + 1, col: currentWidth - 1 };
                break;
              }
            }
          }
        }
      }
    }
  }

  onTileEvent($event, rowIndex, colIndex) {
    switch ($event.type) {
      case 'key-change': {
        let link = this.tiles[rowIndex][colIndex]['link'];
        if (link) {
          this.tiles[link.row][link.col]['content'] = $event.change.content;
          this.tiles[link.row][link.col]['headline'] = $event.change.title;
          this.tiles[link.row][link.col] = JSON.parse(JSON.stringify(this.tiles[link.row][link.col]))
        }
        break;
      }
    }
  }
}
