import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { emptyEventPerson, emptyInvestorProfile, EventPerson, InvestorProfile } from 'src/app/models/event.model';

@Component({
  selector: 'app-investorprofilemodal',
  templateUrl: './investorprofilemodal.component.html',
  styleUrls: ['./investorprofilemodal.component.scss']
})
export class InvestorprofilemodalComponent implements OnInit {

  @Input() person: EventPerson = emptyEventPerson()
  investorProfile: InvestorProfile = emptyInvestorProfile()
  constructor(
    public activeModal: NgbActiveModal,
  ) {

  }
  investorProfileMapping = {
    "aum": {
      title: "Assets under Management (EURm)",
      values: ["< 50", "50 - 200", "200 - 500", "> 500"]
    },
    "size": {
      title: "Segment",
      values: ["Small & Mid Cap", "Large Cap", "All Cap"]
    },
    "holdingPeriod": {
      title: "Holding Period (years)",
      values: ["< 1", "1 -3", "> 3"]
    },
    "investmentStyle": {
      title: "Investment Style",
      values: ["Growth", "Value", "GARP", "ESG", "agnostic"]
    },
    "investorType": {
      title: "Investor Type",
      values: ["Asset-/Portfolio Manager", "Independent Financial Adviser","Family Office", "Presse / Börsenbrief", "Corporate", "Other"]
    },
    "region": {
      title: "Region",
      values: ["Germany", "Europe", "Worldwide"]
    },
    "ticketSize": {
      title: "Ticket Size (EURk)",
      values: ["< 50", "50 - 250", "> 250"]
    },
    "description":
    {
      title: "Further Information",
      values: []
    },


  }

  profileHasDetails(){
    let profileDetails = ["aum","size","holdingPeriod","investmentStyle","region","ticketSize"]
    let found = Object.keys(this.person.investorProfile).some(r=> profileDetails.indexOf(r) >= 0)
    if( found){
      return true
    }else{
      return false
    }
  }
// Order by ascending property key
  keyAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.key > b.key ? 1 : (b.key > a.key ? -1 : 0);
  }
  ngOnInit(): void {
    this.investorProfile = this.person.investorProfile
  }

  closeWindow() {
    this.activeModal.close()
    }
}
