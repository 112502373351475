import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';

@Component({
  selector: 'app-block-newsfeed',
  templateUrl: './block-newsfeed.component.html',
  styleUrls: ['./block-newsfeed.component.scss']
})
export class BlockNewsfeedComponent implements OnInit {

  @Input() type: string = '';
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()

  constructor() { }

  ngOnInit(): void {
  }


}
