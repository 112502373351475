import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { SingleEvent } from "src/app/models/event.model";
import { CorporateDashboardService } from "../services/corporate-dashboard.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";
import { CompanyInfoService } from "src/app/services/company-info.service";
@Component({
  selector: "app-corporate-dashboard",
  templateUrl: "./corporate-dashboard.component.html",
  styleUrls: ["./corporate-dashboard.component.scss"],
})
export class CorporateDashboardComponent implements OnInit {
  myEvents: SingleEvent[] = [];
  nextEvent: SingleEvent[] = [];
  pastEvents: SingleEvent[] = [];
  upcomingEvents: SingleEvent[] = [];
  expanded = [];
  currentUsersCompanyBloombergticker = "";
  currentUsersCompanyName = "";
  notCoveredCompanyName = "";
  existingCompany = false;
  loadingNewData = true;
  globalInviteLink = "https://bit.ly/3TnIHHJ";
  currentCompanyPresentation: any;
  public buildingBlocksResearch: Observable<any>;
  archives: any;
  researchPDFs = [];
  currentResearchDate: any;
  currentResearchTitle: any;
  constructor(
    private emittentenService: CorporateDashboardService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private companyInfoService: CompanyInfoService,
    private clipboard: Clipboard
  ) {
    this.currentUsersCompanyBloombergticker =
      this.authService.getcurrentUserCompanyBloombegticker();
    this.currentUsersCompanyName = this.authService.getcurrentUserCompanyName();
  }

  alsterContact = {
    name: "Hannah Gabert",
    position: "Team Assistant",
    email: "h.gabert@mwb-research.com",
    phone: "+49 40 309 293-52",
  };

  ngOnInit(): void {
    this.getAllEvents();
    this.checkIfCompanyExists(this.currentUsersCompanyBloombergticker);
    if (this.existingCompany) {
      // fetch currentResearchTitle and currentResearchDate from BE in one call
      this.emittentenService
        .getCurrentCompanyCurrentResearch(
          this.currentUsersCompanyBloombergticker
        )
        .subscribe((response: any) => {
          this.currentResearchDate = response.data[0].currentResearchDate;
          this.currentResearchTitle = response.data[0].currentResearchTitle;
          this.researchPDFs = response.data[0].researchPDFs;
        });
    }
    this.emittentenService
      .getCurrentCompanyPresentation(this.currentUsersCompanyBloombergticker)
      .subscribe((response: any) => {
        this.currentCompanyPresentation = response.data[0];
      });
    this.buildingBlocksResearch = this.companyInfoService.getLayoutForCompany(
      this.currentUsersCompanyBloombergticker,
      "research",
      "",
      "",
      "cpage research"
    );
    this.buildingBlocksResearch.subscribe((researchPage) => {
      if (researchPage) {
        this.loadArchives(researchPage);
      }
    });
  }

  getAllEvents() {
    this.emittentenService
      .getEmittentenPageData(this.currentUsersCompanyBloombergticker)
      .subscribe((data: any) => {
        this.myEvents = [];
        this.myEvents = data.data as Array<SingleEvent>;
        this.myEvents = this.myEvents.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        //this.myEvents = this.myEvents.filter(el => el.isPublished === true)
        this.myEvents.map((event: SingleEvent) => {
          const today = new Date().setHours(new Date().getHours() - 2); // returns milliseconds, the event is shown as "next event" for two hours after beginning
          if (new Date(event.date).getTime() < today) {
            this.pastEvents.push(event);
          }
          if (new Date(event.date).getTime() >= today) {
            this.upcomingEvents.push(event);
          }
        });
        if (this.upcomingEvents.length > 0) {
          this.nextEvent.push(this.upcomingEvents.pop());
        }

        this.myEvents.map(() => this.expanded.push(false));
        // put Events without Date from start to end of Array
        let tmpArr = [];
        for (let event of this.myEvents) {
          if (event.date === null) {
            tmpArr.push(event);
            this.myEvents.shift();
          }
        }
        for (let event of tmpArr) {
          this.myEvents.push(event);
        }
        // this.events = eventsArr as Array<any>

        //this.notCoveredCompanyName = this.nextEvent[0].companyName
        this.loadingNewData = false;
        this.cdr.detectChanges();
      });
  }

  toggleExpand(i) {
    this.expanded[i] = !this.expanded[i];
  }

  checkIfCompanyExists(bloombergTicker) {
    if (
      bloombergTicker === null ||
      bloombergTicker === undefined ||
      bloombergTicker === ""
    ) {
      this.existingCompany = false;
    } else {
      let companies = this.companyInfoService.getListOfCompanies();
      companies.map((company) => {
        return {
          companyName: company.name,
          bloombergTicker: company.bloombergTicker,
        };
      });
      if (
        companies.filter(
          (company) => company.bloombergTicker === bloombergTicker
        ).length > 0
      ) {
        this.existingCompany = true;
        this.currentUsersCompanyName = companies.filter(
          (company) => company.bloombergTicker === bloombergTicker
        )[0].name;
      }
    }
  }

  copyTextToClipboard(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  loadArchives(cmsPage) {
    this.archives = [];
    let titles = {};
    if (cmsPage.archivedPages) {
      for (let archiveParams of cmsPage.archivedPages) {
        setTimeout(() => {
          this.loadArchive(archiveParams, titles);
        }, 500);
      }
    }
  }
  loadArchive(archiveParams, titles) {
    // let include = { //manually include archives that break the filtering system
    //   '15.06.2021 May 2021 traffic figures': true,

    // }

    let archivePage = this.companyInfoService.getLayoutForCompany(
      this.companyInfoService.getBloombergTicker(this.currentUsersCompanyName),
      "research",
      archiveParams.archiveDate,
      archiveParams.archiveTitle,
      "cpage research archive"
    );

    let include = {
      "13.09.2022 H1-Zahlen bestätigen Erholung; KAUFEN": true,
    };

    archivePage.subscribe((page) => {
      let title = "";
      let titleSplit = archiveParams.archiveTitle.split(" ");
      for (let i = 1; i < titleSplit.length; i++) {
        if (titleSplit[i] !== "-" || i > 2) {
          title = title + titleSplit[i] + " ";
        }
      }

      if (page.blocks) {
        let buttonIndex = page.blocks.findIndex(
          (block) => block.blockType === "button"
        );
        let downloadLink = page.blocks[buttonIndex].data.link;
        // let correctContent = this.decodeHtml(page.blocks[2].data.editorcontent).includes(title.trim());
        let correctContent = true;
        let correctDownloadLink = true;

        let splitDate = titleSplit[0].split(".");
        for (let dateComponent of splitDate) {
          let datePattern = "\\D" + dateComponent + "\\D";
          let datePattern2 = "\\D" + dateComponent.replace("0", "") + "\\D";
          if (
            !downloadLink ||
            (!downloadLink.match(datePattern) &&
              !downloadLink.match(datePattern2))
          ) {
            correctDownloadLink = false;
          }
        }

        if (
          (correctContent && correctDownloadLink) ||
          include[archiveParams.archiveTitle]
        ) {
          //check if archive is not broken
          let archive = {
            archiveDate: archiveParams.archiveDate,
            archiveTitle: archiveParams.archiveTitle,
            archivePage: archivePage,
            expanded: false,
          };

          archive["downloadLink"] = downloadLink;
          page.blocks.splice(buttonIndex, 1);
          archive.archivePage = of(page);

          if (!titles[title]) {
            titles[title] = true;
            this.archives.unshift(archive);
          }
        } else {
        }

        this.archives = this.archives.sort(
          (b, a) =>
            new Date(a.archiveDate).getTime() -
            new Date(b.archiveDate).getTime()
        );
      }
    });
  }
  public getLogoForCompany(companyId: string): string {
    return this.companyInfoService.getLogoForCompany(companyId);
  }

  public getCompanyLogoFromEvent() {
    if (this.myEvents.length === 0) return "";
    return this.myEvents[0].companyLogoURL;
  }
}
