<div *ngIf="isAllowedCompany()" class="row bg-warning mt-2"
  [ngClass]="{'bannerTransactionEditorHeaderSpacer':transactionMode}">
  <div class="col-12">
    <span class="pull-left">
      <button class="btn btn-std" *ngIf="isAuthenticated() && !isEditing()" (click)="startEditing()">Start change mode
        for
        {{companyName}} / {{pageType}}</button>
      <button class="btn btn-inverted-gray" *ngIf="isAuthenticated() && isEditing()" (click)="endEditing()"
        ngbPopover="All changes will be saved" triggers="mouseenter:mouseleave" popoverTitle="Tip">End change
        mode</button>
    </span>

    <span class="pull-right">
      <button class="btn btn-std " style="background-color:red;" *ngIf="isAuthenticated() && isEditing()"
        (click)="openDeletionWarning()" ngbPopover="
        Delete all changes. (Restore to currently published content.)" triggers="mouseenter:mouseleave"
        popoverTitle="Info">Reset all changes for
        {{companyName}} / {{pageType}}</button>
    </span>
    <span class="pull-right">
      <button class="btn btn-std " *ngIf="isAuthenticated() && isEditing()" (click)="openPublishWarning()">Publish
        changes to
        {{companyName}} / {{pageType}}</button>
    </span>
    <span class="pull-right ml-4">
      <button class="btn btn-inverted-gray " *ngIf="!showTitleInput && isAuthenticated() && isEditing()"
        (click)="showTitleInput = true">Archive</button>
      <input class="form-control" *ngIf="showTitleInput" id="archiveTitle" placeholder="Archiv: Titel"
        [(ngModel)]="archiveTitle">
      <button class="btn btn-std " *ngIf="showTitleInput && isAuthenticated() && isEditing()"
        (click)="archivePublishedPage()">OK</button>
    </span>
  </div>


  <div *ngIf="(companyInfoService.loadingBool$ |async) as cmsStatus">
    <!-- <div  *ngIf="cmsStatus.hasOtherActiveWorkSession"> <small>ACHTUNG</small><button (click)="forceStartEditing()">FORCE</button> </div> -->
    <div *ngIf="cmsStatus.isloading">
      <div class="lds-dual-ring"></div> <small>Processing changes...</small>
    </div>

  </div>
</div>