import { Injectable } from '@angular/core';
import { BlockButtonComponent } from '../cms/layoutElements/blockComponents/block-button/block-button.component';
import { BlockChartingComponent } from '../cms/layoutElements/blockComponents/block-charting/block-charting.component';
import { BlockDcfModelComponent } from '../cms/layoutElements/blockComponents/block-dcf-model/block-dcf-model.component';
import { BlockEsgAllComponent } from '../cms/layoutElements/blockComponents/block-esg-all/block-esg-all.component';
import { BlockFinancialTableComponent } from '../cms/layoutElements/blockComponents/block-financial-table/block-financial-table.component';
import { BlockKeyfactsComponent } from '../cms/layoutElements/blockComponents/block-keyfacts/block-keyfacts.component';
import { BlockLargeImageComponent } from '../cms/layoutElements/blockComponents/block-large-image/block-large-image.component';
import { BlockNewsfeedComponent } from '../cms/layoutElements/blockComponents/block-newsfeed/block-newsfeed.component';
import { BlockPdfSimpleListResearchComponent } from '../cms/layoutElements/blockComponents/block-pdf-simple-list-research/block-pdf-simple-list-research.component';
import { BlockScreenerComponent } from '../cms/layoutElements/blockComponents/block-screener/block-screener.component';
import { BlockTableNotabsComponent } from '../cms/layoutElements/blockComponents/block-table-notabs/block-table-notabs.component';
import { BlockTableTabsComponent } from '../cms/layoutElements/blockComponents/block-table-tabs/block-table-tabs.component';
import { BlockTvChartComponent } from '../cms/layoutElements/blockComponents/block-tv-chart/block-tv-chart.component';
import { BlockVideoComponent } from '../cms/layoutElements/blockComponents/block-video/block-video.component';
import { EditorBlockComponent } from '../cms/layoutElements/blockComponents/editor-block/editor-block.component';
import { BuildingBlock } from '../cms/layoutElements/buildingBlocks';
import { translateProgrammingString } from '../utilities/namingTranslation.util';


@Injectable({
  providedIn: 'root',
})


export class CmsService {

  createNewBlock(blockType: string, blockTag: string): BuildingBlock {
    if (blockType === 'editor') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'largeImage') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'dcfModel') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'screener') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'keyfacts') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'financialtable') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'newsfeed') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'charting') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'pdf-research') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'pdf-companyreports') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'table-notabs') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'table-tabs') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'tv-chart') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }

    if (blockType === 'esg-all') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
    if (blockType === 'video') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }

    if (blockType === 'button') {
      return new BuildingBlock(this.selectBlockType(blockType), blockType, { blockTag: blockTag, blockHeadline: translateProgrammingString(blockType), bloombergTicker: 'Eingabe erforderlich' });
    }
  }

  selectBlockType(blockType) {
    if (blockType === 'editor') {
      return EditorBlockComponent;
    }
    if (blockType === 'screener') {
      return BlockScreenerComponent;
    }
    if (blockType === 'largeImage') {
      return BlockLargeImageComponent;
    }
    if (blockType === 'dcfModel') {
      return BlockDcfModelComponent;
    }
    if (blockType === 'keyfacts') {
      return BlockKeyfactsComponent;
    }

    if (blockType === 'financialtable') {
      return BlockFinancialTableComponent;
    }
    if (blockType === 'newsfeed') {
      return BlockNewsfeedComponent;
    }
    if (blockType === 'charting') {
      return BlockChartingComponent;
    }
    if (blockType === 'pdf-research') {
      return BlockPdfSimpleListResearchComponent;
    }
    if (blockType === 'pdf-companyreports') {
      return BlockPdfSimpleListResearchComponent;
    }
    if (blockType === 'table-tabs') {
      return BlockTableTabsComponent;
    }
    if (blockType === 'table-notabs') {
      return BlockTableNotabsComponent;
    }
    if (blockType === 'tv-chart') {
      return BlockTvChartComponent;
    }
    if (blockType === "esg-all") {
      return BlockEsgAllComponent
    }
    if (blockType === "video") {
      return BlockVideoComponent
    }

    if (blockType === 'button') {
      return BlockButtonComponent;
    }
  }

}
