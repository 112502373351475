import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { RequestpasswordComponent } from './requestpassword/requestpassword.component';
import { SharedModule } from '../sharedModule/shared.module';



@NgModule({
  declarations: [SigninComponent, SignupComponent, ResetpasswordComponent,RequestpasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [SigninComponent, SignupComponent]
})
export class AuthModule { }
