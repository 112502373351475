<div class="container-fluid content-wrapper" style="padding-top: 50px;">
  <section class="px-5">
    <div class="row content-wrapper limit-pagewidth">
      <div class="col-lg-12 col-md-12 text-center p-5">
        <h2 class="p-4  d-none d-md-block">Find and compare<br><span class="blueText">interesting companies</span></h2>
        <h4 class="p-4  d-block d-md-none">Find and compare<br><span class="blueText">interesting companies</span></h4>
        <p class="bigger px-4 bold">Select your own search criteria to find what you are looking for in our coverage.
        </p>
      </div>
    </div>

  </section>
  <section class="px-5">
    <div class="row content-wrapper limit-pagewidth">
      <div class="col-lg-12 col-md-12 p-5">
        <app-screener [noblocktitle]="true"></app-screener>

      </div>
    </div>
  </section>
  <div class="my-5"></div>

  <hr>
  <!-- subscibe banner-->

  <div class="row content-wrapper">
    <div class="col-12">
      <h3>Other interesting companies</h3>
    </div>
    <app-company-grid class="full-width" [displayMode]="'companyPage'" [numRows]=1></app-company-grid>
  </div>
  <div class="row content-wrapper">
    <app-subscribe-banner class="full-width"></app-subscribe-banner>
  </div>
</div>