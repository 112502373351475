import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnChanges } from '@angular/core';




@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit {

  @Input() chart: {data: any[], layout: any, config: any};
  
  constructor(private cdr: ChangeDetectorRef) { }
  
  ngOnInit(): void {
  }

  ngOnChanges(): void{
  }
  
}
