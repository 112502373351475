<div class="col-md-12 col-12 border esgScoreBorder rounded"
  *ngIf="stock.labels.positiveLabels?.length >0 || stock.labels.positiveLabels?.length >0">
  <div class="text-center pt-4">
    <h4> ESG-Labels</h4>
  </div>
  <div *ngIf="stock.labels.positiveLabels?.length >0" class="text-center mt-5 ">
    <strong>positive</strong>
  </div>
  <ul class="ul-labelmarker-pos">
    <li *ngFor="let label of stock.labels.positiveLabels">{{label|esgLabelRenamer:"en" }}</li>
  </ul>
  <div *ngIf="stock.labels.negativeLabels?.length >0" class="text-center mt-5"><strong>negative</strong>
  </div>
  <ul class="ul-labelmarker-neg">
    <li *ngFor="let label of stock.labels.negativeLabels">{{label|esgLabelRenamer:"en" }}</li>
  </ul>
</div>

<div class="rounded bg-secondary px-2 py-2"
  *ngIf="stock.labels.positiveLabels?.length <= 0 && stock.labels.negativeLabels?.length <=0">
  <p>Not enough data available to provide ESG labels.</p>
</div>

<div class="mt-5">
  <span class="text-muted"><a target="_blank" href="https://www.leeway.tech/aktienanalyse/{{stock.companyName}}">Full
      data set</a> provided by</span>
  <a target="_blank" href="https://www.leeway.tech/"> <img style="max-width:60% !important; min-width:40%" alt="Leeway"
      src="https://api.leeway.tech/Leeway.png"></a>
</div>
