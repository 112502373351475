<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">File List</h4>
        </div>
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="input-group">
                <input type="text" class="form-control" (input)="searchFiles($event.target.value)" placeholder="Search files">
                <div class="input-group-append">
                  <button class="btn btn-std" type="button">Search</button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-inline float-right">
                <div class="form-group mr-2">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="searchOptions.pdf" (change)="onSearchOptionsChange()" id="pdfCheckbox">
                    <label class="form-check-label" for="pdfCheckbox">Only PDF</label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="searchOptions.image" (change)="onSearchOptionsChange()" id="imageCheckbox">
                    <label class="form-check-label" for="imageCheckbox">Only Image</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-12">
              <div *ngIf="isLoading$ | async" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <span class="ml-2">Loading...</span>
              </div>
              <div *ngIf="isSyncing$ | async" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Syncing...</span>
                </div>
                <span class="ml-2">Syncing...</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-inline">
                <label for="pageSizeSelect" class="mr-2">Page Size:</label>
                <select id="pageSizeSelect" class="form-control" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="padding:0 0 0 0 !important">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="float-right">
                <div>
                  <button class="btn btn-std" (click)="syncFileList()">Synchronize Files</button>
                </div>

                <div>
                  <small class="text-muted">Missing a file in the list? <br>Synchronize the file list with the server.</small>
                </div>

              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered table-sm w-auto ">
              <thead>
              <tr>
                <th scope="col" class="text-left" ># of {{totalFiles}}</th>
                <th scope="col" class="text-left">Filename</th>
                <th></th>
                <th>Last Modified</th>
                <th scope="col" class="text-left">Link</th>

                <!-- Add other relevant table headers -->
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let file of (fileList$ | async)?.files; let i = index">
              <tr >
                <td class="text-left">{{ i +1 }}</td>
                <td class="text-left fingerFix" (click)="onFileClick(file.filename)">{{ file.filename }}</td>

                <td>

                  <button class="btn btn-std" (click)="copyTextToClipboard('https://downloads.research-hub.de/' + file.filename)">copy link</button>

                </td>
                <td>{{file.lastModified }}</td>
                <td class="text-left">https://downloads.research-hub.de/{{ file.filename }}</td>
              </tr>
              <tr *ngIf="(fileMetadata$ | async)[file.filename]">
                <td colspan="3"><app-file-metadata [fileMetadata]="(fileMetadata$ | async)[file.filename]"></app-file-metadata></td>

              </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-12">
              <app-pagination
                ngDefaultControl
                [totalItems]="totalFiles"
                [itemsPerPage]="pageSize"
                [currentPage]="currentPage$ | async"
                (pageChanged)="onPageChange($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
