import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rating-history',
  templateUrl: './rating-history.component.html',
  styleUrls: ['./rating-history.component.scss']
})
export class RatingHistoryComponent implements OnInit {
  @Input() archives: any
  @Input() researchPDFs: any = [{ url: "prefill", title: "prefill" }]
  @Input() currentResearchTitle: any
  @Input() currentResearchDate: any

  correctedPDFs = []

  constructor(
    public activeModal: NgbActiveModal,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if(this.researchPDFs && this.archives){ //horrible hack to restore research history from pdfs with names
      for(let archive of this.archives){
        let title = archive.archiveTitle.replaceAll('-', '');
        let date = title.slice(0,10).replaceAll('-', '').trim().replaceAll(' ', '.');
        let split = date.split('.');
        let computerDate = split[2] + '-' + split[1] + '-' + split[0];
        this.correctedPDFs.push({archiveTitle: title, downloadLink: archive.downloadLink, archiveDate: computerDate});
      }

      for(let pdf of this.researchPDFs){
        let date = pdf.shownName.slice(0,10).replaceAll('-', '').trim().replaceAll(' ', '.');
        let split = date.split('.');
        let computerDate = split[2] + '-' + split[1] + '-' + split[0];
        if(!this.included(this.archives, date) && date !== this.currentResearchDate.replaceAll("/",".")){
          let title = pdf.shownName.slice(10).replaceAll('-', '').trim();
          this.correctedPDFs.push({archiveTitle: date + ' ' + title, downloadLink: pdf.url, archiveDate: computerDate})
        } 
      }

      let split = this.currentResearchDate.split('/');
      let currentComputerDate = split[2]+'-'+split[1]+'-'+split[0];
      this.correctedPDFs.unshift({archiveDate: currentComputerDate, archiveTitle: this.currentResearchDate.replaceAll("/",".") + ' ' + this.currentResearchTitle.replace(/<\/.+>/,'').replace(/<.+>/,''), downloadLink: this.researchPDFs[0].url});

      this.correctedPDFs.sort((b, a) => new Date(a.archiveDate).getTime() - new Date(b.archiveDate).getTime());

      this.correctedPDFs = JSON.parse(JSON.stringify(this.correctedPDFs));

      //console.log(this.correctedPDFs)
    }
  }

  included(archives: Array<{archiveTitle: string, downloadLink: string}>, date: string): Boolean {
    for(let archive of archives){
      if(archive.archiveTitle.includes(date)){
        return true;
      }

      if(archive.archiveTitle.includes(date.replace('.20','.'))){ //with . so we dont replace the 20th day
        return true;
      }
    }

    return false;
  }

  closeWindow() {
    this.activeModal.close()
  }

  public trackItem (index: number, item) {
    return item.trackId;
  }
}
