<div class="row mt-md-5 pt-md-5 content-wrapper limit-pagewidth">
  <div class="col-12">
    <div class="mt-md-5 p-md-5 bg-bluearea">
      <div class="row">
        <div class="col-md-8 mx-auto ">
          <h2 *ngIf="!editingProfile && !isCustomerInvestor" class="text-center">Create your Account</h2>
          <h2 *ngIf="!editingProfile && isCustomerInvestor" class="text-center">Create your Account (1/2)</h2>
          <h2 *ngIf="editingProfile  && isCustomerInvestor" class="text-center">Complete your Profile (2/2)</h2>
          <div class="fingerFix my-3" *ngIf="editingProfile  && isCustomerInvestor" (click)="toggleEditingProfile()">
            back to (1/2)</div>

          <div class="fingerFix my-3 error-in-previous-page"
            *ngIf="editingProfile  && isCustomerInvestor && formHasBasicInfoErrors()" (click)="toggleEditingProfile()">
            back to (1/2) to add missing information</div>



          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <ng-container *ngIf="!editingProfile">
              <div class="form-group">
                <label for="firstName">First Name*</label>
                <input type="text" formControlName="firstName" id="firstName" class="form-control"
                  [ngClass]="{ 'is-invalid': f.firstName.touched && f.firstName.errors }" />
                <div *ngIf="f.firstName.touched && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="lastName">Last Name*</label>
                <input type="text" formControlName="lastName" id="lastName" class="form-control"
                  [ngClass]="{ 'is-invalid': f.lastName.touched && f.lastName.errors }" />
                <div *ngIf="f.lastName.touched && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Email*</label>
                <input type="text" formControlName="email" id="email" class="form-control"
                  [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" />
                <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password*</label>
                <input type="password" formControlName="password" id="password" class="form-control"
                  [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" />
                <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                  <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
              </div>
              <div class="form-group">
                <label for="confirmPassword">Confirm Password*</label>
                <input type="password" formControlName="confirmPassword" id="confirmPassword" class="form-control"
                  [ngClass]="{ 'is-invalid': f.confirmPassword.touched && f.confirmPassword.errors }" />
                <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="f.confirmPassword.errors.passwordMismatch">Passwords must match</div>
                </div>
              </div>
              <div class="form-group">
                <hr>
              </div>
              <div class="form-group">
                <label for="customerType">Role*</label>
                <select formControlName="customerType" class="form-control removePadding"
                  [ngClass]="{ 'is-invalid': f.customerType.touched && f.customerType.errors }">
                  <option *ngFor="let option of customerTypes" [value]="option">{{ option }}</option>
                </select>
                <div *ngIf="f.customerType.touched && f.customerType.errors" class="invalid-feedback">
                  <div *ngIf="f.customerType.errors.required">Rolle is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="companyName">Company</label>
                <input type="text" formControlName="companyName" id="companyName" class="form-control"
                  [ngClass]="{ 'is-invalid': f.companyName.touched && f.companyName.errors }" />
              </div>
              <div class="form-group">
                <label for="city">City</label>
                <input type="text" formControlName="city" id="city" class="form-control"
                  [ngClass]="{ 'is-invalid': f.city.touched && f.city.errors }" />
              </div>
            </ng-container>
            <ng-container *ngIf="editingProfile">
              <ng-container *ngIf="isCustomerInvestor">
                <div *ngFor="let field of investorProfileFields">
                  <ng-container *ngIf="field !== 'PROFIL_INVESTORENTYP'">
                    <div class="form-group">
                      <label [for]="field">{{ field | profileAttributeTranslator }}*:</label>
                      <select [formControlName]="field" [id]="field" class="form-control removePadding"
                        [ngClass]="{ 'is-invalid': f[field].touched && f[field].errors }">
                        <option *ngFor="let option of getEnumOptions(field)" [value]="option">{{ option }}</option>
                      </select>
                      <div *ngIf="f[field].touched && f[field].errors" class="invalid-feedback">
                        <div *ngIf="f[field].errors.required"> {{ field | profileAttributeTranslator }} is required
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-contianer *ngIf="!editingProfile">
              <hr>
              <div>
                <h4>Subscribe to the following newsletters</h4>
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let list of allNewsletters ; let i= index">
                    <div class="d-flex justify-content-between">
                      <span>{{ list.name }}</span>
                      <div class="form-check align-middle">
                        <input style="transform: scale(1.5);" id="{{'Id'+i}}" class="form-check-input" type="checkbox"
                          [checked]="list.subscribed" [disabled]="loading" (change)="addToAutoSubscribeList(list.id)">
                        <label class="form-check-label" for="{{'Id'+i}}"></label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <hr>
            </ng-contianer>
            <div class="form-group text-center my-5"
              *ngIf="!isCustomerInvestor || (isCustomerInvestor && editingProfile)">
              <button [disabled]="loading || formHasAnyErrors() " class="btn btn-std">
                Register
              </button>
              <div class="mt-3">
                <span *ngIf="loading">Your Request is beeing processed.</span>
                <span *ngIf="success">
                  <p>
                    Thank You! We have sent an email to your email-address.
                    Please click on the link in that email to confirm your registration. You will then be able to login.
                  </p>
                </span>

                <div class=" text-center mt-3 bg-white rounded mx-auto" *ngIf="showAlert">
                  <strong style="color:black"> This email may already exist. <a href="/requestnewpassword"
                      style="color:black; text-decoration: underline;">Forgot
                      your password?</a></strong>
                </div>

              </div>
            </div>

          </form>
          <button *ngIf="isCustomerInvestor && !editingProfile" (click)="toggleEditingProfile()"
            [disabled]="loading || formHasBasicInfoErrors()" class="btn btn-std">
            Complete your Profile
          </button>
          <div class=" text-center mt-3 bg-white rounded mx-auto"
            *ngIf="isCustomerInvestor && formHasBasicInfoErrors()">
            <strong style="color:black">
              Please fill the mandatory fields (marked with *) before completing your investor profile.
            </strong>
          </div>
          <div class=" text-center mt-3 bg-white rounded mx-auto"
          *ngIf="(!isCustomerInvestor && formHasAnyErrors())">
          <strong style="color:black">
            Please fill the mandatory fields (marked with *) before completing registration.
          </strong>
        </div>
        <div class=" text-center mt-3 bg-white rounded mx-auto"
        *ngIf="(isCustomerInvestor &&  editingProfile && formHasProfileErrors())">
        <strong style="color:black">
          Please fill the mandatory fields (marked with *) before completing registration.
        </strong>
      </div>
        </div>
      </div>
    </div>
  </div>
</div>