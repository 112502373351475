import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AllStocksComponent } from './basic-pages/all-stocks/all-stocks.component';
import { BlogComponent } from './basic-pages/blog/blog.component';
import { DatenschutzComponent } from './basic-pages/datenschutz/datenschutz.component';
import { FooterComponent } from './basic-pages/footer/footer.component';
import { HeaderComponent } from './basic-pages/header/header.component';
import { ImpressumComponent } from './basic-pages/impressum/impressum.component';
// import { LandingpageComponent } from './basic-pages/landingpage/landingpage.component';
import { LegalDisclaimerComponent } from './basic-pages/legal-disclaimer/legal-disclaimer.component';
import { MifidDisclaimerComponent } from './basic-pages/mifid-disclaimer/mifid-disclaimer.component';
import { DisclaimerModalComponent } from './basic-pages/modals/disclaimer-modal/disclaimer-modal.component';
import { NewsletterSubscribeComponent } from './basic-pages/modals/newsletter-subscribe/newsletter-subscribe.component';
import { SubscriptionRequestedModalComponent } from './basic-pages/modals/subscription-requested-modal/subscription-requested-modal.component';
import { SubscriptionSuccessModalComponent } from './basic-pages/modals/subscription-success-modal/subscription-success-modal.component';
import { VideoModalComponent } from './basic-pages/modals/video-modal/video-modal.component';
import { StockScreenerComponent } from './basic-pages/stock-screener/stock-screener.component';
import { TransactionbannerComponent } from './basic-pages/transactionbanner/transactionbanner.component';
import { WelcomeComponent } from './basic-pages/welcome/welcome.component';
import { CmsModule } from './cms/cms.module';
import { CompanyInfoModule } from './company-info/company-info.module';
import { SubpageLayoutSelectionComponent } from './company-info/modals/subpage-layout-selection/subpage-layout-selection.component';
import { CorporateDashboardModule } from './corporate-dashboard/corporate-dashboard.module';
import { JwtInterceptor } from "./services/auth/jwt.interceptor";
import { CompanyInfoService } from './services/company-info.service';
import { SharedModule } from './sharedModule/shared.module';
import { ToastsContainer } from "./toast-container/toast-container.component";
import { UserHandlingModule } from './user-handling/user-handling.module';
import { UtilitiesModule } from './utilities/utilities.module';
import { TextModalComponent } from './basic-pages/modals/text-modal/text-modal.component';
import { CookieBannerModalComponent } from './basic-pages/modals/cookie-banner-modal/cookie-banner-modal.component';
import { CookiesDeniedPageComponent } from './basic-pages/cookies-denied-page/cookies-denied-page.component';
import { SignupCompleteComponent } from './basic-pages/signup-complete/signup-complete';




export function SetupApp(setup: CompanyInfoService) {
  return (): Promise<any> => {
    return setup.initalizeApp();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // LandingpageComponent,
    ImpressumComponent,
    DatenschutzComponent,
    WelcomeComponent,
    MifidDisclaimerComponent,
    LegalDisclaimerComponent,
    NewsletterSubscribeComponent,
    SubpageLayoutSelectionComponent,
    DisclaimerModalComponent,
    BlogComponent,
    StockScreenerComponent,
    VideoModalComponent,
    AllStocksComponent,
    SubscriptionSuccessModalComponent,
    SubscriptionRequestedModalComponent,
    TransactionbannerComponent,
    SignupCompleteComponent,
    ToastsContainer,
    TextModalComponent,
    CookieBannerModalComponent,
    CookiesDeniedPageComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UserHandlingModule,
    CmsModule,
    CompanyInfoModule,
    AuthModule,
    UtilitiesModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    NgxGoogleAnalyticsModule.forRoot("G-CDCMZ9EJSM", [{
      command: 'consent',
    values: [
        'default',
        {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        }
    ]
    }]),
    NgxGoogleAnalyticsRouterModule,
    CorporateDashboardModule
  ],
  providers: [CompanyInfoService, {
    provide: APP_INITIALIZER,
    useFactory: SetupApp,
    deps: [CompanyInfoService],
    multi: true,
  }, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
