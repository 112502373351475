import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { alsterAPI_URL, leewayAPI_URL } from 'src/app/app-settings/api-urls';
import { ToastService } from '../toast.service';
import { AlsterUser, CustomerUser, NewContact, NewUser, PossibleCustomerTypes, PossibleUserRoles, investorTypes } from 'src/app/models/user.model';
import { checkValueInEnum } from 'src/app/utilities/general.util';




@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public currentUserName = ""
  public temporaryEmailInput = ""
  constructor(private httpClient: HttpClient, private toastService: ToastService, private router:Router) { }
  currentToken = null;
  login(data): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.getToken(data).then((token:any) => {
        if (token !== null && token !== undefined) {
          this.saveToken(token);
          resolve(token);
        } else {
          _reject(token);
        }

      }, err => {
        _reject(err);
      });
    });

  }

  logOff(): void {
    window.localStorage.removeItem('rh');
    this.temporaryEmailInput = ""
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }



  
  isAuthenticated(): boolean {
    if (this.CurrentUser && checkValueInEnum(this.CurrentUser.userRole, PossibleUserRoles)) {
      return true
    } else {
      return false;
    }
  }
  loadToken() {
    this.currentToken = window.localStorage.getItem('rh');
    return this.currentToken
  }

  saveToken(recievedToken) {
    localStorage.setItem('rh', recievedToken);
  }

  get CurrentToken(): string {
    return this.currentToken;
  }

  get CurrentUser(): any { // : AlsterUser|IssuerUser
    // we should send the token to the backend and verify it there before decoding here.
    const jwtHelper = new JwtHelperService();
    try {
      let token = this.loadToken()
      if (token === undefined || token === null) {
        return null
      } else {
        let decoded =  jwtHelper.decodeToken(token)
        let tmpUser = decoded.user as AlsterUser|CustomerUser
        this.currentUserName = tmpUser.userName
        return tmpUser
      }
    } catch (err) {
      console.log(err)
      return null
    }
  }

  getCurrentUserProfileStatus(){
      const body = { usermail:this.getCurrentUserName()  }; 
      return this.httpClient.post(alsterAPI_URL + 'hubuser/checkinvestorprofile', body)
  }
  getCurrentUserInvestorProfile(){
    return this.httpClient.get(alsterAPI_URL + 'hubuser/getinvestorinfo?usermail='+this.getCurrentUserName())
  }
  updateCurrentUserInvestorProfile(investorProfile:any){
    let body = {
      usermail:this.getCurrentUserName(), //TODO change to currentuser email
      investorProfile
    }
    return this.httpClient.post(alsterAPI_URL + 'hubuser/updateinvestorprofile?usermail='+this.getCurrentUserName(),body)
  }
  getCustomAttributesForInvestorProfile(filtered:boolean){
    if(filtered){
      return this.httpClient.get(alsterAPI_URL + 'hubuser/getcustomattributes?filtered=true')
    }else{
      return this.httpClient.get(alsterAPI_URL + 'hubuser/getcustomattributes')

    }
  }
  getCurrentUsersNewsletterStatus(){
    return this.httpClient.get(alsterAPI_URL + 'hubuser/subscribednewsletters?usermail='+this.getCurrentUserName())
  }

  removeCurrentUserFromList(listId:number){
    let body = {listId, usermail:this.getCurrentUserName()}
    return this.httpClient.post(alsterAPI_URL + 'sib/list/removecontact',body)
  }
  addCurrentUserToList(listId:number){
    let body = {listId, usermail:this.getCurrentUserName()}
    return this.httpClient.post(alsterAPI_URL + 'sib/list/addcontact',body)
  }
  getNewsletterList(){
    if(this.getCurrentUserName() === ""){
      return this.httpClient.get(alsterAPI_URL + 'sib/newsletters')

    }else{
      return this.httpClient.get(alsterAPI_URL + 'sib/newsletters?usermail='+this.getCurrentUserName())
    }
  }
  getCurrentUserName(){
    return this.CurrentUser?.userName?this.CurrentUser.userName:""
  }
  getcurrentUserCompanyBloombegticker():string{
    return this.CurrentUser?.bloombergTicker?this.CurrentUser.bloombergTicker:""
  }
  getcurrentUserCompanyName():string{
    return this.CurrentUser?.companyName?this.CurrentUser.companyName:""
  }
  getToken(data) {
    return new Promise((resolve, reject) => {
      const body = { username: data.userName, password: data.password }; //
      this.httpClient.post(alsterAPI_URL + 'hubuser/login', body).toPromise().then((res: any) => {
        const token = res.data[0].token;
        resolve(token);
      },
        err => {
          reject(err);
        });
    });
  }

  verifyToken() {
    return new Promise((resolve, reject) => {
      const body = { username: this.CurrentUser.userName }; //
      this.httpClient.post(alsterAPI_URL + 'hubuser/verify', body).toPromise().then((res: any) => {
        const valid = res.data[0]?.validToken
        if (valid) { resolve(valid) } else {
          // red banner not shown until it is fixed...
          //this.toastService.show('You recieved new permissions. Please log out and into your account again to use your new permissions.', { classname: 'bg-danger text-light', delay: 150000 });
          resolve(valid)
        }
      })
    })

  }
  getCurrentUserRole(){
    return this.CurrentUser?.type?this.CurrentUser.userRole:"customer"
  }
  isAdmin(): boolean {
    let tmp = this.CurrentUser
    if (tmp && tmp.userRole === PossibleUserRoles.Admin) {
      return true
    } else {
      return false;
    }
  }
  isCustomer(): boolean {
    let tmp = this.CurrentUser
    
    if(this.isAdmin())
    {
      return true
    }
    else if (tmp && tmp.userRole === PossibleUserRoles.Customer) {
      return true
    } else {
      return false;
    }
  } 
  isContributor(): boolean {
    let tmp = this.CurrentUser
    if (tmp && tmp.userRole === PossibleUserRoles.Contributor) {
      
      return true
    } else {
      return false;
    }
  }

  getCurrentCustomerType(){
    return this.CurrentUser.customerType
  }
  isCustomerEmittent(): boolean {
    let tmp = this.CurrentUser
    if (tmp && tmp.customerType === PossibleCustomerTypes.Emittent && this.CurrentUser.isCorporateUser) { 
      
      return true
    } else {
      return false;
    }
  }
  isCustomerInvestor(): boolean {
    let tmp = this.CurrentUser
    if (tmp && checkValueInEnum(tmp.customerType, investorTypes)) { 
      return true
    } else {
      return false;
    }
  }

  canUseAdminPanel() {
    if (this.isAdmin()) { return true; }
    let tmp = this.CurrentUser
    if (tmp && tmp.adminPanel) {
      return true
    } else {
      return false;
    }
  }
  /**
   * needed for ADMIN und CONTRIBUTOR, 
   */
  isAllowedCompany(requestedCompanyId) {
    if (this.isAdmin()) { return true; }


    let tmp = this.CurrentUser
    if (tmp && tmp.rights.companies.find(item => item.companyId === requestedCompanyId)) {
      return true
    } else {
      return false;
    }
  }

  /**
   * needed for ADMIN und CONTRIBUTOR, 
   */
  isAllowedAction(requestedCompanyId, requestedAction) { // requestedAction must be "write", "download", "upload"

    if (this.isAdmin()) { return true; }
    let tmpUser = this.CurrentUser
    if (tmpUser) {
      let tmpCompany = null
      // legacy
      if(tmpUser.allowedCompanies){
        tmpUser.allowedCompanies.find(item => item.companyId === requestedCompanyId)
      }else{
        tmpUser.rights.companies.find(item => item.companyId === requestedCompanyId)
      }

      if (tmpCompany && tmpCompany[requestedAction]) {
        return true
      }
    }
    else {
      return false;
    }
  }


  /**
   * needed for ADMIN und CONTRIBUTOR, 
   */

  getAllUsers() {
    return this.httpClient.get(alsterAPI_URL + 'oldalsteruser/all')
  }
  changeUserRole(userId, newRole) {
    let body = { userId, newRole }
    return this.httpClient.post(alsterAPI_URL + 'oldalsteruser/changeuserrole', body).toPromise()
  }
  setAccessToAdminPanel(userId, user) {
    let body = { userId, adminPanel: user.rights.adminPanel }
    return this.httpClient.post(alsterAPI_URL + 'oldalsteruser/setaccesstoadminpanel', body).toPromise()
  }

  addAllowedCompanies(userId, newAllowedCompany) {
    let body = { userId, company: newAllowedCompany }
    return this.httpClient.post(alsterAPI_URL + 'oldalsteruser/addallowedcompanies', body).toPromise()
  }
  deleteAllowedCompanies(userId, newAllowedCompany) { 
    let body = { userId, company: newAllowedCompany }
    return this.httpClient.post(alsterAPI_URL + 'oldalsteruser/deleteallowedcompanies', body).toPromise()
  }
  updateAllowedCompanies(userId, rights) {
    let body = { userId, newRights: rights }
    return this.httpClient.post(alsterAPI_URL + 'oldalsteruser/updateallowedcompanies', body).toPromise()
  }

  saveDisclaimerModalToken() {
    localStorage.setItem('rhmodal', JSON.stringify({ "seenDisclaimer": true }));
  }

  checkDisclaimerModalTokenIsNotSet() {

    const token: string = window.localStorage.getItem('rhmodal');

    if (token !== null && token !== undefined) {

      let parsedToken = JSON.parse(token);
      if (parsedToken.seenDisclaimer) {
        return true
      } else {
        return false
      }

    } else {

      return false
    }
  }

  signup(newUser:NewUser, newContact:Partial<NewContact>){
    return new Promise((resolve, reject) => {
      const body = { newUser,newContact }; 

      this.httpClient.post(alsterAPI_URL + 'hubuser/register', body).toPromise().then((res: any) => {
        const result = {
          success:res.success,
          msg:res.message,
          emailStatus:res.data[0].emailStatus
        }
        resolve(result);
      },
        err => {
          reject(err);
        });
    });
  }

  requestPasswordReset(usermail){
    return new Promise((resolve, reject) => {
      const body = { usermail }; 
      this.httpClient.post(alsterAPI_URL + 'hubuser/requestpasswordreset', body).toPromise().then((res: any) => {
        const result = {
          success:res.success,
          msg:res.message,
          emailStatus:res.data[0].emailStatus
        }
        resolve(result);
      },
        err => {
          reject(err);
        });
    });
  }
  setNewPassword(usermail, newPassword, passwordResetToken){
    return new Promise((resolve, reject) => {
      const body = { 
        usermail,
        newPassword,
        passwordResetToken
       }; 
      this.httpClient.post(alsterAPI_URL + 'hubuser/resetpassword', body).toPromise().then((res: any) => {
        const result = {
          success:res.success,
          msg:res.message,
          emailStatus:res.data[0].emailStatus
        }
        resolve(result);
      },
        err => {
          reject(err);
        });
    });
  }

  toggleBlockUser(user:any) {
    const url = alsterAPI_URL + 'hubuser/update' 
    const update = {
      "blocked":true // this will be toggled in BE
    }
    const payload = {usermail:user.email, update}
    return this.httpClient.post(url, payload);
  }

  deleteUser(user:any) {
    const url = alsterAPI_URL + 'hubuser/delete' 
    const payload = {usermail:user.email}
    return this.httpClient.post(url, payload);
  }

}
