<div [ngClass]="{'border border-dark rounded' : adminMode}" id="{{data.blockTag}}">
  <!-- <h3>{{data.currentTemplate}}</h3> -->
  <div class="text-center" *ngIf="!isEditorMode && !notInitalized">
    <app-charting [templateName]="data.currentTemplate" [companyId]="metaBloombergTicker"
      [companyData]="metaCompanyData" (templateHeadlineEmitter)="setHeadline(event)"></app-charting>
  </div>
  <div class="row" *ngIf="adminMode">
    <div class="col-12">
      <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
        [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
        [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData"></app-block-handling>
    </div>
  </div>
</div>
