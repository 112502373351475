import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare const TradingView: any;
@Component({
  selector: 'app-tv-chart',
  templateUrl: './tv-chart.component.html',
  styleUrls: ['./tv-chart.component.scss']
})
export class TvChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chartWidthMeasureElementMODAL') divToMeasureElement: ElementRef;
  @Input() bloombergTicker
  chartSymbol = "";
  chartSettings: any = {};
  chartWidgetId: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.chartSymbol = this.createExchangeAndCodeForTVChart(this.bloombergTicker)

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.createChart()
    },500)
  }
  createChart() {
    let divToMeasureWidth = this.divToMeasureElement.nativeElement.offsetWidth;
    let divToMeasureHeight = this.divToMeasureElement.nativeElement.offSetHeight;
    new TradingView.widget(
      {
        "width": divToMeasureWidth, /// 1.6,// / 0.8,
        "height": divToMeasureHeight, // * 1.2, /// 1.5  / 1.60655737705, // this is the standart TV-Chart width/heith ratio
        //"autosize":true,
        "symbol": this.chartSymbol,
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "1",
        "locale": "uk",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "save_image": false,
        "container_id": "tradingview_bac65",
        "allow_symbol_change": true,
      }
    );
    this.cdr.detectChanges()
  }
  createExchangeAndCodeForTVChart(blTicker) {
    let exchange = "XETR"
    let code = blTicker.split(":")[0]
    let symbolMap = {
      "2GI": "MUN",
      "R1B": "MUN",
      "CDZ0": "MUN",
      "991": "MUN",
      "NSP": "MIL",
      "XCX": "DUS",
      "P7V": "SWB",
      "ABA": "FWB",
      "THAG": "SIX"
    }
    /**Hardcode fix for single stocks */
    if (code === "GSJ") {
      code = "SGF"
    }

    if (symbolMap.hasOwnProperty(code)) {
      exchange = symbolMap[code]
    }
    else {
      /*
      use default exchange value
      */
    }
    return exchange + ":" + code
  }
  closeWindow() {
    this.activeModal.close()
    }
}
