import { ChangeDetectionStrategy, Component, Input, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mapPageTypeNames } from 'src/app/utilities/namingTranslation.util';
@Component({
  selector: 'app-subpage-selector',
  templateUrl: './subpage-selector.component.html',
  styleUrls: ['./subpage-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubPageSelectorComponent {
  @Input() currentCompany: any;
  @Input() currentPage: any;

  @ViewChildren("DropdownNavigation") Dropdowns: any;

  allDropdownRefs = {}
  subpages = [];

  /**Order of Links with Dropdown for Subpages */
  allSubpagesWithTags = {
    "investment-case": {
      shownName: "Equity Story",
      tags: [
        { name: "Investment Case", tag: "InvestmentCase" },
        { name: "Key Figures", tag: "keyfacts" },
      ]
    },
    "snapshot": {
      shownName: "Snapshot",
      tags: [
        { name: "Product & Services", tag: "ProductsServices" },
        { name: "Segments", tag: "Segments" },
        { name: "Management", tag: "Management" },
        { name: "Major Shareholders", tag: "Shareholder" },
      ]
    },
    "quality": {
      shownName: "Quality",
      tags: [
        { name: "Competition", tag: "Competition" },
        { name: "ROCE vs. WACC", tag: "ROCE" },
        { name: "Customer", tag: "Customers" },
        { name: "Regional sales Table", tag: "RegionalSales" },
        { name: "Suppliers", tag: "Suppliers" },
      ]
    },
    "growth": {
      shownName: "Growth",
      tags: [{ name: "Growth", tag: "Growth" },
      { name: "Growth Table", tag: "GrowthTable" },
      { name: "FCF growth", tag: "FCF" }]
    },
    "research": {
      shownName: "Research",
      tags: [
        { name: "Latest Research", tag: "LatestResearch" },
        { name: "Quarterly Performance", tag: "Quarterly" },
        { name: "Research", tag: "researchPDF" },

      ]
    },

    "valuation": {
      shownName: "Valuation",
      tags: [
        { name: "DCF Model", tag: "dcfModel" },
        { name: "Adj. FCF yield", tag: "FCFModel" },
        { name: "Trading Multiples", tag: "Multiples" }
      ]
    },
    "financials": {
      shownName: "Financials",
      tags: []
    },
    "swot": {
      shownName: "SWOT",
      tags: []
    },
    "chart": {
      shownName: "Chart",
      tags: []
    },
    "esg": {
      shownName: "ESG",
      tags: []
    },

    "corporate": {
      shownName: "Corporate",
      tags: [
        { name: "Company Presentation", tag: "pdf-presentation" },
        { name: "Annual Reports", tag: "pdf-annual" },
        { name: "Quarterly Reports", tag: "pdf-quarterly" },
        { name: "Company Video", tag: "video" },
      ]
    },
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {

    // das hier hat globale auswirkungen, egal wo es steht. also gibt es jetzt in utils und router.module einen gobalen handler bei dem man diese reload strategie pro route einstellen kann
    // this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    this.router.events.subscribe(events => {
      let e = events as any;
      if (e && e instanceof NavigationEnd) {
        let splitURL = e.url.split('/');
        if (splitURL[1] === 'companies') {
          this.currentPage = mapPageTypeNames(splitURL[2], false) // true: snapshot->overview    false:overview->snapshot
          this.currentCompany = decodeURIComponent(splitURL[3].split(/#|\?/)[0]);
        }
      }
    })

    // this.activatedRoute.url.subscribe(parts => {
    //   this.currentCompany = parts[2].path;
    //   this.currentPage = parts[1].path;
    // });
  }

  ngOnInit() {
    this.subpages = Object.keys(this.allSubpagesWithTags);
  }

  handleHoverDropdown($event, open, index) {
    $event.stopPropagation();
    if (open) {
      this.Dropdowns._results[index].open()
    } else {
      this.Dropdowns._results[index].close()
    }
    this.closeAllOtherDropdowns(index)
  }

  closeAllOtherDropdowns(index) {
    for (let i = 0; i < this.Dropdowns._results.length; i++) {
      if (i !== index) {
        this.Dropdowns._results[i].close();
      }
    }
  }
}
