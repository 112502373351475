import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorType, createChart, LineData } from 'lightweight-charts';
import { VideoModalComponent } from 'src/app/basic-pages/modals/video-modal/video-modal.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BookAMeetingComponent } from '../../modals/book-a-meeting/book-a-meeting.component';
import { NewsAllComponent } from '../../modals/news-all/news-all.component';
import { RatingHistoryComponent } from '../../modals/rating-history/rating-history.component';
import { ShareholdersDiagramComponent } from '../../modals/shareholders-diagram/shareholders-diagram.component';
import { TvChartComponent } from '../../modals/tv-chart/tv-chart.component';
import { TableSliderComponent } from '../tiles/tileparts/table-slider/table-slider.component';
import { timeZoneFormat } from 'src/app/events/utils/event.utils';
import { SingleEvent } from 'src/app/models/event.model';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { DataServiceService } from 'src/app/services/data-service.service';
import { niceDate } from 'src/app/utilities/numberFormatting.util';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-large-tile-grid',
  templateUrl: './large-tile-grid.component.html',
  styleUrls: ['./large-tile-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class LargeTileGridComponent implements OnInit, AfterViewInit, OnChanges {
  timeZoneFormat = timeZoneFormat

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() currentCompanyCMSEntry: any
  @Input() archives: any
  @Input() buildingBlocksSnapshot: any
  @Input() buildingBlocksCorporate: any
  @Input() symbolExchange: any
  @Input() archiveDate: any
  @Input() archiveTitle: any
  @Input() companyName: any
  quotes: any = [];
  @Input() videoURLObj: any
  @Input() buildingBlocksResearch: any
  @Input() lang = 'eng';

  @Input() config: { headline: string, type: string, content: string, height: number, width: number, mobile: boolean };

  @ViewChildren("tvShareChart") chart
  @ViewChild(TableSliderComponent) companyInfoTableSlider: TableSliderComponent;

  shareholders = []
  freefloat = 0
  latestSharePrice = 0
  news = []
  latestArticle: any
  latestEvent: any = undefined;
  @Input() loadingNewData = true;
  public isCompanyLogoFullWidth = false;
  chartWidth = 600;
  chartHeight = 300;
  chartData: LineData[] = [{ time: "a", value: 2 }]
  barValues = []
  barMax = 100;
  barMin = 0;
  freefloatWidth = 0;
  lineSeries = null
  chartObj = null
  imageURL = "";
  researchPDFs = []
  currentResearchTitle: any;
  currentResearchDate: any;
  currentCompanyPresentation: any;
  companyInfoTileSettings: any
  data: any;
  calculations: any = {}
  investorRelationsLink: any = null;
  chartID = null;
  showChart = true;

  currentCompanyId = "";

  showCompanyDescription = false;

  constructor(
    private dataService: DataServiceService,
    private companyInfoService: CompanyInfoService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private utilitiesService: UtilitiesService,
    private tableUtils: Tables
  ) {
    this.chartID = String(Math.floor(Math.random() * 100));

  }
  ngOnInit(): void {
    this.catchMissintCompanyImageOrColor()
    this.handleInputData()



    //this.updateChart()

  }

  prepareCompanyInfoTableTile() {
    // set the company info tile settings
    // this contains some useless info, but it reselmbes the data structure of the other tiles
    this.companyInfoTileSettings = {
      height: 1,
      width: 1,
      tiles: [
        {
          headline: 'company info 1',
          type: 'table-slider',
          // this content is different from the other tile-parts, this one accepts objects as subarray
          content: [
            ['bl_ticker', 'mc', 'ev', 'free_float', 'volume', 'num_shares'],
            ['isin', 'wkn', 'sector', 'country', 'index'],
            ['con_rating', 'con_pt', 'con_pt_range', '52w_high', '52w_low'],
            ['event', 'event_date'],
            [{ type: 'custom', content: 'esgScores', input: this.symbolExchange }]
          ],
          height: 2,
          width: 1,
          mobile: true
        }
      ]
    }
    this.config = this.companyInfoTileSettings.tiles[0]
  }

  catchMissintCompanyImageOrColor() {
    if (this.currentCompanyCMSEntry.imageURL === null || this.currentCompanyCMSEntry.imageURL === undefined) {
      this.currentCompanyCMSEntry.imageURL = "https://api.7markets.de/GEA%20Group___79ras6ku.jpg"
    }
    if (this.currentCompanyCMSEntry.colorfilter === null || this.currentCompanyCMSEntry.colorfilter === undefined) {
      this.currentCompanyCMSEntry.colorfilter = "#0F6BB2"
    }
  }
  handleDataFromBlocks() {
    if (this.buildingBlocksResearch && this.buildingBlocksResearch.blocks) {
      let pdfBlock = this.buildingBlocksResearch.blocks.find(block => block.blockType === "pdf-research")
      if (pdfBlock) {
        this.researchPDFs = pdfBlock.data.pdfLinks
      }
      let editorBlock = this.buildingBlocksResearch.blocks.find(block => block.blockType === "editor")
      if (editorBlock) {
        let text = editorBlock.data.editorcontent
        let tmp = []
        tmp = text.split("<strong>")

        for (let item of tmp) {
          if (!item.split("</strong>")[0].includes("Latest Research") &&
            !item.split("</strong>")[0].includes(": justify") &&
            !item.split("</strong>")[0].includes(":justify") &&
            !item.split("</strong>")[0].includes("Research") &&
            !item.split("</strong>")[0].includes("&nbsp;") &&
            !this.currentResearchTitle
          ) {
            this.currentResearchTitle = item.split("</strong>")[0]
          }
        }
        // this.currentResearchTitle = text.substring(
        //   text.indexOf("<strong>"),
        //   text.indexOf("</strong>")
        // )
        // this.currentResearchTitle = this.currentResearchTitle.replaceAll("<strong>", "")

      }
      let buttonBlock = this.buildingBlocksResearch.blocks.find(block => block.blockType === "button")
      if (buttonBlock) {
        this.currentResearchDate = niceDate(buttonBlock.data.date)
      }
    }
    // GET COMPANY PRESENTATION
    this.buildingBlocksCorporate.subscribe(cmsPage => {
      if (cmsPage.blocks) {
        let companyPresentations = cmsPage.blocks.find(block =>
          block.blockType === "pdf-research" &&
          (block.data.pdfMode === "companypresentation" || block.data.blockTag === "#pdf-presentation"))

        if (companyPresentations) {
          this.currentCompanyPresentation = companyPresentations.data.pdfLinks[0].url
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.companyData.CompanyId !== this.currentCompanyId) {
      this.currentResearchTitle = this.currentCompanyCMSEntry.latestResearchTitle;
      this.currentCompanyId = this.companyData.CompanyId;
      if (this.companyData.CompanyId === 'BAT:SS') {
        this.showChart = false;
      }

      this.scrollToTop(0)
      this.catchMissintCompanyImageOrColor()
      this.handleInputData()
      this.updateChart()
      this.prepareCompanyInfoTableTile()
    }

    this.handleDataFromBlocks()
    this.cdr.detectChanges()

  }

  getInvestorRelationsLink() {
    if (this.mapping && this.companyData && this.companyData.base) {
      let key = "ir_website"
      if (this.mapping[key]) {
        let mappingEntry = this.mapping[key];
        if (mappingEntry.type === 'VALUE' || mappingEntry.type === '') {
          let entry = { title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, 0) };
          this.investorRelationsLink = entry;
        }
      }
    }
  }

  getQuotes(): Promise<any> {
    return new Promise((resolve) => {
      let companyLeewayTicker = this.utilitiesService.getMappedExchange(this.symbolExchange);
      this.dataService.getHistoricalQuotes(companyLeewayTicker).subscribe((res) => {
        this.quotes = res;
        resolve(this.quotes)
      })
    })

  }

  parseShareHolders() {
    this.shareholders = []
    this.barValues = []
    // SHAREHOLDERS
    if (this.companyData.general_information.shareh_name_A !== "#EMPTY") {
      this.shareholders.push({ name: this.companyData.general_information.shareh_name_A, value: 100 * this.companyData.general_information.shareh_perc_A })
      this.barValues.push(100 * this.companyData.general_information.shareh_perc_A)
    }
    if (this.companyData.general_information.shareh_name_B !== "#EMPTY") {
      this.shareholders.push({ name: this.companyData.general_information.shareh_name_B, value: 100 * this.companyData.general_information.shareh_perc_B })
      this.barValues.push(100 * this.companyData.general_information.shareh_perc_B)
    }
    if (this.companyData.general_information.shareh_name_C !== "#EMPTY") {
      this.shareholders.push({ name: this.companyData.general_information.shareh_name_C, value: 100 * this.companyData.general_information.shareh_perc_C })
      this.barValues.push(100 * this.companyData.general_information.shareh_perc_C)
    }
    if (this.companyData.general_information.shareh_name_D !== "#EMPTY") {
      this.shareholders.push({ name: this.companyData.general_information.shareh_name_D, value: 100 * this.companyData.general_information.shareh_perc_D })
      this.barValues.push(100 * this.companyData.general_information.shareh_perc_D)
    }
    if (this.companyData.general_information.shareh_name_E !== "#EMPTY") {
      this.shareholders.push({ name: this.companyData.general_information.shareh_name_E, value: 100 * this.companyData.general_information.shareh_perc_E })
      this.barValues.push(100 * this.companyData.general_information.shareh_perc_E)
    }
    if (this.companyData.general_information.shareh_name_F !== "#EMPTY") {
      this.shareholders.push({ name: this.companyData.general_information.shareh_name_F, value: 100 * this.companyData.general_information.shareh_perc_F })
      this.barValues.push(100 * this.companyData.general_information.shareh_perc_F)
    }
    this.shareholders = this.shareholders.splice(0, 2)
    this.barValues = this.barValues.splice(0, 2)
    // get freefloat
    this.freefloat = 100 * this.companyData.general_information.free_float // in perc
    this.barValues.push(100 * this.companyData.general_information.free_float)
    // get greates value and set it to max width
    this.shareholders.map(holder => holder.width = (holder.value - this.barMin) / (this.barMax - this.barMin) * 100)
    this.freefloatWidth = ((this.freefloat - this.barMin) / (this.barMax - this.barMin) * 100)
  }

  handleInputData() {

    this.imageURL = encodeURI(this.currentCompanyCMSEntry.imageURL)
    // SHARE HOLDERS
    this.parseShareHolders()

    // SHAREPRICE
    this.latestSharePrice = Number(this.companyData.quotes[this.companyData.quotes.length - 1].close_for_show)

    //LATEST VIDEO
    this.getLatestEvent()

    this.getInvestorRelationsLink()

  }

  calculateUpDown(quotes) {
    this.calculations["day"] = this.getChangeInPerc(quotes, 5, 'adjusted_close');
    this.calculations["month"] = this.getChangeInPerc(quotes, 23, 'adjusted_close');
    this.calculations["year"] = this.getChangeInPerc(quotes, 253, 'adjusted_close');
    this.cdr.detectChanges();
  }

  getChangeInPerc(data: Array<{ adjusted_close: number, close: number }>, offset: number, field: string) {
    if (data.length > offset + 1) {
      return ((data[data.length - 1][field] - data[data.length - offset - 1][field]) / data[data.length - offset - 1][field]) * 100;
    } else {
      return null;
    }
  }


  updateChart() {
    // UPDATE CHART
    if (this.chartObj) {
      this.getQuotes().then((quotes) => {
        this.calculateUpDown(quotes)
        if (this.lineSeries) { this.chartObj.removeSeries(this.lineSeries) }
        this.lineSeries = this.chartObj.addLineSeries({
          color: '#0F6BB2',
          lineWidth: 3,
          lastValueVisible: false,
          priceLineVisible: false,
          baseLineVisible: false
        });
        let uniqueQuotes = [...quotes.reduce((map, obj) => map.set(obj.date, obj), new Map()).values()];
        this.changeCurrenciesHistory(uniqueQuotes, this.currentCompanyId).then(res => {
          this.quotes = res;
          this.chartData = res.map(el => { return { time: el.date, value: el.adjusted_close } as LineData })
          this.chartData.sort((a, b) => (a.time > b.time ? 1 : 0))
          this.lineSeries.setData(this.chartData);
          this.setTimeScale()
        });
      })

    }
  }
  setTimeScale() {
    let startDate = new Date(Date.now())
    startDate.setFullYear(startDate.getFullYear() - 1)
    let endDate = new Date(Date.now())
    this.chartObj.timeScale().setVisibleRange({ from: startDate.getTime() / 1000, to: endDate.getTime() / 1000 })
  }
  ngAfterViewInit() {
    this.getQuotes().then(quotes => {
      this.calculateUpDown(quotes)
      this.prepareChart(quotes)
    })
  }

  prepareChart(quotes) {
    if (this.lineSeries) { this.chartObj.removeSeries(this.lineSeries) }
    if (this.chartObj === null) {
      this.chartHeight = this.chart.first.nativeElement.getBoundingClientRect().height > 0 ? this.chart.first.nativeElement.getBoundingClientRect().height : 150
      this.chartWidth = this.chart.first.nativeElement.getBoundingClientRect().width * 0.9
      this.chartObj = createChart("tvShareChart-" + this.chartID, {
        crosshair: {
        },
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        handleScale: {
          mouseWheel: false,
          pinch: false,
          axisPressedMouseMove: false,
        },
        layout: {
          //background:{color:"#F1F5F9", type:ColorType.Solid}
          background: { color: "#8ba1be", type: ColorType.Solid }
        },
        width: this.chartWidth,
        height: this.chartHeight,
        rightPriceScale: {
          visible: false,
        },
        leftPriceScale: {
          visible: true,
          autoScale: true
        },
        timeScale: {
          visible: true,
          borderVisible: true,
          fixLeftEdge: true,
          fixRightEdge: true,
          lockVisibleTimeRangeOnResize: true
        },
        grid: {
          horzLines: {
            visible: false,
          },
          vertLines: {
            visible: false,
          },
        }

      })

      this.lineSeries = this.chartObj.addLineSeries({
        color: '#0F6BB2',
        lineWidth: 3,
        lastValueVisible: false,
        priceLineVisible: false,
        baseLineVisible: false
      });

      this.updateChart();
    }
  }

  public getLogoForCompany(companyId: string): string {
    return this.companyInfoService.getLogoForCompany(companyId);
  }

  getLatestEvent() {
    this.dataService.getAllEvents().subscribe((res: any) => {
      let myEvents = []
      this.latestEvent = undefined
      myEvents = res.data as Array<SingleEvent>
      myEvents = myEvents.filter(el => el.isArchived === true && el.companyName === this.companyData.base.name)
      myEvents = myEvents.sort((b, a) => new Date(a.date).getTime() - new Date(b.date).getTime())
      if (myEvents.length > 0) {
        for (let event of myEvents) {
          if (!event.isHidden) {
            this.latestEvent = event;
            break;
          }
        }
      }
      this.cdr.detectChanges()
    })
  }

  openVideoModal(videoURL) {
    const modalRef = this.modalService.open(VideoModalComponent, { centered: true, size: 'lg', windowClass: 'biggerModal' });
    modalRef.componentInstance.videoURL = videoURL
  }

  formatNewsDate(date: string): string {
    let splitDate = date.split(' ')[0].split('-');
    date = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0] + ' ' + date.split(' ')[1];
    return date;
  }

  openModal(modalType) {
    let modalRef
    let modals = {
      "shareprice": TvChartComponent,
      "research": RatingHistoryComponent, // wohin solls gehen?
      "rating": RatingHistoryComponent,
      "shareholders": ShareholdersDiagramComponent,
      "companyvideo": "", // wohin solls gehen?
      "companyInfo": "", // kein modal, er springt runter
      "companynews": NewsAllComponent,
      "bookameeting": BookAMeetingComponent
    }

    if (modalType === "research" && this.researchPDFs && this.researchPDFs.length <= 0) {
    } else {
      modalRef = this.modalService.open(modals[modalType], {
        centered: true, size: 'xl', windowClass: "largermodal"
      });
    }

    if (modalType === "companynews") {
      modalRef.componentInstance.news = this.news
    }
    if (modalType === "shareprice") {
      modalRef.componentInstance.bloombergTicker = this.companyData.base.bl_ticker
    }
    if (modalType === "companynews") {
      modalRef.componentInstance.news = this.news
    }
    if (modalType === "research" || modalType === "rating") {
      modalRef.componentInstance.researchPDFs = this.researchPDFs
      modalRef.componentInstance.currentResearchTitle = this.currentResearchTitle
      modalRef.componentInstance.archives = this.archives
      modalRef.componentInstance.currentResearchDate = this.currentResearchDate
    }
    if (modalType === "shareholders") {
      modalRef.componentInstance.companyData = this.companyData
      modalRef.componentInstance.config =
      {
        "headline":
          "Major Shareholders",
        "type": "template-chart",
        "content": ["shareholders"],
        "height": 2,
        "width": 1,
        "mobile": false
      },
        modalRef.componentInstance.baseyear = this.baseyear
      modalRef.componentInstance.mapping = this.mapping
    }
    if (modalType === "bookameeting") {
      modalRef.componentInstance.companyName = this.companyName
      modalRef.componentInstance.symbolExchange = this.symbolExchange
    }
  }

  scrollToTop(ms) {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }), ms
    });
  }

  remoteControlTile(index) {
    this.companyInfoTableSlider.navigate(index)
  }

  async changeCurrenciesHistory(quotes: Array<any>, companyId: string) {
    let currencyMapping = {
      'EBM.F': 'EURSEK',
      'BAT:SS': 'EURSEK'
    }


    if (currencyMapping[companyId]) {
      let forexQuotes = await this.dataService.getHistoricalQuotes(currencyMapping[companyId] + '.FOREX').toPromise();
      for (let quote of quotes) {
        let exchangeRate = this.findByValue(quote.date, forexQuotes as any[], 'date');
        if (exchangeRate) {
          quote.adjusted_close = quote.adjusted_close * exchangeRate.adjusted_close;
          quote.close = quote.close * exchangeRate.close;
        } else {
          //doing nothing here so missing datapoints get noticed because chart is messy
        }

      }
    }

    this.quotes = quotes;
    this.cdr.detectChanges();

    return quotes;
  }


  findByValue(value: string, arr: Array<any>, field: string): any {
    for (let i = arr.length - 1; i > -1; i--) {
      if (arr[i][field] === value) {
        return arr[i];
      }
    }

    return null;
  }
}
