import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-all',
  templateUrl: './news-all.component.html',
  styleUrls: ['./news-all.component.scss']
})
export class NewsAllComponent implements OnInit {
  @Input() news = []
  constructor() { }

  ngOnInit(): void {
  }
  formatNewsDate(date: string): string {
    let splitDate = date.split(' ')[0].split('-');
    date = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0] + ' ' + date.split(' ')[1];
    return date;
  }
}
