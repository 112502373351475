import { Directive, HostListener, Input } from '@angular/core';
import { DataServiceService } from '../services/data-service.service';

@Directive({
  selector: '[Pdfdownload]'
})

export class PdfdownloadDirective {

  constructor(private dataService: DataServiceService) { }
  @Input('Pdfdownload')
  @Input() clickedURL: string;
  @HostListener('click') onClick() {
    this.dataService.addPDFCounter(this.clickedURL)
  }


}

/**
 *
 *
 *
<a Pdfdownload [clickedURL]="'https://downloads.research-hub.de/2022%2002%2024%20Puma_FY21_results___6lgci9av.pdf'" target="_blank" href="https://downloads.research-hub.de/2022%2002%2024%20Puma_FY21_results___6lgci9av.pdf">download</a>

 *
 *
 */
