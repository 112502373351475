<div [ngClass]="{ 'border border-dark rounded editMode': adminMode }" id="{{data.blockTag}}">
  <div class="my-3">
    <div *ngIf="!data.url && adminMode" class="text-center my-5">
      Kein Bild eingestellt
    </div>
    <figure *ngIf="data.url">
      <img [src]="data.url" class="img-fluid" alt="Image - {{translate(metaPageType)}} - {{metaBloombergTicker}}" />
    </figure>
  </div>
  <div class="row" *ngIf="adminMode">
    <div class="col-12">
      <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
        [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
        [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData"></app-block-handling>
    </div>
  </div>
</div>
