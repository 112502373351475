<div *ngIf="!isEventPage" class="d-flex row justify-content-between">
  <div class="col-12 col-lg-5 d-flex flex-wrap">
    <h3 class="col-12 text-uppercase">
      Coming
    </h3>
    <div *ngFor="let comingEvent of listOfComingEvents | slice:0:(more ? undefined : 3)" class="coming-event-item col-12 mt-3 d-flex flex-no-wrap  justify-content-end">
      <div class="date d-flex flex-column justify-content-center align-items-center  " style="background-color: #d8e2f3;">
        <h5 class="text-uppercase m-0 e2e-event-date-month">{{comingEvent.date | date: 'MMM'}}</h5> <h3 class="m-0 e2e-event-date-day">{{comingEvent.date | date: 'dd'}}</h3> <h5 class="m-0 e2e-event-date-year">{{comingEvent.date | date: 'yyyy'}}</h5>
      </div>
      <div class="col d-flex flex-column justify-content-center">
        <div class="row" >
        <h4 class="col-12 e2e-event-title" >{{comingEvent.title}}</h4> <h5 class="col-6 align-self-center m-0 pr-0 e2e-event-date-time">{{comingEvent.date | date: 'HH:mm':timeZoneFormat(comingEvent.date)}}
        </h5>
          <button class="d-sm-none col-6 col-sm-3 pl-0 text-nowrap text-uppercase text-center align-self-center btn btn-link float-right e2e-event-link " (click)="navigateToEventRegistrationPage(comingEvent)" >
            register<i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <button class="d-none d-sm-block col-sm-3 text-nowrap text-uppercase text-center align-self-center btn btn-link float-right" (click)="navigateToEventRegistrationPage(comingEvent)" >
        register<i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
  <div class="d-none col-lg-6 d-lg-flex flex-wrap">
    <h3 class="col-12 text-uppercase">
      Video Library
    </h3>
    <div *ngFor="let archiveEvent of listOfArchiveEvents | slice:0:(more ? listOfComingEvents?.length : (isComingEventsMoreThenThree ? 3 : listOfComingEvents?.length))" class="archive-event-item col-12 mt-3 d-flex flex-wrap">
      <div class="date d-flex flex-column justify-content-center align-items-center  " style="background-color: #fbe4d4;">
        <h5 class="text-uppercase m-0 e2e-event-date-month">{{archiveEvent.date | date: 'MMM'}}</h5> <h3 class="m-0 e2e-event-date-day">{{archiveEvent.date | date: 'dd'}}</h3> <h5 class="m-0 e2e-event-date-year">{{archiveEvent.date | date: 'yyyy'}}</h5>
      </div>
      <div class="col-4 d-flex align-items-center">

        <h4 class="e2e-event-title">{{archiveEvent.title}}</h4>
      </div>
      <div class="col d-flex flex-no-wrap p-0 ">
        <div class="col-7">

          <div class=" overlayWrapper" style="position:relative;  vertical-align: top;">
            <div id="modalClickerOverlay " *ngIf="archiveEvent.videoURL" (click)="openVideoModal(archiveEvent.videoURL)" style=" cursor: pointer; position:absolute; top:0; right:0; width:100%; height:100%;
              z-index:1049;"></div>
            <div id="modalClickerOverlay" *ngIf="!archiveEvent.videoURL" style="position:absolute; top:0; right:0; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.7);
                          z-index:1049; text-align:center">
              <h4 style="position:absolute; top:50%; left:35%; color:white; transform:translateY(50%);  margin:0">
                no video</h4>
            </div>

            <app-responsive-video class="center-horizontally" [videoURL]="archiveEvent.videoURL"></app-responsive-video>
            <!-- this does nothing: it is only for showing the thumbnail and play button-->
          </div>
        </div>
          <div class="col-5 p-0 align-self-center" style="font-size:smaller !important; display: block;">
            <a class="btn btn-link text-left float-left strangeFontSizeFix " style="padding:0 !important;"
              *ngIf="archiveEvent.isCovered" [routerLink]="['/companies/'+ archiveEvent.companyName]" [fragment]="'X'">read
              research <i class="ml-1 fas fa-arrow-right"></i>
          </a>
            <br *ngIf="archiveEvent.isCovered">
            <a class="btn btn-link float-left strangeFontSizeFix text-left" style="padding:0 !important;"
              *ngIf="archiveEvent.presentationURL !== ''" [href]="[archiveEvent.presentationURL]">download presentation<i
                class="ml-1 fas fa-arrow-right"></i></a>
          </div>
    </div>
  </div>
</div>
<div *ngIf="!isEventPage" class="col-12 pt-3 text-center">
  <button *ngIf="!more && isComingEventsMoreThenThree" (click)="more = true"  class="btn text-muted "> <i class="fas fa-angle-down"></i>
    show all
  </button>
  <button *ngIf="more || !isComingEventsMoreThenThree" (click)="nextPage('events')"  class="btn btn-std uppercase "> video library <i class="fas fa-angle-right"></i>
  </button>
</div>
</div>

<div *ngIf="isEventPage"  class="d-flex row justify-content-between">
  <div class="col-12  d-flex flex-wrap justify-content-between ">
    <h3 class="col-12 text-uppercase">
      Coming!
    </h3>
    <div *ngFor="let comingEvent of listOfComingEvents"
          class="coming-event-item col-12 col-lg-5 mt-3 d-flex flex-no-wrap  justify-content-end"
          [ngClass]="{'currentUserIsRegistered': comingEvent.isRegistered}"
          >
      <div class="date d-flex flex-column justify-content-center align-items-center  " style="background-color: #d8e2f3;">
        <h5 class="text-uppercase m-0 e2e-event-date-month">{{comingEvent.date | date: 'MMM'}}</h5> <h3 class="m-0 e2e-event-date-day">{{comingEvent.date | date: 'dd'}}</h3> <h5 class="m-0 e2e-event-date-year">{{comingEvent.date | date: 'yyyy'}}</h5>
      </div>
      <div class="col d-flex flex-column justify-content-center">
        <div class="row" >
        <h4 class="col-12 e2e-event-title" >{{comingEvent.title}}</h4> <h5 class="col-6 align-self-center m-0 pr-0 e2e-event-date-time">{{comingEvent.date | date: 'HH:mm':timeZoneFormat(comingEvent.date)}}
        </h5>
        <ng-container *ngIf="comingEvent.isRegistered">
           <a href="{{comingEvent.meetingLink}}" target="_blank"
          class="d-sm-none col-6 col-sm-3 pl-0 text-nowrap text-uppercase text-center align-self-center btn btn-link float-right  e2e-event-link">join<i class="fas fa-arrow-right"></i>
        </a>
        </ng-container>
        <ng-container *ngIf="!comingEvent.isRegistered">
          <button class="d-sm-none col-6 col-sm-3 pl-0 text-nowrap text-uppercase text-center align-self-center btn btn-link float-right  e2e-event-link" (click)="navigateToEventRegistrationPage(comingEvent)" >
            register<i class="fas fa-arrow-right"></i>
          </button>
        </ng-container>
        </div>
      </div>
      <ng-container *ngIf="comingEvent.isRegistered">
        <a href="{{comingEvent.meetingLink}}" target="_blank"
        class="d-none d-sm-block col-sm-3 text-nowrap text-uppercase text-center align-self-center btn btn-link float-right"  >join<i class="fas fa-arrow-right"></i>
        </a>
        </ng-container>
      <ng-container *ngIf="!comingEvent.isRegistered">
      <button class="d-none d-sm-block col-sm-3 text-nowrap text-uppercase text-center align-self-center btn btn-link float-right" (click)="navigateToEventRegistrationPage(comingEvent)" >
        register<i class="fas fa-arrow-right"></i>
      </button>
      </ng-container>
    </div>
  </div>
</div>
