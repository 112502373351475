<div class="row mt-md-5 pt-md-5 content-wrapper limit-pagewidth">
  <div class="col-12">
    <div class="mt-md-5 p-md-5 bg-bluearea row">
      <div class="col-12 text-center mb-md-5 mb-2">
        <h1 class="d-none d-lg-block">Corporate Dashboard</h1>
        <h3 class="d-block d-lg-none">Corporate Dashboard</h3>
        <hr>
      </div>
      <div class="col-12 col-md-8">
        <div class="company-logo-wrapper">
            <img *ngIf="existingCompany" class="company-logo-img cutWhiteAreas" [src]="getLogoForCompany(currentUsersCompanyBloombergticker)" #companyLogo>
          <img *ngIf="!existingCompany" class="company-logo-img cutWhiteAreas" [src]="getCompanyLogoFromEvent()" #companyLogo>

        </div>
        <h4 class="d-none mt-5 d-lg-block">{{currentUsersCompanyName}}</h4>
        <h4 class="d-block mt-2 d-lg-none">{{currentUsersCompanyName}}</h4>

        <!--  <h5 class="d-none d-lg-block">{{currentUsersCompanyBloombergticker}}</h5>
        <p class="d-block d-lg-none">{{currentUsersCompanyBloombergticker}}</p> -->


        <h5 class="mt-3" style="max-width:70%">
          Feel free to invite your investors to each event below by sending them the link to the event registration or posting it on your
          Website.
        </h5>
      </div>
      <div class="col-12 col-md-4 mt-2">
        <div class="kachel">
          <div class="col-12" style="position: absolute; height:100%">
            <div class=" d-none d-lg-block tile-title positionTile-Title col-12">
              mwb research Contact
            </div>
            <div class="tile-content-nobottom col-12">

              <div class="col-12 my-3" style="color:white">
                <div class="mt-1">{{alsterContact.name}}</div>
                <div class="mt-1">{{alsterContact.position}}</div>
                <div class="mt-1">{{alsterContact.email}}</div>
                <div class="mt-1">Tel: {{alsterContact.phone}}</div>
              </div>
              <div class="col-12 mt-4">
                <svg style="max-height:40px" preserveAspectRatio="xMidYMid meet" data-bbox="2.3 2.3 212.6 39.1" viewBox="2.3 2.3 212.6 39.1" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid-6anbqjl68z1g"> <title id="svgcid-6anbqjl68z1g"></title> <g> <path fill="#ffd746" d="M36.6 2.3v16.8H19.8V2.3h16.8z" data-color="1"></path> <path fill="#17548a" d="M32.3 33.1v8.3H24v-8.3h8.3z" data-color="2"></path> <path fill="#84abdb" d="M15 17.7v12.7H2.3V17.7H15z" data-color="3"></path>
                  <text x="48" y="30" font-weight="bold" fill="white" font-size="20">mwb research</text>

                </g> </svg>
              </div>
              <div class="col-6"></div>

            </div>
          </div>



        </div>
      </div>


      <div class="col-12 col-md-12 mt-5">
        <nav ngbNav #nav="ngbNav" class=" row nav-tabs customTabStyle">
          <ng-container ngbNavItem>
            <a class="col-12 col-md-2 ml-md-3 tablink event-list-border noBottomBorder" ngbNavLink>Events</a>
            <ng-template ngbNavContent>
              <div class="row event-list-border">
                <div class="col-12">
                  <h4 *ngIf="loadingNewData">Loading Events</h4>
                  <h4 *ngIf="!loadingNewData && nextEvent.length >0">Next Event</h4>
                  <h4 *ngIf="!loadingNewData && nextEvent.length < 1">No Upcoming Events</h4>
                </div>
                <div class="col-12" *ngIf=" !loadingNewData && nextEvent.length < 1">
                  <p>There are no events planned yet. If you are interested in planning an event with us, please contact
                    Holger Nass (contact details above). </p>
                </div>
                <app-event-list class="col-12" [corporateView]="true" [listType]="1" [events]="nextEvent"
                  [currentUsersCompanyName]="currentUsersCompanyName"
                  [existingCompany]="existingCompany"></app-event-list>
                <div *ngIf="loadingNewData" class="overlay">
                  <span class="centerSpinner">
                    <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                  </span>
                </div>
              </div>
              <div class="row" *ngIf="upcomingEvents.length > 0">
                <div class="col-12">
                  <hr>
                </div>
              </div>

              <div class="row event-list-border" *ngIf="upcomingEvents.length > 0">
                <div class="col-12">
                  <h4>More Upcoming Events</h4>
                </div>
                <app-event-list class="col-12" [corporateView]="true" [listType]="3" [events]="upcomingEvents"
                  [currentUsersCompanyName]="currentUsersCompanyName"
                  [existingCompany]="existingCompany"></app-event-list>
              </div>
              <div class="row  " *ngIf="pastEvents.length > 0">
                <div class="col-12">
                  <hr>
                </div>
              </div>
              <div class="row event-list-border" *ngIf="pastEvents.length > 0">
                <div class="col-12">
                  <h4>Past Events</h4>
                </div>
                <app-event-list class="col-12" [corporateView]="true" [listType]="2" [events]="pastEvents"
                  [currentUsersCompanyName]="currentUsersCompanyName"
                  [existingCompany]="existingCompany"></app-event-list>
              </div>
              <hr>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a class="col-12 col-md-2 tablink event-list-border noBottomBorder" ngbNavLink>Research</a>
            <ng-template ngbNavContent>
              <div class="row event-list-border">
                <div class="col-12">
                  <h4>Research</h4>
                  <hr>
                </div>
                <app-research-list class="col-12" *ngIf="existingCompany && researchPDFs && archives"
                  [archives]="archives" [researchPDFs]="researchPDFs"
                  [currentResearchDate]="currentResearchDate"
                  [currentResearchTitle]="currentResearchTitle"
                  ></app-research-list>
                <div *ngIf="!existingCompany" class="col-12">
                  <p>This is the place to present all publications by mwb research on your company. If you are
                    interested in being covered by us, please contact Holger Nass (<a href="mailto:h.nass@mwb-research.com">h.nass@mwb-research.com</a>, +49 160 - 9798 3302).</p>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a class="col-12 col-md-2 tablink event-list-border noBottomBorder" ngbNavLink>Uploads</a>
            <ng-template ngbNavContent>
              <div class="row event-list-border">
                <div class="col-12">
                  <h4>Uploads</h4>
                  <hr>
                </div>
                <div class="col-12">
                    <h5 *ngIf="existingCompany && currentCompanyPresentation" class="mt-3 text-center">
                      This is the <a
                      href="{{currentCompanyPresentation}}" target="_blank">current company presentation </a>on the research hub.
                    </h5>
                    <h5 *ngIf="existingCompany" class=" text-center">If you want to upload a more current version, please use this upload function below.</h5>
                    <h5 *ngIf="!existingCompany" class=" text-center">If you want to upload a company presentation, please use this upload function below.</h5>
                    <hr>
                  <app-drive-uploader></app-drive-uploader>
                </div>

              </div>
            </ng-template>
          </ng-container>
        </nav>

      </div>
      <div class="col-12  col-md-12">
        <div [ngbNavOutlet]="nav"></div>
      </div>


    </div>
  </div>
</div>
