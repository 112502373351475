<div class="row">
  <div class="col-12 text-right fingerFix"><span (click)="activeModal.dismiss('Cross click')"><strong>X</strong></span>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 mt-2 mb-3">
        <div class="row">
          <div class="col-12">
            <h2>Your Dashboard</h2>
          </div>
          <div class="col-12 col-md-8  mt-3" *ngIf="!status">
            <strong>E-Mail</strong><br>
            <input class="mb-3" style="width:100%" type="text" name="userName" id="username"
              [(ngModel)]="data.userName">
            <strong>Password</strong><br>
            <input class="mb-3" style="width:100%" type="password" name="pw" id="pw" [(ngModel)]="data.password">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-std" id="e2e-login" (click)="login()">Login</button>

              </div>
              <div class="col-6 text-right"> <a href="/requestnewpassword" style="color:white; text-decoration: underline;">forgot
                  your password?</a>
              </div>
            </div>
            <br *ngIf="showError">
            <div class=" text-center mt-3 bg-white rounded mx-auto" *ngIf="showError">
              <strong style="color:black">You have not registered yet or your password is incorrect!</strong>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-2 mt-md-0 border-left" *ngIf="!status">
            <p style="font-size: 1.3em;">
              Don't have an account yet? <strong class="fingerFix" (click)="goToSignup()">Register now</strong> and
              start
              managing your events.</p>
            <div class="text-center">
              <button (click)="goToSignup()" class="btn btn-std" id="e2e-login">Register</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <svg style="max-height:40px" preserveAspectRatio="xMidYMid meet" data-bbox="2.3 2.3 212.6 39.1" viewBox="2.3 2.3 212.6 39.1" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid-6anbqjl68z1g"> <title id="svgcid-6anbqjl68z1g"></title> <g> <path fill="#ffd746" d="M36.6 2.3v16.8H19.8V2.3h16.8z" data-color="1"></path> <path fill="#17548a" d="M32.3 33.1v8.3H24v-8.3h8.3z" data-color="2"></path> <path fill="#84abdb" d="M15 17.7v12.7H2.3V17.7H15z" data-color="3"></path>
          <text x="48" y="30" font-weight="bold" fill="white" font-size="20">mwb research</text>

        </g> </svg>

      </div>

    </div>

  </div>
</div>
