<div class="row mt-md-5 pt-md-5 content-wrapper limit-pagewidth">
  <div class="col-12">
    <div class="mt-md-5 p-md-5 bg-bluearea row" >
    <div class="row">
      <div class="col-12 text-center">
        <h2>Welcome to the ResearchHub!</h2>

      </div>
      <div class="col-12 text-center my-5">
        <h5>We're thrilled to have you as a member of our community. Get ready to unlock a wealth of valuable information and insightful analyses and register to our regular events with interesting companies.</h5>
        <h5>Best regards,</h5>
        <h5>The mwb research Team</h5>
      </div>
      <div class="col-12 text-center">
        <a href="/user/dashboard">
          <button class="btn btn-std">To your Dashboard</button>
        </a>
      </div>
    </div>

    </div>
  </div>
</div>
