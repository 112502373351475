import { HttpClient } from '@angular/common/http';
import { /*ChangeDetectorRef,*/ Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataServiceService } from 'src/app/services/data-service.service';
import { alsterAPI_URL, leewayAPI_URL } from '../app-settings/api-urls';
import { UtilitiesService } from './utilities.service';
import { Company } from '../models/company.model';
import { AuthService } from './auth/auth.service';


// import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { DeleteWarningComponent } from '../cms/delete-warning/delete-warning.component';
interface CmsStatus {
  isloading: boolean;
  companiesEditing: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyInfoService {
  private readonly customerId = '5eed24ff4fe2bd337ccab5d3';
  maxTileComps = 16;
  shortTileComps = 8;
  tiledata: Array<any> = [];
  highlightedList: Array<any> = [];
  shortHighlightedList: Array<any> = [];
  listOfCompanies: Array<Company> = [];
  listOfCompaniesObservable: BehaviorSubject<any[]>;
  availableSites = ['investment-case', 'snapshot', 'corporate', 'financials', 'esg', 'swot', 'research', 'chart', 'valuation', 'quality', 'growth'];



  companyLayouts = [];
  lastLoadedSessionValue = false;
  loadingBool$: Observable<CmsStatus>;
  dynamicCompanyList$: Observable<any>;


  // all this should be an obersable
  appTransactionMode = false;
  appTransactionBannerText = ""
  appTransactionBannerLink = ""


  private readonly apiUrl = leewayAPI_URL;
  private readonly toolsUrl = this.apiUrl + 'tools/';

  private cmsStatus: BehaviorSubject<CmsStatus>;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private dataService: DataServiceService,
    private utilitiesService: UtilitiesService,
    // private cdr: ChangeDetectorRef
  ) { }




  getAvailablePages(): Array<string> {

    return this.availableSites


  }


  getCompanyVideo(companyId: string) {
    return (this.httpClient.get(alsterAPI_URL + 'videourl/videourl/'
      + companyId + '?apitoken=cqt1bii3tq5fuzo0c5id'
    ) as Observable<any>);
  }

  sendPublishRequestForNewResearch(date, companyName, companyTicker, msg) {
    let body = {
     // requestingUser: this.authService.CurrentUser.name,
      date: date,
      companyName: companyName,
      companyTicker: companyTicker,
      msg: msg

    }
    return this.httpClient.post(alsterAPI_URL + 'researchreports/publishrequest/' + this.customerId, body).toPromise()
  }

  sendBookAMeetingRequestForNewResearch(companyName, lastname, firstname, email, requesterscompany, phone) {
    let body = {
      companyName: companyName,
      lastname: lastname,
      firstname: firstname,
      email: email,
      requesterscompany: requesterscompany,
      phone: phone
    }
    return this.httpClient.post(alsterAPI_URL + 'meeting/bookameetingrequest/' + this.customerId, body).toPromise()
  }


  getDisclaimerCompanies() {
    return this.httpClient.get(alsterAPI_URL + 'disclosures/disclosures/' + this.customerId).toPromise()
  }

  updateDisclaimerCompanies(data) {
    let body = { "name": data.name, "ticker": data.ticker, "bloomberg": data.bloomberg, "disclosures": data.disclosures }
    return this.httpClient.post(alsterAPI_URL + 'disclosures/disclosures/' + this.customerId, body).toPromise()
  }

  deleteDisclaimerCompanies(data) {
    let body = { "name": data.name, "ticker": data.ticker, "bloomberg": data.bloomberg, "disclosures": data.disclosures }
    return this.httpClient.post(alsterAPI_URL + 'disclosures/deletedisclosures/' + this.customerId, body).toPromise()
  }

  initalizeCMSStatus(): void {
    this.cmsStatus = new BehaviorSubject<CmsStatus>({
      isloading: false,
      companiesEditing: this.getListOfCompanies().map(item => {
        if (item.name === 'welcomePageStatic') {
          return {
            bloombergTicker: item.bloombergTicker,
            hidden: item.hidden,
            name: item.name,
            welcomePage: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
          };
        } else if (item.name === 'Blog') {
          return {
            bloombergTicker: item.bloombergTicker,
            hidden: item.hidden,
            name: item.name,
            weeklyScreener: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
          };





        } else {
          return {
            bloombergTicker: item.bloombergTicker,
            hidden: item.hidden,
            name: item.name,
            'investment-case': {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
            snapshot: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
            financials: {
              isEditing: false,
              alreadyWorkedOn: false
            },

            corporate: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },


            research: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
            swot: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
            chart: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
            esg: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },

            quality: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },

            growth: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
            valuation: {
              isEditing: false,
              alreadyWorkedOn: false,
              hasNonPublishedChanges: false
            },
          };

        }
      })
    });

    this.loadingBool$ = this.cmsStatus.asObservable();
  }

  startEditingMode(forceOverwrite: boolean, companyId: string, pageType: string, archiveDate, archiveTitle): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      if (forceOverwrite === false) {

        this.updateCmsStatus(true, null, null, null, null);

        this.checkForDocumentStatus(companyId, pageType).then(
          (status: any) => {
            if (status.hasActiveWorkingSession === true) {
              this.updateCmsStatus(false, companyId, pageType, false, true);
              resolve(false);
            } else {
              this.startEditing(companyId, pageType, archiveDate, archiveTitle).then(() => {
                this.updateCmsStatus(false, companyId, pageType, true, false);

                setTimeout(() => {
                  this.setisLoading(false);
                  resolve(true);
                }, 100);
              });
            }

          }
        );
      } else {
        this.startEditing(companyId, pageType, archiveDate, archiveTitle).then(() => {
          //this.authService.updateToken('editing', { companyId, pageType, editMode: true });

          this.updateCmsStatus(null, companyId, pageType, true, null);
          setTimeout(() => {
            this.setisLoading(false);
            resolve(true);
          }, 100);
        });
      }
    });
  }

  forceEndUserEditing(companyId: string, pageType: string): Promise<boolean> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.updateCmsStatus(false, companyId, pageType, false, false);
      this.setisLoading(false);
      resolve(true);
    });
  }

  endEditingMode(companyId: string, pageType: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.endEditing(companyId, pageType).then(() => {
        this.updateCmsStatus(null, companyId, pageType, false, null);
        setTimeout(() => {
          this.setisLoading(false);
          resolve(true);
        }, 200);
      });
    });
  }


  deleteChangesAndEndEditorMode(companyId: string, pageType: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.sendDeleteRequest(companyId, pageType).then(() => {
        this.updateCmsStatus(null, companyId, pageType, false, null);
        setTimeout(() => {
          this.setisLoading(false);
          resolve(true);
        }, 200);
      });
    });
  }

  sendDeleteRequest(companyId: string, pageType: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.httpClient.post(alsterAPI_URL + 'cmseditor/deleteallchanges', { companyId, pageType }).subscribe((res) => {
        this.setisLoading(false);
        resolve((res as any));
      });
    });
  }

  isCurrentlyEditingMode(companyId: string, pageType: string): boolean {
    const currentCMSStatus = this.cmsStatus.getValue();
    let cmsCheck = null;
    const companyIndexFound = currentCMSStatus.companiesEditing
      .findIndex(item => item.bloombergTicker === companyId && item.hasOwnProperty(pageType));

    if (companyIndexFound >= 0) {
      cmsCheck = currentCMSStatus.companiesEditing[companyIndexFound][pageType].isEditing;
    } else {
      cmsCheck = false;
    }

    // const user = this.authService.loadToken();
    // let tokenCheck = null;

    // if (user.editing.length > 0) {
    //   const companyIndex = user.editing
    //     .findIndex(item => item.bloombergTicker === companyId && item.pageType === pageType);
    //   if (companyIndex >= 0) {
    //     tokenCheck = user.editing[companyIndex].editMode;
    //   } else {
    //     tokenCheck = false;
    //   }
    // }

    //return tokenCheck && cmsCheck;
    return cmsCheck;
  }

  setisLoading(newValue: boolean) {
    this.updateCmsStatus(newValue, null, null, null, null);
  }

  updateCmsStatus(loadingValue: boolean, companyId: string, pageType: string, editMode, otherSession) {
    const oldStatus = this.cmsStatus.getValue();
    let newLoading = null;

    if (this.hasValue(loadingValue)) {
      newLoading = loadingValue;
    } else {
      newLoading = oldStatus.isloading;
    }

    if (!this.hasValue(companyId) || !this.hasValue(pageType)) {
      // change loading only
      this.cmsStatus.next({ isloading: newLoading, companiesEditing: oldStatus.companiesEditing });
    } else if (this.hasValue(companyId) && this.hasValue(pageType) && this.hasValue(editMode)) {
      // change editingmode for company
      const companyIndex = oldStatus.companiesEditing.findIndex(item => item.bloombergTicker === companyId && item.hasOwnProperty(pageType));

      oldStatus.companiesEditing[companyIndex][pageType].isEditing = editMode; // .isEditing .alreadyWorkedOn


      this.cmsStatus.next({ isloading: newLoading, companiesEditing: oldStatus.companiesEditing });
    } else if (this.hasValue(companyId) && this.hasValue(pageType) && this.hasValue(otherSession)) {
      // change othersession only
      const companyIndex = oldStatus.companiesEditing.findIndex(item => item.bloombergTicker === companyId && item.hasOwnProperty(pageType));
      oldStatus.companiesEditing[companyIndex][pageType].alreadyWorkedOn = otherSession; // .isEditing .alreadyWorkedOn

      this.cmsStatus.next({ isloading: newLoading, companiesEditing: oldStatus.companiesEditing });
    } else {
      this.cmsStatus.next(oldStatus);
    }
  }

  hasValue(input): boolean {
    if (input === undefined || input === null || input === '') {
      return false;
    } else {
      return true;
    }
  }

  addRSSEntry(news) {
    if(!news['link']){
      news['link'] = 'https://www.research-hub.de';
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post(alsterAPI_URL + 'rss/add', news).subscribe(() => {
        this.setisLoading(false);
        resolve(true);
      });
    });
  }

  archivePublishedPage(companyId: string, pageType: string, archiveTitle: string, teaserText: string) {
    return new Promise((resolve, reject) => {
      this.setisLoading(true);
      this.httpClient.post(alsterAPI_URL + 'cmseditor/archivepublishedpage', { companyId, pageType, archiveTitle, teaserText }).subscribe(() => {
        this.setisLoading(false);
        resolve(true);
      });
    });
  }

  publishChangesForPage(companyId: string, pageType: string, archiveDate, archiveTitle) { // aka endEditing
    return new Promise((resolve, reject) => {
      this.setisLoading(true);
      this.httpClient.post(alsterAPI_URL + 'cmseditor/publishallchanges?stub=stub' + ((archiveDate) ? '&archiveDate=' + archiveDate : "") + ((archiveTitle) ? '&archiveTitle=' + archiveTitle : ""), { companyId, pageType }).subscribe(() => {
        this.endEditingMode(companyId, pageType).then(result => {
          this.setisLoading(false);
          resolve((result as any));
        });
      });
    });
  }

  publishAllChangesForCompany(companyId: string) {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.httpClient.post(alsterAPI_URL + 'cmseditor/publishallpages', { companyId }).subscribe((res) => {
        if (companyId === 'welcomePageStatic') {
          this.endEditingMode(companyId, 'welcomePage').then(() => {
            this.setisLoading(false);

          });
        } else {
          for (const pageType of this.availableSites) {
            this.endEditingMode(companyId, pageType).then(() => {
              this.setisLoading(false);

            });
          }
        }

        resolve((res as any));
      });
    });
  }

  startEditing(companyId: string, pageType: string, archiveDate, archiveTitle): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.httpClient.post(alsterAPI_URL + 'cmseditor/starteditormode?stub=stub' + ((archiveDate) ? '&archiveDate=' + archiveDate : "") + ((archiveTitle) ? '&archiveTitle=' + archiveTitle : ""), {
        companyId, pageType
      }).subscribe((res) => {
        this.setisLoading(false);
        resolve((res as any));
      });
    });
  }

  endEditing(companyId: string, pageType: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.httpClient.post(alsterAPI_URL + 'cmseditor/endeditormode', { companyId, pageType }).subscribe((res) => {
        this.setisLoading(false);
        resolve((res as any));
      });
    });
  }

  checkForDocumentStatus(companyId: string, pageType: string) {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.httpClient.get(
        alsterAPI_URL + 'cmseditor/companysessionstatus/' +
        companyId + '?pageType=' + pageType + '&apitoken=cqt1bii3tq5fuzo0c5id').subscribe((res) => {
          resolve((res as any));
        });
    });
  }

  initalizeApp(): Promise<any> {
    return new Promise((resolve, _reject) => {
      Promise.all([this.initalizeCompanies(true), this.getInitalAppStatus(), this.utilitiesService.loadExchangeMapping()]).then(res => resolve(res))
    })

  }

  getTransactionMode() {
    return this.appTransactionMode
  }

  setTransactionBannerContent(text, link) {
    this.appTransactionBannerText = text;
    this.appTransactionBannerLink = link;
  }
  getTransactionBannerContent() {
    return { "text": this.appTransactionBannerText, "link": this.appTransactionBannerLink }
  }

  getInitalAppStatus() {
    // show transaction Banners and Info and use spacers
    try {
      return new Promise((resolve, _reject) => {
        this.httpClient.get(alsterAPI_URL + 'transactions/appinitstatus').subscribe((result: any) => {
          this.appTransactionMode = result.activeTransaction
          if (result.activeTransaction) {
            this.setTransactionBannerContent(result.transactionContent.text, result.transactionContent.link)
          }
          resolve(true)
        })

      })
    }catch(e){
      return new Promise((resolve, _reject) => {resolve(true)})
    }

  }

  createOrUpdateTransaction(text, link) {
    return new Promise((resolve, reject) => {
      let body = { text, link }
      this.httpClient.post(alsterAPI_URL + 'transactions/transaction', body).toPromise().then(() => this.getInitalAppStatus().then(() => resolve(true)))
    })

  }

  getTransactionContent() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(alsterAPI_URL + 'transactions/transaction').subscribe((result: any) => {
        resolve(result)
      })
    })

  }

  deleteTransaction(text, link) {
    return new Promise((resolve, reject) => {
      let body = { text, link }
      this.httpClient.post(alsterAPI_URL + 'transactions/deletetransaction', body).toPromise().then(() => this.getInitalAppStatus().then(() => resolve(true)))
    })

  }

  // companies
  public getListOfCompanies(): Array<Company> {
    return this.listOfCompanies;
  }

  public getPublishedCompanies(): Array<Company> {
    return this.listOfCompanies.filter((cmp)=>{
      return !cmp.hidden && cmp.name !== 'welcomePageStatic';
    });
  }

  updateCompanyProperty(companyId: string, property: string, value: any) {
    const body = {
      property: property,
      value: value
    };

    this.httpClient.post(alsterAPI_URL + 'companieslist/updatecompanyproperty/' + companyId, body).subscribe(() => {
      this.refreshCompanyList();
    });
  }

  setNewsQueryTermForCompany(company: Company, query: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveCompanyNewsQuery(company, query).subscribe(
        res => {
          //this.listOfCompanies = (res as any).list;
          this.setisLoading(false);
          resolve((res as any).list);
        }
      );
    });
  }

  saveCompanyNewsQuery(company: Company, query: string) {
    const body = { queryterm: query };

    return this.httpClient.post(alsterAPI_URL + 'news/setnewsqueryterm/' + company.bloombergTicker, body);
  }

  saveChartsForCompany(company: Company, charts: Array<string>) {
    const body = { charts: charts };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/setcompanycharts/' + company.bloombergTicker, body);
  }



  setColorfilterForCompany(company: Company, colorfilter: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveCompanyColorfilter(company, colorfilter).subscribe(
        res => {
          //this.listOfCompanies = (res as any).list;
          this.setisLoading(false);
          resolve((res as any).list);
        }
      );
    });
  }
  saveCompanyColorfilter(company: Company, colorfilter: string) {
    const body = { colorfilter: colorfilter };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/setcompanyimagecolor/' + company.bloombergTicker, body);
  }

  setHideOfCompany(company: Company): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveCompanyHiddenStatus(company).subscribe(
        res => {
          //this.listOfCompanies = (res as any).list;
          this.setisLoading(false);
          resolve((res as any).list);
        }
      );
    });
  }

  saveCompanyHiddenStatus(company: Company) {
    const body = { company: company };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/hidesinglecompany/' + company.bloombergTicker, body);
  }

  setExcelVisibilityFlag(companyId: String, state: boolean): Promise<any> {
    return this.httpClient.post(this.apiUrl + 'addin/companydata/sethubvisibilitystate?apitoken=cqt1bii3tq5fuzo0c5id&companyId=' + companyId + '&customerId=' + this.customerId + '&state=' + state, null).toPromise();
  }

  deleteCompanyFully(companyID: string): Promise<any> {
    const body = { companyID: companyID };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/deletecompany/' + this.customerId, body).toPromise();
  }

  setBaseyearOfCompany(baseyear: number, companyId: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveCompanyBaseyear(baseyear, companyId).subscribe(
        res => {
          //this.listOfCompanies = (res as any).list;
          this.setisLoading(false);
          resolve((res as any).list);
        }
      );
    });
  }

  setLogoUrlOfCompany(companyId: string, logoUrl: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveCompanyLogoUrl(companyId, logoUrl).subscribe(
        res => {
          //this.listOfCompanies = (res as any).list;
          this.setisLoading(false);
          resolve((res as any).list);
        }
      );
    });
  }

  setImageUrlOfCompany(companyId: string, imageURL: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveCompanyImageUrl(companyId, imageURL).subscribe(
        res => {
          //this.listOfCompanies = (res as any).list;
          this.setisLoading(false);
          resolve((res as any).list);
        }
      );
    });
  }


  saveCompanyLogoUrl(companyId: string, logoUrl: string) {
    const body = { logoUrl: logoUrl };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/setlogourl/' + companyId, body);
  }

  saveCompanyImageUrl(companyId: string, imageURL: string) {
    const body = { imageURL: imageURL };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/companyimageurl/' + companyId, body);
  }
  //TODO: how do we handle base year? where will it be saved and shared between APIs ?
  saveCompanyBaseyear(baseyear: number, companyId: string) {
    const body = { baseyear: baseyear };

    return this.httpClient.post(alsterAPI_URL + 'companieslist/setbaseyear/' + companyId + '?apitoken=cqt1bii3tq5fuzo0c5id', body);
  }



  initalizeCompanies(withCMSINIT: boolean): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.loadCompaniesFromBackend().subscribe(res => {
        this.listOfCompanies = (res as any).companies;

        if (withCMSINIT === true) {
          this.initalizeCMSStatus();
        }

        this.setisLoading(false);
        this.listOfCompaniesObservable = new BehaviorSubject<Company[]>(this.listOfCompanies) //.asObservable();
        this.dynamicCompanyList$ = this.listOfCompaniesObservable.asObservable()
        this.listOfCompaniesObservable.next(this.listOfCompanies)
        resolve(true);
      }, (err) => { console.log(err); });
    });
  }

  refreshCompanyList() {
    this.loadCompaniesFromBackend().subscribe(res => {
      this.listOfCompanies = (res as any).companies

      this.listOfCompaniesObservable.next(this.listOfCompanies)

      this.setisLoading(false);
    }, (err) => { console.log(err); });
  }

  companyIsHidden(companyId: string): boolean {
    const isCompanyHidden = this.listOfCompanies.find(el => el.bloombergTicker === companyId).hidden;
    const isAuthenticated = this.authService.isAuthenticated();
    const isAdmin = this.authService.isAdmin();
    const notAuthenticatedNorAdmin = !isAuthenticated && isAdmin;

    return isCompanyHidden && notAuthenticatedNorAdmin
      ? true
      : false;
  }

  // whole cms-pages document
  initialLoadPageContent(): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.loadPageContent().subscribe(res => {
        this.companyLayouts = (res as any).content;
        this.setisLoading(false);
        resolve(true);

      }, (err) => { console.log(err); });
    });
  }

  public loadPageContent() {
    return this.httpClient.get(alsterAPI_URL + 'cmspage/loadpage');
  }

  public saveNewlyAddedBlock(companyId: string, pageType: string, blockType, part) {
    const body = {
      companyId,
      bloombergTicker: this.getBloombergTicker(companyId),
      newBlock: part,
      pageType,
    };

    return this.httpClient.post(alsterAPI_URL + 'cmspage/addblock', body);
  }

  public checkIfLayoutsAreEmpty(companyId, pageType): Promise<any> {
    return this.httpClient.get(alsterAPI_URL + 'cmspage/hasemptylayout/' + companyId + '?pageType=' + pageType).toPromise();

  }

  public savePrefilledLayoutForSubpage(companyId, pageType, blocks): Promise<any> {


    const body = {
      companyName: this.getCompanyNameByBloombergTicker(companyId),
      companyId: companyId,
      bloombergTicker: companyId,
      newBlocks: blocks,
      pageType: pageType,
    };


    return this.httpClient.post(alsterAPI_URL + 'cmspage/prefillsubpage', body).toPromise();
  }


  public createNewCompany(name: string, id: string): Promise<any> {
    return this.httpClient.post(alsterAPI_URL + 'companieslist/createcompany' + '?companyId=' + id + '&name=' + name + '&customerId=' + this.customerId, { create: true }).toPromise();
  }


  public saveNewFormURL(newFormURL, formMode): Promise<any> {

    const body = {
      formURL: newFormURL,
      formMode: formMode
    };


    return this.httpClient.post(alsterAPI_URL + 'emailform/formtemplates/' + this.customerId + '?mode=' + formMode, body).toPromise();
  }


  public loadFormURL(formMode): Promise<any> {


    return this.httpClient.get(alsterAPI_URL + 'emailform/formtemplates/' + this.customerId + '?mode=' + formMode).toPromise();
  }


  public getLayoutForCompany(companyId: string, pageType: string, archiveDate: string, archiveTitle: string, componentname): Observable<any> {
    let editorQuery = '';
    let archiveQuery = '';

    if (archiveDate && archiveDate !== '') {
      archiveQuery = '&archiveDate=' + archiveDate + '&archiveTitle=' + encodeURIComponent(archiveTitle);
    }

    if (this.isCurrentlyEditingMode(companyId, pageType)) {
      editorQuery = '&editormode=true';
    }


    //Catch 404 here and send custom 404 page
    return (this.httpClient.get(alsterAPI_URL + 'cmspage/loadpage/'
      + companyId + '?pageType='
      + pageType + '&apitoken=cqt1bii3tq5fuzo0c5id'
      + editorQuery
      + archiveQuery
    ) as Observable<any>);
  }

  // buildingblocks
  updateBuildingBlock(companyId: string, pageType: string, published: boolean, block_id, blockType, data, archiveDate, archiveTitle): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);

      this.saveUpdatedContentOfBlock(companyId, pageType, published, block_id, blockType, data, archiveDate, archiveTitle).subscribe(() => {
        this.setisLoading(false);
        resolve(true);
      }, (err) => _reject(false));
    });
  }

  //Special call to update weekly screener first archive when changes to editor block are made to keep first archive and current consistent
  updateBuildingBlockInArchive(companyId: string, pageType: string, published: boolean, block_id, blockType, data): void {
    const body = {
      companyId,
      blockId: block_id,
      published: published,
      pageType,
      blockType,
      content: data,
    };

    this.httpClient.post(alsterAPI_URL + 'cmspage/updatecontentofarchivedpagescreenerblock', body).subscribe(res => { });
  }

  saveUpdatedContentOfBlock(companyId: string, pageType: string, published: boolean, block_id, blockType, data, archiveDate, archiveTitle) {
    const body = {
      companyId,
      blockId: block_id,
      published: published,
      pageType,
      blockType,
      content: data,
    };

    return this.httpClient.post(alsterAPI_URL + 'cmspage/updatecontentofblock?stub=stub' + ((archiveDate) ? '&archiveDate=' + archiveDate : "") + ((archiveTitle) ? '&archiveTitle=' + archiveTitle : ""), body);
  }

  deleteBlock(companyId: string, pageType: string, block_id): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);

      this.saveDeletedBlock(companyId, pageType, block_id).subscribe(() => {
        this.setisLoading(false);
        resolve(true);
      });
    });
  }

  saveDeletedBlock(companyId: string, pageType: string, blockId) {
    const body = {
      companyId,
      blockId,
      pageType,
    };

    return this.httpClient.post(alsterAPI_URL + 'cmspage/deleteblock', body);
  }

  addNewBlock(companyId: string, pageType: string, blockType, part): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);
      this.saveNewlyAddedBlock(companyId, pageType, blockType, part)
        .subscribe(res => {
          this.companyLayouts = (res as any).result.content;
          this.setisLoading(false);
          resolve(true);
        });
    });
  }

  saveNewOrderOfParts(companyId: string, pageType: string, newlyOrderedParts): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.setisLoading(true);

      const body = {
        companyId,
        bloombergTicker: this.getBloombergTicker(companyId),
        blocks: newlyOrderedParts,
        pageType,
      };

      this.httpClient.post(alsterAPI_URL + 'cmspage/saveneworderofblocks', body).subscribe(res => {
        this.setisLoading(false);
        resolve(true);
      });
    });
  }

  // load specific data for tables (financials, keyfacts,quickfacts)
  //TODO: this is not used anymore. can this be deleted?
  public getFinancials(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.split(':')[0] + '.XETRA'; // das hier ist egal. wahrscheinlicher alte version
    const url = leewayAPI_URL+'upload/financecsv/' + newSymbolExchange + '?apikey=cqt1bii3tq5fuzo0c5id';

    return this.httpClient.get(url);
  }

  public getKeyFacts(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.replace(':', '.');
    const url = leewayAPI_URL+'upload/keyfacts/' + newSymbolExchange + '?apikey=cqt1bii3tq5fuzo0c5id';

    return this.httpClient.get(url);
  }

  //TODO: this is not used anymore. can this be deleted?
  public getQuickFacts(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.replace(':', '.');
    const url = leewayAPI_URL+'upload/quickfacts/' + newSymbolExchange + '?apikey=cqt1bii3tq5fuzo0c5id';

    return this.httpClient.get(url);
  }

  //TODO: this is not used anymore. can this be deleted?
  public getFundamentalScore(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.replace(':', '.');
    const url = leewayAPI_URL+'companies/widgetranker?apitoken=cqt1bii3tq5fuzo0c5id&companyname=' + newSymbolExchange;
    const body = {};
    return this.httpClient.post(url, body);
  }

  public getESGScore(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.replace(':', '.');
    const url = leewayAPI_URL+'companies/esg/score/' + newSymbolExchange + '?apitoken=cqt1bii3tq5fuzo0c5id';
    const body = {};
    return this.httpClient.post(url, body);
  }

  public getESGLabels(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.replace(':', '.');
    const url = leewayAPI_URL+'companies/esg/labels/' + newSymbolExchange + '?apitoken=cqt1bii3tq5fuzo0c5id';
    const body = {};
    return this.httpClient.post(url, body);
  }


  // utilities
  public getBloombergTicker(companyName: string): string | null {
    const company = this.listOfCompanies.find(el => el.name === companyName);

    if (company) {
      return company.bloombergTicker;
    } else {
      return null;
    }
  }

  public getCompanyNameByBloombergTicker(bloombergTicker: string): string | null {
    const company = this.listOfCompanies.find(el => el.bloombergTicker === bloombergTicker);

    if (company) {
      return company.name;
    } else {
      return null;
    }
  }

  public getCompanyDataByBloombergTicker(bloombergTicker: string): any | null {
    const company = this.listOfCompanies.find(el => el.bloombergTicker == bloombergTicker);
    if (!bloombergTicker) {
      console.trace();
    }

    if (company) {
      return company;
    } else {
      return null;
    }
  }

  public getCompanyDataByCompanyName(companyName: string): any {
    return this.getCompanyDataByBloombergTicker(this.getBloombergTicker(companyName));
  }


  // logo name loader
  // ticker or id or symbolexchange
  public getLogoForCompany(companyId: string): string {
    let logoUrl = "../../assets/blanklogo.svg"
    let company = this.listOfCompanies.find(item => item.bloombergTicker === companyId)
    if (company !== undefined && company.logoUrl !== undefined) {
      logoUrl = company.logoUrl
    }


    return logoUrl


    // return '../../assets/logos/' +
    //   companyName
    //     .replace(/-/g, ' ')
    //     .split(' AG')[0]
    //     .split(' SE')[0]
    //     .split(' NV')[0]
    //     .split(' & Co KGaA')[0]
    //     .split(' Stiftung')[0]
    //     .split(' Software')[0]
    //     .split(' Medical')[0]
    //     .split(' Holding')[0]
    //     .split(' Group')[0]
    //     .replace(/\s/g, '')
    //     .replace(':', '')
    //     .replace('&', '')
    //     .toLowerCase() + '.svg';
  }


  public getNewsQueryForCompany(companyId: string): string {
    let company = this.listOfCompanies.find(item => item.bloombergTicker === companyId);
    if (company && company.newsQuery) {
      return company.newsQuery;
    } else {
      return null;
    }
  }




  public getTileData(): Promise<any> {

    return new Promise((resolve, reject) => {
      if (this.tiledata.length < 1) {
        this.tiledata = this.getListOfCompanies()
          .filter(item => item.name !== 'welcomePageStatic')
          .filter(item => !item.hidden)
          .sort((a, b) => a.name.localeCompare(b.name));

        let multiTickers = "";
        this.tiledata.forEach(company => {
          multiTickers = multiTickers + company.bloombergTicker + ",";

          if (company.isFeatured === true) {
            company.isFeatured = 'Featured Stock';
          } else if (!company.isFeatured) {
            company.isFeatured = '';
          }
        });

        this.dataService.getTiledata(this.tiledata[0].bloombergTicker, multiTickers).toPromise().then((res: any) => {
          this.tiledata.forEach(company => {
            let resC = res.find(item => item.CompanyId === company.bloombergTicker)
            if(resC){
              company.rating = resC.general_information.cus_rating;
              company.sector = resC.base.sector;
              company.country = resC.base.country

              if (resC.base.quote_curr) {
                company.currency = resC.base.quote_curr;
              } else {
                company.currency = resC.base.curr;
              }
            }

          });
        });

        this.dataService.getLatestPDfs().toPromise().then((res: any) => {
          //make research comps unique
          let tickers = {};
          for (let i = 0; i < res.length; i++) {
            if (tickers[res[i].bloombergTicker]) {
              res.splice(i, 1);
              i--;
            } else {
              tickers[res[i].bloombergTicker] = true;
            }
          }

          // get badge comps
          let transactions = this.tiledata.filter(comp => comp.isFeatured === 'Transaction');
          let initiations = this.tiledata.filter(comp => comp.isFeatured === 'Initiation');
          let features = this.tiledata.filter(comp => comp.isFeatured === 'Featured Stock');

          let researchTickers = res.map(e => e.bloombergTicker);
          initiations.sort(function (a, b) {
            return researchTickers.indexOf(b.bloombergTicker) - researchTickers.indexOf(a.bloombergTicker);
          });

          //fill with new research comps that are not in badge comps up to max
          let updateLength = this.maxTileComps - transactions.length - initiations.length - features.length;
          let shortUpdateLength = this.shortTileComps - transactions.length - initiations.length - features.length;
          let updates = [];
          let shortUpdates = [];
          for (let i = 0; i < Math.min(updateLength, res.length); i++) {
            let update = res[i];
            if (!(transactions.find(c => c.bloombergTicker === update.bloombergTicker) ||
              initiations.find(c => c.bloombergTicker === update.bloombergTicker) ||
              features.find(c => c.bloombergTicker === update.bloombergTicker))) {
              let updateComp = this.tiledata.find(item => item.bloombergTicker === update.bloombergTicker);
              if (updateComp) {
                updateComp.isFeatured = 'Update';
                updates.push(updateComp);
                if (i < shortUpdateLength) {
                  shortUpdates.push(updateComp);
                }
              }
            }
          }

          transactions.forEach(c => { this.highlightedList.push(c); });
          initiations.forEach(c => this.highlightedList.push(c));
          updates.forEach(c => this.highlightedList.push(c));
          features.forEach(c => this.highlightedList.push(c));

          transactions.forEach(c => { this.shortHighlightedList.push(c); });
          initiations.forEach(c => this.shortHighlightedList.push(c));
          shortUpdates.forEach(c => this.shortHighlightedList.push(c));
          features.forEach(c => this.shortHighlightedList.push(c));

          resolve({ all: this.tiledata, highlight: this.highlightedList, shortHighlight: this.shortHighlightedList });

          setTimeout(() => {
            let multi = "";
            this.tiledata.forEach(company => {
              multi = multi + this.utilitiesService.getMappedExchange(company.bloombergTicker) + ",";
            });

            this.dataService.getLiveQuote(this.tiledata[0].bloombergTicker, multi).subscribe((res: any) => {
              if (Array.isArray(res)) {
                this.tiledata.forEach(company => {
                  let resC = res.find(item => item.code === this.utilitiesService.getMappedExchange(company.bloombergTicker))
                  company.close = resC.close;
                  company.change = resC.change;
                  company.change_p = resC.change_p;
                  company = this.changeCurrencies([company], 'bloombergTicker');
                });
              } else {
                let company = this.tiledata[0];
                company.close = res.close;
                company.change = res.change;
                company.change_p = res.change_p;
                company = this.changeCurrencies([company], 'bloombergTicker');
              }
            });
          }, 600);
        });
      } else {
        resolve({ all: this.tiledata, highlight: this.highlightedList, shortHighlight: this.shortHighlightedList });
      }

    })
  }

  private loadCompaniesFromBackend() {
    return this.httpClient.get(alsterAPI_URL + 'companieslist/cmscompanylist');
  }

  // cant open modal here, because this service is AppInitalizer and needs NgbModal for its own constructor -> circular dependency
  openDeletionWarning(companyId, pageType, blocks = []): Promise<string> {

    return new Promise((resolve, _reject) => {

    })

  }

  changeCurrencies(quotes: Array<any>, field: string): Array<any> {
    let currencyMapping = {
      'EBM.F': 'EURSEK',
      'BAT:SS': 'EURSEK'
    }

    for (let quote of quotes) {
      if (currencyMapping[quote[field]]) {
        this.dataService.getHistoricalQuotes(currencyMapping[quote[field]] + '.FOREX').subscribe(res => {
          let currQuotes = res as Array<any>;
          quote.close = quote.close * currQuotes[currQuotes.length - 1]['close'];
        })
      }
    }

    return quotes;
  }
}
