<ng-template #rt let-r="result" let-t="term">
  <!--bla-->
  <a style="color:black!important;" class="underlineonhover">
    {{r}}
  </a>
</ng-template>

<div class="row mt-5 pt-5" [ngClass]="{'bannerTransactionAdminPanelSpacer':transactionMode}">
  <div class="col-4">
    <h3>Admin-Panel <span *ngIf="isLoading"><span class="lds-dual-ring"></span></span></h3>
    <small>If the site shows unexpected data, try reloading.</small>
  </div>
  <div class="col-8">
    <div class="row">

      <div class="col-2 my-auto border border-dark px-2 py-2">
        <app-button *ngIf="!isLoading" color="primary" (click)="publishAllFigures()">Publish All Figures</app-button>
        <app-button *ngIf="isLoading" class="btn btn-sm btn-inverted-gray">Publish All Figures</app-button>
        <span *ngIf="isLoading"><small>DO NOT LEAVE ADMIN PANEL! Duration ~1s per company. Only public companies will be
            published.</small></span>
      </div>
      <div class="col-2 my-auto">
        <app-button (click)="openNewFormURL('newsletter')" color="primary"><small>New news- letter form</small>
        </app-button>
      </div>

      <div class="col-2 my-auto">
        <app-button (click)="openBackupDownloader()" color="primary"> <small>Download full Data-Backup</small>
        </app-button>
      </div>

      <div class="col-2 my-auto">
        <app-button (click)="openCreateCompany()" color="primary"> <small>Create New Company</small>
        </app-button>
      </div>
    </div>
  </div>




  <div class="container-fluid">
    <div class="row">

      <div class="col-12">

        <nav ngbNav #nav="ngbNav" class="nav-tabs">

          <ng-container ngbNavItem>
            <a ngbNavLink>Actions</a>
            <ng-template ngbNavContent>
              <table class=" table-hover table table-sm table-responsive">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Ticker</th>
                    <!--
                    <th scope="col">aktive Bearbeitung in dieser Sitzung</th>
                    <th scope="col">Nichtveröffentlichte Änderungen</th> -->
                    <th scope="col">Baseyear</th>
                    <th scope="col">Featured</th>
                    <th scope="col">Publish company</th>
                    <!-- <th scope="col">Logo</th> -->
                    <th scope="col">Publish Figures</th>
                    <th>----------------------------</th>
                    <th scope="col">Settings</th>
                    <!-- <th scope="col">Company löschen</th>
                    <th scope="col">Leere Seiten füllen</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let company of companies; let i = index">
                    <th scope="row">{{i+1}}</th>
                    <td><a [routerLink]="['/companies/investment-case/',company.name]">{{company.name}}</a></td>
                    <td>{{company.bloombergTicker}}</td>

                    <!-- <td class="text-center">
                      <div *ngIf="company.isInEditingMode.length > 0">
                        <span *ngFor="let page of company.isInEditingMode"> <a
                            [routerLink]="['/companies/'+page+'/',company.name]">{{page}}</a><br> </span>
                      </div>
                      <div *ngIf="company.isInEditingMode.length <= 0">keine</div>
                    </td> -->
                    <!-- <td>
                      <div *ngIf="company.hasActiveSession.length > 0">
                        <span *ngFor="let page of company.hasActiveSession"> <a  [routerLink]="['/companies/'+page+'/',company.name]">{{page}}</a><br> </span>
                      </div>
                      <div *ngIf="company.hasActiveSession.length <= 0">keine</div>
                    </td> -->
                    <!-- <td>
                      <div *ngIf="company.hasNonpublishedChanges.length > 0">
                        <span *ngFor="let page of company.hasNonpublishedChanges"> <a
                            [routerLink]="['/companies/'+page+'/',company.name]">{{page}}</a><br> </span>
                      </div>
                      <div *ngIf="company.hasNonpublishedChanges.length <= 0">keine</div>
                    </td> -->

                    <td class="text-center">
                      <span>{{company.baseyear}}</span><br>
                      <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-sm btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                          Set baseyear
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu">
                          <button *ngFor="let year of possibleBaseYears"
                            class="btn btn-sm btn-inverted-gray my-1 dropdown-item text-center" ngbDropdownItem
                            (click)="setBaseyearOfCompany(year, company.bloombergTicker)">
                            {{year}}
                          </button>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      <span>{{company.isFeatured}}</span><br>

                      <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-sm btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                          Set badge
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu">
                          <button *ngFor="let badge of possibleBadges"
                            class="btn btn-sm btn-inverted-gray my-1 dropdown-item text-center" ngbDropdownItem
                            (click)="setBadge(badge, company)">
                            {{badge}}
                          </button>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                        <button disabled *ngIf="!isLoading" class="btn btn-sm"
                          [ngClass]="{'btn-std': !isLoading, 'btn-inverted-gray': isLoading}"
                          (click)="publishAllCompanyPages(company.bloombergTicker)">Publish all pages</button>
                    </td>

                    <!--  -->

                    <td class="text-center">
                      <button class="btn btn-sm btn-std" [ngClass]="!company.published===true ? 'active' : ''"
                        (click)="publishFigures(company); company['published']=true; company['publishText'] = 'Published'">
                        {{company.publishText || 'Publish figures'}}</button>
                    </td>

                    <td></td>

                    <td>
                      <button class="btn btn-std" (click)="openSettingsModal(company, i)">settings</button>
                    </td>

                    <!-- <td class="text-center">
                      <button class="btn btn-sm btn-std"
                        *ngIf="(showDeletionWarning[company.bloombergTicker] === null || showDeletionWarning[company.bloombergTicker] === undefined) || showDeletionWarning[company.bloombergTicker] === false"
                        (click)="deleteCompany(false,company.bloombergTicker)">{{company.name}} /
                        {{company.bloombergTicker}}
                        delete
                      </button>
                      <button class="btn btn-sm btn-std" style="background-color:red;"
                        *ngIf="showDeletionWarning[company.bloombergTicker] === true"
                        (click)="deleteCompany(true,company.bloombergTicker)">{{company.name}} /
                        {{company.bloombergTicker}}
                        delete
                      </button>


                    </td> -->
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>Transactions</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-6">
                  <label for="text">Banner-Text (max. 140 characters)</label>
                  <input size=140 name="text" type="text" placeholder="Description" [(ngModel)]="newText">
                  <label for="link">Banner-Link</label>
                  <input size=140 name="link" type="text" placeholder="https//..." [(ngModel)]="newLink">
                  <button class="btn btn-primary" (click)="createOrUpdateTransaction()"> set new text</button>
                  <button class="btn btn-inverted-gray" (click)="deleteTransaction()"> delete transaction</button>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>Disclaimer Companies</a>
            <ng-template ngbNavContent>
              <div class="row mt-3">
                <div class="col-6 mx-auto border border-dark">
                  <p class="text-center"> <button type="button" class="btn btn-primary "
                      (click)="isCollapsed = !isCollapsed">
                      Add/edit company to disclaimer
                    </button></p>
                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <form>
                      <div class="form-group">
                        <input [(ngModel)]="coicName" size=80 name="name" type="text" placeholder="name">
                      </div>
                      <div class="form-group">
                        <input [(ngModel)]="coicTicker" size=80 name="ticker" type="text" placeholder="ticker">
                      </div>
                      <div class="form-group">
                        <input [(ngModel)]="coicBloomberg" size=80 name="bloomberg" type="text" placeholder="bloomberg">
                      </div>
                      <div class="form-group">
                        <input [(ngModel)]="coicDisclosures" size=80 name="disclosures" type="text"
                          placeholder="disclosures">
                      </div>
                      <div class="form-group">
                        <button class="btn my-2 btn-primary" (click)="addNewDisclaimerCompany()">save</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-10">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-light" style="color: black;">
                      <th>Company / Unternehmen ( {{disclaimerCompanies.length}} companies )</th>
                      <th>Ticker</th>
                      <th>Bloomberg</th>
                      <th>Disclosures / Interessenkonflikte</th>
                      <th>Löschen</th>
                    </thead>
                    <tbody class="table-striped">
                      <tr *ngFor="let company of disclaimerCompanies">
                        <td>{{company.name || ""}}</td>
                        <td>{{company.ticker||""}}</td>
                        <td>{{company.bloomberg||""}}</td>
                        <td>{{company.disclosures||""}}</td>
                        <td><button class="btn btn-secondary"
                            (click)="deleteDisclaimerCompanies(company.name, company.ticker,company.bloomberg,company.disclosures)">löschen</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>Events</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 text-center my-5 d-flex justify-content-around">
                  <app-button (click)="openEvents()" color="primary">New event</app-button>
                  <button class="btn" [ngClass]="{'btn-std': !editEvents, 'btn-inverted-gray': editEvents}"
                    (click)="toggleEditEvents()"
                    ngbPopover="1. Click here to unlock edit/delete events.&#10;&#13; 2. Change multiple events entries.&#10;&#13; 3. Click 'update' on each edited event.&#10;&#13; 4. Click here again to lock again."
                    triggers="mouseenter:mouseleave" popoverTitle="How to use:">
                    <span *ngIf="!editEvents">Edit/Delete Events</span>
                    <span *ngIf="editEvents">Lock Edit/Delete Events</span>
                  </button>
                  <app-button (click)="openNewFormURL('events')" color="primary"> <small>New event form</small>
                  </app-button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">

                  <div *ngFor="let myEvent of myEvents; let i = index" class="row border border-top-4 border-bottom-4 border-dark bg-bluearea shadow my-4 p-3">
                    <div class="col-12 ">
                      <i class="fas" [ngClass]="{'fa-lock': !editEvents, 'fa-unlock': editEvents}"></i>
                      <div class="row">
                        <div class="col-4"><h5>{{myEvent.title}}</h5></div>
                        <div class="col-4"><h5>{{myEvent.date |date}}</h5></div>
                        <div class="col-4"> <span *ngIf="myEvent.bloombergTicker && myEvent.date">
                            Link: <a href="{{createEventRegistrationLink(myEvent.bloombergTicker,myEvent.date)}}"
                              target="_blank">
                              {{createEventRegistrationLink(myEvent.bloombergTicker,myEvent.date)}}</a>
                          </span>
                          <span *ngIf="!myEvent.bloombergTicker || !myEvent.date">
                            event has no date or ticker
                          </span>
                        </div>
                      </div>

                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="title-input">
                          <span class="text-danger" *ngIf="!myEvent.title">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          Title:</label>
                        <input id="title-input" class="form-control" [disabled]="!editEvents"
                          [(ngModel)]="myEvent.title">
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="date-input">
                          <span class="text-danger" *ngIf="!myEvent.date">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          Date:</label>
                        <input id="date-input" class="form-control smallerInput" disabled [(ngModel)]="myEvent.date">
                        <button class="btn btn-sm btn-std" [disabled]="!editEvents" (click)="editEventDate(myEvent)">
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group"
                      ngbPopover="It is used to make the navigation from an event to a company-page possible.
                      You can leave this empty, if the company is not covered. And it is used for some older functions
                       like updating the company video when saving a event video."
                      triggers="mouseenter:mouseleave" popoverTitle="Why Companyname?">
                        <label class="input-group-text" for="companyname-input">
                          <span class="text-danger" *ngIf="!myEvent.companyName">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          Companyname:</label>
                        <input id="companyname-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.companyName" [resultTemplate]="rt"
                          #instance="ngbTypeahead" [inputFormatter]="formatter" [editable]='true'
                          [resultFormatter]="formatterCode" [ngbTypeahead]="searchCompanyNames" />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="bl-ticker-input">
                          <span class="text-danger" *ngIf="!myEvent.bloombergTicker">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          BL-Ticker:</label>
                        <input id="bl-ticker-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.bloombergTicker"
                          [resultTemplate]="rt" #instance="ngbTypeahead" [inputFormatter]="formatter" [editable]='true'
                          [resultFormatter]="formatterCode" [ngbTypeahead]="searchBloombergTickers" />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="feedbacklink-input">feedbacklink:</label>
                        <input id="feedbacklink-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.feedbackLink"  />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="sib-list-id-registered-investors-input">
                          <span class="text-danger" *ngIf="!myEvent.sibListId">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          SIB List Id
                          Registered Investors</label>
                        <input id="sib-list-id-registered-investors-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.sibListId"
                           />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="sib-list-id-participants-input">
                          <span class="text-danger" *ngIf="!myEvent.sibListIdForParticipants">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          SIB List Id
                          Participants</label>
                        <input id="sib-list-id-participants-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.sibListIdForParticipants"
                           />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="meeting-link-input">
                          <span class="text-danger" *ngIf="!myEvent.meetingLink">
                            <i class="fas fa-exclamation-circle"></i>&nbsp;</span>
                          Meeting Link</label>
                        <input id="meeting-link-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.meetingLink" />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="videourl-input">VideoURL</label>
                        <input id="videourl-input" class="form-control smallerInput" [disabled]="!editEvents"
                          [(ngModel)]="myEvent.videoURL">
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="presentation-url-input">
                          PresentationURL
                        </label>
                        <input id="presentation-url-input" class="form-control smallerInput" [disabled]="!editEvents"
                          [(ngModel)]="myEvent.presentationURL">
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <label class="input-group-text" for="company-logo-url-input">companyLogoURL</label>
                        <input id="company-logo-url-input" class="form-control smallerInput" type="text"
                          [disabled]="!editEvents" [(ngModel)]="myEvent.companyLogoURL"
                          />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group text-center">
                        <label class="input-group-text" for="event-description-input">Event-Description</label>
                        <textarea id="event-description-input" class="form-control mb-3" rows="3"
                          placeholder="Event: Description" [(ngModel)]="myEvent.description"
                          [disabled]="!editEvents"></textarea>
                      </div>
                    </div>



                    <div class="col-12">
                      <div class="form-group">
                        <label for="speakers-input">Speakers</label>
                        <button [disabled]="!editEvents" class="btn btn-sm btn-primary ml-2"
                          (click)="addNewSpeakerToEvent(myEvent)">Add Speaker</button>
                        <div class="mt-3" *ngFor="let speaker of myEvent.speakers;let i = index">
                          <div class="row align-items-center">
                            <div class="col-1">{{i+1}}</div>
                            <div class="col-3">
                              <input id="speaker-name-input-{{i}}" class="form-control smallerInput" type="text"
                                [disabled]="!editEvents" [(ngModel)]="speaker.name" placeholder="Speaker Name" />
                            </div>
                            <div class="col-3">
                              <input id="speaker-position-input-{{i}}" class="form-control smallerInput" type="text"
                                [disabled]="!editEvents" [(ngModel)]="speaker.position"
                                placeholder="Speaker Position" />
                            </div>
                            <div class="col-3">
                              <input id="speaker-image-url-input-{{i}}" class="form-control smallerInput" type="text"
                                [disabled]="!editEvents" [(ngModel)]="speaker.imageURL"
                                placeholder="Speaker Image URL" />
                            </div>
                            <div class="col-2">
                              <button  [disabled]="!editEvents" class="btn btn-sm btn-danger" (click)="removeSpeakerFromEvent(myEvent,i)">Remove
                                Speaker</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 p-3 mb-3 border border-top-2 border-dark" >
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <button class="btn btn-sm btn-primary btn-block" [disabled]="!editEvents || isLoading" [ngClass]="{'btn-std': !isLoading, 'btn-inverted-gray': isLoading}" (click)="updateEvent(myEvent)">
                            Update
                          </button>
                        </div>
                        <div class="col-12 col-md-4">
                          <button  [disabled]="!editEvents" class="btn btn-sm btn-block" [ngClass]="{'btn-std': !myEvent.isArchived, 'btn-inverted-gray': myEvent.isArchived}" (click)="changeEventStatus(myEvent, 'isArchived')">
                            <span *ngIf="myEvent.isArchived">Archived</span>
                            <span *ngIf="!myEvent.isArchived">Archive</span>
                          </button>
                        </div>
                        <div class="col-12 col-md-4">
                          <button  [disabled]="!editEvents" class="btn btn-sm btn-block" [ngClass]="{'btn-std': !myEvent.isPublished, 'btn-inverted-gray': myEvent.isPublished}" (click)="changeEventStatus(myEvent, 'isPublished')">
                            <span *ngIf="myEvent.isPublished">Published</span>
                            <span *ngIf="!myEvent.isPublished">Publish</span>
                          </button>
                        </div>
                        <div class="col-12 col-md-4">
                          <button  [disabled]="!editEvents" class="btn btn-sm btn-block" [ngClass]="{'btn-std': !myEvent.isHidden, 'btn-inverted-gray': myEvent.isHidden}" (click)="changeEventStatus(myEvent, 'isHidden')">
                            <span *ngIf="myEvent.isHidden">Hidden</span>
                            <span *ngIf="!myEvent.isHidden">Hide</span>
                          </button>
                        </div>
                        <div class="col-12 col-md-4">
                          <button class="btn btn-sm btn-block" [disabled]="!editEvents" (click)="toggleShowFeedbackOfEvent(myEvent)">
                            <span *ngIf="myEvent.showFeedback">Feedback is shown</span>
                            <span *ngIf="!myEvent.showFeedback">Feedback is hidden</span>
                          </button>
                        </div>
                        <div class="col-12 col-md-4">
                          <button class="btn btn-sm btn-danger btn-block" [disabled]="!editEvents" (click)="deleteEvent(myEvent)">
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>Analytics</a>
            <ng-template ngbNavContent>
              <div class="row my-3">
                <div class="col-4 text-center">
                  <button class="btn btn-std" (click)="download()"> Download detailed csv </button>
                </div>
              </div>
              <table class=" table-hover table table-sm table-responsive">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">PDF</th>
                    <th scope="col">Clicked total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let pdf of pdfAnalytics; let i = index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{pdf._id}}</td>
                    <td>{{pdf.count}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>Admins / Contributors</a>
            <ng-template ngbNavContent>

              <table class=" table-hover table table-sm table-responsive">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">AdminPanel</th>
                    <th scope="col">Allowed Companies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of allAdminsAndContributors; let i = index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{user.userName}}</td>
                    <td>
                      <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-outline-primary" id="dropdownBasic1"
                          ngbDropdownToggle>{{user.type}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          <button class="btn-sm" *ngFor="let role of possibleRoles" (click)="setNewUserRole(user, role)"
                            ngbDropdownItem>{{role}}</button>
                        </div>
                      </div>
                    </td>
                    <td> <button (click)="setAccessToAdminPanel(user, !user.rights.adminPanel)"
                        [ngClass]="{'btn-std': !user.rights.adminPanel, 'btn-inverted-gray': user.rights.adminPanel}"
                        class="btn btn-sm  mx-2"><span *ngIf="user.rights.adminPanel">can access</span><span
                          *ngIf="!user.rights.adminPanel">can not access</span></button></td>
                    <td>
                      <ngb-accordion #acc="ngbAccordion">
                        <ngb-panel title="Companies">
                          <ng-template ngbPanelContent>
                            <input type="text" [(ngModel)]="tmpAllowedCompanyId" [resultTemplate]="rt"
                              placeholder="ADD NEW COMPANY" #instance="ngbTypeahead" [resultFormatter]="formatterCode"
                              [ngbTypeahead]="search" [editable]='false' [inputFormatter]="formatter" />
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" (change)="changeEvent($event)"
                                [checked]="tmpNewWrite" id="write">
                              <label class="form-check-label" for="write">
                                hub
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" (change)="changeEvent($event)"
                                [checked]="tmpNewUpload" id="upload">
                              <label class="form-check-label" for="upload">
                                excel
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" (change)="changeEvent($event)"
                                [checked]="tmpNewDownload" id="download">
                              <label class="form-check-label" for="download">
                                word
                              </label>
                            </div>
                            <button class="btn btn-sm btn-std mx-2"
                              (click)="addAllowedCompany(user, 'add')">add</button>

                            <ol>
                              <li *ngFor="let company of user.rights.companies" class="my-2">{{company.companyId}}
                                <button [ngClass]="{'btn-std': !company.write, 'btn-inverted-gray': company.write}"
                                  (click)="updateAllowedCompany(user, company.companyId, 'write', !company.write)"
                                  class="btn btn-sm  mx-2">
                                  <span *ngIf="company.write"> hub</span>
                                  <span *ngIf="!company.write"> no hub</span>
                                </button>
                                <button [ngClass]="{'btn-std': !company.upload, 'btn-inverted-gray': company.upload}"
                                  (click)="updateAllowedCompany(user, company.companyId, 'upload', !company.upload)"
                                  class="btn btn-sm  mx-2"> <span *ngIf="company.upload"> excel</span>
                                  <span *ngIf="!company.upload"> no excel</span> </button>
                                <button
                                  [ngClass]="{'btn-std': !company.download, 'btn-inverted-gray': company.download}"
                                  (click)="updateAllowedCompany(user, company.companyId, 'download', !company.download)"
                                  class="btn btn-sm  mx-2"> <span *ngIf="company.download"> word</span>
                                  <span *ngIf="!company.download"> no word</span> </button>
                                <button class="btn btn-sm btn-std"
                                  (click)="deleteAllowedCompany(user, company.companyId)">delete</button>
                              </li>

                            </ol>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>RSS</a>
            <ng-template ngbNavContent>
              <div class="row m-5">
                <div class="col-2">
                  <div ngbDropdown class="d-inline-block my-2">
                    <button class="btn btn-sm btn-outline-primary" id="languageDropdownLabel" ngbDropdownToggle>
                      Set language
                    </button>
                    <div ngbDropdownMenu aria-labelledby="languageDropdownLabel" class="dropdown-menu">
                      <button *ngFor="let lang of newsLangs"
                        class="btn btn-sm btn-inverted-gray my-1 dropdown-item text-center" ngbDropdownItem
                        (click)="setNewsLanguage(lang)">
                        {{lang}}
                      </button>
                    </div>
                  </div>
                  <button class="btn btn-std my-2" (click)="publishNews()">{{news_button_label}}</button>
                  {{news.lang}}
                  {{news.title}}
                  {{news.content}}
                </div>
                <div class="col-10">
                  <label for="newstitle">Title of the news.</label>
                  <input class="w-100 mb-3" id="newstitle" placeholder="News: Title" [(ngModel)]="news.title">
                  <label for="newsContent">Content of the news.</label>
                  <textarea class="w-100 mb-3" id="newsContent" rows="5" placeholder="News: Content"
                    [(ngModel)]="news.description"></textarea>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12" *ngFor="let rss of rssFeed">
                  <div class="mx-4 my-4 row">
                    <div class="col-2 fingerFix" (click)="rss.expanded = !rss.expanded">
                      {{rss.pubDate}}
                    </div>
                    <div class="col-8">
                      <input class="form-control" [(ngModel)]="rss.title">
                    </div>
                    <div class="col-1 fingerFix" (click)="rss.expanded = !rss.expanded">
                      edit
                    </div>
                  </div>
                  <div class="mx-4 mt-2 mb-4 row" *ngIf="rss.expanded">
                    <div class="col-8">
                      <textarea rows="5" class="form-control" [(ngModel)]="rss.description"></textarea>
                    </div>
                    <div class="col-2">
                      <button class="btn btn-std" (click)="updateRSS(rss)">{{rss.buttonLabel}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>Emittenten</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-around flex-wrap my-2">
                  <h5 class="mb-0">Existing Emittenten Users</h5>

                  <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-std mr-2" (click)="getEmittentenUsers(currentPage - 1)" [disabled]="currentPage === 1 || loadingEmittenten">Previous</button>
                    <span class="mr-2">Page {{ currentPage }} of {{ totalPages }}</span>
                    <button class="btn btn-sm btn-std" (click)="getEmittentenUsers(currentPage + 1)" [disabled]="currentPage === totalPages || loadingEmittenten">Next</button>
                    <span *ngIf="loadingEmittenten" class="ml-2">
                              Loading Emittenten Users...
                       </span>
                  </div>


                    <div class="d-flex align-items-center mt-2 mt-md-0">
                      <input type="text" [(ngModel)]="searchEmail" placeholder="Search by email">
                      <button class="btn btn-sm btn-std" (click)="onSearchButtonClick()">Search</button>
                    </div>

                  <div class="d-flex align-items-center mt-2 mt-md-0">
                    <span>Sort: </span><select [(ngModel)]="selectedSortOption"
                            [disabled]="loadingEmittenten"

                    >
                      <option value="lastName_asc">Last Name (A..Z)</option>
                      <option value="lastName_desc">Last Name (Z..A)</option>
                      <option value="email_asc">Email (A..Z)</option>
                      <option value="email_desc">Email (Z..A)</option>
                      <option value="approved_desc">Approved (Yes..No)</option>
                      <option value="approved_asc">Approved (No..Yes)</option>
                      <option value="createdAt_asc">Created At (Asc)</option>
                      <option value="createdAt_desc">Created At (Desc)</option>
                    </select>
                    <button class="btn btn-sm btn-std" (click)="onSortButtonClick()">Sort</button>
                  </div>
                  <div class="mt-2 mt-md-0">
                    <span>Pagesize: </span><select [(ngModel)]="selectedPageSizes"
                            [disabled]="loadingEmittenten"

                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">


                  <table class="table table-bordered table-hover">
                    <thead class="thead-light" style="color: black;">
                      <th>Firstname</th>
                      <th>Lastname</th>
                      <th>Email</th>
                      <th>Bloomberg Ticker</th>
                      <th>Companyname</th>
                      <th>Approved</th>
                      <th>Actions</th>
                    </thead>
                    <ng-container *ngFor="let emittentenUser of emittentenUsers">

                      <tbody *ngIf="emittentenUser.contact && emittentenUser.contact.attributes" class="table-striped" style="border:solid 3px blue" class="shadow mb-2">
                        <tr>
                          <td><span>{{emittentenUser.contact.attributes.VORNAME}}</span></td>
                          <td><span>{{emittentenUser.contact.attributes.NACHNAME}}</span></td>
                          <td><span>{{emittentenUser.contact.email}}</span></td>
                          <td><span><input class="smallerInput" [(ngModel)]="emittentenUser.bloombergTicker" type="text"
                              placeholder="bloombergTicker"
                              [resultTemplate]="rt" #instance="ngbTypeahead" [inputFormatter]="formatter" [editable]='true'
                              [resultFormatter]="formatterCode" [ngbTypeahead]="searchBloombergTickers"
                              /></span></td>
                          <td><span><input class="smallerInput" [(ngModel)]="emittentenUser.companyName" type="text"
                              placeholder="Companyname"
                              [resultTemplate]="rt"
                              #instance="ngbTypeahead" [inputFormatter]="formatter" [editable]='true'
                              [resultFormatter]="formatterCode" [ngbTypeahead]="searchCompanyNames"

                              />
                            </span>


                            </td>





                              <td>                            <div ngbDropdown>
                                <button type="button" class="btn btn-std" id="selectisCorporateUser" ngbDropdownToggle>
                                  <span *ngIf="emittentenUser.isCorporateUser">is approved</span>
                                  <span *ngIf="!emittentenUser.isCorporateUser">is NOT approved</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="selectisCorporateUser">
                                      <button ngbDropdownItem (click)="emittentenUser.isCorporateUser = true">set to approved</button>
                                      <button ngbDropdownItem (click)="emittentenUser.isCorporateUser = false">set to NOT approved</button>
                                </div>
                              </div></td>
                          <td>
                            <div>
                              <button *ngIf="!loadingUpdateEmittentenUser" class="btn btn-sm btn-std" (click)="updateEmittentenUser(emittentenUser)">save updated user  {{emittentenUser.contact.attributes.VORNAME}} {{emittentenUser.contact.attributes.NACHNAME}}
                              </button>
                              <button *ngIf="loadingUpdateEmittentenUser" class="btn btn-sm btn-std">loading...</button>
                            </div>
                          </td>
                        </tr>


                      </tbody>
                    </ng-container>


                  </table>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>User Accounts</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <h5>User Accounts</h5>
                  <table>
                    <tr *ngFor="let role of userRoleNumbers | keyvalue">
                      <td >
                        {{ role.key }}
                      </td>
                      <td>
                        {{ role.value }}
                      </td>
                    </tr>
                  </table>

                  <table class="table table-bordered table-hover">
                    <thead class="thead-light" style="color: black;">
                        <th>Email</th>
                      <th>Type</th>
                      <th>delete</th>
                    </thead>
                    <ng-container *ngFor="let user of allUsers">
                      <tbody class="table-striped">

                        <tr>
                          <td><span>{{user.email}}</span></td>
                          <td><span>{{user.customerType}}</span></td>
                          <td>
                            <ng-container *ngIf="userMailMarkedForDeletion === '' || userMailMarkedForDeletion !== user.email">
                              <button class="btn btn-std" (click)="showUserDeletionWarning(user.email)">delete hub account</button>
                            </ng-container>
                            <ng-container *ngIf="userMailMarkedForDeletion === user.email">
                              <button class="btn btn-std" (click)="resetUserDeletionWarning()">cancel</button>
                              <button class="ml-2 btn btn-warning" (click)="deleteUser(user)">delete hub account</button>
                            </ng-container>
                          </td>
                        </tr>


                      </tbody>
                    </ng-container>


                  </table>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>File Manager</a>

            <ng-template ngbNavContent>
              <div class="mx-auto">
                <div class="row">
                  <div class="col-12 text-center my-3">
                    <h2>General Uploader</h2>
                    <p>Here you can upload anything and get the Link to the uploaded File.</p>
                    <p>This upload is not connected to any company or research. It just uploads files.</p>
                  </div>
                </div>
                <app-file-manager></app-file-manager>

              </div>


            </ng-template>
          </ng-container>
        </nav>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>






      </div>
    </div>
  </div>
</div>
