import { Component, OnInit } from '@angular/core';
import { CompanyInfoService } from 'src/app/services/company-info.service';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.scss']
})
export class LegalDisclaimerComponent implements OnInit {

  companies = [
    {
      "name": "",
      "ticker": "",
      "bloomberg": "",
      "disclosures": ""
    },
  ]


  constructor(private companyInfoService: CompanyInfoService) { }

  getDisclaimerCompanies() {
    this.companyInfoService.getDisclaimerCompanies().then((companies) => {
      this.companies = companies as any
      this.companies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    })
  }
  ngOnInit(): void {
    this.getDisclaimerCompanies()

  }

}
