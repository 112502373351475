import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
declare const TradingView: any;

@Component({
  selector: 'app-block-tv-chart',
  templateUrl: './block-tv-chart.component.html',
  styleUrls: ['./block-tv-chart.component.scss']
})
export class BlockTvChartComponent implements OnInit, AfterViewInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any
  @ViewChild('chartWidthMeasureElement') divToMeasureElement: ElementRef;
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()

  chartSymbol = "";
  chartSettings: any = {};
  chartWidgetId: string = '';

  constructor(
  ) { }
  ngOnInit(): void {
    this.chartSymbol = this.createExchangeAndCodeForTVChart(this.data.bloombergTicker)
  }

  ngAfterViewInit() {
    let divToMeasureWidth = this.divToMeasureElement.nativeElement.offsetWidth;
    // new TradingView.widget(
    //   {
    //      "width": divToMeasureWidth,
    //      "height": divToMeasureWidth/1.60655737705, // this is the standart TV-Chart width/heith ratio
    //     //autosize:true,
    //     "symbol": this.chartSymbol,
    //     "interval": "D",
    //     "timezone": "Etc/UTC",
    //     "theme": "light",
    //     "style": "1",
    //     "locale": "uk",
    //     "toolbar_bg": "#f1f3f6",
    //     "enable_publishing": false,
    //     "save_image": false,
    //     "container_id": "tradingview_bac65",
    //     "allow_symbol_change": true,
    // }
    //   );
    this.createChart()
  }
  createChart() {
    let divToMeasureWidth = this.divToMeasureElement.nativeElement.offsetWidth;
    new TradingView.widget(
      {
        "width": divToMeasureWidth,
        "height": divToMeasureWidth / 1.60655737705, // this is the standart TV-Chart width/heith ratio
        //autosize:true,
        "symbol": this.chartSymbol,
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "1",
        "locale": "uk",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "save_image": false,
        "container_id": "tradingview_bac65",
        "allow_symbol_change": true,
      }
    );
  }
  createExchangeAndCodeForTVChart(blTicker) {
    let exchange = "XETR"
    let code = blTicker.split(":")[0]
    let symbolMap = {
      "2GI": "MUN",
      "R1B": "MUN",
      "CDZ0": "MUN",
      "991": "MUN",
      "NSP": "MIL",
      "XCX": "DUS",
      "P7V": "SWB",
      "ABA": "FWB",
      "THAG": "SIX",
      "PO1": "FWB",
      "CICN":"SIX"
    }
    /**Hardcode fix for single stocks */
    if (code === "GSJ") {
      code = "SGF"
    }

    if (symbolMap.hasOwnProperty(code)) {
      exchange = symbolMap[code]
    }
    else {
      /*
      use default exchange value
      */
    }
    return exchange + ":" + code
  }
}
