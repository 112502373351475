<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h4>Prefill subpages</h4>
    </div>

    <div class="col-12">
      <div class="text-center">
        <button class="btn btn-std" (click)="prefillAllBlocks()">Prefill Everything</button>
        <span *ngIf="pendingRequest">creating new blocks...</span>
        <p><b>Attention: Subpages may already contain content, see below which. Content will be overwritten!</b></p>
      </div>
    </div>

    <div class="col-12">
      Subpages:

      <ul>
        <li class="row border border-blue rounded my-2" *ngFor=" let page of availableForPrefill | keyvalue">
          <div class="col-4 ">{{page.key|renamePageTypes}}</div>
          <div class="col-6">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-std" id="dropdownBasic1" ngbDropdownToggle>
                Select layout
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu">
                <button *ngFor="let layout of layouts | keyvalue"
                  class="btn btn-inverted-gray my-1 dropdown-item text-center" ngbDropdownItem
                  (click)="prefillSubpage(layout.key, page.key)">
                  {{layout.key}} - {{page.key |renamePageTypes}}
                </button>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="newData[page.key] !== null && newData[page.key] !== undefined">
            <div class="fingerFix text-right" (click)="removePageFromPrefiller(page.key)">X</div>
          </div>
          <div class="col-12">
            <span *ngIf="!page.value">
              <small>Attention: Subpage already contains blocks. They will be overwritten.</small>
            </span>
          </div>

        </li>
      </ul>
    </div>
    <div class="col-12">
      <div class="text-center">
        <button class="btn btn-std" [disabled]="(newData | json) == ({} | json)" (click)="savePrefilledBlocks()">Prefill</button>
        <span *ngIf="pendingRequest">creating new blocks...</span>
      </div>
    </div>
    <div class="col-12">

      <div class="col-12">
        <div class="col-12">
          <button type="button" class="btn btn-outline-primary" (click)="collapse()">
            See layout
          </button>
        </div>
        <div class="col-12" *ngIf="isCollapsed">
          <div class="col-12" *ngFor="let layout of layouts | keyvalue">
            <strong>{{layout.key}}</strong>
            <div class="col-12  border border-blue rounded" *ngFor="let pageType of layout.value | keyvalue">
              <div class="col-2">
                <strong>{{pageType.key |renamePageTypes}}</strong>
              </div>
              <div class="col-10">
                <div *ngFor="let block of pageType.value">
                  <small>{{translate(block)}}</small>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>