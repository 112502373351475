import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  newPasswordForm: FormGroup;
  submitted = false;
  loading = false;
  success = false;

  usermail: string;
  token: string;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute
    ) {
    this.newPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', [Validators.required]],
    }, {
      validators: this.passwordMatchValidator
    });
  }

  get f() {
    return this.newPasswordForm.controls;
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
     
      this.token = params.token;
    });
    this.route.queryParams.subscribe(params => {
     
     this.usermail = params.usermail;
    });
  }
  onNewPasswordSubmit() {
    this.submitted = true;

    if (this.newPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    // Implement password update logic here
    // Simulate a delay for demonstration purposes
    this.authService.setNewPassword(this.usermail, this.newPasswordForm.value.newPassword, this.token).then((res) => {
      this.loading = false;
      this.success = true;
     })

  }

  private passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmNewPassword').value;

    if (password === confirmPassword) {
      formGroup.get('confirmNewPassword').setErrors(null);
    } else {
      formGroup.get('confirmNewPassword').setErrors({ passwordMismatch: true });
    }
  }
}