<div class="row" *ngIf="adminMode">
  <!-- Show or Hide Menu-->
  <div class="col-12 text-center">
    <button *ngIf="adminMode && !changeMode && isEditing()" class="btn btn-sm form-control blockButton btn-inverted-gray"
      style="max-width:30%" (click)="startChangeMode()">Show settings for {{translate(blockType)}}</button>
    <button *ngIf="adminMode && changeMode && isEditing()" class="btn btn-sm form-control btn-inverted-gray" style="max-width:30%"
      (click)="endChangeMode()">Hide settings</button>
  </div>
  <div class="col-8"></div>
  <!-- Init Block - only shows when Block needs BL but does not have it -->
  <div class="col-12" *ngIf="notInitalized">
    <div class="row">
      <div class="col-12">
        <p>Bitte Bloomberg-Ticker eintragen.</p>
        <p class="text-center"> Bloomberg-Ticker </p>
        <p class="text-center">
          <input class="form-control" type="text" name="bloombergticker" id="bloombergticker"
            placeholder="bloombergticker" [(ngModel)]=data.bloombergTicker>
        </p>
        <p class="text-center my-5"><button class="btn form-control  blockButton  btn-sm"
            (click)="saveAllChanges()">Block
            initalisieren</button></p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="changeMode">
    <hr>
  </div>
  <div class="col-12" *ngIf="changeMode">
    <!-- Blocktype specific menues-->
    <div class="row text-center">
      <div class="col-12">
        <h5>Settings for {{translate(blockType)}}</h5>
      </div>
      <!-- SHARED-->
      <div class="col-12" [ngClass]="{ 'form-group form-inline': needsBL && needsTitle }">
        <div class="col-4 form-group form-inline"
          [ngClass]="{ 'col-12': !needsBL, 'col-6':needsBL && needsTitle && !needsDecimals }" *ngIf="needsTitle">
          <label for="blocktitel">Block Title</label>
          <input name="blocktitel" class="form-control" type="text" placeholder="headline"
            [(ngModel)]="data.blockHeadline">

        </div>
        <div class="col-4 form-group form-inline"
          [ngClass]="{ 'col-12': !needsTitle, 'col-6':needsBL && needsTitle && !needsDecimals }" *ngIf="needsBL">
          <label for="blTicker">Bloomberg Ticker</label>
          <input class="form-control" type="text" name="blTicker" id="blTicker" [(ngModel)]="data.bloombergTicker">
        </div>
        <div class="col-4 form-group" *ngIf="needsDecimals">
          <div ngbDropdown class="d-inline-block ">
            <button class="btn btn-sm form-control  blockButton btn-outline-primary" id="dropdownBasic1"
              ngbDropdownToggle>
              Decimals
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let decimal of decimals" class="btn  btn-inverted-gray my-1" ngbDropdownItem
                (click)="setDecimals(decimal)">
                {{decimal}}
              </button>
            </div>

          </div>

        </div>
      </div>

      <div class="col-12">
        <hr>
      </div>
      <div class="col-12">
        <!-- Large Image-->
        <div class="row" *ngIf="blockType ==='largeImage'">
          <div class="col-6 form-group form-inline"
            ngbPopover="This URL can be copied and entered in other image blocks. This saves having to upload the same image multiple times."
            triggers="mouseenter:mouseleave" popoverTitle="Info">
            <label for="url"> Image-URL:</label>
            <input class="form-control" type="text" name="url" id="url" placeholder="Bild-URL"
              (changes)="onChangeInput($event)" [(ngModel)]=data.url>
          </div>
          <div class="col-3 text-center">
            <button class=" form-control  blockButton btn btn-sm" (click)="showUploader()">Upload image</button>
          </div>
        </div>
        <!-- Key Figures-->
        <div class="col-12" *ngIf="blockType ==='keyfacts'">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-sm form-control  blockButtonbtn btn-outline-primary" id="languageToggle"
              ngbDropdownToggle>
              Set Language </button>
            <div ngbDropdownMenu aria-labelledby="languageToggle">
              <button *ngFor="let lang of languages" class="btn   btn-inverted-gray my-1" ngbDropdownItem
                (click)="setLanguage(lang)">
                {{lang}}
              </button>
            </div>
          </div>
        </div>
        <!-- Charting-->
        <div class="col-12 form-group form-inline" *ngIf="blockType ==='charting'">
          <div ngbDropdown class="d-inline-block">
            <button class="btn  form-control  blockButton btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              {{this.data.currentTemplate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let template of allTemplateNames" class="btn btn-inverted-gray my-1" ngbDropdownItem
                (click)="setTemplateName(template)">
                {{template}}
              </button>
            </div>
          </div>#
        </div>
        <!-- DCF-->

        <div class="col-12 form-group form-inline" *ngIf="blockType ==='dcfModel'">
          <div ngbDropdown class="d-inline-block">
            <button class="btn  form-control  blockButton btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              {{this.data.dcfType}}
            </button>
            <div ngbDropdownMenu>
              <button *ngFor="let dcfType of dcfTypes" class="btn btn-inverted-gray my-1" ngbDropdownItem
                (click)="setDCFType(dcfType)">
                {{dcfType}}
              </button>
            </div>
          </div>


          <button class="btn form-control  blockButton btn-sm btn-inverted-gray" (click)="editChartingTemplate()">Edit
            template</button>

          <button class="btn form-control  blockButton btn-sm btn-inverted-gray"
            (click)="createChartingTemplate()">Create
            template</button>
        </div>
        <!-- Table NO-TABS && Financial Table-->
        <div class="col-12 form-group form-inline" *ngIf="blockType ==='table-notabs' ||blockType ==='financialtable'">
          <div ngbDropdown class="d-inline-block">
            <button class="btn form-control  blockButton btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              {{this.data.currentTemplate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let template of allTemplateNames" class="btn btn-inverted-gray my-1" ngbDropdownItem
                (click)="setTemplateName(template)">
                {{template}}
              </button>
            </div>
          </div>
          <button class="btn form-control  blockButton btn-sm btn-inverted-gray" (click)="editTemplate()">Edit
            template</button>
          <button class="btn  form-control  blockButton btn-sm btn-inverted-gray" (click)="createTemplate()">Create
            template</button>
        </div>
        <!-- Video -->
        <div class="col-12 form-group form-inline" *ngIf="blockType ==='video'">
          <label ngbPopover="
          This URL can be copied and entered in other video blocks" triggers="mouseenter:mouseleave"
            popoverTitle="Info" for="url">Video-URL:</label>
          <input class="form-control" type="text" name="url" id="url" placeholder="Video-URL"
            (changes)="onChangeInput($event)" [(ngModel)]=data.videoURL>
        </div>
      </div>
    </div>
    <!-- General Blockmenu-->
    <div class="row">
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-6 form-group form-inline">
        <label for="blockTagInput">Block-Tag</label>
        <input class="form-control" name="blockTagInput" type="text" [(ngModel)]="data.blockTag">
      </div>
      <div class="col-3 text-center form-group">
        <button
          ngbPopover="In order to delete blocks that have just been inserted, the page must be reloaded once. (reload or click on other subpage)"
          triggers="mouseenter:mouseleave" popoverTitle="Info"
          class="btn btn-sm btn-inverted-gray form-control  blockButton" (click)="openDeletionWarning()">delete block
        </button>
      </div>
      <div class="col-3 text-center form-group">
        <button class=" btn btn-sm form-control  blockButton" [ngClass]="{ 'btn-inverted-gray': !showSaveWarning }"
          (click)="saveAllChanges()">Save
          all changes</button>
      </div>
    </div>
  </div>

</div>
