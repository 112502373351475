import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-list',
  templateUrl: './research-list.component.html',
  styleUrls: ['./research-list.component.scss']
})
export class ResearchListComponent implements OnInit {

  @Input() archives: any;
  @Input() researchPDFs = [];
  @Input() currentResearchTitle: any
  @Input() currentResearchDate: any
  correctedPDFs = []

  constructor() { }

  ngOnInit(): void {
    this.prepareResearch()

  }

  prepareResearch(){

    if(this.researchPDFs.length > 0 && this.archives){ //horrible hack to restore research history from pdfs with names
      for(let archive of this.archives){
        let title = archive.archiveTitle.replaceAll('-', '');
        let date = title.slice(0,10).replaceAll('-', '').trim().replaceAll(' ', '.');
        let split = date.split('.');
        let computerDate = split[2] + '-' + split[1] + '-' + split[0];
        this.correctedPDFs.push({archiveTitle: title, downloadLink: archive.downloadLink, archiveDate: computerDate});
      }

      for(let pdf of this.researchPDFs){
        let date = pdf.shownName.slice(0,10).replaceAll('-', '').trim().replaceAll(' ', '.');
        let split = date.split('.');
        let computerDate = split[2] + '-' + split[1] + '-' + split[0];
        if(!this.included(this.archives, date) && date !== this.currentResearchDate.replaceAll("/",".")){
          let title = pdf.shownName.slice(10).replaceAll('-', '').trim();
          this.correctedPDFs.push({archiveTitle: date + ' ' + title, downloadLink: pdf.url, archiveDate: computerDate})
        } 
      }
      let split = this.currentResearchDate.split('/');
      let currentComputerDate = split[2]+'-'+split[1]+'-'+split[0];
      this.correctedPDFs.unshift({archiveDate: currentComputerDate, archiveTitle: this.currentResearchDate
        .replaceAll("/",".") + ' ' + this.currentResearchTitle
        .replace(/<\/.+>/,'').replace(/<.+>/,''), downloadLink: this.researchPDFs[0].url});
      this.correctedPDFs.sort((b, a) => new Date(a.archiveDate).getTime() - new Date(b.archiveDate).getTime());
      this.correctedPDFs = JSON.parse(JSON.stringify(this.correctedPDFs));

    }
  }
  included(archives: Array<{archiveTitle: string, downloadLink: string}>, date: string): Boolean {
    for(let archive of archives){
      if(archive.archiveTitle.includes(date)){
        return true;
      }
      if(archive.archiveTitle.includes(date.replace('.20','.'))){ //with . so we dont replace the 20th day
        return true;
      }
    }

    return false;
  }
}
