import { Injectable } from '@angular/core';
import { DataServiceService } from '../services/data-service.service';
import { CompanyInfoService } from '../services/company-info.service';
import { formatValue } from './numberFormatting.util';

@Injectable({
  providedIn: 'root'
})

export class Charting {

  notApplicable = 0;
  baseYear = '2020'; //set this companyWide in admin panel
  languages = ['eng', 'ger'];
  chartTypes = [
    { name: 'Bar-/Line-Chart', key: 'TIMESERIES' },
    { name: 'Pie-Chart', key: 'VALUE' }
  ]
  visualizationTypes = ['GROUPEDBAR', 'STACKEDBAR', 'LINE', 'SCATTER']
  axisNumbers = ['1', '2']

  defaultTemplate = {
    TIMESERIES: {
      "name": "",
      "customerId": "5eed24ff4fe2bd337ccab5d3",
      "settings": {
        "type": "TIMESERIES"
      },
      "template": {
        "settings": {
          "language": "eng",
          "numYearsFront": 9,
          "numYearsBack": 9
        },
        "name": {
          "ger": "",
          "eng": "Profit and loss"
        },
        "data": [
          {
            "key": "sales",
            "type": "GROUPEDBAR"
          },
          {
            "key": "ebitda",
            "type": "GROUPEDBAR"
          }
        ]
      },
    },
    VALUE: {
      "name": "",
      "customerId": "5eed24ff4fe2bd337ccab5d3",
      "settings": {
        "type": "VALUE"
      },
      "template": {
        "settings": {
          "language": "eng",
          "numYearsFront": 9,
          "numYearsBack": 9
        },
        "name": {
          "ger": "",
          "eng": "Profit and loss"
        },
        "data": [
          {
            "key": "mc",
            "type": "PIE"
          }
        ]
      },
    }
  } as any;

  defaultLayout = {
    "settings": {
      "language": "eng",
      "numYearsFront": 9,
      "numYearsBack": 9
    },
    "name": {
      "ger": "",
      "eng": "Profit and loss"
    },
    "xAxis": ["2018", "2019", "2020", "2021"],
    "data": [
      {
        "key": "sales",
        "data": [1, 1, 1, 1],
        "type": "BAR"
      }
    ]
  } as any;


  constructor(private dataService: DataServiceService, private companyInfoService: CompanyInfoService) { }


  async createChartingData(companyData: any, chartingLayout, decimals: number, companyId: string, type: string) {
    if (companyData.base) {
      let bY = this.companyInfoService.getCompanyDataByBloombergTicker(companyId)?.baseyear;

      if (typeof bY !== 'undefined' && bY !== null) {
        this.baseYear = bY.toString();
      }

      let mapping = await this.dataService.getMapping()

      //process each tab
      let language: string = chartingLayout.settings.language;
      let backYears: number = parseInt(chartingLayout.settings.numYearsBack);
      let frontYears: number = parseInt(chartingLayout.settings.numYearsFront);

      //process each line in a tab
      chartingLayout.data.forEach(dataEntry => {
        if (typeof dataEntry['data'] === 'undefined') {
          let key = dataEntry.key;
          let labelKey = dataEntry.label;
          let mappingEntry = mapping[key];
          dataEntry.key = mappingEntry[language];

          if (typeof labelKey !== 'undefined') {
            let labelMapping = mapping[labelKey];
            if (labelMapping) {
              dataEntry.label = this.getValue(companyData, labelMapping, backYears, frontYears, 'value', language, decimals);
            }
          }

          if (dataEntry.baseyear) {
            dataEntry['data'] = this.getValueFromTimeseries(companyData, mappingEntry, dataEntry.baseyear, 'value', language, decimals);
          } else if (type == 'VALUE') {
            dataEntry['data'] = this.getValue(companyData, mappingEntry, backYears, frontYears, 'value', language, decimals);
          } else if (type === 'TIMESERIES') {
            dataEntry['data'] = this.getTimeseries(companyData, mappingEntry, backYears, frontYears, 'value', language, decimals);
            dataEntry['xAxis'] = this.getTimeseries(companyData, mappingEntry, backYears, frontYears, 'date', language, decimals);
          }
        }
      })

      // old 
      // if(type === 'TIMESERIES'){
      //   //when we add arbitrary headlines upload a set of headline keys with mapping upload
      //   //get those headline keys dynamically and implement their resolution here dynamicall
      //   //also dynamicall fill types list in template creation with uploaded headline keys
      //   if(chartingLayout.settings.xAxis === 'QDATES'){
      //     chartingLayout['xAxis'] = this.getTimeseries(companyData, mapping['dates_q'], backYears, frontYears, 'date', language, decimals);
      //   }else{
      //     if(typeof mapping['dates'].path.replace('companyData.', '').split('.').reduce((o, i) => o[i], companyData) !== 'undefined'){
      //       chartingLayout['xAxis'] = this.getTimeseries(companyData, mapping['dates'], backYears, frontYears, 'date', language, decimals);
      //     }else{
      //       chartingLayout['xAxis'] = this.getTimeseries(companyData, {path: 'companyData.financial_figures.profit_and_loss.sales'}, backYears, frontYears, 'date', language, decimals);
      //     }
      //   }
      // }

    }

    return chartingLayout;

  }

  getValue(companyData, mappingEntry, backYears: number, frontYears: number, dateOrValue: string, language: string, defaultDecimals): Array<string> {
    let path = mappingEntry.path.replace('companyData.', '');

    let tableData = [];

    try {
      let originalData = path.split('.').reduce((o, i) => o[i], companyData)

      if (typeof originalData !== 'undefined') {
        tableData.push(originalData);
      } else {
        tableData.push(this.notApplicable);
      }
    }
    catch (e) { }

    return tableData;
  }

  getValueFromTimeseries(companyData, mappingEntry, baseyear, dateOrValue: string, language: string, defaultDecimals: number): Array<string> {
    let path = mappingEntry.path.replace('companyData.', '');

    let tableData = [];

    try {
      let originalData = path.split('.').reduce((o, i) => o[i], companyData)

      if (typeof originalData !== 'undefined') {
        let baseYearIndex = this.findBaseYear(baseyear, originalData);

        if (typeof baseYearIndex !== 'undefined') {
          let val;
          if (dateOrValue !== 'date') {
            val = formatValue(originalData[baseYearIndex][dateOrValue], mappingEntry, language, defaultDecimals)
          } else {
            val = originalData[baseYearIndex][dateOrValue];
          }
          tableData.push(val)
        }
        //  else {
        //   for (let i = 0; i < backYears + frontYears + 1; i++) {
        //     tableData.push(this.notApplicable);
        //   }
        // }
      } else {
        tableData = new Array(1).fill(this.notApplicable);
      }
    } catch (e) { }

    return tableData;
  }

  getTimeseries(companyData, mappingEntry, backYears: number, frontYears: number, dateOrValue: string, language: string, defaultDecimals: number): Array<string> {
    let path = mappingEntry.path.replace('companyData.', '');

    let tableData = [];

    let originalData = path.split('.').reduce((o, i) => o[i], companyData)

    if (typeof originalData !== 'undefined') {
      let baseYearIndex = this.findBaseYear(this.baseYear, originalData);

      if (typeof baseYearIndex !== 'undefined') {
        for (let i = baseYearIndex - backYears; i < baseYearIndex + frontYears + 1; i++) {
          if (i > -1 && i < originalData.length) {
            let val;
            if (dateOrValue !== 'date') {
              val = formatValue(originalData[i][dateOrValue], mappingEntry, language, defaultDecimals)
            } else {
              val = originalData[i][dateOrValue];
            }
            tableData.push(val)
          }
        }
      }
      //  else {
      //   for (let i = 0; i < backYears + frontYears + 1; i++) {
      //     tableData.push(this.notApplicable);
      //   }
      // }
    } else {
      tableData = new Array(frontYears + backYears + 1).fill(this.notApplicable);
    }

    return tableData;
  }


  findBaseYear(baseYear, data: Array<{ date: string, value: any }>): number {
    if (typeof data !== 'undefined') {
      return data.findIndex(entry => {
        return entry.date.replace('E', '').replace('e', '').replace('P', '').replace('p', '') == baseYear;
      })
    } else {
      return 0;
    }
  }
}
