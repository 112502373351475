import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }



  canActivate() {
    if (this.authService.isAuthenticated() && this.authService.isAdmin()) { return true; }

    this.router.navigate(['/signin']);
    return false;
  }
}
