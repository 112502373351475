import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileAttributeTranslator'
})
export class ProfileAttributeTranslatorPipe implements PipeTransform {

  constructor() { }

  public transform(input: string): string {
      switch (input) {
        case "E-Mail":
          return "E-Mail";
        case "CITY":
          return "City"; 
        case "COMPANY_NAME":
          return "Company name"; 
        case "NACHNAME":
          return "Last name"; 
        case "PROFIL_INVESTORENTYP":
          return "Role"; 
        case "VORNAME":
          return "First name"; 
        case "PROFIL_AUM":
          return "Assets under Management (EURm)"; 
        case "PROFIL_GROESSE":
          return "Segment"; 
        case "PROFIL_HALTEDAUER":
          return "Holding Period (years)"; 
        case "PROFIL_INVESTITIONSSTIL":
          return "Investment Style";
        case "PROFIL_REGION":
          return "Region"; 
        case "PROFIL_TICKET_SIZE":
          return "Ticket Size (EURk)"; 
        default:
          return input; 
      }
  }
}
