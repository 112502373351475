import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NewsletterSubscribeComponent } from "src/app/basic-pages/modals/newsletter-subscribe/newsletter-subscribe.component";
import { createEventRegistrationLink, timeZoneFormat } from "src/app/events/utils/event.utils";
import { Company } from "src/app/models/company.model";
import { SingleEvent } from "src/app/models/event.model";
import { CompanyInfoService } from "src/app/services/company-info.service";
import { DataServiceService } from "src/app/services/data-service.service";

@Component({
  selector: "app-auto-slider",
  templateUrl: "./auto-slider.component.html",
  styleUrls: ["./auto-slider.component.scss"],
})
export class AutoSliderComponent implements OnInit, OnDestroy {
  public randomCompany: Company;
  public initiationCompany: Company;
  public descriptionOfRandomCompany: string;
  public descriptionOfInitiationCompany: string;
  public nextEvent: any;
  public emailInput = '';
  public staticEventImage = '../assets/event-slider-picture.png';
  private destroy$ = new Subject<void>();
  private listOfCompanies: any[];
  private listOfEvents: any[];

  private researchHubExcelDataTicker = 'RH:ALS';
  public customSlideData = {};
  timeZoneFormat = timeZoneFormat
  constructor(
    private companyInfoService: CompanyInfoService,
    private dataService: DataServiceService,
    private modalService: NgbModal,
  ) { }

  public ngOnInit(): void {
    this.initCompanies();
    this.initEvents();
    this.getCustomSlideData();
  }

  public subscribeToEvents(): void {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });

    modalRef.componentInstance.emailMode = 'subscribetoevent'; // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'events';
  }

  public openSubscription(): void {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.emailMode = "subscription" // delete this when new forms are used in production
    modalRef.componentInstance.emailInput = this.emailInput // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'newsletter'
  }

  public customSlideButtonClick(){
    window.open(this.customSlideData['slider_button_link']);
  }

  public navigateToEventRegistrationPage(event: SingleEvent): void {
    const route = createEventRegistrationLink(event.bloombergTicker, event.date);
    window.open(route);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initCompanies(): void {
    this.listOfCompanies = this.companyInfoService.getPublishedCompanies();
    const random = Math.floor(Math.random() * this.listOfCompanies.length);
    this.randomCompany = this.listOfCompanies[random];
    this.dataService.getFinancialsNew(this.randomCompany.bloombergTicker).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.descriptionOfRandomCompany = res.general_information.description;
    });
    this.initiationCompany = this.listOfCompanies.find((cmp) => {
      return cmp.isFeatured === "Initiation";
    });
    this.initiationCompany && this.dataService.getFinancialsNew(this.initiationCompany.bloombergTicker).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.descriptionOfInitiationCompany = res.general_information.description;
    })
  }

  private initEvents(): void {
    this.dataService
      .getAllEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.listOfEvents = (result.data as SingleEvent[])
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
          .filter((event) => {
            return event.isPublished === true;
          });
        this.nextEvent = this.listOfEvents.find((event) => {
          return event.date !== null && new Date(event.date).getTime() > Date.now();
        });
        this.nextEvent.imageURL = this.staticEventImage;

      });
  }

  private getCustomSlideData(): void { //mockup to get data from excel for the slide with custom content
    this.dataService.getFinancialsNew(this.researchHubExcelDataTicker).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.customSlideData['title'] = res.general_information.slider_title;
      this.customSlideData['subtitle'] = res.general_information.slider_subtitle;
      this.customSlideData['description'] = res.general_information.slider_description;
      this.customSlideData['imageURL'] = res.general_information.slider_image;
      this.customSlideData['slider_button'] = res.general_information.slider_button;
      this.customSlideData['slider_button_link'] = res.general_information.slider_button_link;
    });
  }
}
