<footer class="footer w-100  u-bg-default">
  <div class="container-fluid limit-pagewidth">
    <!-- <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-md-6 col-lg-3">

      </div>
      <div class="col-md-6 col-lg-3">

      </div>
    </div> -->
    <div class="row">
      <div class="col-12 footer-wrapper">
        <div class="row pt-4 pt-4">
          <div class="col-md-12 col-lg-10">
            <div class="row">
              <div class="col-12 col-lg-4">
                <a class="alster logo mt-3 pb-3" routerLink="/">
                  <img loading="lazy" src="../../../assets/ResearchHubLogo.svg" alt="logo image mwb research">
                </a>

              </div>
              <div class="col-12 col-lg-8 text-center text-lg-left">
                <p class="mt-3">Research<span class="bold">Hub</span> is the place for professional investors to get
                  regularly-updated high-quality research on German and selected European stocks.</p>
              </div>
              <div class="col-md-12 col-lg-3">
                <div class="extra-small text-muted text-center">
                  Portal solution,<br> price and ESG data by
                  <a class="leeway logo mx-auto mt-1" href="https://leeway.tech">
                    <img loading="lazy" src="https://leeway.tech/assets/logo/Leeway_Logo_ohne_Icon_2020-04_RGB.svg"
                      alt="logo leeway">
                  </a>
                </div>

              </div>
              <div class="col-lg-3">
                <div class="extra-small text-muted text-center">
                  Stock chart by
                  <a class="affiliate logo mx-auto mt-1" href="https://www.tradingview.com">
                    <img loading="lazy" src="https://avatars.githubusercontent.com/u/7644688?s=400&v=4"
                      alt="logo image tv">
                  </a>
                </div>
              </div>
              <div class="col-sm-0 col-md-0 col-lg-3">
                <div class="extra-small text-muted text-center">
                  Deutsche Börse Capital Market Partner
                  <a class=" logo rectangle mx-auto mt-1"
                    href="https://www.deutsche-boerse-cash-market.com/dbcm-de/primary-market/capital-market-partner">
                    <img loading="lazy" src="https://downloads.research-hub.de/Capital_Market_Partner_Signet_sm.jpg"
                      alt="logo image tv">
                  </a>
                </div>

              </div>
              <div class="col-lg-3">
                <div class="extra-small text-muted text-center">
                  Supported by
                  <a class=" logo larger mx-auto mt-1" href="https://www.ifbhh.de/foerderprogramm/hamburg-digital">
                    <img loading="lazy" src="https://downloads.research-hub.de/hamburg_digital.png" alt="logo image tv">
                  </a>
                </div>
              </div>
            </div>


          </div>

          <div class="col-sm-12  col-lg-2">
            <ul class="list-unstyled small text-center text-lg-right mt-3">
              <li class="mt-1">
                <a class="" routerLink="/datenschutz">
                  Data privacy
                </a>
              </li>
              <li *ngIf="isCookiesAccepted" class="mt-1">
                <a class="" href="/" (click)="revokeCookieConsent()">
                  Revoke Cookie Consent
                </a>
              </li>
              <li class="mt-1">
                <a class="" routerLink="/impressum">
                  Imprint
                </a>
              </li>
              <li class="mt-1">
                <a class="" routerLink="/mifid2">
                  MiFID II
                </a>
              </li>
              <li class="mt-1">
                <a class="" href="https://www.mwb-research.com/">
                  Contact
                </a>
              </li>
              <li class="mt-1">
                <a class="" routerLink="/disclaimer">
                  Disclaimer
                </a>
              </li>
              <li class="mt-1">
                <div class="text-muted">&copy; mwb research</div>
              </li>
              <li class="mt-1">
                <small>
                  <button class="btn btn-login" type="button" *ngIf="!isAuthenticated()" (click)="openLogin()">
                    Login - <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-person-fill" viewBox="0 0 16 16">
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </button>
                  <app-button *ngIf="isAuthenticated()" (click)="openLogin()" color="secondary">
                    log out {{currentUserName || ""}}</app-button>
                </small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
