import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { DataServiceService } from "../../../services/data-service.service";
import { chartConfig } from "./chart-config.const";
import { chartLayout } from "./chart-layout.const";
import { ChartData } from "./models/chart-data.model";
import { Charting } from "src/app/utilities/charting.util";

@Component({
  selector: "app-charting",
  templateUrl: "./charting.component.html",
  styleUrls: ["./charting.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartingComponent implements OnInit, OnChanges {
  @Input() companyId: string;
  @Input() companyData: any = {};
  @Input() templateName = "default-chart";
  @Input() decimals = 0;
  public chart: any = {};
  // {"data":[{"labels":["Market Cap","Shareholder1"],"values":["1511","10.0"],"type":"pie"}],"layout":{"title":"Profit and loss"},"config":{"modeBarButtonsToRemove":[],"displaylogo":false}};
  // {};
  private chartLayout = chartLayout;

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: "Company data; mwb research",
    ger: "Firmenangaben; mwb research",
  };
  public headline: string = "";
  constructor(
    private chartingUtil: Charting,
    private dataService: DataServiceService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataService
      .getChartingTemplate(this.templateName)
      .subscribe((res: any) => {
        this.chartingUtil
          .createChartingData(
            this.companyData,
            res.template,
            this.decimals,
            this.companyId,
            res.settings.type
          )
          .then((chart) => {
            this.initChart(chart);
            this.cdr.detectChanges();
          });
      });
  }

  ngOnChanges(): void {
    this.dataService
      .getChartingTemplate(this.templateName)
      .subscribe((res: any) => {
        this.chartingUtil
          .createChartingData(
            this.companyData,
            res.template,
            this.decimals,
            this.companyId,
            res.settings.type
          )
          .then((chart) => {
            this.initChart(chart);
            this.cdr.detectChanges();
          });
      });
  }

  prepareData(chartingLayout): Array<Partial<ChartData>> {
    let data = [];

    for (let i = 0; i < chartingLayout.data.length; i++) {
      let dataset = chartingLayout.data[i];
      if (dataset.type === "PIE") {
        //process value type data

        if (data.length < 1) {
          let labels = [];
          let values = [];
          if (!dataset.data[0].length) {
            let label = "";
            if (typeof dataset["label"] === "undefined") {
              label = dataset.key;
            } else {
              label = dataset.label;
            }
            labels = [label];
            values = dataset.data;
          } else {
            for (let entry of dataset.data[0]) {
              if (entry.date !== "#EMPTY") {
                values.push(entry.value);
                labels.push(entry.date);
              }
            }
          }

          let entry = {
            labels: labels,
            values: values,
            type: "pie",
          };

          data.push(entry);
        } else {
          if (dataset.data.length === 1) {
            let label = "";
            if (typeof dataset["label"] === "undefined") {
              label = dataset.key;
            } else {
              label = dataset.label;
            }

            data[0].values.push(dataset.data[0]);
            data[0].labels.push(label);
          } else {
            for (let entry of dataset.data) {
              data[0].values.push(entry.value);
              data[0].labels.push(entry.date);
            }
          }
        }

        data[0] = this.addColor(dataset.type, 0, data[0]);

        this.chart.layout = {};
        this.chart.layout["paper_bgcolor"] = "rgba(0,0,0,0)";
        this.chart.layout["plot_bgcolor"] = "rgba(0,0,0,0)";
      } else {
        //process timeseries type data
        let entry = {
          x: dataset.xAxis,
          y: dataset.data,
          name: dataset.key,
          type: this.getType(dataset.type),
          mode: this.getMode(dataset.type),
        };

        entry = this.addColor(dataset.type, i, entry);

        if (typeof dataset.yaxis !== "undefined") {
          entry["yaxis"] = "y" + dataset.yaxis;
          entry["offsetgroup"] = i;
        } else {
          entry["yaxis"] = "y1";
          entry["offsetgroup"] = i;
        }

        data.push(entry);

        this.chart.layout = this.chartLayout;
        this.chartLayout["yaxis2"] = {
          side: "right",
        };

        this.chartLayout["yaxis"] = {
          overlaying: "y2",
          ...this.chart.layout.yaxis,
          title: "",
          titlefont: {
            size: 20,
          },
        };
      }
    }

    return data;
  }

  private addColor(type, index, entry) {
    let colors = [
      "rgb(20, 83, 137)",
      "rgb(255, 214, 67)",
      "rgb(131, 171, 219)",
      "rgb(223, 233, 246)",
      "rgb(236, 236, 236)",
    ];
    let obj = {
      color: colors[index],
    };

    // if(this.getMode(type) === 'markers'){
    if (type !== "PIE") {
      entry["marker"] = obj;
      entry["line"] = obj;
    } else {
      entry["marker"] = {
        colors: colors,
      };
    }
    // }else{
    //   entry[]
    // }

    return entry;
  }

  private getType(type: string): string {
    if (type === "GROUPEDBAR") {
      this.chartLayout["barmode"] = "group";
      return "bar";
    } else if (type === "STACKEDBAR") {
      this.chartLayout["barmode"] = "stack";
      return "bar";
    } else if (type === "LINE") {
      return "scatter";
    } else if (type === "SCATTER") {
      return "scatter";
    }
  }

  private getMode(type: string): string {
    if (type === "GROUPEDBAR") {
      return "";
    } else if (type === "STACKEDBAR") {
      return "";
    } else if (type === "LINE") {
      return "lines";
    } else if (type === "SCATTER") {
      return "markers";
    }
  }

  private initChart(chartingLayout): void {
    this.chart.data = this.prepareData(chartingLayout);

    this.headline = chartingLayout.name[chartingLayout.settings.language];
    //this.chart.layout.title = chartingLayout.name[chartingLayout.settings.language];

    this.chart.config = chartConfig;
  }
}
