import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-requestpassword',
  templateUrl: './requestpassword.component.html',
  styleUrls: ['./requestpassword.component.scss']
})
export class RequestpasswordComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;
  loading = false;
  success = false;
  prefilledEmail = ''
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    if (this.isAuthenticated()) {
      this.prefilledEmail = this.authService.getCurrentUserName()
      this.resetForm.controls['email'].setValue(this.prefilledEmail)
    }
  }


  isAuthenticated() {
    return this.authService.isAuthenticated()
  }


  get f() {
    return this.resetForm.controls;
  }

  onResetSubmit() {
    this.submitted = true;

    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;
    // Implement password reset request logic here
    // Simulate a delay for demonstration purposes
    this.authService.requestPasswordReset(this.resetForm.value.email).then((res) => {
      this.loading = false;
      this.success = true;
    })

  }

}