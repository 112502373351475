import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-denied-page',
  templateUrl: './cookies-denied-page.component.html',
  styleUrls: ['./cookies-denied-page.component.scss']
})
export class CookiesDeniedPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public backToCookieConsent(): void{
    window.open('/', '_self');
    
  }

}
