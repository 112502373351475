import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { SubpageLayoutSelectionComponent } from '../subpage-layout-selection/subpage-layout-selection.component';
import { FileUploaderComponent } from 'src/app/cms/file-uploader/file-uploader.component';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-admin-panel-settings-modal',
  templateUrl: './admin-panel-settings-modal.component.html',
  styleUrls: ['./admin-panel-settings-modal.component.scss']
})
export class AdminPanelSettingsModalComponent implements OnInit {
  @Input() company: any;
  @Input() companies: any;
  @Input() index: any;

  public newMappingSet = { blTicker: "", mappedTicker: "" }
  public isLoading = false;
  public showDeletionWarning = {};
  public isDeleting: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private companyInfoService: CompanyInfoService,
    private modalservice: NgbModal,
    private utilitiesService: UtilitiesService,
  ) { }

  public ngOnInit(): void {
    this.newMappingSet.blTicker = this.company.bloombergTicker;
    this.getAlsterExchageMapping();
    if(this.company.charts[0] === ''){
      this.company.charts = ['pie timeseries,Line Chart Regional Sales,shareholders','Sales and earnings momentum,ROCE chart,FCF growth'];
      this.setCharts();
    }
  }

  public hideCompany(isHidden): void {
    this.companies[this.index].hidden = isHidden;
    this.companyInfoService.setHideOfCompany(this.companies[this.index]).then(res => {
      this.companyInfoService.setExcelVisibilityFlag(this.companies[this.index].bloombergTicker, !isHidden).then(res => { });
    })
  }

  public openSubpageLayoutSelection(companyID): void {
    const modalRef = this.modalservice.open(SubpageLayoutSelectionComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.companyId = companyID;
    modalRef.result.then((layoutresult) => {

    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {

      } else {
        // do nothing
      }
    });
  }

  public openLogoUploader(companyId): void {
    const modalRef = this.modalservice.open(FileUploaderComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.uploaderMode = 'imageOnly';
    modalRef.result.then((logourl) => {
      this.updateCompanyWithLogoURL(companyId, logourl).then((result) => {
        this.companyInfoService.refreshCompanyList()
      })
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {

      } else {
        // do nothing
      }
    });
  }

  public openCompanyImageUploader(companyId): void {
    const modalRef = this.modalservice.open(FileUploaderComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.uploaderMode = 'imageOnly';
    modalRef.result.then((imageURL) => {
      this.updateCompanyWithImageURL(companyId, imageURL).then((result) => {
        this.companyInfoService.refreshCompanyList()
      })
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {

      } else {
        // do nothing
      }
    });
  }

  public setCharts(): void {
    this.companyInfoService.saveChartsForCompany(this.companies[this.index], this.companies[this.index].charts).subscribe(()=>{});
  }

  public saveExchangeToMapping(): void {
    if(this.newMappingSet.mappedTicker){
      this.isLoading = true;
      this.utilitiesService.updateExchangeMapping(this.newMappingSet).then((res: any) => {
        this.isLoading = false;
      });
    } else {
      this.deleteAlsterExchangeMappingSet(this.company.bloombergTicker.replaceAll(":", "_"));
    }
  }

  public deleteCompany(force: boolean, companyID: string): void {
    // if (force === true) {
    //   this.showDeletionWarning[companyID] = false;
    //   this.isDeleting = true;
    //   this.companyInfoService.deleteCompanyFully(companyID).then(
    //     result => { this.isDeleting = false; }
    //
    //   )
    // } else {
    //   this.showDeletionWarning[companyID] = true;
    // }
  }

  private getAlsterExchageMapping(): void {
    const tempBT = this.company.bloombergTicker.replaceAll(":", "_");
    this.utilitiesService.getExchangeMapping().then(res => {
        if(res.hasOwnProperty(tempBT)){
          this.newMappingSet.mappedTicker = res[tempBT];
        }
    })
  }

  private deleteAlsterExchangeMappingSet(key): void {
    this.utilitiesService.deleteExchangeMapping(key).then();
  }

  private updateCompanyWithLogoURL(companyId, logoUrl): Promise<any> {
    return new Promise((resolve, reject) => {
      this.companyInfoService.setLogoUrlOfCompany(companyId, logoUrl).then(result => resolve(result))
    })
  }

  private updateCompanyWithImageURL(companyId, imageURL): Promise<any> {
    return new Promise((resolve, reject) => {
      this.companyInfoService.setImageUrlOfCompany(companyId, imageURL).then(result => resolve(result))
    })
  }
}
