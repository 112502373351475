<div class="create-event-wrapper container-fluid" *ngIf="mode === 'createEvent'">
  <div class="row py-5">
    <div class="col-11">
      <h3>Create new event</h3>
    </div>
    <div class="col-1">
      <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      Title
    </div>
    <div class="col-8">
      <input class="form-control" [(ngModel)]="data.title">
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-4">
      Date (click to open calendar)
    </div>
    <div class="col-8">
      <!-- <input class="form-control" [(ngModel)]="data.date"> -->

      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="data.date" ngbDatepicker
              #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                  class="fas fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
      </form>
      
      <div class="input-group">
        <ngb-timepicker [(ngModel)]="time"></ngb-timepicker>
      </div>
    </div>

  </div>
  <hr>
  <div class="row">
    <div class="col-4">
      <label for="tcCheck">No date:</label>
    </div>
    <div class="col-8">
      <input id="tcCheck" type="checkbox" class="customCheckbox" [(ngModel)]="noDate" />
    </div>
    <div class="col-12">
    <strong>selected Date: {{createNewDateInAlsterTZ(data.date.year, data.date.month, data.date.day, time.hour, time.minute,0)}}</strong>
    </div>

  </div>

  <div class="row py-5">
    <app-button (click)="sendEvent()" color="primary">Create event</app-button>
  </div>
</div>

<div class="create-event-wrapper container-fluid" *ngIf="mode === 'editEventDate'">
  <div class="row py-5">
    <div class="col-11">
      <h3>Edit event date</h3>
      <p>current Date: {{currentEvent.date}}</p>
    </div>
    <div class="col-1">
      <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      Date (click to open calendar)
    </div>
    <div class="col-8">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="newDate" ngbDatepicker
              #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                  class="fas fa-calendar-alt"></i></button>
            </div>
          </div>

        </div>

      </form>
      <div class="input-group">
        <ngb-timepicker [(ngModel)]="time"></ngb-timepicker>
      </div>
    </div>
    <div class="col-12" *ngIf="newDate && time">

        <strong>selected Date: {{createNewDateInAlsterTZ(newDate.year, newDate.month, newDate.day, time.hour, time.minute,0)}}</strong>
          
    </div>
    <button (click)="saveNewDate()">save new date</button>
  </div>
</div>
