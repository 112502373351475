export function deepCopy(oldObj: any) {
  var newObj = oldObj;
  if (oldObj && typeof oldObj === "object") {
      if (oldObj instanceof Date) {
         return new Date(oldObj.getTime());
      }
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
          newObj[i] = deepCopy(oldObj[i]);
      }
  }
  return newObj;
}
