import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { DataServiceService } from "../../../services/data-service.service";
import { Tables } from "src/app/utilities/tables.util";

@Component({
  selector: "app-table-notabs",
  templateUrl: "./table-notabs.component.html",
  styleUrls: ["./table-notabs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableNotabsComponent implements OnInit, OnChanges {
  @Input() companyId: string;
  @Input() companyData: any = {};
  @Input() decimals = 0;
  @Input() templateName = "Keyfacts PnL";
  @Input() collapse;
  public headline: string = "";

  tableLayout = this.tableUtil.defaultLayout;

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: "Company data; mwb research",
    ger: "Firmenangaben; mwb research",
  };

  possibleJumpmarks = ["valuation-fcf-yield", "valuation-trading-multiples"];

  constructor(
    private tableUtil: Tables,
    private dataService: DataServiceService,
    private cdr: ChangeDetectorRef
  ) {}

  findAndAddJumpmark() {
    switch (this.headline) {
      case "Adj. FCF yield": {
        return this.possibleJumpmarks[0];
      }
      case "Trading Multiples": {
        return this.possibleJumpmarks[1];
      }
    }
  }

  ngOnInit(): void {
    this.dataService
      .getTableTemplate(this.templateName)
      .subscribe((res: any) => {
        let template = res.template;
        this.tableUtil
          .createTableData(
            this.companyData,
            template,
            this.decimals,
            this.companyId
          )
          .then((table) => {
            this.tableLayout = table;
            this.headline = res.settings.title[table[0].settings.language];
            this.cdr.detectChanges();
          });
      });
  }

  ngOnChanges(): void {
    this.dataService
      .getTableTemplate(this.templateName)
      .subscribe((res: any) => {
        let template = res.template;

        this.tableUtil
          .createTableData(
            this.companyData,
            template,
            this.decimals,
            this.companyId
          )
          .then((table) => {
            this.tableLayout = table;
            this.headline = res.settings.title[table[0].settings.language];
            this.cdr.detectChanges();
          });
      });
  }
}
