<div class="px-3 py-3">
  <div class="row text-right">
    <div class="col-12"><span class="fingerFix bigger text-muted"
        (click)="activeModal.dismiss('deny')"><strong>&#10005;</strong></span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 ">

      <h4>Thank you for subscribing.</h4>
    </div>
  </div>



  <div class="row mt-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>Please confirm your subscription in the Email we have sent you.</p>

        </div>
      </div>
    </div>
  </div>