<div class="bg-white" id="overview">
    <div class="row">
        <div class="col-12 pr-0 pl-0">
            <div class="tile-grid">
                <div
                class="company-image  kachel border2 flex-d"
                (click)="showCompanyDescription=!showCompanyDescription"
                (mouseenter)="showCompanyDescription=true"
                (mouseleave)="showCompanyDescription=false"

                 [ngClass]="showCompanyDescription ? 'showCompanyDesc' : ''">
                <img class="img-bg" background-image src="{{imageURL}}" alt="">
                    <div class="padding-wrapper">
                        <div class="row companyDescription m-0 m-lg-5">
                        <div  class="col-12 ">
                            <span class="d-none d-lg-block">{{companyData?.general_information?.description || 'Here you will shortly find the company description.'}}</span>
                            <small style="font-size: small;" class="d-block d-lg-none">
                            {{companyData?.general_information?.description || 'Here you will shortly find the company description.'}}
                            </small>
                        </div>
                    </div>
                    <div class="interactionArrow">
                        <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                fill="#FFFFFF" />
                        </svg>
                    </div>
                        <div class="tile-content flex-mobile-fix row ">

                            <div class="col-12">
                                <div
                                    class="companyHeaderDownloadText  d-flex flex-column flex-lg-row justify-content-lg-around">
                                    <span class="fingerFix mini-kachel mb-1 my-lg-0 d-none d-lg-inline">
                                      <ng-container *ngIf="companyData?.base?.bl_ticker ==='9MQ:GR'">
                                      <a *ngIf="researchPDFs && researchPDFs?.length > 0" style="color:inherit"
                                         href="{{researchPDFs[researchPDFs?.length-2]?.url}}"
                                         target="_blank">Initiation Note (PDF)
                                        </a>
                                      </ng-container>
                                      <ng-container *ngIf="companyData?.base?.bl_ticker !=='9MQ:GR'">
                                    <a *ngIf="researchPDFs && researchPDFs?.length > 0" style="color:inherit"
                                       href="{{researchPDFs[researchPDFs?.length-1]?.url}}"
                                       target="_blank">Initiation Note (PDF)
                                        </a>
                                      </ng-container>


                                        <a *ngIf="!researchPDFs || researchPDFs?.length <= 0" style="color:inherit"
                                        target="_blank">Initiation Note<span class="d-none d-lg-inline"> (PDF)</span></a>
                                    </span>
                                    <span class="fingerFix mini-kachel my-1 my-lg-0 d-none d-lg-inline"><a style="color:inherit"
                                            href="{{currentCompanyPresentation}}" target="_blank"><span class="d-none d-lg-inline">Company</span> Presentation<span class="d-none d-lg-inline"> (PDF)</span></a></span>
                                    <span class="fingerFix mini-kachel mt-1 my-lg-0 d-none d-lg-inline">
                                        <a *ngIf="investorRelationsLink?.value && investorRelationsLink?.value[0] !== 'na'"  style="color:inherit" href="{{investorRelationsLink?.value[0]}}" target="blank_"><span class="d-none d-lg-inline">Investor Relations</span><span class="d-lg-none d-inline">IR-Page</span></a>
                                        <span *ngIf="investorRelationsLink?.value && investorRelationsLink?.value[0] === 'na'"  style="color:inherit"><span class="d-none d-lg-inline">Investor Relations</span><span class="d-lg-none d-inline">IR-Page</span></span>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div class="company-info kachel border2">
                    <div class="row tile-title">
                        <div class="col-8 col-lg-10">company info</div>
                        <div class="col-4 col-lg-2">
                            <div class="row">
                            <div
                            class="slider-arrow-left col-6 fingerFixNew"
                            (click)="remoteControlTile(-1)"
                            >
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="-4 -18 1 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                    d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                    fill="#0F6BB2" transform="rotate(225)"/>
                                    </svg>
                            </div>
                            <div
                             class="slider-arrow-right col-6 fingerFixNew"
                             (click)="remoteControlTile(1)"
                             >
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="-2 3 10 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                    d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                    fill="#0F6BB2" transform="rotate(45)"/>
                                    </svg>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class=" tile-content row">
                        <app-table-slider
                            class="full-width lessPaddingForRows"
                            *ngIf="config && config.type === 'table-slider'"
                            [companyData]="companyData"
                            [mapping]="mapping"
                            [config]="config"
                            [valueTable]="true"
                            ></app-table-slider>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>


                     <div class="d-block d-md-none company-presentation fingerFix kachel downloader-kachel">
                        <div class="tile-content" style="line-height: 4.5em;">                        <a style="color:inherit"

                            href="{{currentCompanyPresentation}}" target="_blank"><span class="d-none d-lg-inline">Company</span>
                            <span>Presentation</span>
                            <span class="d-none d-lg-inline"> (PDF)</span>
                            <span style="position: absolute;
                            right: 10%;">
                            <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                fill="#FFFFFF" />
                        </svg>
                    </span>
                        </a></div>

                        </div>
                    <div class="d-block d-md-none company-IR fingerFix kachel downloader-kachel">
                        <div class="tile-content" style="line-height: 4.5em;">                     <a *ngIf="investorRelationsLink?.value && investorRelationsLink?.value[0] !== 'na'"
                            style="color:inherit" href="{{investorRelationsLink?.value[0]}}" target="blank_"
                            >
                            <span class="d-none d-lg-inline">Investor Relations</span><span class="d-lg-none d-inline">IR-Page</span>
                            <span style="position: absolute;
                            right: 10%;">
                            <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                fill="#FFFFFF" />
                        </svg>
                      </span>
                          </a>

                           <span *ngIf="investorRelationsLink?.value && investorRelationsLink?.value[0] === 'na'"  style="color:inherit">
                              <span class="d-none d-lg-inline">Investor Relations</span><span class="d-lg-none d-inline">IR-Page</span>
                          </span></div>

                    </div>

                <div (click)="openModal('research')" class="company-research kachel interactive fingerFixNew border2">

                    <div class="tile-title row">
                        <div class="col-10" style="color:white">Research</div>

                        <div class="col-2 text-right fingerFix">
                            <div>
                                <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                        fill="#FFFFFF" />
                                </svg>
                            </div>
                        </div>

                    </div>
                    <div class="tile-content row">
                        <div class="col-6">
                            <svg width="49" height="51" viewBox="0 0 49 51" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M42.6552 28.3719C41.666 28.3719 40.8621 29.1973 40.8621 30.213V38.805C40.8621 39.8218 40.0593 40.6461 39.069 40.6461C38.0787 40.6461 37.2759 39.8218 37.2759 38.805V30.213C37.2759 27.1636 39.6853 24.6896 42.6552 24.6896C45.6251 24.6896 48.0346 27.1636 48.0346 30.213V41.2598C48.0346 46.3443 44.0209 50.4655 39.069 50.4655C34.1172 50.4655 30.1035 46.3443 30.1035 41.2598V33.8953C30.1035 32.8785 30.9063 32.0542 31.8966 32.0542C32.8869 32.0542 33.6897 32.8785 33.6897 33.8953V41.2598C33.6897 44.3107 36.0978 46.7832 39.069 46.7832C42.0403 46.7832 44.4483 44.3107 44.4483 41.2598V30.213C44.4483 29.1973 43.6445 28.3719 42.6552 28.3719Z"
                                    fill="#FCFCFC" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M9.93115 33.6552C9.93115 32.4173 10.7522 31.4138 11.765 31.4138H21.5456C22.5584 31.4138 23.3794 32.4173 23.3794 33.6552C23.3794 34.8931 22.5584 35.8966 21.5456 35.8966H11.765C10.7522 35.8966 9.93115 34.8931 9.93115 33.6552Z"
                                    fill="#FCFCFC" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M9.93115 24.1293C9.93115 23.2009 10.7723 22.4483 11.81 22.4483H29.3455C30.3831 22.4483 31.2243 23.2009 31.2243 24.1293C31.2243 25.0577 30.3831 25.8104 29.3455 25.8104H11.81C10.7723 25.8104 9.93115 25.0577 9.93115 24.1293Z"
                                    fill="#FCFCFC" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M9.93115 14.0431C9.93115 13.1147 10.7723 12.3621 11.81 12.3621H29.3455C30.3831 12.3621 31.2243 13.1147 31.2243 14.0431C31.2243 14.9715 30.3831 15.7241 29.3455 15.7241H11.81C10.7723 15.7241 9.93115 14.9715 9.93115 14.0431Z"
                                    fill="#FCFCFC" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.965576 6.83046C0.965576 3.07608 3.97502 0.0344849 7.68971 0.0344849H34.5863C38.301 0.0344849 41.3104 3.07608 41.3104 6.83046V16.7155C41.3104 17.7392 40.4894 18.569 39.4765 18.569C38.4637 18.569 37.6427 17.7392 37.6427 16.7155V6.83046C37.6427 5.12335 36.2753 3.74138 34.5863 3.74138H7.68971C6.00064 3.74138 4.63329 5.12335 4.63329 6.83046V41.4282C4.63329 43.1353 6.00064 44.5172 7.68971 44.5172H22.3606C23.3734 44.5172 24.1944 45.3471 24.1944 46.3707C24.1944 47.3943 23.3734 48.2241 22.3606 48.2241H7.68971C3.97502 48.2241 0.965576 45.1825 0.965576 41.4282V6.83046Z"
                                    fill="#FCFCFC" />
                            </svg>
                        </div>
                        <div class="col-6" style="color:white">
                            <p>Last update {{currentResearchDate}}</p>
                            <div>{{companyData?.base?.research_provider || "mwb research"}}</div>
                            <div style="color:#A8C4DA">{{currentResearchTitle | HTMLSpecialChar}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div class="company-rating kachel border2 " [style.background-color]="
                    companyData.general_information.cus_rating==='BUY'?'#ECFDF5':
                    companyData.general_information.cus_rating==='Spec. BUY'?'#ECFDF5':
                    companyData.general_information.cus_rating==='HOLD'?'#FFFBEB':
                    companyData.general_information.cus_rating==='SELL'?'#FEF2F2':'#F1F5F9'
                     ">
                    <div class="tile-title row ">
                        <div class="col-12 tile-text-dark">Rating</div>
                    </div>
                    <div class="tile-content  row">
                        <div class="col-7 col-lg-6">
                            <div class="ratingWord" style="text-transform: uppercase;"
                                [style.color]="
                            companyData.general_information.cus_rating==='BUY'?'#10B981':
                            companyData.general_information.cus_rating==='Spec. BUY'?'#10B981':
                            companyData.general_information.cus_rating==='HOLD'?'#EAB308':
                            companyData.general_information.cus_rating==='SELL'?'#EF4444':'#000000'

                            ">{{companyData?.general_information.cus_rating}}
                            </div>

                        </div>
                        <div class="col-5 col-lg-6">
                            <div class="align-bottom tile-text-dark">
                                Price Target: {{companyData?.base?.quote_curr || companyData?.base?.curr}} {{companyData?.general_information?.cus_pt
                                |number:"1.2-2"}}
                                <br>
                                Up-/Downside:
                                <span
                                    [style.color]="companyData.financial_figures.ratios.cus_pt_range>=0?'#10B981':'#EF4444'">{{companyData?.financial_figures?.ratios?.cus_pt_range
                                    * 100
                                    |number:'1.1-1'}} %</span>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div (click)="openModal('shareprice')" class="d-none d-lg-block company-share-price  kachel interactive fingerFixNew quadrat border2">
                    <div class="tile-title row">
                        <div class="col-10">Share Price</div>
                        <div class="col-2 text-right fingerFix">
                            <div (click)="openModal('shareprice')">
                                <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                        fill="#0F6BB2" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="tile-content-nobottom" style="height:100%">
                        <div class="row" style="height:50%">
                            <div class="col-12">
                                <small style="position: absolute;bottom: 5%;">{{companyData?.base?.quote_curr || companyData?.base?.curr}}</small>
                            </div>
                            <div class="col-8">
                                <div class="sharePriceHugeNumber">{{quotes[quotes.length-1]?.adjusted_close|number:'1.2-2'}}</div>
                            </div>
                            <div class="col-4">
                                <table class="sharePriceSmallNumber">
                                    <tr>
                                        <td>7D </td>
                                        <td *ngIf="calculations" [ngClass]="calculations?.day < 0 ? 'negative-change-shareprice':'positive-change-shareprice'">
                                             {{calculations?.day |number:'1.1-1'}} %</td>
                                    </tr>
                                    <tr>
                                        <td>30D </td>
                                        <td *ngIf="calculations" [ngClass]="calculations?.month < 0 ? 'negative-change-shareprice':'positive-change-shareprice'">
                                          {{calculations?.month |number:'1.1-1'}} %</td>
                                    </tr>
                                    <tr>
                                        <td>12M </td>
                                        <td *ngIf="calculations" [ngClass]="calculations?.year < 0 ? 'negative-change-shareprice':'positive-change-shareprice'">
                                          {{(calculations?.year |number:'1.1-1')  || 'n/a'}} %</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row" style=" height:50%; position: absolute; bottom: 20px; width:100%">
                            <!--   -->
                            <div class="col-12">
                                <div id="tvShareChart-{{chartID}}" #tvShareChart style="  width: 100%; height: 100%;"></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div class="d-block d-lg-none company-share-price  kachel border2">
                    <div class="tile-title row">
                        <div class="col-10">Share Price</div>
                     </div>
                    <div class="tile-content row">
                        <div class="col-12">
                            <small>{{companyData?.base?.quote_curr || companyData?.base?.curr }}</small>
                        </div>
                        <div class="col-7">
                            <div class="sharePriceHugeNumber">{{latestSharePrice | number:'1.2-2'}}</div>
                        </div>
                        <div class="col-5">
                            <table class="sharePriceSmallNumber">
                                <tr>
                                    <td>7D </td>
                                    <td *ngIf="calculations" [ngClass]="calculations?.day < 0 ? 'negative-change-shareprice':'positive-change-shareprice'">
                                         {{calculations?.day |number:'1.1-1'}} %</td>
                                </tr>
                                <tr>
                                    <td>30D </td>
                                    <td *ngIf="calculations" [ngClass]="calculations?.month < 0 ? 'negative-change-shareprice':'positive-change-shareprice'">
                                      {{calculations?.month |number:'1.1-1'}} %</td>
                                </tr>
                                <tr>
                                    <td>12M </td>
                                    <td *ngIf="calculations" [ngClass]="calculations?.year < 0 ? 'negative-change-shareprice':'positive-change-shareprice'">
                                      {{calculations?.year |number:'1.1-1'}} %</td>
                                </tr>
                            </table>
                        </div>


                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>

                <div class="company-video kachel interactive border2">
                    <div class="tile-title row ">
                        <div class="col-12">Company video</div>
                    </div>
                    <div class="tile-content-nobottom-absolute row">
                        <div *ngIf="latestEvent !== undefined && latestEvent.videoURL"
                            class="col-5 text-muted text-vertical-center">
                            {{latestEvent?.title}}<br>
                            {{latestEvent?.date |date:'MMM dd, yyyy, HH:mm':timeZoneFormat(latestEvent?.date)}}
                        </div>
                        <div *ngIf="latestEvent === undefined && videoURLObj.videoURL"
                            class="col-5 text-muted text-vertical-center">
                            <span>{{companyName}}, </span><br>
                            <span>Corporate Video</span>
                        </div>

                        <div *ngIf="latestEvent === undefined && videoURLObj.videoURL === undefined"
                            class="col-5 text-muted text-vertical-center mx-auto"> <!-- center the text right-->
                            <span>Currently no video available. Please check again soon.</span>
                        </div>

                        <div *ngIf="latestEvent !== undefined || videoURLObj.videoURL !== undefined"
                            class="overlayWrapper col-7 padding-wrapper"
                            style="position:relative;  vertical-align: top;">
                            <div id="modalClickerOverlay" *ngIf="latestEvent !== undefined &&  latestEvent.videoURL"
                                (click)="openVideoModal(latestEvent.videoURL)" style=" cursor: pointer; position:absolute; top:0; right:0; width:100%; height:100%;
                              z-index:1049;">
                            </div>
                            <div id="modalClickerOverlay" *ngIf="latestEvent === undefined && videoURLObj.videoURL"
                                (click)="openVideoModal(videoURLObj.videoURL)" style=" cursor: pointer; position:absolute; top:0; right:0; width:100%; height:100%;
                                                        z-index:1049;">
                            </div>
                            <!-- <div id="modalClickerOverlay"
                                *ngIf="latestEvent === undefined && videoURLObj.videoURL === undefined"
                                style="position:absolute; top:0; right:0; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.7);
                                          z-index:1049; text-align:center">
                                 <h4
                                    style="position:absolute; top:50%; left:35%; color:white; transform:translateY(50%);  margin:0">
                                    no video</h4>
                            </div> -->


                            <span *ngIf="latestEvent && latestEvent.videoURL">
                                <app-responsive-video [videoURL]="latestEvent !== undefined && latestEvent.videoURL">
                                </app-responsive-video>
                            </span>
                            <span *ngIf="latestEvent === undefined">
                                <app-responsive-video [videoURL]="videoURLObj.videoURL"></app-responsive-video>
                            </span>

                            <!-- this does nothing: it is only for showing the thumbnail and play button-->
                        </div>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div (click)="openModal('shareholders')" class="d-none d-lg-block company-shareholders interactive fingerFixNew  kachel border2">
                    <div class="tile-title row">
                        <div class="col-10">Shareholders</div>

                        <div class="col-2 text-right fingerFix">
                            <div (click)="openModal('shareholders')">
                                <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                        fill="#0F6BB2" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="tile-content" style="width: calc(100% - 40px) !important">
                        <section class="bar-graph bar-graph-horizontal bar-graph-one" [style.height.%]="80">
                            <ng-container *ngFor="let shareholder of shareholders">
                                <div class="bar-one my-1" [style.min-height.em]="1.7" [style.height.%]="100"
                                    style="background-color: #e2e8f0;">
                                    <div class="bar" [style.min-height.em]="1.7" [style.height.%]="100"
                                        [style.width.%]="shareholder.width"></div>
                                    <div class="barchart-text"
                                        style=" color:#6c757d !important; position:absolute; padding-left:2%; padding-top:1%">
                                        {{shareholder?.value |
                                        number:'1.1-1'}} %
                                        {{shareholder?.name}}</div>
                                </div>
                            </ng-container>
                            <div class="bar-one my-1" [style.min-height.em]="1.7" [style.height.%]="100"
                                style="background-color: #e2e8f0;">
                                <div class="bar" style="background-color: #C7E7FD;" [style.width.%]="freefloatWidth"
                                    [style.min-height.em]="1.7" [style.height.%]="100"></div>
                                <div class="barchart-text"
                                    style=" color:#6c757d !important; position:absolute; padding-left:2%; padding-top:1%">
                                    {{freefloat | number:'1.1-1'}} %
                                    Freefloat</div>
                            </div>
                        </section>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div class="d-block d-lg-none company-shareholders   kachel border2">
                    <div class="tile-title row">
                        <div class="col-10">Shareholders</div>
                    </div>
                    <div class="tile-content" style="width: calc(100% - 40px) !important">
                        <section class="bar-graph bar-graph-horizontal bar-graph-one" [style.height.%]="80">
                            <ng-container *ngFor="let shareholder of shareholders">
                                <div class="bar-one my-1" [style.min-height.em]="1.7" [style.height.%]="100"
                                    style="background-color: #e2e8f0;">
                                    <div class="bar" [style.min-height.em]="1.7" [style.height.%]="100"
                                        [style.width.%]="shareholder.width"></div>
                                    <div class="barchart-text"
                                        style=" color:#6c757d !important; position:absolute; padding-left:2%; padding-top:1%">
                                        {{shareholder?.value |
                                        number:'1.1-1'}} %
                                        {{shareholder?.name}}</div>
                                </div>
                            </ng-container>
                            <div class="bar-one my-1" [style.min-height.em]="1.7" [style.height.%]="100"
                                style="background-color: #e2e8f0;">
                                <div class="bar" style="background-color: #C7E7FD;" [style.width.%]="freefloatWidth"
                                    [style.min-height.em]="1.7" [style.height.%]="100"></div>
                                <div class="barchart-text"
                                    style=" color:#6c757d !important; position:absolute; padding-left:2%; padding-top:1%">
                                    {{freefloat | number:'1.1-1'}} %
                                    Freefloat</div>
                            </div>
                        </section>
                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div (click)="openModal('bookameeting')" class="company-contact interactive fingerFixNew kachel border2">
                    <div class="tile-title row">
                        <div class="col-10">Book a meeting</div>
                        <div class="col-2 text-right">
                            <div class="fingerFix" (click)="openModal('bookameeting')">
                                <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                        fill="#0F6BB2" />
                                </svg>
                            </div>

                        </div>
                    </div>
                    <div class="full-height display-flex">
                        <div class="tile-content-nobottom row">
                            <div class="col-4 display-flex">
                                <div class="company-logo-wrapper text-vertical-center">
                                    <img class="company-logo-img cutWhiteAreas"
                                        [src]="getLogoForCompany(companyData.base.bl_ticker)"
                                        [alt]="'Logo for ' + companyData.base.bl_ticker" #companyLogo>
                                </div>
                            </div>
                            <div class="col-8 tile-text text-vertical-center">
                                Click here to request a meeting with a representative of {{companyData?.base?.name}}
                            </div>
                        </div>
                    </div>

                    <!-- later get event -->
                    <!-- <button class="btn btn-std m-5">Contact US!</button> -->
                    <!-- <div class="tile-content">
                        <div>
                            Thomas Wissler
                        </div>
                        <div>
                            +49 11 111 111

                        </div>
                        <div>
                            email@alster.de
                        </div>
                    </div> -->
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
                <div class="company-news  kachel border2">
                    <div class="tile-title row">
                        <div class="col-10">latest news</div>
                        <div class="col-2 text-right">
                            <div class="fingerFix" (click)="openModal('companynews')">
                                <svg width="13" height="13" viewBox="0 0 15 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
                                        fill="#0F6BB2" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="tile-content row" *ngIf="latestArticle">
                        <div class="col-12 text-muted">
                            <small>
                                <a href={{latestArticle.url}}>{{formatNewsDate(latestArticle.date)}}</a></small>
                        </div>
                        <div class="col-12">
                            <a href={{latestArticle.url}}><b class=""><small style="color:black;">{{latestArticle.title
                                        |slice:0:50}}...</small></b></a>
                            <div class="text-muted"><small>{{latestArticle?.summary |slice:0:150}}...</small></div>
                        </div>


                    </div>
                    <div *ngIf="loadingNewData" class="overlay">
                        <span class="centerSpinner">
                            <app-loading-indicator class="sizingIndicator"></app-loading-indicator>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
