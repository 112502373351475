import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { translateProgrammingString } from 'src/app/utilities/namingTranslation.util';

@Component({
  selector: 'app-subpage-layout-selection',
  templateUrl: './subpage-layout-selection.component.html',
  styleUrls: ['./subpage-layout-selection.component.scss']
})
export class SubpageLayoutSelectionComponent implements OnInit {

  @Input() companyId: ""
  possiblePages: Array<any> = []
  oldPages = ["business", "investmentcase", "shareholder"]
  newData: any = {};
  availableForPrefill = {}
  public isCollapsed = false;
  public pendingRequest = false;

  constructor(private cmsService: CmsService, private companyInfoService: CompanyInfoService) { }

  ngOnInit(): void {
    this.possiblePages = this.companyInfoService.getAvailablePages().filter(item => !this.oldPages.includes(item));
    for (let pageType of this.possiblePages) {
      this.companyInfoService.checkIfLayoutsAreEmpty(this.companyId, pageType).then(result => {
        this.availableForPrefill[pageType] = result.canBePrefilled

      })
    }
  }


  /**
   *
   * ... "pagetype":["blockType:customJumpTag", "blockType:customJumpTag"...]
   *
   */
  layouts = {
    "standard": {
      /** Equity Story **/   "investment-case": ['largeImage:LargeImage', 'editor:InvestmentCase', 'keyfacts:KeyFigures'],
       /** Snapshot snapshot **/   "snapshot": ['largeImage:LargeImage', 'editor:ProductsServices', 'charting:Segments', 'editor:Management', 'charting:Shareholder'],
       /** Quality quality **/    "quality": ['largeImage:LargeImage', 'editor:Competition', 'charting:ROCE', 'editor:Customers', 'table-notabs:RegionalSales', 'editor:Suppliers'],
       /** Growth growth **/     "growth": ['largeImage:LargeImage', 'editor:Growth', 'table-notabs:GrowthTable', 'charting:FCF'],
       /** Research research **/   "research": ['largeImage:LargeImage', 'button:latestResearchButton', 'editor:LatestResearch', 'table-notabs:Quarterly', 'pdf-research:researchPDF'],
       /** Valuation valuation **/  "valuation": ['largeImage:LargeImage', 'table-notabs:Multiples', 'dcfModel:dcfModel', 'table-notabs:FCFModel'],
       /** Financials financials **/ "financials": ['largeImage:LargeImage', 'financialtable:financialtable'],
       /** SWOT swot **/       "swot": ['largeImage:LargeImage', 'editor:SWOT'],
       /** Chart chart **/      "chart": ['largeImage:LargeImage', 'tv-chart:Chart'],
       /** ESG esg **/        "esg": ['largeImage:LargeImage', 'esg-all:esg-all'],
       /** Corporate corporate **/  "corporate": ['largeImage:LargeImage', 'pdf-research:pdf-presentation', 'pdf-research:pdf-annual', 'pdf-research:pdf-quarterly', 'video:video']
    }
  }

  prefillSubpage(layoutName, pageType) {
    if (pageType !== null && pageType !== undefined) {
      let newBlocks = []
      for (let blockType of this.layouts[layoutName][pageType] as Array<any>) {
        let blockTag = '#' + blockType.split(':')[0];

        if (blockType.includes(':')) {
          let split = blockType.split(':');
          blockType = split[0];
          blockTag = '#' + split[1];
        }

        let newBlock = this.cmsService.createNewBlock(blockType, blockTag)
        newBlock.data.bloombergTicker = this.companyId

        newBlocks.push(newBlock)

      }
      this.newData[pageType] = newBlocks
    } else {
      for (const [key, value] of Object.entries(this.layouts[layoutName])) {

        let newBlocks = []
        for (let blockType of value as Array<any>) {

          let blockTag = '#' + blockType.split(':')[0];

          if (blockType.includes(':')) {
            let split = blockType.split(':');
            blockType = split[0];
            blockTag = '#' + split[1];
          }
          let newBlock = this.cmsService.createNewBlock(blockType, blockTag)
          newBlock.data.bloombergTicker = this.companyId

          newBlocks.push(newBlock)
        }

        this.newData[key] = newBlocks
      }
    }
  }



  savePrefilledBlocks() {
    this.pendingRequest = true;
    for (const [key, value] of Object.entries(this.newData)) {
      this.companyInfoService.savePrefilledLayoutForSubpage(this.companyId, key, value).then(res => {console.log(res)}
      )

    }

    setTimeout(
      () => {
        this.pendingRequest = false;
      }, 2000
    )
  }

  prefillAllBlocks() {
    // [disabled]="(newData | json) == ({} | json)"
    for(let page in this.availableForPrefill){
      this.prefillSubpage('standard', page);
    }

    this.savePrefilledBlocks();

  }

  translate(s: string): string {
    return translateProgrammingString(s);
  }

  removePageFromPrefiller(pageType) {
    delete this.newData[pageType]
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed
  }

}
