<div class="company-grid container-fluid">
  <ng-template #rt let-r="result" let-t="term">
    <a
      class="uppercase"
      style="color: black"
      [routerLink]="['/companies/investment-case/', r.name]"
      [fragment]="'X'"
    >
      {{ r.name }}
    </a>
  </ng-template>

  <div class="row" *ngIf="displayMode ==='full' || displayMode ==='welcome-page-top-half'">
    <div class="col-12 col-md-6">
      <input
        id="typeahead-basic"
        type="text"
        class="form-control w-100"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        (selectItem)="nextPage($event)"
        [resultTemplate]="rt"
        placeholder="Search companies..."
      />
    </div>

    <div class="col-12 col-md-6 float-md-right my-auto">
      <button
        class="btn btn-link pr-0 float-md-right fingerFix"
        (click)="goToURL('screener')"
      >
        try advanced search <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>

  <div class="row mt-5 "  *ngIf="displayMode !=='welcome-page-bottom-half'">
    <div class="col-12">
      <p class="text-muted uppercase bigger">Recent Updates</p>
    </div>

    <!-- Das hier ist das Highlight Ding (wird in Adminpanel gesetzt) -->
    <div
      *ngFor="let company of shortenedHighlightList"
      class="companyTile my-2 my-md-3 top-bottom-spread col-md-12 fingerFix e2e-company-update-item "
      [ngClass]="'col-lg-' + 12 / companyHighlightsPerRow"
    >
      <!--   <div class=" pb-3  w-100 h-100 mx-auto"
        [routerLink]="company.featuredStock === true && i >4?['/blog/weeklyScreener/']:['/companies/snapshot/', company.name]">
        -->
      <div
        class=" row w-100 h-100 mx-auto"
        [routerLink]="['/companies', company.name]"
        [fragment]="'X'"
      >

          <div class="col-12 col-md-6 pb-3">
            <div class="row mx-auto pt-3 w-100">
              <div class="col-12">
                <h4>
                  {{ company.name }}
                  <span
                    class="float-right fi fi flagColorFilter"
                    [ngClass]="selectCountryFlag(company.country)"
                  ></span>
                </h4>
              </div>
            </div>
            <div class="row mx-auto w-100 mt-2">
              <div class="col-12 col-md-7 pr-0 ">
                <span
                  [ngClass]="getColourClass(company.isFeatured)"
                  *ngIf="company.isFeatured !== 'Featured Stock'"
                  class="label d-inline-block small uppercase mw-100 mw-md-75"
                >
                  {{ company.isFeatured }}
                </span>

                <!--  DAS HIER IST DER BTN LINK ZUM SCREENER-->
                <span
                  *ngIf="company.isFeatured === 'Featured Stock'"
                  [routerLink]="['/blog/weeklyScreener/']"
                  [fragment]="'X'"
                  class="btn-link fingerFix d-inline-block small uppercase mw-100 mw-75"
                >
                  Featured Stock <i class="fas fa-arrow-right"></i>
                </span>
              </div>


              <div class="col-12 col-md-5 my-2 my-md-0 pl-md-0 text-right d-flex flex-row justify-content-between d-md-block">
                <div *ngIf="!company.close">
                  <app-loading-indicator></app-loading-indicator>
                </div>
                <div class="">
                  <span *ngIf="company.close">{{ company.currency }} </span>
                  <span *ngIf="company.close !== 'NA'">{{
                    company.close | number : "1.2-2" || "n/a"
                  }}</span>
                </div>
                <div
                  class=""
                  [ngClass]="
                    company.change_p < 0 ? 'negativeRed' : 'positiveGreen'
                  "
                >
                  <span *ngIf="company.close !== 'NA'">{{
                    company.change_p | number : "1.1-1" || "n/a"
                  }}</span>
                  <span *ngIf="company.close">%</span>
                </div>
                <div
                  class="caps signalWord"
                  [ngStyle]="{ color: recColors[company.rating] }"
                >
                  {{ company.rating }}
                </div>
              </div>
              <div class="col-12 uppercase small lineHeight-1 mb-2">
                mwb research AG
              </div>
              <div class="col-12 text-muted uppercase small lineHeight-1 mb-2">
                {{ company.latestResearchTitle }}
              </div>
            </div>
          </div>
          <div class="col-6 d-none d-md-block pr-0">
            <div class="w-100 h-100">
            <img
              src="{{ company.imageURL }}"
              alt=""

              style="width: 100%; object-fit: cover; height: 100%"
            />
            </div>
          </div>

      </div>
    </div>

    <div class="col-12 show-all" *ngIf="displayMode !== 'companyPage'">
      <button
        (click)="showFullHighlights()"
        *ngIf="highlightedList.length - shortenedHighlightList.length > 0"
        class="btn text-muted smaller"
      >
        show all ({{
          highlightedList.length - shortenedHighlightList.length
        }}
        more) <i class="fas fa-arrow-right"></i>
      </button>
      <button
        (click)="shortenHighlights(highlightedList)"
        *ngIf="highlightedList.length - shortenedHighlightList.length === 0"
        class="btn text-muted smaller"
      >
        show less
      </button>
    </div>
  </div>

  <div class="row" *ngIf="displayMode === 'full'">
    <div class="col-12">
      <hr />
    </div>
  </div>

  <div class="row" *ngIf="displayMode ==='full'  || displayMode ==='welcome-page-bottom-half'">
    <div class="col-12">
      <p class="text-muted uppercase bigger">All stocks</p>
    </div>
    <!-- Das hier ist das normale Company Grid, ohne jegliches Labelsgedoens -->
    <div
      *ngFor="
        let company of listOfCompanies.slice(
          0,
          math.min(numRows * companiesPerRow, listOfCompanies.length)
        )
      "
      class="companyTile my-3 top-bottom-spread col-md-12 fingerFix e2e-company-all-item"
      [ngClass]="'col-lg-' + 12 / companiesPerRow"
    >
      <div
        class="pb-3 w-100 h-100 mx-auto"
        [routerLink]="['/companies', company.name]"
        [fragment]="'X'"
      >
        <div class="row mx-auto pt-3 w-100">
          <div class="col-12">
            <h4>
              {{ company.name }}
              <span
                class="float-right fi flagColorFilter"
                [ngClass]="selectCountryFlag(company.country)"
              ></span>
            </h4>
          </div>
        </div>
        <div class="row mx-auto w-100 mt-2">
          <div class="col-7 pr-0">
            <div class="text-muted uppercase small lineHeight-1 mb-2">
              {{ company.sector }}
            </div>
          </div>
          <div class="col-5 pl-0 text-right">
            <div *ngIf="!company.close">
              <app-loading-indicator></app-loading-indicator>
            </div>
            <div class="">
              <span *ngIf="company.close">{{ company.currency }} </span>
              <span *ngIf="company.close !== 'NA'">{{
                company.close | number : "1.2-2" || "n/a"
              }}</span>
            </div>
            <div
              class=""
              [ngClass]="company.change_p < 0 ? 'negativeRed' : 'positiveGreen'"
            >
              <span *ngIf="company.close !== 'NA'">{{
                company.change_p | number : "1.1-1" || "n/a"
              }}</span>
              <span *ngIf="company.close"> %</span>
            </div>
            <div
              class="caps signalWord"
              [ngStyle]="{ color: recColors[company.rating] }"
            >
              {{ company.rating }}
            </div>
          </div>
        </div>
        <!-- <div class="row mx-auto  w-100 py-3">
          <div class="col-12">
            <span *ngIf="company.newResearch" class="label labelyellow small uppercase">New Research</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <div *ngIf="!showAll" class="col-12 show-all">
    <button
      (click)="
        numRowsTMP = numRows;
        numRows = math.ceil(listOfCompanies.length / companiesPerRow)
      "
      *ngIf="
        listOfCompanies.length - numRows * companiesPerRow > 0 &&
        displayMode === 'full'
      "
      class="btn text-muted smaller"
    >
      show all ({{
        1 + listOfCompanies.length - numRows * companiesPerRow
      }}
      more) <i class="fas fa-arrow-right"></i>
    </button>
    <button
      (click)="numRows = numRowsTMP"
      *ngIf="
        listOfCompanies.length - numRows * companiesPerRow <= 0 &&
        displayMode === 'full'
      "
      class="btn text-muted smaller"
    >
      show less
    </button>
    <button (click)="numRowsTMP = numRows; numRows=math.ceil(listOfCompanies.length/companiesPerRow); "
      *ngIf="listOfCompanies.length-numRows*companiesPerRow > 0  && (displayMode==='full'  || displayMode ==='welcome-page-bottom-half')"
      class="btn text-muted smaller">show all
      ({{(1+listOfCompanies.length)-numRows*companiesPerRow}} more) <i class="fas fa-arrow-right"></i></button>
    <button (click)="numRows=numRowsTMP"
      *ngIf="listOfCompanies.length-numRows*companiesPerRow <= 0 && (displayMode==='full'  || displayMode ==='welcome-page-bottom-half')"
      class="btn text-muted smaller">show less </button>

    <button
      (click)="goToURL('all-stocks')"
      *ngIf="
        listOfCompanies.length - numRows * companiesPerRow > 0 &&
        displayMode === 'companyPage'
      "
      class="btn text-muted smaller"
    >
      show all ({{
        1 + listOfCompanies.length - numRows * companiesPerRow
      }}
      more) <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</div>
