<h5>Achtung: </h5>

<div *ngIf="blocks.length >0;else revertChanges">
  <p>Do you really want to delete the content {{companyName}}/{{pageType}}?</p>
  <ul><li *ngFor="let block of blocks">{{block}}</li></ul>
  <button class="btn btn-std" (click)="activeModal.dismiss('ok')">Delete block</button>
</div>

<ng-template #revertChanges>
  <p>Really revert the changes to {{companyName}}/{{translate(pageType)}}? The resets the changes to the currently published content.</p>
<button  class="btn btn-std" (click)="activeModal.dismiss('ok')">Revert all changes</button>
</ng-template>
<button class="btn btn-std" (click)="activeModal.dismiss('deny')">Cancel</button>


<span (click)="activeModal.dismiss('deny')"><strong>X</strong></span>
