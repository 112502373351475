import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
@Component({
  selector: 'app-markets-block-video',
  templateUrl: './block-video.component.html',
  styleUrls: ['./block-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockVideoComponent implements OnInit, AfterViewInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()
  @ViewChild('measurementDiv') element: ElementRef;

  videoMeasurementWidth = 0;
  videoMeasurementHeight = 0;
  vimeoUrl = "https://vimeo.com/522221637"
  renderedHTML = ""
  constructor(
    private embedService: EmbedVideoService
  ) {

  }
  createEmbeddedHTML(vimeoUrl) {
    return this.embedService.embed(vimeoUrl, {
      query: { border: 1, portrait: 0, byline: false, title: false, badge: true, color: 'FFF', responsive: true },
      attr: { width: '768', height: "432" }
    })
  }

  renderVideo() {
    this.renderedHTML = this.createEmbeddedHTML(this.data.videoURL)
  }

  ngOnInit(): void {
    this.data.videoURL = this.data.videoURL ? this.data.videoURL : this.vimeoUrl
    this.renderVideo()
  }
  ngAfterViewInit() {
    let currentMargin: number = parseInt((this.element.nativeElement.style.marginLeft as string).substring(0, 2));
    this.videoMeasurementWidth = this.element.nativeElement.offsetWidth
    this.videoMeasurementHeight = this.element.nativeElement.offsetHeight
  }


}
