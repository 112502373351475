<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Upload Files</h5>
        </div>
        <div class="card-body">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input #fileInput type="file" class="custom-file-input" multiple (change)="onFileSelected($event)" id="fileInput">
              <label class="custom-file-label" for="fileInput">Choose file(s)</label>
            </div>
            <div class="input-group-append">
              <button class="btn btn-primary" (click)="uploadFiles()">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="uploadMessage$ | async as message" class="mt-3">
    <div class="alert alert-info" role="alert">
      {{ message }}
    </div>
  </div>
  <div *ngIf="isLoading$ | async" class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="ml-2">Loading...</span>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div *ngFor="let file of selectedFiles$ | async; let i = index" class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div *ngIf="file.type.startsWith('image/')">
                <img [src]="file.url" [alt]="file.name" class="img-fluid rounded">
              </div>
              <div *ngIf="!file.type.startsWith('image/')">
                <i class="fas fa-file fa-4x"></i>
              </div>
            </div>
            <div class="col-md-9">
              <h5 class="card-title">{{ file.name }}</h5>
              <div class="input-group mb-3">
                <input type="text" class="form-control" [formControl]="file.control" (blur)="onFileNameChange(i)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" *ngIf="!file.uploaded" (click)="onFileDeselect(i)">Deselect</button>
                </div>
              </div>
              <ul class="list-group">
                <li class="list-group-item">Size: {{ file.size / 100000  }} MB</li>
                <li class="list-group-item">Type: {{ file.type }}</li>
                <li class="list-group-item" *ngIf="file.uploaded">URL: {{ file.url }}</li>
                <li class="list-group-item" *ngIf="file.uploaded"><button class="btn btn-std" (click)="copyTextToClipboard(file.url)">copy link</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
