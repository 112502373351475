<div [ngClass]="{ 
        'border border-dark rounded': adminMode,
        'd-none d-lg-block':data.blockTag === '#FCFModel'
      
      }" id="{{data.blockTag}}">
  <div>
    <div *ngIf="!notInitalized">
      <app-table-notabs *ngIf="
            bloombergTicker !== 'Eingabe erforderlich' 
          " [companyId]="metaBloombergTicker" [decimals]="data.numDecimals" [templateName]="data.currentTemplate"
        [companyData]="metaCompanyData" [collapse]="data.collapse"></app-table-notabs>
    </div>
    <div class="row" *ngIf="adminMode">
      <div class="col-12">
        <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
          [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName"
          [metaBloombergTicker]="metaBloombergTicker" [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData">
        </app-block-handling>
      </div>
    </div>
  </div>
</div>
