<div class="container" style="padding-top: 50px;">
  <div class="row">
    <div class="col-10">
      <h3>MiFID II</h3>
      <br>
      <h4>
        Our status under MiFID II
      </h4>
      <p>
        Our research is classified as minor non-monetary benefit under MiFID II. This applies to all forms of
        transmission, including email, the digital research hub (DRH) and financial platforms such as Bloomberg or
        Refinitiv. We prominently disclose on our research that we are commissioned to write the research we produce on
        our notes. Our research is read on a free-to-access basis by institutions across the globe. It has been accessed
        by more than 5,000 professional investment institutions since MiFID II was introduced and many other market
        participants including private investors, sell side, advisors and press. The MiFID II rules make provisions for
        certain content and services to be received by institutional investors without the requirement for payment under
        the inducement rules. The list includes:
      </p>
      ​ <ol>
        <li>
          Written research that is paid for by the issuer to promote a new issue
        </li>
        <li>
          Written research where there is a contractual engagement to produce this written content on an ongoing basis
          where
          the relationship between the issuer and the research provider is clearly disclosed and is made widely
          available
          to
          the general public.
        </li>
        <li>
          Brief, unsubstantiated summaries on company results and upcoming releases and events.
        </li>
        <li>
          Macroeconomic and fixed income research that is freely disseminated with information that is generic in
          nature.
        </li>
        <li>
          Investor roadshows that are organized by an investor relations officer, or where the issuer pays a third-party
          provider to organize a roadshow that is made widely available to institutional and other investors.
        </li>
      </ol>

      <p>
        <strong>
          We do not seek payment from the asset management community and do not have any execution function. Investors
          can
          continue to receive our research under the new MiFID II regime without the need for a contract for services to
          be
          put in place. This applies to all forms of transmission, including email, the digital research hub (DRH) and
          financial platforms such as Bloomberg and Refinitiv.</strong>
      </p>

      <p>
        We highlight the following to recipients of our research and attendees of roadshows:
      </p>
      <ol>
        <li>
          We do not conduct any execution business, hence we cannot induce our readers to trade with us.
        </li>
        <li>
          Our content is disseminated widely and made freely available to the public through a range of different
          channels.
        </li>
        <li>
          We do not seek any payment from our readers for our research.
        </li>
      </ol>

      <p>
        Many have expressed concerns about the potential negative impact of the new rules on the research coverage of
        smaller companies and the liquidity of their shares on secondary markets.
      </p>

      <p>
        To ease this potential impact, we took certain steps ahead of MiFID II by:
      </p>
      <ol>
        <li>
          allowing free distribution of research that supports capital raising events;
        </li>
        <li>
          allowing issuer-sponsored research – an important source of coverage for smaller companies – to be freely
          circulated; and
        </li>
        <li>
          clarifying that research made publicly available cannot be an inducement.
        </li>
      </ol>
    </div>
  </div>
</div>
