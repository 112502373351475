<div class="table-wrapper container py-0 my-0 customFont" >
  <div class="row py-0 my-0">
    <div class="table-responsive mx-auto mt-2">
      <div>
        <table class="table table-sm table-hover bilanzkennzahlen">
          <tbody>
            <tr *ngFor="let row of tableLayoutPnL[0].data">
              <th [attr.colspan]="row.colspan" [ngClass]="{
                  rowHighlight: row.highlight,
                  'rowHighlight2': row.highlight2,
                  tabHeader: row.headline
                }">
                {{ row.key }}
              </th>
              <td *ngFor="let datapoint of row.data" [ngClass]="{
                  rowHighlight: row.highlight,
                  'rowHighlight2': row.highlight2,
                  tabHeader: row.headline
                }">
                {{ datapoint }}
              </td>
            </tr>
            <!-- </tbody>
        </table>
      </div> -->
            <tr class="blank_row">
              <th></th>
            </tr>
            <!-- <div>
        <table class="table table-sm table-hover bilanzkennzahlen">
          <tbody> -->
            <tr *ngFor="let row of tableLayoutBS[0].data">
              <th [attr.colspan]="row.colspan" [ngClass]="{
                  rowHighlight: row.highlight,
                  'rowHighlight2': row.highlight2,
                  tabHeader: row.headline
                }">
                {{ row.key }}
              </th>
              <td *ngFor="let datapoint of row.data" [ngClass]="{
                  rowHighlight: row.highlight,
                  'rowHighlight2': row.highlight2,
                  tabHeader: row.headline
                }">
                {{ datapoint }}
              </td>
            </tr>
            <!-- </tbody>
        </table>
      </div> -->
            <tr class="blank_row">
              <th></th>
            </tr>
            <!-- <div>
        <table class="table table-sm table-hover bilanzkennzahlen">
          <tbody> -->
            <tr *ngFor="let row of tableLayoutMult[0].data">
              <th [attr.colspan]="row.colspan" [ngClass]="{
                  rowHighlight: row.highlight,
                  'rowHighlight2': row.highlight2,
                  tabHeader: row.headline
                }">
                {{ row.key }}
              </th>
              <td *ngFor="let datapoint of row.data" [ngClass]="{
                  rowHighlight: row.highlight,
                  'rowHighlight2': row.highlight2,
                  tabHeader: row.headline
                }">
                {{ datapoint }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="text-left">Source: {{ dataSource.eng }}</p>
    </div>
  </div>
</div>
