import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-figure-graph',
  templateUrl: './figure-graph.component.html',
  styleUrls: ['./figure-graph.component.scss']
})
export class FigureGraphComponent implements OnInit,AfterViewInit {

  @ViewChild ('chartWrapperFigureGraph') chartWrapper: ElementRef;

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: { headline: string, type: string, content: string, height: number, width: number, mobile: boolean };
  @Input() decimals = 0;
  @Input() lang = 'eng';

  chart: { data: any, layout: any, config: any, useResizeHandler, style: any }

  constructor(private tableUtils: Tables, private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
   
  }
  ngAfterViewInit(): void {
    this.prepareData();
  }

  ngOnChanges(): void {
    // if(this.chartWrapper){
    //   this.prepareData();
    // }
  }

  setChartDefaults():{ data: any, layout: any, config: any, useResizeHandler, style: any } {
    return {
      data: [], layout: {
        autosize: true,
        showlegend: false,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {
          tickangle: 0,
          showgrid: false,
          zeroline: false,
          type: 'category',
          dividercolor: 'rgba(0,0,0,0)',
          tickfont: {
            size: 16,
            color: '#e2e8f0'
          },
          fixedrange: true, 
        },
        yaxis: {
          showgrid: false,
          visible: true,
          zeroline: false,
          tickfont: {
            size: 16,
            color: '#e2e8f0'
          },
          fixedrange: true, 
          // tickformat: 'd'
        },
        margin: {
          autoexpand: true,
          t: 15,
          b: 30,
          l: 45,
          r: 10
        }
      },
      config: {
        displayModeBar: false,
        // responsive: true
      },
      useResizeHandler: true,
      style: {height:'100%', width: '100%'} 
    };
  }

  prepareData() {
    let chart = this.setChartDefaults();
    if (this.mapping && this.companyData && this.companyData.base) {
      for (let key of this.config.content) {
        if (this.mapping[key]) {
          let mappingEntry = this.mapping[key];
          this.config.headline = mappingEntry[this.lang];
          let entry;
          if (mappingEntry.type === 'TIMESERIES') {
            entry = { x: this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, 1, 3, 'date', this.lang, this.decimals), y: this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, 1, 3, 'value', this.lang, this.decimals) };
          }

          if (entry) {
            // if (!mappingEntry.unit) {
            //   entry.valueModifier = this.companyData?.base?.curr + 'm';
            // } else {
            //   entry.valueModifier = '';
            // }

            if(mappingEntry.unit){
              if(mappingEntry.unit === 'PERCENT'){
                chart.layout.yaxis.ticksuffix  = '%';
              }
            }

            if(mappingEntry.format){
              if(mappingEntry.format === '2-decimals'){
                chart.layout.yaxis.tickformat = '.2f';
              }
            }

            let trace = {
              x: entry.x,//[entry.x, entry.y.map(y => y + ' ' + entry.valueModifier)],
              y: entry.y.map(y => { let matches = y.match(/-?\d+(.\d+)?%?/g); if (matches) { return matches[0] } else { return 'n/a' } }),
              type: 'scatter'
            }

            chart.data = [trace];
          }
        }
      }
      chart.style.height = this.chartWrapper?this.chartWrapper.nativeElement.offsetHeight + 'px':'100%';
      this.chart = chart;
      this.cdr.detectChanges();

    }
  }

}
