import { parseISO } from "date-fns";
import { researchHub_URL } from "src/app/app-settings/api-urls"
import { format, formatInTimeZone } from 'date-fns-tz'


export function formatDate(date, mode) {
  if (mode === "toUrl") {

    return formatDateToString(date)
  }
  if (mode === "toValid" || mode === "toBERequest") {
    return parseDate(date)
  }
}

export function formatBloombergTicker(BLTicker, mode) {
  if (mode === "toUrl") {
    return BLTicker.replace(/:/g, '-')
  }
  if (mode === "toValid") {
    return BLTicker.replace(/-/g, ':')
  }
}


export function createEventRegistrationLink(blTicker, date) {
  let formattedDate = formatDate(date, "toUrl")
  let formattedTicker = formatBloombergTicker(blTicker, "toUrl")
  return researchHub_URL + "/events/registration/" + formattedDate + "/" + formattedTicker
}

function parseDate(str) {
  let tmp = str.split("-")
  const date = new Date(tmp[0], Number(tmp[1]) - 1, tmp[2], tmp[3], tmp[4], 0);
  //console.log(date)
  const pattern = 'EEE MMM dd yyyy HH:mm:ss \'GMT\'XXX'
  const formattedDate = format(date, pattern, { timeZone: 'Europe/Berlin' });
  return formattedDate;
}

function formatDateToString(date) {
  const tmpDate = new Date(date)
  const formattedDate = formatInTimeZone(tmpDate, "Europe/Berlin", 'yyyy-MM-dd-HH-mm');
  return formattedDate;
}

export function timeZoneFormat(datestring: string): string {
  if(!datestring) return null;
  const regex = /GMT([+-]\d{2}:?\d{2})/;
  const match = datestring.match(regex);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}
