<div class="container-fluid">
  <div class="row py-4">
    <div class="col-lg-2 col-md-0 bg-bluearea subscribe-banner pr-0"></div>
    <div class="col-lg-8 col-md-12 bg-bluearea subscribe-banner px-0">
      <div class="p-5">
        <h2 class="py-3 d-none d-md-block">Join <span class="blueText">1,700+</span> professional investors</h2>
        <h4 class="py-3 d-block d-md-none">Join <span class="blueText">1,700+</span> professional investors</h4>
        <p class="bigger">Subscribe now. Our research on German and selected European stocks is MIFID&nbsp;II compliant
          and delivered to your inbox
          free
          of
          charge.</p>
        <div class="button-group d-flex flex-wrap">
          <input type="text" placeholder="Your EMail address" style="min-width:0;" class="bigger w-xs-100 w-sm-65"
            [(ngModel)]="emailInput" />
          <button class="ml-0 mt-2 mt-sm-0 ml-sm-3 btn btn-std uppercase bigger"
            (click)="openSubscription()">subscribe</button>
        </div>
        <div class="mt-3">
          <span class="bigger text-muted pb-5">Cancel anytime.</span>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-0 bg-bluearea subscribe-banner pl-0"></div>
  </div>
</div>
