import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tables } from 'src/app/utilities/tables.util';

@Component({
  selector: 'app-table-change',
  templateUrl: './table-change.component.html',
  styleUrls: ['./table-change.component.scss']
})
export class TableChangeComponent implements OnInit {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: {headline: string, type: string, content: string[], height: number, width: number, mobile: boolean, decimals: number};
  @Input() lang = 'eng';
  @Input() valueTable = false;

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  data: [{title: string, value: string[]}];
  dates: string[];
  index: number = 0;

  constructor(private tableUtils: Tables) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{

    this.prepareData();
  }

  prepareData() {
    this.data = null;
    let numFigures = 8;
    if(this.mapping && this.companyData  && this.companyData.base){
      for(let key of this.config.content){

        if(this.mapping[key]){
          let mappingEntry = this.mapping[key];
          if(mappingEntry.type === 'VALUE'){
            let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.config.decimals)};

            if(this.data){
              this.data.push(entry);
            }else{
              this.data = [entry];
            }
          } else if(mappingEntry.type === 'TIMESERIES'){
            let quarterlyPattern = /_q$/;
            let jump = 4; //number of timesteps between shown data
            //crap hack
            //uses halfyearly data if quarterly isnt there
            if(quarterlyPattern.test(key)
              && (this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0] === 'na' || this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0].includes('WERT'))
              && (this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[1] === 'na' || this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0].includes('WERT'))
              ) {
              if(this.tableUtils.getTimeseries(key.replace('_q','_h'), this.companyData, this.mapping[key.replace('_q','_h')], -1, numFigures, 'value', this.lang, this.config.decimals)[0] !== 'na'){
                key = key.replace('_q','_h');
                mappingEntry = this.mapping[key.replace('_q','_h')];
                this.tileEvent.emit({ type: 'half-year'})
                jump = 2;
              }
            }

            if(!this.dates){
              this.dates = this.reduceAndCalc('dates',this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'date', this.lang, this.config.decimals),jump);
              this.dates = this.dates.filter(item => item.trim() !== "")
            }

            let entry = {title: mappingEntry[this.lang], value: this.reduceAndCalc(key, this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals),jump)};

            if(this.data){
              this.data.push(entry);
            }else{
              this.data = [entry];
            }
          }
        }

      }
    }
  }

  reduceAndCalc(key: string, data: Array<any>, jump: number): Array<any>{
    let res = [];

    res.push(data[data.length-jump-1]);
    res.push(data[data.length-1]);

    if(!(key.includes('date') || key.includes('_margin') || parseFloat(res[1].replace(',','')) < 0 || parseFloat(res[0].replace(',','')) <= 0 || data[data.length-jump-1].includes('na') || data[data.length-1].includes('na'))){
      res.push(Math.round((parseFloat(res[1].replace(',',''))-parseFloat(res[0].replace(',','')))/parseFloat(res[0].replace(',',''))*1000)/10 + '%')
    }else{
      res.push('   ')
    }

    return res;
  }
}
