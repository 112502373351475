import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
@Component({
  selector: 'app-block-dcf-model',
  templateUrl: './block-dcf-model.component.html',
  styleUrls: ['./block-dcf-model.component.scss']
})
export class BlockDcfModelComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: boolean;
  @Input() blockType: string;
  @Input() deleteEmitter: any;
  @Input() pageType: any;
  @Input() metaCompanyData: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;

  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()

  constructor(
  ) { }
  ngOnInit(): void {

  }

}
