import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SigninComponent } from 'src/app/auth/signin/signin.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {

  @Input() isCookiesAccepted: boolean = false;
  justClickedStartEditor = false;
  currentCompany = '';
  currentPageType = '';
  currentUserName = ""

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private cookieService: CookieService,
  ) {
    this.currentUserName = this.authService.getCurrentUserName()
  }
  public ngOnInit(): void {
    this.isCookiesAccepted = this.cookieService.checkCookiePermission();
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  openLogin() {
    const modalRef = this.modalService.open(SigninComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.status = this.isAuthenticated();
  }

  public revokeCookieConsent(): void {
    this.cookieService.clearCookiePermission();
  }

}
