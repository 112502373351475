<!-- <div [id]="'#'+headline" class="fingerFix border-top border-light-blue py-2 full-width collapseBlockHeadline" (click)="expanded = !expanded">
  <i class="fa fa-chevron-up px-2" *ngIf="expanded"></i>
  <i class="fa fa-chevron-down px-2" *ngIf="!expanded"></i>
  {{headline}}
</div> -->
<div class="table-wrapper col-12 py-0 my-0 customFont">
 <!-- <div class="table-wrapper container py-0 my-0 customFont" [ngClass]="{'is-hidden': !expanded}"> -->
  <div class="row py-0 my-0">
    <div class="table-responsive mx-auto mt-2">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab *ngFor="let tab of tableLayout">
          <ng-template ngbTabTitle>
            <small class="colorBlack" >
              {{tab.name[tab.settings.language]}}
            </small>
          </ng-template>
          <ng-template ngbTabContent>
            <div>
              <table class="table table-sm table-hover">
                <tbody>
                  <tr *ngFor="let row of tab.data">
                    <th class="legend" [attr.colspan]="row.colspan" [ngClass]="{
                      'rowHighlight': row.highlight,
                      'rowHighlight2': row.highlight2,
                      'tabHeader': row.headline
                    }">
                      {{ row.key }}
                    </th>
                    <td *ngFor="let datapoint of row.data" [ngClass]="{
                      'rowHighlight': row.highlight,
                      'rowHighlight2': row.highlight2,
                      'tabHeader': row.headline
                    }">
                      {{ datapoint }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="tab.settings.commonSize" class="mb-4">
              <div [id]="'#'+headline" class="fingerFix border-top border-light-blue py-2 full-width collapseBlockHeadline" (click)="expanded = !expanded">
                <i class="fa fa-chevron-up px-2" *ngIf="expanded"></i>
                <i class="fa fa-chevron-down px-2" *ngIf="!expanded"></i>
                <b>Common Size</b>
              </div>
              <!-- <table class="table table-sm table-hover"> -->
              <table class="table table-sm table-hover" [ngClass]="{'is-hidden': !expanded}">
                <tbody>
                  <tr *ngFor="let row of tab.data">
                    <th [ngClass]="{
                      'rowHighlight': row.highlight,
                      'rowHighlight2': row.highlight2,
                      'tabHeader': row.headline
                    }">
                      {{ row.commonSizeKey }}
                    </th>

                    <td *ngFor="let datapoint of row.commonSizeData" [ngClass]="{
                      'rowHighlight': row.highlight,
                      'rowHighlight2': row.highlight2,
                      'tabHeader': row.headline
                    }">
                      {{ datapoint }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </ng-template>
        </ngb-tab>
      </ngb-tabset>


      <p>Source: {{ dataSource.eng }}</p>
    </div>
  </div>
</div>