<div [ngClass]="loadingMessage?'text-center':''">
  <svg xmlns="http://www.w3.org/2000/svg" class="equilizer" viewBox="0 0 128 128">
    <g>
      <rect class="bar" transform="translate(0,25)" y="25"></rect>
      <rect class="bar" transform="translate(25,25)" y="25"></rect>
      <rect class="bar" transform="translate(50,25)" y="25"></rect>
    </g>
  </svg><br>
</div>
<div class="h1 text-white message-backround rounded p-2" *ngIf="loadingMessage">
  {{loadingMessage}}
</div>
