import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alsterAPI_URL } from 'src/app/app-settings/api-urls';

@Injectable({
  providedIn: 'root'
})
export class EventService {

constructor(
    private httpClient: HttpClient,
){}

  getEventsWithSIBContext(currentUsersCompanyBloombergticker){
    const url = alsterAPI_URL+'events/people/'+currentUsersCompanyBloombergticker;

    return this.httpClient.get(url);
    
  }
  getSingleEventWithSIBContext(listID){
    const url = alsterAPI_URL+'events/event/'+listID;
    return this.httpClient.get(url);
  }

  getEvent(date,ticker){
    const body = {
      ticker: ticker,
      date:date
    }
    const url = alsterAPI_URL+'events/forregistration'
    return this.httpClient.post(url,body);
  }

  registerForEvent(email,listId){
    const body = {
      email: email,
      listId:listId
    }
    const url = alsterAPI_URL+'sib/events/addcontact'
    return this.httpClient.post(url,body);
  }



}
