import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInfoService } from 'src/app/services/company-info.service';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit {

  name: string;
  id: string;
  err: string = '';

  constructor(public activeModal: NgbActiveModal, private companyInfoService: CompanyInfoService) { }

  ngOnInit(): void {
  }

  createCompany() {
    if (this.name && this.id) {
      this.companyInfoService.createNewCompany(this.name, this.id).then((res) => {
        this.activeModal.close()
        window.location.reload()
      }, (err) => {
        this.err = err.error.msg
        console.log(err)
      })
    } else {
      this.err = 'Please provide name and ID'
    }
  }

}
