import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { translateProgrammingString } from 'src/app/utilities/namingTranslation.util';

@Component({
  selector: 'app-delete-warning',
  templateUrl: './delete-warning.component.html',
  styleUrls: ['./delete-warning.component.scss']
})
export class DeleteWarningComponent implements OnInit {

  @Input() companyName = "Company/missing"
  @Input() pageType = "Unterseite/missing"
  @Input() blocks = []

  constructor(    public activeModal: NgbActiveModal
    ) { }

  ngOnInit(): void {
  }

  translate(s: string): string{
    return translateProgrammingString(s);
  }

}
